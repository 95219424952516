import React from 'react';
import { AutocompleteBase } from 'components';

const AutocompleteAssets = ({ setter, settings = null, selected = null }) => {
  settings = settings ?? {};
  settings['@resourceType'] = 'digitalAsset';
  settings.multiple = false;

  return (
    <AutocompleteBase setter={setter} settings={settings} selected={selected} />
  );
};

export default AutocompleteAssets;
