import { action } from 'utils';
import appConfig from 'config/appConfig.json';

export const getLocalUser = (strict = true) => {
  const storedUser = localStorage.getItem(appConfig.keys.session.user);
  if (storedUser) {
    const jsonUser = JSON.parse(storedUser);
    if (jsonUser) {
      return strict ? jsonUser.user : jsonUser;
    }
  }

  return null;
};

export const getToken = () => {
  const jsonUser = getLocalUser(false);
  if (null !== jsonUser && jsonUser.tokens) {
    return getOrRefreshToken(
      jsonUser.tokens.accessToken ?? null,
      jsonUser.tokens.refreshToken ?? null,
      jsonUser.tokens.expire ?? null
    );
  }

  return null;
};

const getOrRefreshToken = (
  accessToken: string = null,
  refreshToken: string = null,
  expire: string = null
) => {
  if (null === accessToken || '' === accessToken) return false;
  if (null === expire) return false;

  const now = Math.floor(Date.now() / 1000);

  if (Number(expire) > now) return accessToken;
  if (null !== refreshToken) return requestToken(refreshToken);

  return null;
};

const requestToken = (refreshToken: string) => {
  action({
    resourceType: 'authorization',
    action: 'refreshToken',
    data: { token: refreshToken },
    error: {
      callback: () => {
        localStorage.removeItem(appConfig.keys.session.user);
        window.location.reload();
      }
    },
    success: {
      callback: (res) => {
        const storedUser = localStorage.getItem(appConfig.keys.session.user);
        if (storedUser) {
          const jsonUser = JSON.parse(storedUser);
          if (jsonUser && jsonUser.user && jsonUser.tokens) {
            const jwt = require('jsonwebtoken');
            const payload = jwt.decode(res.data.accessToken.accessToken);
            if (payload && payload.exp) {
              jsonUser.tokens = {
                accessToken: res.data.accessToken.accessToken,
                refreshToken: res.data.accessToken.refreshToken,
                expire: payload.exp
              };

              localStorage.setItem(
                appConfig.keys.session.user,
                JSON.stringify(jsonUser)
              );
            }
          }
        }
      }
    },
    auth: false
  }).then();

  return null;
};

export const isSuperAdmin = (roles = null) => {
  if (null === roles) {
    const jsonUser = getLocalUser();
    if (
      null !== jsonUser &&
      jsonUser.memberships &&
      jsonUser.memberships.global
    ) {
      roles = jsonUser.memberships.global;
    } else {
      return false;
    }
  }

  return (
    roles.filter((r) => r.role === appConfig.roles.superAdmin.name).length > 0
  );
};

export const getRoleName = ({ user, tenantUuid }) => {
  if (isSuperAdmin()) {
    return appConfig.roles.superAdmin.name;
  }

  const tenant = user.memberships.tenant.filter(
    (t) => t.tenant['@uuid'] === tenantUuid
  );
  if (tenant.length > 0) {
    return tenant[0].role.name;
  }

  return null;
};

export const can = ({
  actionType,
  resourceIri = null,
  userIri = null,
  tenantIri = null,
  callback = null,
  aOptions = {},
  aSync = false
}) => {
  // Bypass all checks if superadmin.
  if (isSuperAdmin()) {
    if (null !== callback) {
      callback(true);
    }

    return true;
  }

  // Bypass checks if admin or webmaster.
  const lastSlashIndex = tenantIri.lastIndexOf('/');
  const tenantUuid = tenantIri.substring(lastSlashIndex + 1);
  const user = getLocalUser();
  if (isWebmaster({user, tenantUuid}) || isAdmin({user, tenantUuid})) {
    if (null !== callback) {
      callback(true);
    }

    return true;
  }

  // Call API to ensure permission then.
  // @TODO this not works ATM due to Promises...
  let checkParams = {
    resourceType: 'security',
    action: 'checkPermission',
    data: {
      action: actionType,
      iri: resourceIri,
      user: userIri,
      tenant: tenantIri
    },
    redirectOnError: false,
    notif: false,
    aOptions,
    aSync
  };

  if (false === aSync && null !== callback) {
    checkParams['success'] = {
      callback: (res) => {
        if (null !== callback) {
          callback(res.data.result ?? false);
        }
      }
    };
  }

  return action(checkParams);
};

export const hasRole = ({ roleRequired, user, tenantUuid }) => {
  if (isSuperAdmin()) {
    return true;
  }

  let userRole = appConfig.roles.anonymous.name;
  let userRoleWeight = appConfig.roles.anonymous.weight;

  if (null === tenantUuid) {
    tenantUuid = user.currentTenant
      ? user.currentTenant['@uuid'] ?? null
      : null;
  }

  if (null !== tenantUuid) {
    const tenant = user.memberships.tenant.filter(
      (t) => t.tenant['@uuid'] === tenantUuid
    );
    if (tenant.length > 0) {
      userRole = tenant[0].role.name;
    } else {
      return false;
    }
  } else {
    return false;
  }

  switch (userRole) {
    case appConfig.roles.admin.name:
      userRoleWeight = appConfig.roles.admin.weight;
      break;
    case appConfig.roles.webmaster.name:
      userRoleWeight = appConfig.roles.webmaster.weight;
      break;
    case appConfig.roles.member.name:
      userRoleWeight = appConfig.roles.member.weight;
      break;
    case appConfig.roles.customer.name:
      userRoleWeight = appConfig.roles.customer.weight;
      break;
    default:
      userRoleWeight = appConfig.roles.anonymous.weight;
      break;
  }

  return appConfig.roles[roleRequired].weight <= userRoleWeight;
};

export const isAdmin = ({ user, tenantUuid }) => {
  return hasRole({
    roleRequired: appConfig.roles.admin.label,
    user: user,
    tenantUuid: tenantUuid
  });
};

export const isWebmaster = ({ user, tenantUuid }) => {
  return hasRole({
    roleRequired: appConfig.roles.webmaster.label,
    user: user,
    tenantUuid: tenantUuid
  });
};

export const isCustomer = ({ user, tenantUuid }) => {
  return hasRole({
    roleRequired: appConfig.roles.customer.label,
    user: user,
    tenantUuid: tenantUuid
  });
};

export const getUpperRoles = (name) => {
  let roles = getRoles();
  let role = roles.filter((r) => r.name === name);

  if (0 === role.length) return [];

  return roles.filter((r) => r.weight > role[0].weight);
};

export const getRoles = () => {
  return [
    appConfig.roles.superAdmin,
    appConfig.roles.admin,
    appConfig.roles.webmaster,
    appConfig.roles.commercial,
    appConfig.roles.member,
    appConfig.roles.customer,
    appConfig.roles.anonymous
  ];
};

export const isHigher = ({ roleAname, roleBname }) => {
  let roles = getRoles();
  let roleA = roles.filter((r) => r.name === roleAname);
  let roleB = roles.filter((r) => r.name === roleBname);

  if (0 === roleA.length || 0 === roleB.length) return false;

  return roleA[0].weight > roleB[0].weight;
};
