import React from 'react';
import { useTranslation } from 'react-i18next';
import { ClimbingBoxLoader, PacmanLoader } from 'react-spinners';

const Loader = ({ type = null, color = '#3c44b1', optClasses = '' }) => {
  const { t } = useTranslation();

  return (
    <div
      className={`d-flex align-items-center flex-column justify-content-center text-center py-3 ${optClasses}`}>
      <div className="d-flex align-items-center flex-column px-4">
        {type === 'pacman' && <PacmanLoader color={color} loading={true} />}
        {type === null && <ClimbingBoxLoader color={color} loading={true} />}
      </div>
      <div className="text-muted font-size-xl text-center pt-5">
        {t('loadingMessage')}
      </div>
    </div>
  );
};

export default Loader;
