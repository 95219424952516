import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const PageTitleMenu = ({ activeMenu, links }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <ul className={'nav justify-content-end'}>
      {(links ?? [])
        .sort((a, b) => ((a.index ?? 0) > (b.index ?? 0) ? 1 : -1))
        .map((link, index) => (
          <li key={link.label} className={'nav-item'}>
            <button
              className={clsx(
                'nav-link btn-link btn d-block',
                {
                  active:
                    activeMenu === link.label ||
                    (null === activeMenu && 1 === index)
                },
                { disabled: !(link.display ?? true) }
              )}
              onClick={() =>
                history.push(link.display ?? true ? link.link : '#')
              }>
              {t(link.label)}
            </button>
          </li>
        ))}
    </ul>
  );
};

export default PageTitleMenu;
