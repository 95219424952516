import axios from 'axios';
import { nError, nSuccess, getToken } from 'utils';
import apiConfig from 'config/apiConfig.json';
import i18n from 'config/i18nConfig';

const baseUrl = process.env.REACT_APP_API_URL;
const debugMode = process.env.REACT_APP_DEBUG_MODE || '0';

export const getSource = () => {
  return axios.CancelToken.source();
};

const headers = ({
  auth = true,
  operation = null,
  additionalHeaders = null
}) => {
  const headers = {};
  const token = auth ? getToken() : null;
  const contentTypes = {
    patch: 'application/merge-patch+json',
    post: 'application/json',
    // multipart: 'multipart/form-data',
    default: 'application/ld+json'
  };

  if (null !== token) {
    headers['Authorization'] = 'Bearer ' + token;
  }

  if (
    'multipart' !== operation &&
    'patch' !== operation &&
    'post' !== operation
  ) {
    // We are receiving something.
    headers['Accept'] = contentTypes['default'];
  } else {
    // We are sending something.
    headers['Content-Type'] = contentTypes[operation ?? 'default'];
  }

  if (null !== additionalHeaders) {
    Object.keys(additionalHeaders).forEach((key) => {
      if (
        null !== additionalHeaders[key] &&
        undefined !== additionalHeaders[key] &&
        '' !== additionalHeaders[key]
      ) {
        headers[key] = additionalHeaders[key];
      }
    });
  }

  return headers;
};

const ApiClient = (auth = true, additionalHeaders = null) => {
  return axios.create({
    baseURL: baseUrl,
    headers: headers({ auth, additionalHeaders })
  });
};
export default ApiClient;

export const ApiPatchClient = () => {
  return axios.create({
    baseURL: baseUrl,
    headers: headers({ operation: 'patch' })
  });
};

export const ApiClientJsonContent = ({
  auth = true,
  additionalHeaders = null
}) => {
  return axios.create({
    baseURL: baseUrl,
    headers: headers({ auth, operation: 'post', additionalHeaders })
  });
};

export const ApiClientMultipart = ({
  auth = true,
  progress = null,
  additionalHeaders = null
}) => {
  return progress
    ? axios.create({
        baseURL: baseUrl,
        headers: headers({ auth, operation: 'multipart', additionalHeaders }),
        onUploadProgress: progress
      })
    : axios.create({
        baseURL: baseUrl,
        headers: headers({ auth, operation: 'multipart', additionalHeaders })
      });
};

export const _then = ({ res, success, notif }) => {
  if (success && success.callback) {
    success.callback(res);
  }

  // Display notification.
  if (true === notif && success && success.message) {
    nSuccess({
      message: success && success.message ? success.message : 'success'
    });
  }
};

export const _catch = ({
  url,
  clientError,
  error,
  notif,
  redirectOnError = true
}) => {
  // Stop early if request just cancelled.
  if (axios.isCancel(clientError)) {
    console.log('Request canceled : ' + url);
    return;
  }

  if (redirectOnError && '0' === debugMode) {
    // Redirect on error page if debugMode inactive.
    const statusCode =
      clientError && clientError.response
        ? mapStatusCode(clientError.response.status)
        : 500;

    if (window.location.pathname.lastIndexOf('/error/', 0) !== 0)
      window.location.replace(
        '/error/' +
          statusCode +
          '/' +
          (clientError && clientError.response
            ? clientError.response.status
            : 500)
      );
  }

  // Display notification.
  if (
    true === notif ||
    '1' === debugMode ||
    (error && error.forceNotif && true === error.forceNotif)
  ) {
    let errorMessage = error && error.message ? error.message : null;

    if (null === errorMessage) {
      if (clientError && clientError.response && clientError.response.data) {
        if (clientError.response.data.error_code) {
          errorMessage = i18n.t(clientError.response.data.error_code);
        } else {
          if (clientError.response.data.error) {
            errorMessage = clientError.response.data.error;
          } else {
            if (clientError.response.data['hydra:description']) {
              errorMessage = clientError.response.data['hydra:description'];
            }
          }
        }
      }
    }

    if (null === errorMessage) {
      nError({});
    } else {
      nError({ message: errorMessage });
    }
  }

  // Trigger callback if any.
  if (error && error.callback) {
    error.callback(clientError);
  }
};

const mapStatusCode = (code) => {
  let parentCode = 500;

  switch (code) {
    case 403:
    case 401:
    case 511:
      // Unauthorized.
      parentCode = 403;
      break;

    case 404:
      // Not Found.
      parentCode = 404;
      break;

    default:
      break;
  }

  // Server error.
  return parentCode;
};

export const _finally = (always) => {
  if (always && always.callback) {
    always.callback();
  }
};

export const post = async ({
  url,
  params = null,
  headers = null,
  data = null,
  setter = null,
  error = null,
  success = null,
  always = null,
  notif = apiConfig.default.notif,
  auth = true,
  redirectOnError = true,
  paramType = 'jsonContent',
  aOptions = {},
  aSync = false
}) => {
  if ('jsonContent' === paramType) {
    // Build params.
    params?.map((param) => {
      return url.searchParams.append(param.label, param.value);
    });
    if (false === aSync) {
      try {
        JSON.stringify(data);
      } catch (e) {
        return;
      }

      ApiClientJsonContent({ auth, additionalHeaders: headers })
        .post(url, JSON.stringify(data), aOptions)
        .then((res) => {
          _then({ res, success, notif });
          if (null !== setter) {
            setter(res.data);
          }
        })
        .catch((clientError) => {
          _catch({ url, clientError, error, notif, redirectOnError });
        })
        .finally(() => {
          _finally(always);
        });
    } else {
      try {
        return await ApiClientJsonContent({
          auth,
          additionalHeaders: headers
        }).post(url, JSON.stringify(data), aOptions);
      } catch (clientError) {
        _catch({ url, clientError, error, notif, redirectOnError });
        return null;
      }
    }
  } else if ('postParams' === paramType) {
    if (false === aSync) {
      ApiClientMultipart({ auth, additionalHeaders: headers })
        .post(url, data, aOptions)
        .then((res) => {
          _then({ res, success, notif });
          if (null !== setter) {
            setter(res.data);
          }
        })
        .catch((clientError) => {
          _catch({ url, clientError, error, notif, redirectOnError });
        })
        .finally(() => {
          _finally(always);
        });
    } else {
      try {
        return await ApiClientMultipart({
          auth,
          additionalHeaders: headers
        }).post(url, data, aOptions);
      } catch (clientError) {
        _catch({ url, clientError, error, notif, redirectOnError });
        return null;
      }
    }
  }

  return null;
};

export const put = ({
  url,
  params = null,
  data = null,
  setter = null,
  error = null,
  success = null,
  always = null,
  notif = apiConfig.default.notif,
  auth = true,
  redirectOnError = true,
  aOptions = {}
}) => {
  // Build params.
  params?.map((param) => {
    return url.searchParams.append(param.label, param.value);
  });

  ApiClientJsonContent({ auth })
    .put(url, JSON.stringify(data), aOptions)
    .then((res) => {
      _then({ res, success, notif });
      if (null !== setter) {
        setter(res.data);
      }
    })
    .catch((clientError) => {
      _catch({ url, clientError, error, notif, redirectOnError });
    })
    .finally(() => {
      _finally(always);
    });
};

export const get = async ({
  url,
  params = null,
  headers = null,
  setter = null,
  error = null,
  success = null,
  always = null,
  notif = apiConfig.default.notif,
  auth = true,
  redirectOnError = true,
  aOptions = {},
  aSync = false
}) => {
  // Build params.
  params?.map((param) => {
    return url.searchParams.append(param.label, param.value);
  });

  if (false === aSync) {
    ApiClient(auth, headers)
      .get(url, aOptions)
      .then((res) => {
        _then({ res, success, notif });
        if (null !== setter) {
          setter(res.data);
        }
      })
      .catch((clientError) => {
        _catch({ url, clientError, error, notif, redirectOnError });
      })
      .finally(() => {
        _finally(always);
      });
  } else {
    try {
      return await ApiClient(auth, headers).get(url, aOptions);
    } catch (clientError) {
      _catch({ url, clientError, error, notif, redirectOnError });
      return null;
    }
  }
};

export const patch = ({
  uri,
  data,
  error = null,
  success = null,
  always = null,
  notif = apiConfig.default.notif,
  redirectOnError = false,
  aOptions = {}
}) => {
  ApiPatchClient()
    .patch(uri, JSON.stringify(data), aOptions)
    .then((res) => {
      _then({ res, success, notif });
    })
    .catch((clientError) => {
      _catch({ url: uri, clientError, error, notif, redirectOnError });
    })
    .finally(() => {
      _finally(always);
    });
};

export const guessFromResourceType = (resourceType, key = 'uri') => {
  if (
    !apiConfig.resources[resourceType] ||
    null === apiConfig.resources[resourceType][key]
  )
    return null;

  return apiConfig.resources[resourceType][key];
};

export const guessUriFromAction = (resourceType, id, action) => {
  const uri = guessFromResourceType(resourceType, 'uri');
  if (null === uri) return null;

  if (
    !apiConfig.resources[resourceType].actions ||
    null === apiConfig.resources[resourceType].actions[action]
  )
    return null;

  return null === id
    ? uri + '/' + apiConfig.resources[resourceType].actions[action]
    : uri + '/' + id + '/' + apiConfig.resources[resourceType].actions[action];
};

// CRUD - CREATE
export const create = ({
  resourceType,
  data = null,
  progress = null,
  setter = null,
  error = null,
  success = null,
  always = null,
  notif = false,
  redirectOnError = false,
  aOptions = {}
}) => {
  const uri = guessFromResourceType(resourceType, 'uri');

  if (null !== uri) {
    const mParams =
      progress && progress.callback ? { progress: progress.callback } : {};
    ApiClientMultipart(mParams)
      .post(uri, data, aOptions)
      .then((res) => {
        _then({ res, success, notif });
        if (null !== setter) {
          setter(res.data);
        }
      })
      .catch((clientError) => {
        _catch({ url: uri, clientError, error, notif, redirectOnError });
      })
      .finally(() => {
        _finally(always);
      });
  }
};

// CRUD - READ
export const read = ({
  resourceType,
  id,
  setter = null,
  error = null,
  success = null,
  always = null,
  notif = apiConfig.default.notif,
  redirectOnError = true,
  aOptions = {},
  headers = null,
  aSync = false
}) => {
  const uri = guessFromResourceType(resourceType, 'uri');

  if (null !== uri) {
    return get({
      url: new URL(baseUrl + '/' + uri + '/' + id),
      setter,
      error,
      success,
      always,
      notif,
      redirectOnError,
      aOptions,
      headers,
      aSync
    });
  }

  return null;
};

export const list = ({
  resourceType,
  params = null,
  setter = null,
  error = null,
  success = null,
  always = null,
  notif = apiConfig.default.notif,
  redirectOnError = true,
  autocomplete = false,
  aOptions = {},
  headers = null,
  aSync = false
}) => {
  const uri =
    guessFromResourceType(resourceType, 'uri') +
    (true === autocomplete ? '/autocomplete' : '');

  if (null !== uri) {
    return get({
      url: new URL(baseUrl + '/' + uri),
      params,
      setter,
      error,
      success,
      always,
      notif,
      redirectOnError,
      aOptions,
      headers,
      aSync
    });
  }

  return null;
};

// CRUD - UPDATE
export const update = ({
  resourceType,
  id,
  data,
  error = null,
  success = null,
  always = null,
  notif = true,
  redirectOnError = false,
  aOptions = {}
}) => {
  const uri = guessFromResourceType(resourceType, 'uri');

  if (null !== uri) {
    patch({
      uri: uri + '/' + id,
      data: data,
      error: error,
      success: success,
      always: always,
      notif: notif,
      redirectOnError: redirectOnError,
      aOptions: aOptions
    });
  }
};

// CRUD - DELETE
export const remove = ({
  resourceType,
  id,
  error = null,
  success = null,
  always = null,
  notif = apiConfig.default.notif,
  redirectOnError = false,
  aOptions = {}
}) => {
  const uri = guessFromResourceType(resourceType, 'uri');

  if (null !== uri) {
    ApiClient()
      .delete(uri + '/' + id, aOptions)
      .then((res) => {
        _then({ res, success, notif });
      })
      .catch((clientError) => {
        _catch({
          url: uri + '/' + id,
          clientError,
          error,
          notif,
          redirectOnError
        });
      })
      .finally(() => {
        _finally(always);
      });
  }
};

// CRUD - OTHER ACTIONS
export const action = ({
  resourceType,
  id = null,
  action,
  params = null,
  data = null,
  setter = null,
  error = null,
  success = null,
  always = null,
  notif = apiConfig.default.notif,
  auth = true,
  redirectOnError = false,
  method = 'post',
  paramType = 'jsonContent',
  aOptions = {},
  headers = null,
  aSync = false
}) => {
  const uri = guessUriFromAction(resourceType, id, action);

  if (null !== uri) {
    switch (method) {
      case 'get':
        return get({
          url: new URL(baseUrl + '/' + uri),
          params,
          setter,
          error,
          success,
          always,
          notif,
          auth,
          redirectOnError,
          aOptions,
          headers,
          aSync
        });

      case 'put':
        put({
          url: new URL(baseUrl + '/' + uri),
          params: params,
          data: data,
          setter: setter,
          error: error,
          success: success,
          always: always,
          notif: notif,
          auth: auth,
          redirectOnError: redirectOnError,
          aOptions: aOptions
        });
        break;

      case 'patch':
        patch({
          uri: new URL(baseUrl + '/' + uri),
          data: data,
          error: error,
          success: success,
          always: always,
          notif: notif,
          redirectOnError: redirectOnError,
          aOptions: aOptions
        });
        break;

      default:
        return post({
          url: new URL(baseUrl + '/' + uri),
          params,
          data,
          setter,
          error,
          success,
          always,
          notif,
          auth,
          redirectOnError,
          paramType,
          aOptions,
          headers,
          aSync
        });
    }
  }

  return null;
};
