import React from 'react';

import { SidebarTenantBoxList } from 'components';
import { useUserStateValues } from 'states/UserState';

const SidebarUserbox = () => {
  const userStateValues = useUserStateValues();
  const tenants = userStateValues.isAuthenticated && userStateValues.user ?
    (userStateValues.user.tenants ?? []) : [];

  return (
    <>
      {tenants.length > 0 && (
        <SidebarTenantBoxList tenants={tenants} />
      )}
    </>
  );
};

export default SidebarUserbox;
