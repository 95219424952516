import React, { useEffect, useState } from 'react';
import { action, getColors, getMediaSrc, getSource, list } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loader, QuotaTypeWrapper } from 'components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import statsConfig from 'config/statsConfig.json';
import defaultAvatarImg from 'assets/images/avatars/defaultAvatar.png';

const ProfileBlock = ({ entity, resourceType = 'user' }) => {
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  const isCancelled = React.useRef(false);

  let subProfileLastThings = {
    resourceType: 'digitalAsset',
    imgKey: 'preview',
    title: t('latestAdditions'),
    link: '/digital-assets/###',
    params: [
      { label: 'page', value: 1 },
      { label: 'itemsPerPage', value: 4 },
      { label: 'order[created]', value: 'desc' }
    ]
  };

  if ('tenant' === resourceType) {
    // Restrict users by current tenant.
    subProfileLastThings.resourceType = 'user';
    subProfileLastThings.title = t('latestConnectedUsers');
    subProfileLastThings.link = '/users/###/profile';
    subProfileLastThings.imgKey = 'avatar';
    subProfileLastThings.params = [
      ...subProfileLastThings.params,
      ...[
        { label: 'memberships.tenant', value: entity['@uuid'] },
        { label: 'order[lastLogin]', value: 'desc' }
      ]
    ];
  }

  if ('user' === resourceType) {
    // Restrict digital assets by current user.
    subProfileLastThings.params.push({
      label: 'upload.user',
      value: entity['@uuid']
    });
  }

  useEffect(() => {
    const source = getSource();

    list({
      resourceType: subProfileLastThings.resourceType,
      params: subProfileLastThings.params,
      success: {
        callback: (res) => {
          if (isCancelled.current) return;

          if (res.data) {
            setItems(res.data);
          }
        }
      },
      aOptions: { cancelToken: source.token }
    }).then();

    return () => {
      isCancelled.current = true;
      source.cancel();
      setItems([]);
    };

    // eslint-disable-next-line
  }, [entity]);

  const entity1Default = {
    type: 'user',
    currentValue: 19,
    maxValue: 25,
    planType: 'premium'
  };
  const [entity1, setEntity1] = useState(entity1Default);

  const entity2Default = {
    type: 'bandwidth',
    currentValue: 147,
    maxValue: 500,
    data: [0, 1, 5, 25]
  };
  const [entity2, setEntity2] = useState(entity2Default);

  const entity3Default = {
    type: 'digitalAsset',
    currentValue: 58,
    maxValue: 100
  };
  const [entity3, setEntity3] = useState(entity3Default);

  const entity4Default = {
    type: 'storage',
    currentValue: 1,
    maxValue: 5
  };
  const [entity4, setEntity4] = useState(entity4Default);

  const now = new Date();
  const start = now.toISOString();
  const end = new Date(
    now.setMonth(now.getMonth() - statsConfig.period)
  ).toISOString();

  useEffect(() => {
    const source = getSource();

    if ('tenant' === resourceType) {
      action({
        action: 'quota',
        resourceType: resourceType,
        id: entity['@uuid'],
        data: {
          start: end,
          end: start,
          interval: statsConfig.interval
        },
        success: {
          callback: (res) => {
            if (isCancelled.current) return;

            setQuotaTypes(res.data);
          }
        },
        aOptions: { cancelToken: source.token }
      }).then();
    }

    return () => {
      isCancelled.current = true;
      source.cancel();
      setQuotaTypes(null);
    };

    // eslint-disable-next-line
  }, [entity]);

  const setQuotaTypes = (quotas) => {
    if (quotas && quotas.current) {
      const quotasCurrent = quotas.current['hydra:member'][0];
      const quotasHistory = quotas.history
        ? quotas.history['hydra:member']
        : [];
      let historyBandwidth = [];
      quotasHistory.map((h) =>
        historyBandwidth.push(h.data.bandwidth.usage / 1000000000)
      );
      setEntity1({
        type: 'user',
        currentValue: quotasCurrent.users.usage,
        maxValue:
          -1 !== quotasCurrent.users.quota ? quotasCurrent.users.quota : -1,
        planType: 'premium'
      });
      setEntity2({
        type: 'bandwidth',
        currentValue: quotasCurrent.bandwidth.usage,
        maxValue:
          -1 !== quotasCurrent.bandwidth.quota
            ? quotasCurrent.bandwidth.quota / 1000000
            : -1,
        data: historyBandwidth
      });
      setEntity3({
        type: 'digitalAsset',
        currentValue: quotasCurrent.assets.usage,
        maxValue:
          -1 !== quotasCurrent.assets.quota ? quotasCurrent.assets.quota : -1
      });
      setEntity4({
        type: 'storage',
        currentValue: Number.parseFloat(
          (quotasCurrent.storage.usage / 1000000000).toFixed(2)
        ),
        maxValue:
          -1 !== quotasCurrent.storage.quota
            ? quotasCurrent.storage.quota / 1000000000
            : -1
      });
    } else {
      setEntity1(entity1Default);
      setEntity2(entity2Default);
      setEntity3(entity3Default);
      setEntity4(entity4Default);
    }
  };

  return (
    <>
      {!entity ? (
        <Loader type={'pacman'} />
      ) : (
        <div className={'bg-midnight-bloom p-3 rounded text-white h-100'}>
          <div className={'d-flex align-items-center'}>
            <div
              className={'d-flex align-items-center avatar-icon-wrapper d-100'}>
              {entity.plan && (
                <span
                  className={
                    'badge badge-circle bg-' + getColors(entity.plan).badge
                  }>
                  {entity.plan.name}
                </span>
              )}
              <div className={'avatar-icon d-100'}>
                <img
                  alt={entity.name}
                  src={getMediaSrc(entity, 'avatar') ?? defaultAvatarImg}
                />
              </div>
            </div>
            <div className={'ps-3'}>
              <span className={'fw-bold font-size-lg d-flex'}>
                {entity.name}
              </span>
              <p className={'mb-4 font-size-md text-white-50'}>
                {entity.nickname}
              </p>
            </div>
          </div>

          <div className={'divider opacity-2 my-4'} />

          <div
            className={'fw-bold font-size-lg d-flex align-items-center mb-3'}>
            <span>{subProfileLastThings.title}</span>
          </div>
          <div className={'py-2'}>
            <div className={'avatar-wrapper-overlap mb-3'}>
              {!items['hydra:member'] ? (
                <Loader type={'pacman'} />
              ) : (
                <>
                  {items['hydra:member'].map((item, index) => (
                    <div className={'avatar-icon-wrapper'} key={'item' + index}>
                      <div className={'avatar-icon d-70 bg-white'}>
                        <Link
                          className={'btn-userbox'}
                          to={subProfileLastThings.link.replace(
                            '###',
                            item['@uuid']
                          )}>
                          <img
                            alt={'preview'}
                            src={getMediaSrc(item, subProfileLastThings.imgKey)}
                          />
                        </Link>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>

          <div className={'divider opacity-3 my-4'} />
          <div className={'row font-size-xs'}>
            <div className={'col'}>
              <div className={'card text-center my-2 p-3'}>
                <div>
                  <FontAwesomeIcon
                    icon={['fas', 'upload']}
                    className={'font-size-xxl text-success'}
                  />
                </div>
                <div className={'mt-2 line-height-sm'}>
                  <b className={'font-size-lg text-black'}>
                    {entity ? entity.statistics.upload ?? 0 : 0}
                  </b>
                  <span className={'text-black-50 d-block'}>
                    {t('upload_plural')}
                  </span>
                </div>
              </div>
            </div>
            <div className={'col'}>
              <div className={'card text-center my-2 p-3'}>
                <div>
                  <FontAwesomeIcon
                    icon={['fas', 'download']}
                    className={'font-size-xxl text-info'}
                  />
                </div>
                <div className={'mt-2 line-height-sm'}>
                  <b className={'font-size-lg text-black'}>
                    {entity ? entity.statistics.download ?? 0 : 0}
                  </b>
                  <span className={'text-black-50 d-block'}>
                    {t('download', { count: 2693 })}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={'divider opacity-3 my-4'} />
          {'tenant' === resourceType && (
            <>
              <div className={'row'}>
                <div className={'col col-md-12'}>
                  <QuotaTypeWrapper
                    entity={entity3}
                    quotaTypeType={'jauge'}
                    optionalClass={'card-box-tierce-rounded-bottom-left'}
                  />
                </div>
              </div>
              <div className={'row'}>
                <div className={'col col-md-6'}>
                  <QuotaTypeWrapper
                    entity={entity1}
                    quotaTypeType={'jaugeBig'}
                    optionalClass={'card-box-tierce-rounded-bottom-right'}
                  />
                </div>
                <div className={'col col-md-6'}>
                  <QuotaTypeWrapper
                    entity={entity4}
                    quotaTypeType={'jaugeBig'}
                    optionalClass={'card-box-tierce-rounded-bottom-left'}
                  />
                </div>
              </div>
              <div className={'row'}>
                <div className={'col col-md-12'}>
                  <QuotaTypeWrapper entity={entity2} quotaTypeType={'chart'} />
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ProfileBlock;
