import React, { Fragment } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Row,
  Col,
  Card,
  Button,
  Progress
} from 'reactstrap';

// DashboardAnalyticsSection2
const ExampleTest = (props) => {
  return (
    <Fragment>
      <Row>
        <Col xl="7">
          <Row>
            <Col lg="6">
              <Card className="card-box p-4 mb-5">
                <div className="d-flex align-items-start">
                  <FontAwesomeIcon
                    icon={['far', 'object-group']}
                    className="display-4"
                  />
                  <div className="flex-grow-1 ps-4">
                    <div className="d-block mb-2 mt-1">
                      <b>5GB</b> used out of <b>25GB</b>
                    </div>
                    <Progress
                      className="progress-animated-alt"
                      color="danger"
                      value="39">
                      39%
                    </Progress>
                  </div>
                </div>
                <small className="d-block mt-3">
                  <span className="d-block pb-3">
                    Get <b>1 GB</b> of free storage if you take advantage of our
                    premium plans.
                  </span>
                  <Button
                    tag="a"
                    href="#/"
                    onClick={e => e.preventDefault()}
                    color="link"
                    className="p-0 fw-bold font-size-xs btn-link-first"
                    title="View details">
                    View details
                  </Button>
                </small>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
}

export default ExampleTest;