import React from 'react';
import { TabPane } from 'reactstrap';
import { DigitalAssetForm } from 'forms';

const DigitalAssetEditTab = ({ digitalAsset, tabId, setActiveTab }) => {
  return (
    <TabPane tabId={tabId}>
      <DigitalAssetForm digitalAsset={digitalAsset} /*setActiveTab={setActiveTab}*/ />
    </TabPane>
  );
};

export default DigitalAssetEditTab;
