import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { BreadcrumbItem } from 'components';

const Breadcrumb = ({ items = [] }) => {
  const { t } = useTranslation();
  const itemsCount = items.length;

  return (
    <ol className={'app-page-title--breadcrumb breadcrumb'}>
      <li key={'home-icon'} className={'breadcrumb-item start'}>
        <Link to="/">
          <FontAwesomeIcon icon={['fas', 'home']} />
        </Link>
      </li>
      <li key={'home-text'} className={'breadcrumb-item'}>
        <Link to="/">{t('home')}</Link>
      </li>
      {items.map((item, i) => (
        <BreadcrumbItem
          key={i}
          title={item.title}
          url={item.to ?? null}
          index={i}
          count={itemsCount}
        />
      ))}
    </ol>
  );
};

export default Breadcrumb;
