import React from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useThemeState, useThemeStateValues } from 'states';
import { useTranslation } from 'react-i18next';
import { getQueryParam } from 'utils';

const HeaderSearch = () => {
  const themeState = useThemeState();
  const themeOptions = useThemeStateValues();
  const { t } = useTranslation();
  const history = useHistory();

  const toggleHeaderSearchHover = () => {
    themeState.headerSearchHover.set(!themeOptions.headerSearchHover);
  };

  const triggerSearch = (event) => {
    if (event.key === 'Enter') {
      history.push('/digital-assets?search_text=' + event.target.value);
    }
  };

  return (
    <>
      <div className={'header-search-wrapper'}>
        <div
          className={clsx('search-wrapper', {
            'is-active': themeOptions.headerSearchHover
          })}>
          <label
            className={'icon-wrapper text-black'}
            htmlFor={'header-search-input'}>
            <FontAwesomeIcon icon={['fas', 'search']} />
          </label>
          <input
            defaultValue={getQueryParam('search_text')}
            onFocus={toggleHeaderSearchHover}
            onBlur={toggleHeaderSearchHover}
            onKeyUp={triggerSearch}
            className={'form-control'}
            id={'header-search-input'}
            name={'header-search-input'}
            placeholder={t('search')}
            type={'search'}
          />
        </div>
      </div>
    </>
  );
}

export default HeaderSearch;
