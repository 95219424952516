import React from 'react';
import { AutocompleteBase } from 'components';

const AutocompleteVariationGroups = ({ setter, settings = null, selected = null }) => {
  settings = settings ?? {} ;
  settings['@resourceType'] = 'variationGroup';
  settings.fullObjects = true;
  settings.multiple = false;
  // Avoid "/variations-groups/autocomplete" endpoint...
  settings.addUriSuffix = false;

  return (
    <AutocompleteBase setter={setter} settings={settings} selected={selected} />
  );
};

export default AutocompleteVariationGroups;
