import React, { useEffect, useState } from 'react';
import { getSource, list } from 'utils';
import { useTranslation } from 'react-i18next';
import { InvoiceList, Placeholder } from 'components/index';

const PaymentHistory = ({ tenant }) => {
  const isCancelled = React.useRef(false);
  const tenantIdentifier = tenant['@uuid'];

  const { t } = useTranslation();

  const [invoiceList, setInvoiceList] = useState(null);

  useEffect(() => {
    const source = getSource();

    // Load tenant invoices.
    if (invoiceList === null) {
      list({
        resourceType: 'invoice',
        params: [{ label: 'tenant', value: tenantIdentifier }],
        success: {
          callback: (res) => {
            if (isCancelled.current) {
              return;
            }

            setInvoiceList(res.data['hydra:member']);
          }
        },
        aOptions: {
          cancelToken: source.token
        }
      }).then();
    }
  });

  return (
    <>
      <div className={'col'}>
        <p className={'m-1 text-dark'}>{t('PaymentHistory')}</p>
        {null !== invoiceList ? (
          <>
            {invoiceList.length === 0 && <p>{t('NoPaymentYet')}.</p>}
            {invoiceList.length > 0 && <InvoiceList items={invoiceList} />}
          </>
        ) : (
          <Placeholder size="big" />
        )}
      </div>
    </>
  );
};

export default PaymentHistory;
