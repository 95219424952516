import React from 'react';
import Moment from 'react-moment';
import { Card, Badge, ListGroup, ListGroupItem, Progress } from 'reactstrap';
import { ClockLoader, DotLoader } from 'react-spinners';
import { alt, getMediaSrc, iri } from 'utils';
import { useTranslation } from 'react-i18next';

const UploadedItemsList = ({ upload, rdm }) => {
  const { t } = useTranslation();

  return (
    <>
      <Card className={'card-box mb-3'}>
        <ListGroup flush>
          <ListGroupItem className={'d-flex justify-content-between bg-light align-items-center'}>
            <div>
              <b>{t('uploadStatus')}&nbsp;</b>
              <small><Moment calendar={true} locale={'fr'}>{upload.created}</Moment></small>

              <div className={'avatar-wrapper-overlap mt-2'}>
                {upload.digitalAssets.map(digitalAsset => (
                  <div key={digitalAsset['@uuid']} className={'avatar-icon-wrapper avatar-icon-sm'}>
                    <div className={'avatar-icon'}>
                      <img alt={alt(digitalAsset, 'digitalAsset', 'preview')} src={getMediaSrc(digitalAsset, 'preview')} />
                    </div>
                  </div>
                ))}
              </div>

            </div>
            <Badge pill color="primary" className={'rounded-pill bg-primary'}>
              {upload.digitalAssets.length}
            </Badge>
          </ListGroupItem>

          {upload.processing.map((digitalAsset, uuid) => (
            <ListGroupItem key={ uuid }>
              <div className={'d-flex align-items-center'}>
                <div className={'d-flex align-items-center justify-content-center me-3'}>
                  <DotLoader color={'var(--first)'} loading={true} />
                </div>
                <div className="w-100">
                  <a
                    href={iri(digitalAsset, 'digitalAsset')}
                    className="fw-bold font-size-lg"
                    title={digitalAsset.name ?? digitalAsset['@uuid']}>
                    {digitalAsset.name ?? digitalAsset['@uuid']}
                  </a>
                  <span className="text-black-50 d-block">
                      {digitalAsset.lastWorkerLog ?? t('digitalAssetWorkflowState0')}
                    </span>
                  <div className="d-flex align-items-center pt-1">
                    <Progress
                      animated
                      className="progress-xs progress-bar-rounded flex-grow-1 progress-animated-alt"
                      color="first"
                      value={(digitalAsset.workerLogsCount ?? 0) * 10}
                    />
                    <div className="fw-bold text-black-50 ps-2">
                      {(digitalAsset.workerLogsCount ?? 0) * 10}%
                    </div>
                  </div>
                </div>
              </div>
            </ListGroupItem>
          ))}

          {upload.queued.map((digitalAsset) => (
            <ListGroupItem key={digitalAsset['@uuid']}>
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center justify-content-center me-3">
                  <ClockLoader color={'var(--warning)'} loading={true} />
                </div>
                <div className="w-100">
                  <a
                    href={iri(digitalAsset, 'digitalAsset')}
                    className="fw-bold font-size-lg"
                    title={digitalAsset.name ?? digitalAsset['@uuid']}>
                    {digitalAsset.name ?? digitalAsset['@uuid']}
                  </a>
                  <span className="text-black-50 d-block">
                      {t('digitalAssetWorkflowState0')}
                    </span>
                  <div className="d-flex align-items-center pt-1">
                    <Progress
                      className="progress-xs progress-bar-rounded flex-grow-1"
                      color="first"
                      value="0"
                    />
                    <div className="fw-bold text-black-50 ps-2">
                      {digitalAsset.positionInQueue}<sup>e</sup>
                    </div>
                  </div>
                </div>
              </div>
            </ListGroupItem>
          ))}

          {upload.validated.length > 0 && (
            <ListGroupItem className="d-flex justify-content-between align-items-center">
              <div className="w-100">
                <h6 className="pt-1 pb-1">
                  <Badge
                    pill
                    color="neutral-success"
                    className="text-success rounded-pill bg-neutral-success">
                    {t('digitalAssetsReady')}
                  </Badge>
                </h6>

                <div className="mt-3 mb-2">
                  {upload.validated.map(digitalAsset => (
                    <div
                      key={digitalAsset['@uuid']}
                      className="d-inline-block me-3 text-center">
                      <a
                        title={digitalAsset.name}
                        href={iri(digitalAsset, 'digitalAsset')}>
                        <img
                          alt={alt(digitalAsset, 'digitalAsset', 'preview')}
                          className="img-fluid rounded shadow-sm"
                          src={getMediaSrc(digitalAsset, 'preview')}
                          width="128" />

                        <span className="d-block mt-2">
                          {digitalAsset.name}
                        </span>
                      </a>
                    </div>
                  ))}
                </div>

              </div>
            </ListGroupItem>
          )}
        </ListGroup>
      </Card>
    </>
  );
}

export default UploadedItemsList;
