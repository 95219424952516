import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import {
  DigitalAsset,
  DigitalAssetCaoData,
  Loader,
  QRCode,
  TopXitemsList,
  TopXitemsListSelect
} from 'components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  FormDisplayOptions,
  FormARSettings,
  FormVisibilitySettings
} from 'forms';
import { getMediaSrc, getSource, iri, list } from 'utils';
import { Badge, Button, Collapse, UncontrolledAlert } from 'reactstrap';

import { isSafari } from 'react-dnd-html5-backend/lib/BrowserDetector';
import appConfig from 'config/appConfig.json';
import defaultImg from 'assets/images/avatars/defaultAvatar.png';

const DigitalAssetMainTab = ({ digitalAsset, cruds }) => {
  const uuid = digitalAsset['@uuid'];
  const [tenantDigitalAssets, setTenantDigitalAssets] = useState(null);
  const [variationGroups, setVariationGroups] = useState([]);
  const [variationGroupAssets, setVariationGroupAssets] = useState([]);
  const [dismiss, setDismiss] = useState(
    isSafari() &&
      null === localStorage.getItem(appConfig.keys.local.browserLimitations)
  );
  const { t } = useTranslation();
  const [uniqueParents, setUniqueParents] = useState([]);

  const isCancelled = React.useRef(false);
  // Let's get variations, variation groups & a few assets from the same tenant.
  useEffect(() => {
    const source = getSource();

    list({
      resourceType: 'variationGroup',
      params: [{ label: 'digitalAsset', value: uuid }],
      success: {
        callback: (res) => {
          if (isCancelled.current) return;

          setVariationGroups(res.data['hydra:member']);
          (res.data['hydra:member'] ?? []).map((vg) =>
            vg.variations.map((v) => variationGroupAssets.push(v.digitalAsset))
          );
        }
      },
      aOptions: {
        cancelToken: source.token
      }
    }).then();

    list({
      resourceType: 'variation',
      params: [{ label: 'digitalAsset', value: uuid }],
      success: {
        callback: (res) => {
          if (isCancelled.current) return;

          const tmpArrayKeys = [];
          const tmpArray = [];
          res.data['hydra:member'].map((v) => {
            if (tmpArrayKeys.indexOf(v.group.digitalAsset['@uuid']) === -1) {
              tmpArray.push(v.group.digitalAsset);
              tmpArrayKeys.push(v.group.digitalAsset['@uuid']);
            }
            return true;
          });
          setUniqueParents(tmpArray);
        }
      },
      aOptions: {
        cancelToken: source.token
      }
    }).then();

    cruds.read.methods.list.params = [
      ...[
        { label: 'tenant', value: digitalAsset.tenant['@uuid'] },
        { label: 'itemsPerPage', value: 4 },
        { label: 'page', value: 1 },
        { label: 'has[variations]', value: false }
      ],
      ...cruds.read.methods.list.params
    ];
    cruds.read.methods.list.action({
      resourceType: 'digitalAsset',
      params: cruds.read.methods.list.params,
      success: {
        callback: (res) => {
          if (isCancelled.current) return;

          setTenantDigitalAssets(res.data);
        }
      },
      aOptions: {
        cancelToken: source.token
      }
    });

    return () => {
      isCancelled.current = true;
      source.cancel();
      setTenantDigitalAssets(null);
      setVariationGroupAssets([]);
      setVariationGroups([]);
      setUniqueParents([]);
      setAccordion([true, false, false]);
    };

    // eslint-disable-next-line
  }, [digitalAsset]);

  const [accordion, setAccordion] = useState([true, false, false]);
  const toggleAccordion = (tabIndex = 0) => {
    const newState = [false, false, false];
    newState[tabIndex] = true;

    setAccordion(newState);
  };

  const doNotShowAgain = () => {
    localStorage.setItem(appConfig.keys.local.browserLimitations, '1');
    setDismiss(false);
  };

  const badgeColors = ['success', 'info', 'warning', 'first', 'danger'];

  const topXsettingsBySameTenant = {
    title: t('bySameTenant'),
    desc: '',
    displayDivider: false,
    type: 'digitalAsset',
    imgType: 'preview',
    imgAlt: 'logo-model',
    icon: ['fas', 'eye'],
    iconClass: 'text-success me-1',
    sliderSettings: {
      dots: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1100,
          settings: { slidesToShow: 1, slidesToScroll: 1 }
        }
      ]
    }
  };

  const topXsettingsInSameGroup = {
    ...topXsettingsBySameTenant,
    ...{ title: t('variantOf_plural') }
  };
  const topXsettingsMultipleParents = {
    ...topXsettingsBySameTenant,
    ...{ title: t('variantOf') }
  };
  const stats = [
    { label: 'view', icon: 'eye', color: 'success' },
    { label: 'viewAR', icon: 'cube', color: 'warning' },
    { label: 'download', icon: 'download', color: 'info' }
  ];
  const bcolor = badgeColors[Math.floor(Math.random() * 5)];

  return (
    <>
      {null !== tenantDigitalAssets ? (
        <>
          <div className={'d-flex'}>
            {digitalAsset.collections.map((col) => (
              <div key={col['@uuid']} className={'ps-2'}>
                <Badge
                  pill
                  color={bcolor}
                  className={'rounded-pill bg-' + bcolor}>
                  {col.name}
                </Badge>
              </div>
            ))}
          </div>
          {dismiss && (
            <UncontrolledAlert className={'alerts-alternate'} color={'warning'}>
              <div className={'d-flex align-items-center align-content-start'}>
                <span
                  className={
                    'font-size-lg d-block d-40 me-3 text-center bg-warning text-white rounded-1'
                  }>
                  <FontAwesomeIcon icon={['far', 'object-group']} />
                </span>
                <span>
                  <strong className={'d-block'}>
                    {t('browserLimitedHeading')}
                  </strong>
                  {t('browserLimitedDescription') + ' '}
                  <Link key={'chrome'} to={'https://www.google.com/chrome/'}>
                    Google Chrome
                  </Link>
                </span>
                <hr />
                <p className={'mb-0 text-end'}>
                  <Link
                    key={'doNotShowAgain'}
                    to={'#'}
                    onClick={doNotShowAgain}>
                    {t('doNotShowAgain')}
                  </Link>
                </p>
              </div>
            </UncontrolledAlert>
          )}

          <div id={'digital-asset-container-' + uuid}>
            <div className={'row'}>
              <div className={'col col-md-8'}>
                <DigitalAsset digitalAsset={digitalAsset} />

                <div className={'card px-3 py-3 card-box-half-rounded-bottom'}>
                  <div className={'row'}>
                    <div className={'col col-6'}>
                      <div className={'d-flex align-items-center'}>
                        <div>
                          <Link to={iri(digitalAsset.tenant, 'tenant')}>
                            <img
                              alt={'logo-avatar-' + digitalAsset['@uuid']}
                              src={
                                getMediaSrc(digitalAsset.tenant, 'avatar') ??
                                defaultImg
                              }
                              width={70}
                            />
                          </Link>
                        </div>
                        <div className={'ps-3'}>
                          {t('madeBy')}
                          <Link
                            className={'ms-1 me-1'}
                            to={iri(digitalAsset.tenant, 'tenant')}>
                            {digitalAsset.tenant.name}
                          </Link>
                          {digitalAsset.upload && digitalAsset.upload.user && (
                            <Link
                              className={'ms-1 me-1'}
                              to={iri(digitalAsset.upload.user, 'user')}>
                              {'-  '}
                              {digitalAsset.upload.user.name}
                            </Link>
                          )}

                          {digitalAsset.supplierUrl && (
                            <div
                              key={'supplierUrl'}
                              className={'font-size-sm text-info mt-1 d-flex'}>
                              <FontAwesomeIcon
                                icon={['fas', 'link']}
                                className={'text-primary me-1'}
                              />
                              <Link
                                className={'d-block ps-2'}
                                to={{ pathname: digitalAsset.supplierUrl }}
                                target={'_blank'}
                                title={'supplierUrl'}>
                                {t('seeMakerProfile')}
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {uniqueParents.length === 1 && (
                      <div className={'col col-6 d-flex justify-content-end'}>
                        <div className={'d-flex align-items-center'}>
                          <div className={'pe-3'}>
                            {t('variantOf')}
                            <Link
                              className={'ms-1 me-1'}
                              to={iri(uniqueParents[0], 'digitalAsset')}>
                              {uniqueParents[0].name}
                            </Link>
                          </div>
                          <div>
                            <Link to={iri(uniqueParents[0], 'digitalAsset')}>
                              <img
                                alt={'logo-avatar-' + uniqueParents[0]['@uuid']}
                                src={
                                  getMediaSrc(uniqueParents[0], 'preview') ??
                                  defaultImg
                                }
                                width={70}
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {variationGroups.length > 0 &&
                  variationGroups.filter((vg) => vg.variations.length > 0)
                    .length > 0 && (
                    <div className={'mt-5'}>
                      <TopXitemsListSelect
                        items={variationGroupAssets}
                        setItems={setVariationGroupAssets}
                        settings={topXsettingsInSameGroup}
                        itemsSelect={variationGroups}
                      />
                    </div>
                  )}
                {uniqueParents.length > 1 && (
                  <div className={'mt-5'}>
                    <TopXitemsList
                      items={uniqueParents}
                      settings={topXsettingsMultipleParents}
                    />
                  </div>
                )}
                <div className={'mt-5'}>
                  <TopXitemsList
                    items={tenantDigitalAssets['hydra:member'].filter(
                      (da) => da['@id'] !== digitalAsset['@id']
                    )}
                    settings={topXsettingsBySameTenant}
                  />
                </div>
              </div>

              <div className={'col col-md-4'}>
                <div className={'accordion mb-5'}>
                  <div
                    className={clsx('carad card-box', {
                      'panel-open': accordion[0]
                    })}>
                    <div className={'card'}>
                      <div className={'card-header'}>
                        <div className={'panel-title'}>
                          <div className={'accordion-toggle'}>
                            <Button
                              color={'link'}
                              size={'lg'}
                              className={
                                'd-flex align-items-center justify-content-between'
                              }
                              onClick={() => toggleAccordion(0)}
                              aria-expanded={accordion[0]}>
                              <span>
                                <b>{t('viewerDisplayOptions')}</b>
                              </span>
                              <FontAwesomeIcon
                                icon={['fas', 'angle-up']}
                                className={'font-size-xl accordion-icon'}
                              />
                            </Button>
                          </div>
                        </div>
                      </div>

                      <Collapse isOpen={accordion[0]}>
                        <div className={'d-block p-2 mb-2'}>
                          <FormDisplayOptions
                            digitalAsset={digitalAsset}
                            cruds={cruds}
                          />
                        </div>
                      </Collapse>
                    </div>
                  </div>

                  {digitalAsset.caoData && digitalAsset.caoData.nNodes && (
                    <div
                      className={clsx('card card-box', {
                        'panel-open': accordion[1]
                      })}>
                      <div className={'card'}>
                        <div className={'card-header'}>
                          <div className={'panel-title'}>
                            <div className={'accordion-toggle'}>
                              <Button
                                color={'link'}
                                size={'lg'}
                                className={
                                  'd-flex align-items-center justify-content-between'
                                }
                                onClick={() => toggleAccordion(1)}
                                aria-expanded={accordion[1]}>
                                <span>
                                  <b>{t('caoData')}</b>
                                </span>
                                <FontAwesomeIcon
                                  icon={['fas', 'angle-up']}
                                  className={'font-size-xl accordion-icon'}
                                />
                              </Button>
                            </div>
                          </div>
                        </div>

                        <Collapse isOpen={accordion[1]}>
                          <div className={'d-block p-2'}>
                            <DigitalAssetCaoData digitalAsset={digitalAsset} />
                          </div>
                        </Collapse>
                      </div>
                    </div>
                  )}

                  {cruds.update.authorized && (
                    <>
                      <div
                        className={clsx('card card-box', {
                          'panel-open': accordion[2]
                        })}>
                        <div className={'card'}>
                          <div className={'card-header'}>
                            <div className={'panel-title'}>
                              <div className={'accordion-toggle'}>
                                <Button
                                  color={'link'}
                                  size={'lg'}
                                  className={
                                    'd-flex align-items-center justify-content-between'
                                  }
                                  onClick={() => toggleAccordion(2)}
                                  aria-expanded={accordion[2]}>
                                  <span>
                                    <b>{t('arSettings')}</b>
                                  </span>
                                  <FontAwesomeIcon
                                    icon={['fas', 'angle-up']}
                                    className={'font-size-xl accordion-icon'}
                                  />
                                </Button>
                              </div>
                            </div>
                          </div>

                          <Collapse isOpen={accordion[2]}>
                            <div className={'d-block p-2'}>
                              <FormARSettings
                                digitalAsset={digitalAsset}
                                cruds={cruds}
                              />
                            </div>
                          </Collapse>
                        </div>
                      </div>

                      <div
                        className={clsx('card card-box', {
                          'panel-open': accordion[3]
                        })}>
                        <div className={'card'}>
                          <div className={'card-header'}>
                            <div className={'panel-title'}>
                              <div className={'accordion-toggle'}>
                                <Button
                                  color={'link'}
                                  size={'lg'}
                                  className={
                                    'd-flex align-items-center justify-content-between'
                                  }
                                  onClick={() => toggleAccordion(3)}
                                  aria-expanded={accordion[3]}>
                                  <span>
                                    <b>{t('visibilitySettings')}</b>
                                  </span>
                                  <FontAwesomeIcon
                                    icon={['fas', 'angle-up']}
                                    className={'font-size-xl accordion-icon'}
                                  />
                                </Button>
                              </div>
                            </div>
                          </div>

                          <Collapse isOpen={accordion[3]}>
                            <div className={'d-block p-2'}>
                              <FormVisibilitySettings
                                digitalAsset={digitalAsset}
                                cruds={cruds}
                              />
                            </div>
                          </Collapse>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                {digitalAsset.qrCode && digitalAsset.qrCode !== '' && (
                  <QRCode base64={digitalAsset.qrCode} />
                )}

                {digitalAsset.statistics && (
                  <div className={'card'}>
                    <div className={'px-3'}>
                      {stats.map((s, index) => (
                        <div
                          key={index}
                          className={'font-size-sm text-black-50 mb-2 mt-2'}>
                          <FontAwesomeIcon
                            icon={['fas', s.icon as IconName]}
                            className={'text-' + s.color + ' me-1'}
                          />
                          <strong>
                            {digitalAsset.statistics[s.label] ?? '-'}{' '}
                          </strong>
                          {t(s.label, {
                            count: digitalAsset.statistics[s.label] ?? 0
                          })}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loader type={'pacman'} optClasses={'vh-100'} />
      )}
    </>
  );
};

export default DigitalAssetMainTab;
