import React from 'react';
import { SidebarTenantBox } from 'components';
import { useUserStateValues } from 'states/UserState';

const SidebarTenantboxList = (props) => {
  const tenants = props.tenants ?? [];
  const userState = useUserStateValues();
  const tenantUuid = userState.currentTenant
    ? userState.currentTenant['@uuid'] ?? []
    : [];

  return (
    <div className="tenants-container">
      {tenants.map((tenant) => (
        <SidebarTenantBox
          key={tenant['@uuid']}
          tenant={tenant}
          selected={tenantUuid === tenant['@uuid'] ? 'selected' : ''}
        />
      ))}
    </div>
  );
};

export default SidebarTenantboxList;
