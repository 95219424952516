import React from 'react';
import { useTranslation } from 'react-i18next';
import { getQueryParams, mergeQueryParams, removeQueryParams } from 'utils';

import apiConfig from 'config/apiConfig.json';

const Facets = ({ facets }) => {
  return (
    <>
      {facets.map((f) => (
        <Facet key={'facet-' + f.properties.label} facet={f} />
      ))}
    </>
  );
};

export default Facets;

export const Facet = ({ facet }) => {
  const { t } = useTranslation();
  let totalItems = 0;
  (facet.results ?? [])
    .filter((o) => o.label && o.label !== '')
    .map((o) => (
      totalItems += o.count ?? 0
    ));

  return (0 < totalItems &&
    <>
      <h5 className={'pt-5'}>{t('facet_' + facet.properties.label)}</h5>
      {(facet.results ?? [])
        .filter((o) => o.label && o.label !== '')
        .map((o) => (
        <FacetOption key={'facet-option-' + (o.uuid ?? '-')} facet={facet} option={o} />
      ))}
    </>
  );
};

export const FacetOption = ({ facet, option }) => {
  const params = getQueryParams();
  const isSelected = params.filter((p) => p.label === facet.properties.resource.property + '[]' && p.value === (option.uuid ?? '-')).length > 0;

  const triggerSearch = (e) => {
      const q = (e.target.checked) ?
        mergeQueryParams(facet.properties.resource.property + '[]', (option.uuid ?? '-'), params) :
        removeQueryParams(facet.properties.resource.property + '[]', (option.uuid ?? '-'), true, params)
      ;

    window.location.replace('/' + apiConfig.resources.digitalAsset.uri + '?' + q);
  };

  return (
    <>
      <div className={'form-check form-check-md mt-3'}>
        <input
          className={'form-check-input'}
          type={'checkbox'}
          id={'facetOption-' + (option.uuid ?? '-')}
          onChange={triggerSearch}
          checked={isSelected}
        />
        <label className={'form-check-label'} htmlFor={'facetOption-' + (option.uuid ?? '-')}>
          {option.label} <small className={'ms-2'}>({option.count ?? 0})</small>
        </label>
      </div>
    </>
  );
};
