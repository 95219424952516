import { iri, isAdmin, isCustomer, isWebmaster } from 'utils';
import { unState } from 'states';

export const getPageNavigation = ({
  user,
  resourceType,
  entity,
  page,
  tab
}) => {
  if (resourceType === 'tenant') {
    return getTenantPageNavigation({
      user: user,
      entity: entity,
      page: page,
      tab: tab
    });
  }

  if (resourceType === 'user') {
    return getUserPageNavigation({
      user: user,
      entity: entity,
      page: page,
      tab: tab
    });
  }

  if (resourceType === 'digitalAsset') {
    return getDigitalAssetPageNavigation({
      user: user,
      entity: entity,
      page: page,
      tab: tab
    });
  }
};

const getTenantPageNavigation = ({ user, entity, page, tab }) => {
  const uuid = entity['@uuid'];

  return [
    {
      label: 'dashboard',
      link: '/tenants/' + uuid,
      index: 1
    },
    {
      label: 'subscription',
      link: '/tenants/' + uuid + '/subscription',
      index: 2
    },
    {
      label: 'payment',
      link: '/tenants/' + uuid + '/payment',
      index: 3,
      display: isAdmin({ user, tenantUuid: uuid })
    },
    {
      label: 'member_plural',
      link: '/tenants/' + uuid + '/members',
      index: 4,
      display: isAdmin({ user, tenantUuid: uuid })
    },
    {
      label: 'advanced',
      link: '/tenants/' + uuid + '/advanced',
      index: 5,
      display: isAdmin({ user, tenantUuid: uuid })
    }
  ];
};

const getUserPageNavigation = ({ user, entity, page, tab }) => {
  const uuid = entity['@uuid'];

  return [
    {
      label: 'profile',
      link: '/users/' + uuid,
      index: 1
    },
    {
      label: 'accounts',
      link: '/users/' + uuid + '/accounts',
      index: 2
    }
  ];
};

const getDigitalAssetPageNavigation = ({ user, entity, page, tab }) => {
  return [];
};

export const getPageActions = ({ user, resourceType, entity, page, tab }) => {
  if (resourceType === 'tenant') {
    return getTenantPageActions({
      user: user,
      entity: entity,
      page: page,
      tab: tab
    });
  }

  if (resourceType === 'user') {
    return getUserPageActions({
      user: user,
      entity: entity,
      page: page,
      tab: tab
    });
  }

  if (resourceType === 'digitalAsset') {
    return getDigitalAssetPageActions({
      user: user,
      entity: entity,
      page: page,
      tab: tab
    });
  }
};

const getTenantPageActions = ({ user, entity, page, tab }) => {
  const uuid = entity['@uuid'];
  const isAdminLoc = isAdmin({ user, tenantUuid: uuid });
  const isCustomerLoc = isCustomer({ user, tenantUuid: uuid });

  return [
    {
      label: 'accountCancellation',
      link: '#',
      iconLink: ['fas', 'sign-out-alt'],
      display: isCustomerLoc,
      iconColor: 'red'
    },
    {
      label: 'accountDeletion',
      link: '#',
      iconLink: ['fas', 'trash'],
      display: isAdminLoc,
      iconColor: 'red',
      textColor: 'text-danger'
    }
  ];
};

const getUserPageActions = ({ user, entity, page, tab }) => {
  return [];
};

const getDigitalAssetPageActions = ({ user, entity, page, tab }) => {
  const pa = [];

  if ('edit' !== tab) {
    pa.push({
      label: 'edit',
      type: 'edit',
      link: iri(entity, 'digitalAsset') + '/edit',
      iconLink: ['fas', 'edit'],
      display: true
    });
  }

  pa.push({
    label: 'delete',
    // link: '/digital-assets',
    type: 'delete',
    iconLink: ['fas', 'trash'],
    display: true
  });

  return pa;
};

export const getPageTabs = ({ user, resourceType, entity, page, tab }) => {
  if (resourceType === 'tenant') {
    return getTenantPageTabs({
      user: user,
      entity: entity,
      page: page,
      tab: tab
    });
  }

  if (resourceType === 'user') {
    return getUserPageTabs({
      user: user,
      entity: entity,
      page: page,
      tab: tab
    });
  }

  if (resourceType === 'digitalAsset') {
    return getDigitalAssetPageTabs({
      user: user,
      entity: entity,
      page: page,
      tab: tab
    });
  }
};

const getTenantPageTabs = ({ user, entity, page, tab }) => {
  const uuid = entity['@uuid'];
  const isWebmasterLoc = isWebmaster({ user, tenantUuid: uuid });

  return 'database' === page
    ? [
        {
          tab: 'digital-assets',
          label: 'digitalAsset_plural',
          index: 1,
          link: '/tenants/' + uuid + '/database/digital-assets',
          display: true
        },
        {
          tab: 'datamodel',
          label: 'datamodel',
          index: 2,
          link: '/tenants/' + uuid + '/database/datamodel',
          display: isWebmasterLoc
        },
        {
          tab: 'users',
          label: 'usersAndGroups',
          index: 3,
          link: '/tenants/' + uuid + '/database/users',
          display: isWebmasterLoc
        }
      ]
    : [];
};

const getUserPageTabs = ({ user, entity, page, tab }) => {
  return [];
};

const getDigitalAssetPageTabs = ({ user, entity, page, tab }) => {
  return [];
};

export const assignNav = ({
  resourceType,
  entity = null,
  setActions = null,
  setCruds = null,
  setLinks = null,
  setTabs = null,
  setEntity = null,
  user = null,
  page = null,
  tab = null
}) => {
  if (null === entity) {
    if (null !== setActions) setActions(entity);
    if (null !== setTabs) setTabs(entity);
    if (null !== setEntity) setEntity(entity);
    if (null !== setCruds) setCruds(entity);
    if (null !== setLinks) setLinks(entity);

    return;
  }

  // Populate LINKS.
  if (null !== setLinks) {
    setLinks(
      getPageNavigation({
        user: unState(user.user),
        resourceType: resourceType,
        entity: entity,
        page: page,
        tab: tab
      })
    );
  }

  // Populate TABS.
  if (null !== setTabs) {
    setTabs(
      getPageTabs({
        user: unState(user.user),
        resourceType: resourceType,
        entity: entity,
        page: page,
        tab: tab
      })
    );
  }

  // Populate ACTIONS.
  if (null !== setActions) {
    setActions(
      getPageActions({
        user: unState(user.user),
        resourceType: resourceType,
        entity: entity,
        page: page,
        tab: tab
      })
    );
  }
};
