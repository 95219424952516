import React from 'react';
import clsx from 'clsx';
import { useThemeState, useThemeStateValues } from 'states';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  UncontrolledTooltip,
  Button
} from 'reactstrap';

const HeaderDrawer = () => {
  const themeState = useThemeState();
  const themeOptions = useThemeStateValues();

  function toggleSidebarMobile() {
    themeState.headerDrawerToggle.set(!themeOptions.headerDrawerToggle);
  }

  return (
    <>
      <div className="app-drawer-wrapper">
        <button
          onClick={toggleSidebarMobile}
          className={clsx('navbar-toggler hamburger hamburger--elastic', {
            'is-active': themeOptions.headerDrawerToggle
          })}>
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
      </div>

      <div className="app-drawer-content">
        <Button
          onClick={toggleSidebarMobile}
          className="close-drawer-btn btn btn-sm"
          id="CloseDrawerTooltip">
          <div
            className={clsx('navbar-toggler hamburger hamburger--elastic', {
              'is-active': themeOptions.headerDrawerToggle
            })}>
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </div>
        </Button>
        <UncontrolledTooltip target="CloseDrawerTooltip" placement="left">
          Close drawer
        </UncontrolledTooltip>
        <div className="vh-100 shadow-overflow">
          <PerfectScrollbar>
            <div className="p-4">
              <div className="text-center rounded p-2 text-white bg-deep-sky">
                <div className="fw-bold font-size-lg mb-0">
                  Last viewed
                </div>
                <p>
                  Last models you consulted
                </p>
              </div>
            </div>
          </PerfectScrollbar>
        </div>
      </div>

      <div
        onClick={toggleSidebarMobile}
        className={clsx('app-drawer-overlay', {
          'is-active': themeOptions.headerDrawerToggle
        })}
      />
    </>
  );
}

export default HeaderDrawer;
