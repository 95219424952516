import React from 'react';
import { TopXItem } from 'components';
import Slider from 'react-slick';

import defaultImage from 'assets/images/avatars/defaultModelAvatar.png';

const TopXitemsList = ({ items, settings }) => {
  const itemsList = items ?? [];
  const defaultsSettings = {
    ...{
      title: 'title',
      desc: 'desc',
      displayDivider: true,
      type: '',
      imgType: '',
      imgAlt: '-',
      slider: itemsList.length > 2,
      defaultAssetImg: defaultImage,
      useStats: true
    },
    ...settings
  };

  return (
    <div className={'card card-box mb-5 p-3 text-center'}>
      <div className={'my-3'}>
        <h6 className={'fw-bold font-size-lg mb-1 text-black'}>{settings.title}</h6>
        <p className={'text-black-50 mb-0'}>{settings.desc}</p>
      </div>
      {defaultsSettings.slider ? (
        <div className={'card-box d-block'}>
          <Slider {...settings.sliderSettings}>
            {itemsList.map((item, index) => (
              <div key={item['@id']}>
                <div className={'card-body'}>
                  <TopXItem item={item} last={index === itemsList.length - 1} settings={defaultsSettings}/>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      ) : (
        <div className={'d-flex flex-row flex-wrap justify-content-center'}>
          {itemsList.map((item, index) => (
            <TopXItem key={item['@id']} item={item} last={index === itemsList.length - 1} settings={defaultsSettings}/>
          ))}
        </div>
      )}
    </div>
  );
};

export default TopXitemsList;
