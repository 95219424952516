import React, { useEffect, useState } from 'react';
import {
  DatatableGroups,
  DatatableUsers,
  Loader,
  TopXitemsList
} from 'components';
import { useUserStateValues } from 'states';
import { getSource, list } from 'utils';
import { useTranslation } from 'react-i18next';

import defaultAssetImg from 'assets/images/avatars/defaultAvatar.png';

const UsersAndGroups = ({ tenant }) => {
  const userState = useUserStateValues();
  const { t } = useTranslation();
  const [items, setItems] = useState([]);

  // Get topX list.
  useEffect(() => {
    let componentMounted = true;
    const source = getSource();

    if (true === componentMounted) {
      list({
        resourceType: 'user',
        params: [{ label: 'memberships.tenant', value: tenant['@uuid'] }],
        success: {
          callback: (res) => {
            if (res.data && res.data['hydra:member']) {
              setItems(
                res.data['hydra:member']
                  .sort((a, b) =>
                    a.statistics.upload <= b.statistics.upload ? 1 : -1
                  )
                  .slice(0, 3)
              );
            }
          }
        },
        aOptions: { cancelToken: source.token }
      }).then();
    }

    return () => {
      componentMounted = false;
      source.cancel();
      setItems([]);
    };

    // eslint-disable-next-line
  }, [tenant]);

  return (
    <>
      <div className={'row'}>
        <div className={'col'}>
          {items.length >= 0 ? (
            <TopXitemsList
              items={items}
              settings={{
                title: t('topContributor'),
                desc: t('topContributorDesc'),
                displayDivider: true,
                type: 'user',
                imgType: 'avatar',
                imgAlt: 'contributor-avatar',
                icon: ['fas', 'user'],
                iconClass: 'text-warning me-1',
                slider: false,
                defaultAssetImg: defaultAssetImg,
                useStats: false
              }}
            />
          ) : (
            <Loader type={'pacman'} />
          )}
        </div>
      </div>

      <div className={'row'}>
        <div className={'col'}>
          <DatatableUsers
            settings={{ displayRoles: false }}
            tenant={tenant}
          />
        </div>
      </div>

      <div className={'row'}>
        <div className={'col'}>
          <DatatableGroups
            user={userState.user}
            tenant={
              userState.user.tenants.filter(
                (t) => t['@uuid'] === tenant['@uuid']
              )[0]
            }
          />
        </div>
      </div>
    </>
  );
};

export default UsersAndGroups;
