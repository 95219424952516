import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { deci } from 'utils';
import {
  Badge,
  ListGroupItem,
} from 'reactstrap';

const CaoDataBenchmark = ({ title, description, icon, keys, digitalAsset }) => {
  const { t } = useTranslation();

  return (
    <>
      <ListGroupItem className="d-flex justify-content-between bg-light align-items-center">
        <div className="d-70 d-flex gradient-icon align-items-center justify-content-center">
          <i className={`pe-7s-${icon} display-6 gradient-icon bg-ripe-malin`}>
          </i>
        </div>
        <div className="flex-grow-1 ps-2">
          <h5 className="mt-0 mb-1">{title}</h5>
          <p className="card-text mb-0">
            <span>{description}</span>
            <Link
              key={"plan-upgrade"}
              to={'/'}
              className="ps-2"
            >
              {t('upgradePlan')}
            </Link>
          </p>
        </div>
      </ListGroupItem>

      {keys.map(function(key) {
        const val1 = digitalAsset.caoData[key];
        const val2 = digitalAsset.optimizedCaoData[key];

        let displayDiff = false;
        let boost = 0;
        let boostDisplay = "";
        let color = "primary";

        // Calculate optimizer performance on given item key
        if (val1 && (val1 > 0) && (val1 !== val2)) {
          boost = 100 - (100 * val2 / val1);
          boostDisplay = deci(boost);
          if (boost > 20) {
            color = 'success';
          }
          displayDiff = true;
        }

        return (
          <ListGroupItem
            key={key}
            className="d-flex justify-content-between align-items-center"
          >
            <div>
              <b>{t(key)}</b>
              {displayDiff && (
                <Badge pill color={'neutral-' + color} className={'ms-2 rounded-pill bg-neutral-' + color}>
                  -{boostDisplay}%
                </Badge>
              )}
            </div>
            <div>
              <Badge pill color="neutral-info" className={'rounded-pill bg-neutral-info'}>
                {val1}
              </Badge>
              <Badge pill color="neutral-info" className={'rounded-pill bg-neutral-info'}>
                {val2}
              </Badge>
            </div>
          </ListGroupItem>
        );
      })}
    </>
  );
}

export default CaoDataBenchmark;
