import { State, createState, useState } from '@hookstate/core';
import config from 'config/viewerConfig.json';

interface ViewerConfig {
  currentEnvironment: string,
  skyboxToggle: boolean
}

const state: State<ViewerConfig> = createState(config);

export const useViewerState = () => useState(state);
export const useViewerStateValues = () => useViewerState().value;
