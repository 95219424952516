import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  UncontrolledPopover,
  Badge,
  Button
} from 'reactstrap';
import { Grid, Users, Bell } from 'react-feather';
import { useUserStateValues } from 'states/UserState';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import { iri } from 'utils';

const HeaderDots = () => {
  const userState = useUserStateValues();
  const tenant = userState.currentTenant;
  const { t } = useTranslation();
  const newMessages = Math.floor(Math.random() * 100);

  return (
    <>
      <div className="d-flex align-items-center popover-header-wrapper">
        {tenant && (
          <>
            <span className="d-inline-block pe-2">
              <Button
                id="alertsPopover"
                color="neutral-success"
                className="bg-neutral-success text-success font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-44 rounded position-relative btn-transition-none">
                <span>
                  <Badge color="success" className="badge-circle bg-success">
                    New notifications
                  </Badge>
                  <Bell />
                </span>
              </Button>
              <UncontrolledPopover
                target="alertsPopover"
                trigger="legacy"
                container="body"
                className="popover-custom-wrapper popover-custom-md"
                placement="auto">
                <div className="bg-composed-wrapper bg-midnight-bloom mx-3 mt-3 border-0 text-center rounded-1">
                  <div className="bg-composed-img-3 bg-composed-wrapper--image"></div>
                  <div className="bg-composed-wrapper--content text-light px-2 py-4">
                    <h4 className="font-size-xl fw-bold mb-2">
                      Notifications
                    </h4>
                    <p className="opacity-8 mb-0">
                      {t(newMessages === 1 ? 'notifNewMessage' : 'notifNewMessage_plural', { count: newMessages })}
                    </p>
                  </div>
                </div>
                <div className="text-center py-3">
                  <Button
                    color="primary"
                    className="px-4 btn-gradient badge-wrapper bg-midnight-bloom">
                    <Badge
                      color="warning"
                      className="badge-position badge-position--top-right badge-circle-inner bg-warning">
                      New notifications
                    </Badge>
                    <span className="btn-wrapper--label">{t('learnMore')}</span>
                    <span className="btn-wrapper--icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </span>
                  </Button>
                </div>
              </UncontrolledPopover>
            </span>
            <span className="d-inline-block pe-2">
              <HashLink
                to={iri(tenant, 'tenant') + '#digital-assets-table'}
                id="settingsPopover"
                color="neutral-danger"
                className="bg-neutral-danger text-danger font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-44 rounded position-relative btn-transition-none">
                <span>
                  <Grid />
                </span>
              </HashLink>
            </span>
            <span className="d-inline-block pe-2">
              <HashLink
                to={iri(tenant, 'tenant') + '/users#users-table'}
                id="dotsMenuPopover"
                color="neutral-first"
                className="bg-neutral-first text-first font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-44 rounded btn-transition-none">
                <span>
                  <Users />
                </span>
              </HashLink>
            </span>
          </>
        )}
      </div>
    </>
  )
}

export default HeaderDots;
