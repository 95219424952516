import React from 'react';
import PaymentMethod from './PaymentMethod';
import PaymentHistory from './PaymentHistory';

const Payment = ({ tenant }) => {
  return (
    <div className={'tenant-payment-page'}>
      <div className={'row'}>
        <div className={'col col-md-12 mb-4'}>
          <div className={'row row--payment-method'}>
            <PaymentMethod tenant={tenant} />
          </div>

          <hr />

          <div className={'row row--invoices'}>
            <PaymentHistory tenant={tenant} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
