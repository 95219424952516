import React from 'react';
import {
  DigitalAssetMain,
  TenantDashboard,
  TenantDatamodel,
  TenantDigitalAssets,
  TenantPayment,
  TenantSubscription,
  TenantUsersAndGroups,
  UserProfile,
  UserTenants
} from './internals';
import { DigitalAssetForm } from 'forms';

const ProfileContent = ({
  page = 'dashboard',
  activeTab = null,
  tabs = null,
  entity = null,
  resourceType = 'user',
  organizations = [],
  action = null,
  cruds = null
}) => {
  let classes = 'app-page-content';

  if (typeof resourceType === 'string' && resourceType.length > 0) {
    classes += ' app-page-content--' + resourceType;

    if (typeof page === 'string' && page.length > 0) {
      classes += ' app-page-content--' + resourceType + '--' + page;

      if (typeof action === 'string' && action.length > 0) {
        classes +=
          ' app-page-content--' + resourceType + '--' + page + '--' + action;
      }
    }
  }

  return (
    <div
      className={
        null === tabs || 0 === tabs.length ? classes : classes + ' mt-5'
      }>
      {'tenant' === resourceType && (
        <>
          {('dashboard' === page || null === page) && (
            <TenantDashboard tenant={entity} />
          )}

          {'subscription' === page && <TenantSubscription tenant={entity} />}
          {'payment' === page && <TenantPayment tenant={entity} />}
          {'members' === page && <TenantUsersAndGroups tenant={entity} />}

          {'database' === page && (
            <>
              {('digital-assets' === activeTab || null === activeTab) && (
                <TenantDigitalAssets tenant={entity} itemsPerPage={25} />
              )}
              {'datamodel' === activeTab && <TenantDatamodel tenant={entity} />}
              {'users' === activeTab && (
                <TenantUsersAndGroups tenant={entity} />
              )}
            </>
          )}
        </>
      )}

      {'user' === resourceType && (
        <>
          {('profile' === page || null === page) && (
            <UserProfile user={entity} />
          )}
          {'accounts' === page && <UserTenants user={entity} />}
        </>
      )}

      {'digitalAsset' === resourceType && (
        <>
          {'edit' === activeTab && true === cruds?.update?.authorized ? (
            <DigitalAssetForm digitalAsset={entity} />
          ) : (
            <DigitalAssetMain digitalAsset={entity} cruds={cruds} />
          )}
        </>
      )}
    </div>
  );
};

export default ProfileContent;
