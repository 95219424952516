import React from 'react';
import { ModelViewer } from 'components';

const DigitalAsset = ({ digitalAsset }) => {
  return (
    <>
      <div className="digital-asset-container">
        <div className="model-viewer-container">
          <ModelViewer digitalAsset={digitalAsset} />
        </div>
      </div>
    </>
  );
};

export default DigitalAsset;
