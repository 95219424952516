import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Button, Collapse, Progress } from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import {
  AutocompleteAssets,
  Loader,
  PlanTeaser,
  QuotaTypeWrapper
} from 'components';
import { Checkbox, FormARSettings, FormVisibilitySettings } from 'forms';
import { deci, create, nError, iri, list, action } from 'utils';
import { useTranslation } from 'react-i18next';
import {
  getPlan,
  refreshState,
  usePlansState,
  usePlansStateValues,
  useUserStateValues
} from 'states';
import Select, { Option } from 'rc-select';
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import apiConfig from 'config/apiConfig.json';
import cloudFile from 'assets/images/baseline-cloud_upload-24px.svg';
import appConfig from 'config/appConfig.json';
import arConfig from 'config/arConfig.json';
import { useHistory } from 'react-router-dom';

const FormUploadDropzone = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const userStateValues = useUserStateValues();
  const planState = usePlansState();
  const planStateValues = usePlansStateValues();

  // Init form state
  const [files, setFiles] = useState([]);
  const [sendingFiles, setSendingFiles] = useState(false);
  const [errored, setErrored] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [tenants] = useState(
    userStateValues.isAuthenticated && userStateValues.user
      ? userStateValues.user.tenants
      : []
  );
  const [tenant, setTenant] = useState(
    userStateValues.isAuthenticated && userStateValues.user
      ? userStateValues.currentTenant
      : null
  );
  const [groups, setGroups] = useState([{ '@uuid': -1, name: t('any') }]);
  const [users, setUsers] = useState([{ '@uuid': -1, name: t('any') }]);
  const [mode, setMode] = useState(1);
  const [refresher, setRefresher] = useState(Date.now());
  const [plan, setPlan] = useState();

  useEffect(() => {
    if (!userStateValues.isAuthenticated) {
      history.push('/error/403/403');
      return;
    }

    let currentPlanName = null;
    if (tenant.plan !== null) {
      currentPlanName = tenant.plan.name;
    }

    if (planStateValues.plans === null) {
      refreshState(planState, (newPlans) => {
        // Default plan.
        if (currentPlanName === null) {
          currentPlanName = newPlans['standard'][0]['name']
        }

        setPlan(getPlan({ plans: newPlans, name: currentPlanName }));
      });
    } else {
      // Default plan.
      if (currentPlanName === null) {
        currentPlanName = planStateValues.plans['standard'][0]['name']
      }

      setPlan(getPlan({ plans: planStateValues.plans, name: currentPlanName }));
    }

    if (null !== tenant) {
      refreshUserTenantGroupsSelect(tenant);
      refreshUserTenantUsersSelect(tenant);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!userStateValues.isAuthenticated) {
      history.push('/error/403/403');
      return;
    }

    if (null !== variationParent) {
      list({
        resourceType: 'variationGroup',
        params: [
          { label: 'digitalAsset', value: variationParent['@uuid'] },
          { label: 'tenant', value: tenant['@uuid'] }
        ],
        setter: setVariationGroups
      }).then();
    }

    // eslint-disable-next-line
  }, []);

  const refreshUserTenantGroupsSelect = (t = null) => {
    setVisibilitySettingsGroups(null);

    refreshUserTenantSelect({
      resourceType: 'group',
      params: [{ label: 'tenant', value: t ? iri(t, 'tenant') : null }],
      setter: setGroups
    });
  };

  const refreshUserTenantUsersSelect = (t = null) => {
    setVisibilitySettingsUsers(null);

    refreshUserTenantSelect({
      resourceType: 'user',
      params: [{ label: 'tenant', value: t ? iri(t, 'tenant') : null }],
      setter: setUsers
    });
  };

  const refreshUserTenantSelect = ({
    resourceType,
    params,
    setter,
    subItem = null
  }) => {
    list({
      resourceType: resourceType,
      params: params,
      success: {
        callback: (res) => {
          let newRes = [{ '@uuid': -1, name: t('any') }];

          res.data['hydra:member'].map((item) => {
            if (null !== subItem) {
              newRes.push(item[subItem]);
            } else {
              newRes.push(item);
            }

            return item;
          });

          setter(newRes);
        }
      }
    }).then();
  };

  const onDrop = useCallback(
    (droppedFiles) => {
      setFiles(files.concat(droppedFiles));
    },
    [files]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    minSize: 0,
    maxSize: apiConfig.default.maxUploadSize * 1000000
  });

  const handleSubmit = (event) => {
    event.preventDefault();

    if (files.length > 0) {
      setSendingFiles(true);

      const formData = new FormData();
      formData.append('tenant', tenant['@uuid']);
      formData.append('mode', mode.toString());
      formData.append(
        'uploadSettings',
        JSON.stringify({
          digitalAsset: {
            visibility: visibility,
            playerSettings: arSettings,
            visibilitySettings:
              -1 === visibility
                ? {
                    groups: visibilitySettingsGroups.filter(
                      (g) => g !== '/groups/-1'
                    ),
                    users: visibilitySettingsUsers.filter(
                      (u) => u !== '/users/-1'
                    )
                  }
                : null
          },
          variationGroup: variationGroup
            ? iri(variationGroup, 'variationGroup')
            : null
        })
      );

      files.map((file, index) => {
        return formData.append('file' + index, file, file.name);
      });

      create({
        resourceType: 'upload',
        data: formData,
        progress: {
          callback: (event) => {
            setUploadProgress((event.loaded / event.total) * 100);
          }
        },
        success: {
          message: t('uploadSuccess'),
          callback: (res) => {
            // Redirect on the upload page status instead of first DA, if many files.
            if (
              res.data['digitalAssets'] &&
              res.data['digitalAssets'].length > 0
            ) {
              window.location.href =
                res.data['digitalAssets'].length > -1
                  ? '/uploads/' + res.data['@uuid']
                  : '/' +
                    apiConfig.resources.digitalAsset.uri +
                    '/' +
                    res.data['digitalAssets'][0]['@uuid'];
            }
          }
        },
        error: {
          message: t('uploadError'),
          callback: () => {
            setErrored(true);
          }
        },
        always: {
          callback: () => {
            setSendingFiles(false);
          }
        }
      });
    } else {
      nError({ message: t('missingFiles') });
    }
  };

  const switchTenant = (value) => {
    tenants.map((t) => {
      if (t['@uuid'] === value) {
        setTenant(t);
        setPlan(getPlan({ plans: planStateValues.plans, name: t.plan.name }));

        // Refresh Groups & Users options.
        refreshUserTenantGroupsSelect(t);
        refreshUserTenantUsersSelect(t);

        // Reset DA & VariationGroup.
        setIsVariation(false);
        setIsInVariationGroup(false);
        setVariationGroup(null);
        setVariationParent(null);
      }
      return t;
    });
  };

  const uploadModes = [
    { value: 0, name: t('uploadMode0') },
    { value: 1, name: t('uploadMode1') },
    { value: 2, name: t('uploadMode2') }
  ];

  const switchMode = (value) => {
    setMode(value);
  };

  const renderProgress = () => {
    const message =
      uploadProgress !== 100
        ? t('sendingFiles') + ' ' + deci(uploadProgress) + '%'
        : sendingFiles
        ? t('handlingFiles') + '...'
        : t('done');
    const color =
      message === t('done') ? (errored ? 'danger' : 'success') : 'primary';

    return (
      <div className={'progress-wrapper pt-2 pb-2'}>
        <Progress
          className={'progress-animated-alt progress-wd'}
          value={uploadProgress}
          color={color}>
          {message}
        </Progress>
      </div>
    );
  };

  const renderLimit = () => {
    const uploadSize = totalUploadSize();
    const limit = apiConfig.default.maxUploadSize;
    const color =
      uploadSize > 2 * (limit / 3)
        ? uploadSize > limit
          ? 'danger'
          : 'warning'
        : 'success';
    const currentVal = getCurrentProgress();

    return (
      <div className={'progress'}>
        <div
          className={
            'progress-bar progress-bar-striped progress-bar-animated bg-' +
            color
          }
          role={'progressbar'}
          style={{ width: currentVal + '%' }}
          aria-valuenow={currentVal}
          aria-valuemin={0}
          aria-valuemax={limit}
        />
      </div>
    );
  };

  const droppedFiles = () => {
    return (
      <>
        {files.length > 0 && (
          <div className={'list-group'}>
            <div className={'list-group-item fw-bold text-center'}>
              {t('dropzoneFilesToUploadTitle')}{' '}
              <small>
                {' '}
                {t('limitedTo', { count: apiConfig.default.maxUploadSize })} Mo
              </small>
            </div>

            <div className={'list-group-item text-center'}>{renderLimit()}</div>

            {files.map((file) => (
              <div className={'list-group-item'} key={file.name}>
                <span
                  onClick={() => removeFile(file.name)}
                  className={'me-1 qe-icon-wrapper text-primary'}>
                  <FontAwesomeIcon
                    title={t('cancel')}
                    className={'qe-icon'}
                    icon={['fas', 'trash']}
                  />
                </span>

                {file.name}

                <small> {deci(file.size / 1000000)} Mo</small>
              </div>
            ))}

            {uploadProgress > 0 && (
              <div className={'list-group-item text-center'}>
                {renderProgress()}
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  const removeFile = (filename) => {
    let copy = [];

    files.map((file) => {
      if (file.name !== filename) {
        copy = copy.concat(file);
      }

      return copy;
    });

    setFiles(copy);
  };

  const totalUploadSize = (format = false) => {
    let totalUploadSize = 0;

    files.map((file) => {
      return (totalUploadSize += file.size);
    });

    return format ? deci(totalUploadSize / 1000000) : totalUploadSize / 1000000;
  };

  const isUploadTooLarge = () => {
    return totalUploadSize() > apiConfig.default.maxUploadSize;
  };

  const getCurrentProgress = () => {
    let totalUploadSize = 0;

    files.map((file) => {
      return (totalUploadSize += file.size);
    });

    return (
      ((totalUploadSize / 1000000) * 100) / apiConfig.default.maxUploadSize
    );
  };

  const [accordion, setAccordion] = useState([false, false, false]);
  function toggleAccordion(tabIndex = 0) {
    const newState = [false, false, false];
    newState[tabIndex] = !accordion[tabIndex];

    setAccordion(newState);
  }

  // AR Settings.
  const [arSettings, setARSettings] = useState(arConfig.defaultPlayerSettings);
  function updateARSettings(newData) {
    setARSettings(newData);
    setRefresher(Date.now());
  }

  // Visibility Settings.
  const [visibility, setVisibility] = useState(1);
  const [visibilitySettingsGroups, setVisibilitySettingsGroups] = useState(
    groups.map((g) => {
      return iri(g, 'group');
    })
  );
  const [visibilitySettingsUsers, setVisibilitySettingsUsers] = useState(
    users.map((u) => {
      return iri(u, 'user');
    })
  );

  // Quotas.
  const [entity3, setEntity3] = useState({
    type: 'digitalAsset',
    currentValue: 58,
    maxValue: 100
  });
  const [entity4, setEntity4] = useState({
    type: 'storage',
    currentValue: 1,
    maxValue: 5
  });

  useEffect(() => {
    if (!userStateValues.isAuthenticated) {
      history.push('/error/403/403');
      return;
    }

    action({
      action: 'quota',
      resourceType: 'tenant',
      id: userStateValues.currentTenant['@uuid'],
      success: {
        callback: (quotas) => {
          const quotasCurrent = quotas.data.current['hydra:member'][0];
          setEntity3({
            type: 'digitalAsset',
            currentValue: quotasCurrent.assets.usage,
            maxValue:
              quotasCurrent.assets.quota !== -1
                ? quotasCurrent.assets.quota
                : -1
          });
          setEntity4({
            type: 'storage',
            currentValue: Math.round(quotasCurrent.storage.usage / 1000000),
            maxValue:
              quotasCurrent.storage.quota !== -1
                ? quotasCurrent.storage.quota / 1000000
                : -1
          });
        }
      }
    }).then();
    // eslint-disable-next-line
  }, [tenant]);

  const [isVariation, setIsVariation] = useState(false);
  const [isInVariationGroup, setIsInVariationGroup] = useState(false);
  const [variationParent, setVariationParent] = useState(null);
  const [variationGroup, setVariationGroup] = useState(null);
  const [variationGroups, setVariationGroups] = useState(null);

  const setVGroups = (val) => {
    setVariationParent(val);

    list({
      resourceType: 'variationGroup',
      params: [
        {
          label: 'digitalAsset',
          value: val ? val['@uuid'] ?? null : null
        },
        { label: 'tenant', value: tenant['@uuid'] }
      ],
      success: {
        callback: (res) => setVariationGroups(res.data['hydra:member'])
      }
    }).then();
  };

  const checkVariationParent = () => {
    if (!isVariation) {
      setVariationParent(null);
    }
  };

  const checkVariationGroup = (active) => {
    if (!active) {
      setVariationGroup(null);
    } else {
      if (null === variationGroup) {
        setVariationGroup(variationGroups[0]['@uuid']);
      }
    }
  };

  const switchVariationGroup = (value) => {
    setVariationGroup(value);
  };

  return (
    <>
      <form
        className={'last-refresh-' + refresher}
        name={'uploadNewFiles'}
        id={'uploadNewFiles'}
        onSubmit={handleSubmit}>
        <div className={'row'}>
          <div className={'col col-md-6'}>
            <div className={'dropzone mb-4'}>
              <div {...getRootProps()}>
                <input {...getInputProps()} />

                <div className={'dz-message'}>
                  <img
                    alt={'upload'}
                    className={'dz-teaser-img'}
                    src={cloudFile}
                  />

                  <div className={'dx-text'}>
                    {!isDragActive && t('dropzoneMessageDefault')}
                    {isDragActive && t('dropzoneMessageActive')}
                    {isUploadTooLarge() && (
                      <div className={'text-danger mt-2'}>
                        {t('dropzoneMessageTooLarge')}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={'col col-md-6'}>
            {0 !== tenants.length ? (
              <>
                <div>
                  <label htmlFor={'uploadForm-tenant'} className={'form-label'}>
                    {t('tenant')}
                  </label>
                  <Select
                    id={'uploadForm-tenant'}
                    defaultValue={userStateValues.currentTenant['@uuid']}
                    style={{ width: '100%' }}
                    optionLabelProp={'children'}
                    onChange={switchTenant}>
                    {tenants.map((tenant) => (
                      <Option key={tenant.name} value={tenant['@uuid']}>
                        {tenant.name}
                      </Option>
                    ))}
                  </Select>
                </div>

                <div className={'my-3'}>
                  <label htmlFor={'uploadForm-mode'} className={'form-label'}>
                    {t('uploadMode')}
                  </label>
                  <Select
                    id={'uploadForm-mode'}
                    defaultValue={uploadModes[1].name}
                    style={{ width: '100%' }}
                    optionLabelProp={'children'}
                    onChange={switchMode}>
                    {uploadModes.map((uploadMode) => (
                      <Option key={uploadMode.name} value={uploadMode.value}>
                        {uploadMode.name}
                      </Option>
                    ))}
                  </Select>
                </div>

                <div className={'accordion my-3'}>
                  <div
                    className={clsx('card card-box', {
                      'panel-open': accordion[0]
                    })}>
                    <div className={'card'}>
                      <div className={'card-header'}>
                        <div className={'panel-title'}>
                          <div className={'accordion-toggle'}>
                            <Button
                              color={'link'}
                              size={'lg'}
                              className={
                                'd-flex align-items-center justify-content-between'
                              }
                              onClick={() => toggleAccordion(0)}
                              aria-expanded={accordion[0]}>
                              <span>
                                <b>{t('viewerDisplayOptions')}</b>
                              </span>
                              <FontAwesomeIcon
                                icon={['fas', 'angle-up']}
                                className={'font-size-xl accordion-icon'}
                              />
                            </Button>
                          </div>
                        </div>
                      </div>

                      <Collapse isOpen={accordion[0]}>
                        <div className={'d-block p-2 mb-2'}>
                          <FormARSettings setter={updateARSettings} />
                        </div>
                      </Collapse>
                    </div>
                  </div>

                  <div
                    className={clsx('card card-box', {
                      'panel-open': accordion[1]
                    })}>
                    <div className={'card'}>
                      <div className={'card-header'}>
                        <div className={'panel-title'}>
                          <div className={'accordion-toggle'}>
                            <Button
                              color={'link'}
                              size={'lg'}
                              className={
                                'd-flex align-items-center justify-content-between'
                              }
                              onClick={() => toggleAccordion(1)}
                              aria-expanded={accordion[1]}>
                              <span>
                                <b>{t('visibilitySettings')}</b>
                              </span>
                              <FontAwesomeIcon
                                icon={['fas', 'angle-up']}
                                className={'font-size-xl accordion-icon'}
                              />
                            </Button>
                          </div>
                        </div>
                      </div>

                      <Collapse isOpen={accordion[1]}>
                        <div className={'d-block p-2 mb-2'}>
                          <FormVisibilitySettings
                            tenant={tenant}
                            setterG={setVisibilitySettingsGroups}
                            setterU={setVisibilitySettingsUsers}
                            setterV={setVisibility}
                          />
                        </div>
                      </Collapse>
                    </div>
                  </div>

                  <div
                    className={clsx('card card-box', {
                      'panel-open': accordion[2]
                    })}>
                    <div className={'card'}>
                      <div className={'card-header'}>
                        <div className={'panel-title'}>
                          <div className={'accordion-toggle'}>
                            <Button
                              color={'link'}
                              size={'lg'}
                              className={
                                'd-flex align-items-center justify-content-between'
                              }
                              onClick={() => toggleAccordion(2)}
                              aria-expanded={accordion[2]}>
                              <span>
                                <b>{t('treeStructure')}</b>
                              </span>
                              <FontAwesomeIcon
                                icon={['fas', 'angle-up']}
                                className="font-size-xl accordion-icon"
                              />
                            </Button>
                          </div>
                        </div>
                      </div>

                      <Collapse isOpen={accordion[2]}>
                        <div className={'d-block p-2 mb-2'}>
                          <div>
                            <Checkbox
                              checked={isVariation}
                              field={'uploadForm-modeVariation'}
                              label={
                                true === isVariation
                                  ? t('uploadModeVariation1')
                                  : t('uploadModeVariation')
                              }
                              toggle={(e) => {
                                setIsVariation(e.target.checked);
                                checkVariationParent();
                              }}
                            />

                            {isVariation && (
                              <div className={'ms-2 ps-4 pt-2 d-flex'}>
                                <AutocompleteAssets
                                  setter={setVGroups}
                                  settings={{
                                    displayLabel: false,
                                    fullObjects: true,
                                    params: [
                                      {
                                        label: 'tenant',
                                        value: tenant['@uuid'] ?? -1
                                      }
                                    ]
                                  }}
                                />
                              </div>
                            )}
                          </div>
                          {null !== variationParent && isVariation && (
                            <div>
                              <Checkbox
                                checked={isInVariationGroup}
                                field={'uploadForm-modeVariationGroup'}
                                label={
                                  true === isInVariationGroup
                                    ? t('uploadModeVariation2') + ' :'
                                    : t('uploadModeVariation2')
                                }
                                toggle={(e) => {
                                  setIsInVariationGroup(e.target.checked);
                                  checkVariationGroup(e.target.checked);
                                }}
                              />

                              {isInVariationGroup && null !== variationGroups && (
                                <div className={'ms-2 ps-4 pt-2 d-flex'}>
                                  <Select
                                    id={'variation-groups-form'}
                                    defaultValue={
                                      variationGroups[0]
                                        ? variationGroups[0].name
                                        : ''
                                    }
                                    style={{ width: '100%' }}
                                    optionLabelProp={'children'}
                                    onChange={switchVariationGroup}
                                    dropdownStyle={{ zIndex: 2500 }}>
                                    {variationGroups
                                      .sort((a, b) =>
                                        a.name > b.name ? 1 : -1
                                      )
                                      .map((item) => (
                                        <Option
                                          key={item.name}
                                          value={item['@uuid']}>
                                          {item.name}
                                        </Option>
                                      ))}
                                  </Select>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </div>

                <div>
                  <LaddaButton
                    type={'submit'}
                    className={'m-2 btn btn-primary'}
                    loading={sendingFiles}
                    disabled={isUploadTooLarge()}
                    data-style={ZOOM_IN}>
                    {t('submit')}
                  </LaddaButton>
                </div>
              </>
            ) : (
              <Loader type={'pacman'} optClasses={'vh-100'} />
            )}
          </div>
        </div>

        <div className={'row mt-4'}>
          <div
            className={
              'col col-md-6 d-flex justify-content-center align-items-center'
            }>
            <div className={'w-100'}>{droppedFiles()}</div>
          </div>
        </div>

        <div className={'row mt-5'}>
          <div className={'col col-lg-3 col-md-6 col-sm-12'}>
            <div>
              <QuotaTypeWrapper
                entity={entity3}
                quotaTypeType={'progress'}
                optionalClass={'card-box-tierce-rounded-bottom-right'}
                icon={'cubes'}
                color={appConfig.colors.dark.name}
              />
            </div>

            <div className={'d-lg-none'}>
              <QuotaTypeWrapper
                entity={entity4}
                quotaTypeType={'progress'}
                optionalClass={'card-box-tierce-rounded-bottom-right'}
                icon={'car-battery'}
                color={appConfig.colors.dark.name}
              />
            </div>
          </div>

          <div className={'col col-md-6'}>
            <div>
              {!plan ? <Loader type={'pacman'} /> : <PlanTeaser plan={plan} />}
            </div>
          </div>

          <div className={'col col-lg-3 col-md-6 col-sm-12'}>
            <div className={'d-none d-lg-block'}>
              <QuotaTypeWrapper
                entity={entity4}
                quotaTypeType={'progress'}
                optionalClass={'card-box-tierce-rounded-bottom-left'}
                icon={'car-battery'}
                color={appConfig.colors.dark.name}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default FormUploadDropzone;
