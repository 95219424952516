export const getQueryParam = (name, defaultValue = null, existing = null) => {
  const params = existing ?? getQueryParams();

  for (let i = 0; i < params.length; i++) {
    if (params[i].label === name) {
      return decodeURIComponent(params[i].value);
    }
  }

  return defaultValue ? decodeURIComponent(defaultValue) : '';
};

export const getQueryParams = (asString = false): any => {
  const query = window.location.search.substring(1);
  const stringParams = query.split('&');
  const params = [];

  for (let i = 0; i < stringParams.length; i++) {
    const pair = stringParams[i].split('=');

    if ('' !== pair[0] && undefined !== pair[1])
      params.push({ label: pair[0], value: pair[1] });
  }

  if (!asString) return params;

  let newStringParams = '';
  params.map((param) => {
    return (newStringParams += '&' + param.label + '=' + param.value);
  });

  return newStringParams.substring(1);
};

export const mergeQueryParams = (
  name,
  value,
  existing = null,
  removeOthers = false,
  replace = true,
  string = true
) => {
  const params = existing ?? getQueryParams();
  const newParams = [];
  let found = false;

  params.map((param) => {
    if (param.label === name) {
      found = true;
      newParams.push(
        replace
          ? { label: name, value: value }
          : { label: name, value: param.value + ',' + value }
      );
    } else {
      if (!removeOthers)
        newParams.push({ label: param.label, value: param.value });
    }

    return true;
  });

  if (!found) {
    newParams.push({ label: name, value: value });
  }

  if (!string) return newParams;

  let stringParams = '';
  newParams.map((param) => {
    return (stringParams += '&' + param.label + '=' + param.value);
  });

  return stringParams.substring(1);
};

export const removeQueryParams = (name, value, string = true, existing = null) => {
  const params = existing ?? getQueryParams();
  const newParams = params.filter((p) => p.label !== name && p.value !== value);

  if (!string) return newParams;

  let stringParams = '';
  newParams.map((param) => {
    return (stringParams += '&' + param.label + '=' + param.value);
  });

  return newParams.length > 0 ? stringParams.substring(1) : '';
};
