import React, { useState } from 'react';
import NiceModal, { useModal, bootstrapDialog } from '@ebay/nice-modal-react';
import { useTranslation } from 'react-i18next';
import { FormActions, PaymentInformationForm } from 'forms';
import BootstrapModal from 'react-bootstrap/Modal';
import { PaymentMethod } from 'interfaces';

const PaymentMethodModal = NiceModal.create(
  ({
    tenant,
    paymentMethod
  }: {
    tenant: object;
    paymentMethod: PaymentMethod;
  }) => {
    const modal = useModal();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(true);
    const [submitDisabled, setSubmitDisabled] = useState(true);

    return (
      <BootstrapModal {...bootstrapDialog(modal)}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>
            {t('tenantPaymentInformation')}
          </BootstrapModal.Title>
        </BootstrapModal.Header>

        <BootstrapModal.Body>
          <PaymentInformationForm
            tenant={tenant}
            paymentMethod={paymentMethod}
            setLoading={setLoading}
            setSubmitDisabled={setSubmitDisabled}
          />
        </BootstrapModal.Body>

        <BootstrapModal.Footer>
          <FormActions
            confirmAction={(e) => {
              setLoading(true);
            }}
            confirmDisabled={submitDisabled}
            confirmName={t('submit')}
            confirmForm={'tenant-payment-method-form'}
            loading={loading}
            displayCancel={true}
            cancelAction={modal.hide}
            cancelName={t('cancel')}
          />
        </BootstrapModal.Footer>
      </BootstrapModal>
    );
  }
);

export default PaymentMethodModal;
