import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import Select, { Option } from 'rc-select';

import arConfig from 'config/arConfig.json';
import { Checkbox } from 'forms';

export default function FormARSettings({
  digitalAsset = null,
  cruds = null,
  setter = null
}) {
  const { t } = useTranslation();
  const [sending, setSending] = useState(false);
  const [data, setData] = useState(null);

  // Ensure Int values.
  useEffect(() => {
    const data =
      null !== digitalAsset && digitalAsset.playerSettings
        ? digitalAsset.playerSettings ?? arConfig.defaultPlayerSettings
        : arConfig.defaultPlayerSettings;
    const newData =
      null !== digitalAsset && digitalAsset.playerSettings
        ? digitalAsset.playerSettings ?? arConfig.defaultPlayerSettings
        : arConfig.defaultPlayerSettings;

    ['gestures', 'anchors'].forEach((key) => {
      data[key].forEach((val, index) => {
        newData[key][index] = parseInt(val);
      });
    });

    setData(newData);
    // eslint-disable-next-line
  }, [digitalAsset]);

  const switchScale = (value) => {
    const newData = data;
    newData.scale = value;

    setData(newData);

    if (null !== setter) {
      return setter(newData);
    }

    if (!digitalAsset || !digitalAsset['@uuid'] || true !== cruds?.update?.authorized) return;

    handleSubmit();
  };

  const updateFormData = (e, key) => {
    const newData = data;
    const index = newData[key].indexOf(parseInt(e.target.value));

    if (index !== -1) {
      newData[key].splice(index, 1);
    } else {
      newData[key].push(parseInt(e.target.value));
    }

    setData(newData);

    if (null !== setter) {
      setter(newData);
    }

    if (!digitalAsset || !digitalAsset['@uuid'] || true !== cruds?.update?.authorized) return;

    handleSubmit();
  };

  const handleSubmit = (event = null) => {
    if (event) event.preventDefault();
    setSending(true);

    cruds.update.action({
      data: { playerSettings: data },
      success: {
        callback: () => {
          setSending(false);
        }
      }
    });
  };

  const anchorOptions = [
    { value: 1, label: t('anchorType1') },
    // { value: 2, label: t('anchorType2') },
    { value: 3, label: t('anchorType3') },
    { value: -1, label: t('anchorType-1') },
    { value: -2, label: t('anchorType-2') }
    // { value: -3, label: t('anchorType-3') }
  ];

  const gestureOptions = [
    { value: 1, label: t('gestureType1') },
    { value: 2, label: t('gestureType2') },
    { value: 3, label: t('gestureType3') }
    // { value: -1, label: t('gestureType-1') }
  ];

  const scaleOptions = [
    { value: 1.0, label: t('scale1') },
    { value: 0.1, label: t('scale01') },
    { value: 0.01, label: t('scale001') },
    { value: 0.001, label: t('scale0001') }
  ];

  return (
    <>
      {null !== data && (
        <>
          <div>
            <label htmlFor={'arSettingsScale'} className={'form-label mt-3'}>
              {t('arSettingsScale')}
            </label>
            <Select
              id={'arSettingsScale'}
              defaultValue={data.scale}
              onChange={switchScale}
              style={{ width: '100%' }}
              optionLabelProp={'children'}>
              {scaleOptions.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </div>

          <div className={'my-3'}>
            <label htmlFor={'arSettingsGestures'} className={'form-label mt-3'}>
              {t('arSettingsGestures')}
            </label>
            {gestureOptions.map((item, index) => (
              <Checkbox
                key={index}
                checked={-1 !== data['gestures'].indexOf(item.value)}
                field={'arSettingsGestures-' + index}
                label={item.label}
                value={item.value}
                toggle={(e) => updateFormData(e, 'gestures')}
              />
            ))}
          </div>

          <div className={'mb-3'}>
            <label htmlFor={'arSettingsAnchors'} className={'form-label mt-3'}>
              {t('arSettingsAnchors')}
            </label>
            {anchorOptions.map((item, index) => (
              <Checkbox
                key={index}
                checked={-1 !== data['anchors'].indexOf(item.value)}
                field={'arSettingsAnchors-' + index}
                label={item.label}
                value={item.value}
                toggle={(e) => updateFormData(e, 'anchors')}
              />
            ))}
          </div>

          {null === digitalAsset && null !== cruds && cruds.update.authorized && (
            <div className={'text-end'}>
              <LaddaButton
                type={'submit'}
                className={'m-2 btn btn-primary'}
                loading={sending}
                data-style={ZOOM_IN}
                onClick={handleSubmit}>
                {t('update')}
              </LaddaButton>
            </div>
          )}
        </>
      )}
    </>
  );
}
