export const getColors = ( plan = null ) => {
  const label = plan ? plan.label ?? null : null;
  let colors = {
    badge: '',
    background: ''
  };

  switch (label) {
    // color for PREMIUM and POWER plans
    case 'PREMIUM':
    case 'POWER':
      colors.badge = 'warning';
      colors.background = 'bg-mean-fruit';
      break;
    // color for PRO and TEAM plans
    case 'PRO':
    case 'TEAM':
      colors.badge = 'first';
      colors.background = 'bg-malibu-beach';
      break;
    // color for START and LIGHT plans
    case 'START':
    case 'LIGHT':
      colors.badge = 'info';
      colors.background = 'bg-tempting-azure';
      break;
    default:
      colors.badge = 'dark';
      colors.background = 'bg-vicious-stance';
      break;
  }

  return colors;
};
