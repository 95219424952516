import defaultImage from 'assets/images/logo.png';

export const getMediaSrc = (json, key = 'preview', param = 'contentUrl') => {
  let src = key === 'preview' ? defaultImage : null;
  if (json && json.medias) {
    json.medias.map(function(media) {
      if (media.key && media.key === key) {
        src = media[param] ?? defaultImage;
      }

      return media[param];
    });
  } else {
    // Fallback (to remove ?)
    if (json && json[key]) {
      src = json[key];
    }
  }

  return src;
};

export const getMediaObject = (json, key = 'preview') => {
  let object = null;
  if (json && json.medias) {
    json.medias.map(function(media) {
      if (media.key && media.key === key) {
        object = media;
      }

      return object;
    });
  }

  return object;
};

export const setMediaObject = (json, key = 'preview', newMedia = null) => {
  if (json && json.medias) {
    let mediasTmp = [];
    json.medias.map(function(media, index) {
      if (media.key && media.key === key) {
        mediasTmp.push(newMedia);
      } else {
        mediasTmp.push(media);
      }

      return true;
    });
    return mediasTmp;
  }
};
