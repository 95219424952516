import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getMediaSrc, iri } from 'utils';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import defaultImage from 'assets/images/avatars/defaultModelAvatar.png';

const TopXItem = ({ item, last = false, settings }) => {
  const { t } = useTranslation();
  let stat1 = (settings.userStats && item.statistics) ? item.statistics.view : null;
  let stat2 = (settings.userStats && item.statistics) ? (item.statistics.viewAR ? item.statistics.viewAR : 0) : null;
  let stat3 = (settings.userStats && item.statistics) ? (item.statistics.download ? item.statistics.download : 0) : null;

  return (
    <div className={settings.slider ? "position-relative" : "position-relative px-" + (settings.px ?? 5) + " py-3"}>
      {!last && settings.displayDivider && !settings.slider && (
        <div className={'divider-v divider-v-lg d-none d-xl-block'} />
      )}

      <div className={'avatar-icon-wrapper rounded-circle d-80 mx-auto'}>
        <div className={'d-block p-0 avatar-icon-wrapper rounded-circle m-0'}>
          <div className={'rounded-circle overflow-hidden'}>
            <Link to={iri(item, settings.type)}>
              <img alt={settings.imgAlt} className={'img-fluid'} src={getMediaSrc(item, settings.imgType) ?? (settings.defaultAssetImg ?? defaultImage)} />
            </Link>
          </div>
        </div>
      </div>

      <div>
        <div className={settings.slider ? "fw-bold mt-1 font-size-md text-black" : "fw-bold mt-1"}>{item.nickname ?? item.name ?? 'name'}</div>
        <div className={'d-flex align-items-center justify-content-center'}>
          {stat1 && (
            <div className={'fw-bold font-size-sm text-black-50 me-2'} title={t('view', {count: stat1})}>
              <FontAwesomeIcon
                icon={settings.icon}
                className={settings.iconClass}
              />{stat1}
            </div>
          )}
          {stat2 && (
            <div className={'fw-bold font-size-sm text-black-50 me-2'} title={t('viewAR', {count: stat2})}>
              <FontAwesomeIcon
                icon={['fas', 'cube']}
                className={'text-warning me-1'}
              />{stat2}
            </div>
          )}
          {stat3 && (
            <div className={'fw-bold font-size-sm text-black-50 me-2'} title={t('download', {count: stat3})}>
              <FontAwesomeIcon
                icon={['fas', 'download']}
                className={'text-info me-1'}
              />{stat3}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopXItem;
