import React from 'react';
import clsx from 'clsx';
import { Button } from 'reactstrap';
import {
  HeaderDots,
  HeaderDrawer,
  HeaderUserbox,
  HeaderSearch,
  HeaderMenu,
} from 'layout-components';
import { LanguageSwitcher } from 'components';
import {
  useThemeState,
  useThemeStateValues,
  useUserStateValues,
  login
} from 'states';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Header = () => {

  const user = useUserStateValues();
  const themeState = useThemeState();
  const themeOptions = useThemeStateValues();
  const { t } = useTranslation();

  function toggleSidebarMobile() {
    themeState.sidebarToggleMobile.set(!themeOptions.sidebarToggleMobile)
  }

  return (
    <>
      <div
        className={clsx('app-header', {
          'app-header--shadow': themeOptions.headerShadow,
          'app-header--opacity-bg': themeOptions.headerBgTransparent
        })}>
        <div className="app-header--pane">
            <button
              className={clsx(
                'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
                { 'is-active': themeOptions.sidebarToggleMobile }
              )}
              onClick={toggleSidebarMobile}
            >
            <span className="hamburger-box">
                <span className="hamburger-inner" />
            </span>
            </button>
            <HeaderSearch />
            <HeaderMenu />
            <LanguageSwitcher />
        </div>

        {user.isAuthenticated ? (
          <div className="app-header--pane">
            <HeaderDots />
            <HeaderUserbox />
            <HeaderDrawer />
          </div>
        ) : (
          <div className="app-header--pane">
            <Button
              className="m-2"
              id="qsLoginBtn"
              color="primary"
              onClick={() => login({ errorMessage: t('loginError') })}
            >
            <span className="btn-wrapper--icon">
              <FontAwesomeIcon
                icon={['fas', 'door-open']}
                className="font-size-lg"
              />
            </span>
              <span className="btn-wrapper--label">{t('login')}</span>
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

export default Header;
