import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { getMediaSrc, getColors, list, iri } from 'utils';
import {
  Badge,
  ListGroup,
  ListGroupItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap';
import { setCurrentTenant, useUserState } from 'states';

const SidebarTenantbox = ({ tenant, selected = '' }) => {
  const { t } = useTranslation();
  const classNameUsrBox =
    selected === 'selected'
      ? 'app-sidebar--userbox border'
      : 'app-sidebar--userbox';
  const userState = useUserState();
  const planLabel = tenant.plan
    ? tenant.plan.label ?? t('undefined')
    : t('undefined');
  const selectTenant = () => {
    setCurrentTenant({
      uState: userState,
      tenant: tenant
    });
  };

  const [userCount, setUserCount] = useState(0);
  const [assetCount, setAssetCount] = useState(0);

  useEffect(() => {
    const params = [{ label: 'tenant', value: tenant['@uuid'] }];

    list({
      resourceType: 'user',
      params: params,
      success: {
        callback: (res) => {
          setUserCount(res.data['hydra:totalItems']);
        }
      },
      notif: false
    });
    list({
      resourceType: 'digitalAsset',
      params: params,
      success: {
        callback: (res) => {
          setAssetCount(res.data['hydra:totalItems']);
        }
      },
      notif: false
    });
    // eslint-disable-next-line
  }, [tenant]);

  // Do not display anything if there are issues with tenants or their plans.
  if (!tenant) {
    return null;
  }

  return (
    <>
      <div className={classNameUsrBox} onClick={selectTenant}>
        <div className="card-tr-actions">
          <UncontrolledDropdown className="card-tr-actions">
            <DropdownToggle
              color="link"
              className="p-0 border-0 text-white-50 no-caret">
              <FontAwesomeIcon
                icon={['fas', 'ellipsis-h']}
                className="font-size-lg"
              />
            </DropdownToggle>
            <DropdownMenu
              right
              className=" text-nowrap overflow-hidden px-2 pt-2 pb-0">
              <ListGroup flush className="text-start bg-transparent">
                <ListGroupItem className="rounded-top p-2">
                  <div className="align-box-row align-items-center">
                    <div className="avatar-icon-wrapper avatar-icon-md">
                      <div className="avatar-icon rounded-circle">
                        <img
                          alt="logo-tenant"
                          src={getMediaSrc(tenant, 'avatar')}
                        />
                      </div>
                    </div>
                    <div className="ps-3">
                      <span className="pb-1 d-block">{tenant.name}</span>
                      <Badge
                        color={getColors(tenant.plan).badge}
                        className={'bg-' + getColors(tenant.plan).badge}>
                        {planLabel}
                      </Badge>
                    </div>
                  </div>
                </ListGroupItem>
                <ListGroupItem className="bg-transparent d-flex">
                  <div className="d-flex py-2">
                    <div className="pe-3">
                      <FontAwesomeIcon
                        icon={['fas', 'users']}
                        className="font-size-xxl text-info"
                      />
                    </div>
                    <div className="line-height-sm">
                      <b className="font-size-lg">{userCount}</b>
                      <span className="text-black-50 d-block">
                        {t('user_plural')}
                      </span>
                    </div>
                  </div>
                </ListGroupItem>
                <ListGroupItem className="bg-transparent d-flex">
                  <div className="d-flex py-2">
                    <div className="pe-3">
                      <FontAwesomeIcon
                        icon={['fas', 'cubes']}
                        className="font-size-xxl text-info"
                      />
                    </div>
                    <div className="line-height-sm">
                      <b className="font-size-lg">{assetCount}</b>
                      <span className="text-black-50 d-block">
                        {t('asset_plural')}
                      </span>
                    </div>
                  </div>
                </ListGroupItem>
              </ListGroup>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <div className="avatar-icon-wrapper avatar-icon-md">
          <Badge
            color={getColors(tenant.plan).badge}
            className={'badge-circle bg-' + getColors(tenant.plan).badge}>
            {planLabel}
          </Badge>
          <div className="avatar-icon rounded-circle">
            <img alt="logo-tenant" src={getMediaSrc(tenant, 'avatar')} />
          </div>
        </div>
        <div className="my-3 userbox-details">
          {tenant['name']}
          <Link
            className="d-block text-white-50"
            to={{ pathname: tenant.website }}
            target="_blank"
            rel="noopener noreferrer"
            title={tenant.name}>
            {tenant.website}
          </Link>
        </div>
        <Link className="btn-userbox" to={iri(tenant, 'tenant')}>
          <span onClick={selectTenant} className={'d-block my-1 mx-2'}>
            {t('dashboard')}
          </span>
        </Link>
      </div>
    </>
  );
};

export default SidebarTenantbox;
