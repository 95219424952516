import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DigitalAssetVariationForm,
  DigitalAssetVariationGroupForm
} from 'forms';
import { DatatableDefault, DatatableDigitalAssets } from 'components';
import { getCruds, getSource, iri, list } from 'utils';
import { useUserStateValues } from 'states';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DropdownMenu,
  DropdownToggle,
  Modal,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown
} from 'reactstrap';

const DatatableVariationGroups = ({
  digitalAsset = null,
  tenant = null,
  model = null,
  parent = null,
  data = null,
  settings = {},
  pagination = {},
  cruds = {}
}) => {
  const { t } = useTranslation();
  const userState = useUserStateValues();
  const resourceType = 'variationGroup';
  const [rows, setRows] = useState(data);
  const [reloaded, setReloaded] = useState(0);
  const defaultSettings = {
    ...{
      linkAssetAvailable: true,
      linkVariationGroupAvailable: false,
      hideIfEmpty: true
    },
    ...settings
  };

  const isCancelled = React.useRef(false);
  useEffect(() => {
    const source = getSource();

    if (null === data && digitalAsset) {
      const variationGroupsParams = [
        { label: 'digitalAsset', value: digitalAsset['@uuid'] },
        { label: 'order[weight]', value: 'ASC' }
      ];

      const baseFields = ['name', 'parent', 'variations', 'weight'];
      baseFields.map((prop) =>
        variationGroupsParams.push({ label: 'properties[]', value: prop })
      );
      const daFields = ['name', 'created', 'updated', 'version', 'medias'];
      daFields.map((prop) =>
        variationGroupsParams.push({
          label: 'properties[variations][digitalAsset][]',
          value: prop
        })
      );

      if (null !== parent) {
        variationGroupsParams.push({ label: 'parent', value: parent['@uuid'] });
      }

      list({
        resourceType: resourceType,
        params: variationGroupsParams,
        success: {
          callback: (res) => {
            if (isCancelled.current) return;

            if (null === parent) {
              setRows(
                res.data['hydra:member'].filter((g) => null === g.parent)
              );
            } else {
              setRows(res.data['hydra:member']);
            }
          }
        },
        aOptions: { cancelToken: source.token }
      }).then();
    }

    return () => {
      isCancelled.current = true;
      source.cancel();
      setRows(null);
    };
  }, [data, reloaded, parent, digitalAsset]);

  const columns = [
    {
      name: t('name'),
      selector: 'name',
      sortable: true,
      hide: 'sm',
      center: true
    },
    {
      name: t('actions'),
      selector: 'actions',
      center: true,
      cell: (row) => (
        <UncontrolledDropdown className="mx-3">
          <DropdownToggle
            color="link"
            className="p-0 border-0 text-blue-50 no-caret">
            <FontAwesomeIcon
              icon={['fas', 'ellipsis-h']}
              className="font-size-lg"
            />
          </DropdownToggle>
          <DropdownMenu right className="text-nowrap p-0">
            <Nav className="flex-column">
              <NavItem>
                <NavLink
                  href="#"
                  onClick={() => {
                    toggleEditVariationGroupModal();
                    setVariationGroupEdit(row);
                  }}>
                  <div className="nav-link-icon">
                    <FontAwesomeIcon icon={['fas', 'edit']} />
                  </div>
                  <span>{t('editVariationGroup') + ' ' + row.name}</span>
                </NavLink>
              </NavItem>
              {defaultSettings.linkAssetAvailable && (
                <NavItem>
                  <NavLink
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      toggleAddAssetModal(row);
                    }}>
                    <div className="nav-link-icon">
                      <FontAwesomeIcon icon={['fas', 'cube']} />
                    </div>
                    <span>
                      {t('linkAssetToVariationGroupShort') +
                        ' ' +
                        t('to') +
                        ' ' +
                        row.name}
                    </span>
                  </NavLink>
                </NavItem>
              )}
              {defaultSettings.linkVariationGroupAvailable && (
                <NavItem>
                  <NavLink
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      toggleAddVariationGroupModal(row);
                    }}>
                    <div className="nav-link-icon">
                      <FontAwesomeIcon icon={['fas', 'folder']} />
                    </div>
                    <span>
                      {t('linkItemToContent', {
                        item: t('variationGroup'),
                        content: row.name
                      })}
                    </span>
                  </NavLink>
                </NavItem>
              )}
            </Nav>
          </DropdownMenu>
        </UncontrolledDropdown>
      )
    }
  ];

  const [variationGroupEdit, setVariationGroupEdit] = useState(null);
  const [modalVariationGroupEdit, setModalVariationGroupEdit] = useState(false);
  const toggleEditVariationGroupModal = () =>
    setModalVariationGroupEdit(!modalVariationGroupEdit);

  // Be careful ! data = row (locked param name)
  const ExpandedComponent = ({ data }) => {
    const settingsAssetsDatatable = {
      // <-- @TODO set correct settings here
      tableId: 'dav-sub-table-assets-lists-' + (parent ? parent['@uuid'] : ''),
      title: t('asset_plural'),
      loading: false,
      subdatatable: true,
      hideHeaders: true,
      removeAction: true,
      datatableParent: 'variationGroup',
      parent: data,
      canReload: false
    };

    // Let's organize a bit.
    const digitalAssets = (data.variations ?? []).map((v) => {
      return { ...v.digitalAsset, ...{ _variationId: v['@uuid'] } };
    });

    return (
      <>
        {digitalAssets.length > 0 && (
          <DatatableDigitalAssets
            data={digitalAssets}
            settings={settingsAssetsDatatable}
          />
        )}

        <DatatableVariationGroups digitalAsset={digitalAsset} parent={data} />
      </>
    );
  };

  const [selectedVariationGroup, setSelectedVariationGroup] = useState(null);
  const [modalAddAsset, setModalAddAsset] = useState(false);
  const toggleAddAssetModal = (vGroup = null) => {
    setModalAddAsset(!modalAddAsset);
    setSelectedVariationGroup(vGroup);
  };

  const variationFormSetting = digitalAsset
    ? {
        redirectPath: iri(digitalAsset, 'digitalAsset'),
        originUuid: digitalAsset['@uuid']
      }
    : {};

  const crudsDefaults = getCruds({
    resourceType: 'variationGroup',
    userState,
    translator: t
  });

  const variationGroupFormSetting = {
    redirectPath: iri(digitalAsset, 'digitalAsset')
  };

  const [modalAddVariationGroup, setModalAddVariationGroup] = useState(false);
  // const [variationGroupSelected, setVariationGroupSelected] = useState(null);
  const toggleAddVariationGroupModal = (value) => {
    // setVariationGroupSelected(value);
    setModalAddVariationGroup(!modalAddVariationGroup);
  };

  return (
    <>
      {((rows && rows.length > 0) ||
        (rows && false === defaultSettings.hideIfEmpty)) && (
        <>
          <DatatableDefault
            columns={columns}
            data={rows}
            tenant={tenant}
            settings={{
              ...{
                tableId: 'dav-' + (digitalAsset ? digitalAsset['@uuid'] : ''),
                title: t('variationGroup_plural'),
                expandableRows: true,
                expandableRowsComponent: <ExpandedComponent data={rows} />,
                // user: userUuid,
                selectableRows: true,
                hideHeaders: false,
                loading: false,
                displayTableHeaders: true,
                hideAddButton: null !== model,
                modalForm: (
                  <DigitalAssetVariationGroupForm
                    digitalAsset={digitalAsset}
                    settings={variationGroupFormSetting}
                    toggleModal={toggleAddVariationGroupModal}
                    model={model}
                  />
                ),
                toggleModal: toggleAddVariationGroupModal,
                modalOpen: modalAddVariationGroup
              },
              ...defaultSettings
            }}
            pagination={{
              ...{
                orderBy: 'weight',
                sortDirection: 'ASC'
              },
              ...pagination
            }}
            cruds={{
              ...crudsDefaults,
              ...cruds
            }}
          />

          <Modal
            zIndex={2000}
            centered
            isOpen={modalAddAsset}
            toggle={toggleAddAssetModal}>
            <div className="text-center p-5">
              <div className="avatar-icon-wrapper rounded-circle m-0">
                <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-info text-info m-0 d-130">
                  <FontAwesomeIcon
                    icon={['fas', 'cube']}
                    className="d-flex align-self-center display-6"
                  />
                </div>
              </div>
              <h4 className="fw-bold mt-4">{t('linkAssetToVariationGroup')}</h4>
            </div>

            <div className="p-5">
              <DigitalAssetVariationForm
                settings={{
                  ...variationFormSetting,
                  ...{
                    callback: () => {
                      setReloaded(reloaded + 1);
                    }
                  }
                }}
                selectedVariationGroup={selectedVariationGroup}
                toggleModal={toggleAddAssetModal}
                selectedAsset={digitalAsset}
              />
            </div>
          </Modal>

          <Modal
            zIndex={2000}
            centered
            isOpen={modalVariationGroupEdit}
            toggle={toggleEditVariationGroupModal}>
            <div className="p-5">
              {null !== variationGroupEdit && (
                <DigitalAssetVariationGroupForm
                  uuid={variationGroupEdit['@uuid']}
                  toggleModal={toggleEditVariationGroupModal}
                />
              )}
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default DatatableVariationGroups;
