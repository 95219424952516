import { State, createState, useState } from '@hookstate/core';
import config from 'config/billingConfig.json';

interface BillingConfig {
  priceRecurrency: string
}

const state: State<BillingConfig> = createState(config);

export const useBillingState = () => useState(state);
export const useBillingStateValues = () => useBillingState().value;
