import React from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const ProfileTabs = ({ activeTab = null, tabs = null }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className={'tabs-animated tabs-animated-line'}>
      <ul className={'nav nav-tabs nav-justified'}>
        {(tabs ?? [])
          .sort((a, b) => ((a.index ?? 0) > (b.index ?? 0) ? 1 : -1))
          .map(
            (tab, index) =>
              tab.display && (
                <li key={tab.label} className={'nav-item'}>
                  <button
                    className={clsx('nav-link btn-link btn d-block', {
                      active:
                        activeTab === tab.tab ||
                        (null === activeTab && 0 === index)
                    })}
                    onClick={() =>
                      history.push(tab.display ?? true ? tab.link : '#')
                    }>
                    {t(tab.label)}
                  </button>
                </li>
              )
          )}
      </ul>
    </div>
  );
};

export default ProfileTabs;
