import React from 'react';
import Switch from 'rc-switch';

const SwitchField = ({
  toggle,
  label = '',
  checked = false,
  size = 'medium',
  color = 'primary',
  classname = 'd-flex align-items-center mt-3 justify-content-end'
}) => {
  return (
    <div className={classname}>
      <span className={'d-block pr-2'}>{label}</span>
      <Switch
        checked={checked}
        className={'switch-' + size + ' toggle-switch-' + color}
        onChange={toggle}
      />
    </div>
  );
};

export default SwitchField;
