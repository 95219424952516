import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CategoryForm } from 'forms';
import { DatatableDefault, DatatableDigitalAssets, Modal } from 'components';
import { getCruds, getSource, read, update } from 'utils';
import { useUserStateValues } from 'states';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';

const DatatableCategories = ({ data = null, tenant = null }) => {
  const { t } = useTranslation();
  const userState = useUserStateValues();
  const isCancelled = React.useRef(false);

  const columns = [
    {
      name: t('category_plural'),
      selector: 'name'
    },
    {
      name: t('priority'),
      selector: 'weight',
      sortable: true,
      omit: true
    },
    {
      name: t('actions'),
      selector: 'actions',
      center: true,
      cell: (row) => (
        <div
          className={'d-flex align-items-center w-100 justify-content-evenly'}>
          <button
            type={'button'}
            className={'btn btn-link d-f-0150'}
            onClick={() => {
              setSelectedCategory(row);
            }}
            data-bs-toggle={'modal'}
            data-bs-target={'#categories-table-datatableEdit'}
            data-tip
            data-for={'edit-row-' + row['@uuid']}>
            <span className={'btn-wrapper--icon me-2'}>
              <FontAwesomeIcon icon={['fas', 'edit']} />
              <ReactTooltip
                id={'edit-row-' + row['@uuid']}
                type={'info'}
                effect={'solid'}>
                <span>{t('edit')}</span>
              </ReactTooltip>
            </span>
          </button>

          <button
            type={'button'}
            className={'btn btn-link d-f-0150'}
            onClick={() => {
              changePriority('raise', row);
            }}>
            <span className={'btn-wrapper--icon me-2'}>
              <FontAwesomeIcon icon={['fas', 'chevron-up']} />
              <ReactTooltip
                id={'raise-' + row['@uuid']}
                type={'info'}
                effect={'solid'}>
                <span>{t('raisePriority')}</span>
              </ReactTooltip>
            </span>
          </button>

          <button
            type={'button'}
            className={'btn btn-link d-f-0150'}
            onClick={() => {
              changePriority('lower', row);
            }}>
            <span className={'btn-wrapper--icon me-2'}>
              <FontAwesomeIcon icon={['fas', 'chevron-down']} />
              <ReactTooltip
                id={'raise-' + row['@uuid']}
                type={'info'}
                effect={'solid'}>
                <span>{t('lowerPriority')}</span>
              </ReactTooltip>
            </span>
          </button>
        </div>
      )
    }
  ];

  const changePriority = (priority, object) => {
    const categories = data['hydra:member'];
    categories.sort(function (a, b) {
      return b.weight - a.weight;
    });

    categories.map((c, index) => {
      if (c.name === object.name) {
        let weightDataObject = {};
        let lastItem = false;
        weightDataObject['weight'] = object.weight;
        if ('raise' === priority) {
          weightDataObject['weight']++;
          lastItem = !!categories[index - 1];
        }
        if ('lower' === priority) {
          weightDataObject['weight']--;
          lastItem = !!categories[index + 1];
        }

        const paramsObject = {
          resourceType: 'digitalAssetsCategory',
          data: weightDataObject,
          success: {
            message: t('categoryOrderSucceeded')
          },
          error: {
            message: t('categoryOrderErrored')
          },
          notif: true
        };

        if (true === lastItem) {
          update({ ...paramsObject, ...{ id: object['@uuid'] } });
        }
      }

      return true;
    });
  };

  // Be careful ! data = row (locked param name)
  const ExpandedComponent = ({ data }) => {
    const [category, setCategory] = useState(null);
    const settingsDigitalAssets = {
      tableId: 'digitalassets-sub-table',
      title: t('digitalAssetsList'),
      loading: false,
      subdatatable: true,
      removeAction: true,
      datatableParent: 'category',
      parent: data,
      canReload: false
    };

    useEffect(() => {
      const source = getSource();

      read({
        resourceType: 'digitalAssetsCategory',
        id: data['@uuid'],
        success: {
          callback: (res) => {
            if (isCancelled.current) return;

            if (res.data) {
              setCategory(res.data);
            }
          }
        },
        aOptions: { cancelToken: source.token }
      }).then();

      return () => {
        isCancelled.current = true;
        source.cancel();
        setCategory(null);
      };

      // eslint-disable-next-line
    }, []);

    return (
      <>
        {category && (
          <DatatableDigitalAssets
            data={category.digitalAssets ?? null}
            settings={settingsDigitalAssets}
          />
        )}
      </>
    );
  };

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [cruds, setCruds] = useState(null);

  useEffect(() => {
    const newCruds = getCruds({
      resourceType: 'digitalAssetsCategory',
      userState,
      translator: t
    });

    setCruds(newCruds);

    return () => {
      setCruds(null);
    };

    // eslint-disable-next-line
  }, []);

  return (
    cruds && (
      <>
        <DatatableDefault
          columns={columns}
          data={data}
          tenant={tenant}
          settings={{
            tableId: 'categories-table',
            title: t('category_plural'),
            selectedMessage: {
              singular: t('category'),
              plural: t('category_plural')
            },
            expandableRows: true,
            expandableRowsComponent: <ExpandedComponent data={data} />,
            contextActionType: 'merge',
            modals: {
              actions: [
                {
                  label: 'add',
                  modal: (
                    <Modal
                      options={{
                        id: 'categories-table-datatableAdd',
                        icon: {
                          name: 'users'
                        },
                        title: t('addCategory'),
                        useFooter: false
                      }}>
                      <CategoryForm />
                    </Modal>
                  )
                }
              ]
            }
          }}
          pagination={{
            orderBy: 'weight',
            sortDirection: 'DESC'
          }}
          cruds={cruds}
        />

        <Modal
          options={{
            id: 'categories-table-datatableEdit',
            icon: {
              name: 'edit'
            },
            title:
              t('edit') +
              ' ' +
              (selectedCategory ? selectedCategory.name ?? '' : ''),
            useFooter: false
          }}>
          <CategoryForm entity={selectedCategory} />
        </Modal>
      </>
    )
  );
};

export default DatatableCategories;
