import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useViewerState, useViewerStateValues } from 'states';
import { Button, Row, Col, Label, ListGroup, ListGroupItem } from 'reactstrap';
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import Select, { Option, OptGroup } from 'rc-select';
import { ModelViewerElement } from '@google/model-viewer';
import { saveAs } from 'file-saver';
import { action, deci, nError } from 'utils';
import { isSafari } from 'react-dnd-html5-backend/lib/BrowserDetector';
import { useDropzone } from 'react-dropzone';
import apiConfig from 'config/apiConfig.json';
import cloudFile from 'assets/images/baseline-cloud_upload-24px.svg';
import Cropper from 'react-cropper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function FormDisplayOptions({ digitalAsset, cruds }) {
  const { t } = useTranslation();
  const viewerState = useViewerState();
  const viewerStateValues = useViewerStateValues();
  const [uploadPreview, setUploadPreview] = useState(false);
  const allowedImgExtensions = ['png', 'jpg', 'jpeg'];

  const toggleSkybox = () => {
    viewerState.skyboxToggle.set(!viewerStateValues.skyboxToggle);
  };

  const switchEnvironment = (value, option) => {
    viewerState.currentEnvironment.set(value);
    digitalAsset = {
      ...digitalAsset,
      ...{ viwerConfig: { envSrc: '../shared-assets/backgrounds/' + value } }
    };
  };

  const takeScreenshot = (download = true) => {
    const viewer = document.getElementById(
      'model-viewer-element'
    ) as ModelViewerElement;
    viewer.fieldOfView = 'auto';
    viewer.jumpCameraToGoal();
    setUploadPreview(true);

    if (download) {
      viewer
        .toBlob({ mimeType: 'image/png', idealAspect: true })
        .then((data) => {
          // Just download the file.
          saveAs(data, digitalAsset.name + '-preview.png');
          setUploadPreview(false);
        });
    } else {
      // Replace the DigitalAsset preview.
      sendRequest({ base64: viewer.toDataURL(), filename: digitalAsset.name + '-preview.png' }, digitalAsset['@uuid'], 'jsonContent');
    }
  };

  const environmentOptions = [
    { value: 'colorful_studio_1k.hdr', label: t('viewerEnvironment1') },
    { value: 'greenwich_park_02_1k.hdr', label: t('viewerEnvironment2') },
    { value: 'san_giuseppe_bridge_1k.hdr', label: t('viewerEnvironment3') },
    { value: 'spiaggia_di_mondello_1k.hdr', label: t('viewerEnvironment4') }
  ];

  const updateMedias = (base64File = null) => {
    const identifier = digitalAsset['@uuid'];
    const formData = new FormData();
    setUploadPreview(true);


    files.map((file, index) => {
      if (null !== previewFileRow) {
        return file.name !== previewFileRow.name ? formData.append('file' + index, file, file.name) : formData.append('preview', file, file.name);
      } else {
        return formData.append('file' + index, file, file.name);
      }
    });

    if (null === base64File && null !== previewFile) {
      cropper.getCroppedCanvas().toBlob((blob) => {
        formData.append(
          'preview',
          blobToFile(blob, previewFileRow.name),
          previewFileRow.name
        );

        sendRequest(formData, identifier);
      });
    } else {
      sendRequest(formData, identifier);
    }
  };

  const sendRequest = (data, identifier, paramType = 'postParams') => {
    action({
      resourceType: 'digitalAsset',
      id: identifier,
      action: 'updateMedia',
      paramType: paramType,
      data: data,
      success: {
        message: t('crudUpdateSucceeded', { itemName: digitalAsset.name })
      },
      always: {
        callback: () => {
          setUploadPreview(false);
          setPreviewStatus('dropzone');
        }
      },
      notif: true
    });
  };

  const blobToFile = (theBlob, fileName) => {
    return new File([theBlob], fileName, {
      lastModified: new Date().getTime(),
      type: theBlob.type
    });
  };

  // Dropzone.
  // Init form state
  const [previewFile, setPreviewFile] = useState(null);
  const [previewFileRow, setPreviewFileRow] = useState(null);
  const [files, setFiles] = useState([]);
  const [cropper, setCropper] = useState(null);
  const [previewStatus, setPreviewStatus] = useState('dropzone');
  const [tooManyImgFiles, setTooManyImgFiles] = useState(false);

  const onDropAccepted = (droppedFiles) => {
    checkIfTooManyImgFiles(droppedFiles);
  };

  const checkIfTooManyImgFiles = (droppedFiles) => {
    const droppedFilesWithExtensions = droppedFiles.filter((d) =>
      allowedImgExtensions.includes(d.name.split('.').pop())
    );
    switch (droppedFilesWithExtensions.length) {
      case 0:
        break;
      case 1:
        if (null === previewFile) {
          setPreviewFileRow(droppedFilesWithExtensions[0]);
          let reader = new FileReader();
          reader.readAsDataURL(droppedFilesWithExtensions[0]);
          reader.onloadend = () => {
            setPreviewFile(reader.result);
            setPreviewStatus('cropper');
          };
          setTooManyImgFiles(false);
        }
        break;
      default:
        setTooManyImgFiles(true);
        break;
    }
  };

  const onDropRejected = (fileRejections) => {
    fileRejections[0].errors.map((e) => nError({ message: t(e.code) }));
  };

  const onDrop = useCallback(
    (droppedFiles) => {
      setFiles(files.concat(droppedFiles));
    },
    [files]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropAccepted,
    onDropRejected,
    minSize: 0,
    maxSize: apiConfig.default.maxUploadSize * 1000000
  });

  const totalUploadSize = (format = false) => {
    let totalUploadSize = 0;

    files.map((file) => {
      return (totalUploadSize += file.size);
    });

    return format ? deci(totalUploadSize / 1000000) : totalUploadSize / 1000000;
  };

  const isUploadTooLarge = () => {
    return totalUploadSize() > apiConfig.default.maxUploadSize;
  };

  const toogleAvatar = (event, value = 'dropzone') => {
    event.preventDefault();
    setPreviewStatus(value);
    if ('dropzone' === value) {
      removeFile(previewFileRow.name);
      setPreviewFile(null);
      setPreviewFileRow(null);
    }
  };

  const removeFile = (filename) => {
    let copy = [];

    if (null !== previewFile) {
      if (filename === previewFileRow.name) {
        setPreviewFile(null);
        setPreviewFileRow(null);
        setPreviewStatus('dropzone');
      }
    }
    files.map((file) => {
      if (file.name !== filename) {
        copy = copy.concat(file);
      }

      return copy;
    });

    setFiles(copy);
    checkIfTooManyImgFiles(copy);
  };

  const onCropperInit = (cropper) => {
    setCropper(cropper);
  };

  return (
    <>
      <div className={'form-group'} data-children-count="1">
        <Label htmlFor="viewerEnvironment" className="">
          {t('viewerEnvironment')}
        </Label>
        <Select
          id="viewerEnvironment"
          defaultValue={viewerStateValues.currentEnvironment}
          onChange={switchEnvironment}
          style={{ width: '100%' }}
          optionLabelProp={'children'}>
          <OptGroup label="HDR">
            {environmentOptions.map((item) => (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            ))}
          </OptGroup>
          <OptGroup>
            <Option key={'none'} value={'none'}>
              {t('original')}
            </Option>
          </OptGroup>
        </Select>
      </div>

      <div className={'form-check form-switch form-switch-lg my-3'}>
        <input
          className={'form-check-input'}
          type={'checkbox'}
          id={'viewerDisplaySkybox'}
          checked={viewerStateValues.skyboxToggle}
          onChange={toggleSkybox}
        />
        <label className={'form-check-label'} htmlFor={'viewerDisplaySkybox'}>
          {t('viewerDisplaySkybox')}
        </label>
      </div>

      {cruds.update.authorized && (
        <Row className={'text-center'}>
          {!isSafari() && (
            <>
              <Col md={'6'} xs={'12'}>
                <LaddaButton
                  className={'m-2 btn btn-primary'}
                  loading={uploadPreview}
                  onClick={() => takeScreenshot(true)}
                  data-style={ZOOM_IN}>
                  {t('viewerTakeAScreenshot')}
                </LaddaButton>
              </Col>

              <Col md={'6'} xs={'12'}>
                <LaddaButton
                  className={'m-2 btn btn-primary'}
                  loading={uploadPreview}
                  onClick={() => takeScreenshot(false)}
                  data-style={ZOOM_IN}>
                  {t('viewerReplacePreview')}
                </LaddaButton>
              </Col>
            </>
          )}

          <Col className={'mt-2'}>
            {'dropzone' === previewStatus ? (
              <>
                <div className="dropzone mb-3">
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="dz-message">
                      <img
                        alt="upload"
                        className="dz-teaser-img"
                        src={cloudFile}
                      />

                      <div className="dx-text">
                        {!isDragActive && t('dropzoneMessageDefault')}
                        {isDragActive && t('dropzoneMessageActive')}
                        {isUploadTooLarge() && (
                          <div className="text-danger mt-2">
                            {t('dropzoneMessageTooLarge')}
                          </div>
                        )}
                        {true === tooManyImgFiles && (
                          <div className="text-danger mt-2">
                            {t('dropzoneMessageTooManyImgFiles')}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {previewFile && (
                  <>
                    <Cropper
                      src={previewFile}
                      style={{ height: 200, width: '100%' }}
                      aspectRatio={1}
                      minCropBoxWidth={150}
                      onInitialized={onCropperInit}
                    />
                  </>
                )}
              </>
            )}
            <>
              {files.length > 0 && (
                <>
                  <ListGroup>
                    <ListGroupItem className="fw-bold text-center">
                      {t('dropzoneFilesToUploadTitle')}{' '}
                      <small>
                        {' '}
                        {t('limitedTo', {
                          count: apiConfig.default.maxUploadSize
                        })}{' '}
                        Mo
                      </small>
                    </ListGroupItem>

                    {files.map((file, index) => (
                      <ListGroupItem key={file.name}>
                        <span
                          onClick={() => removeFile(file.name)}
                          className={'me-1 qe-icon-wrapper text-primary'}>
                          <FontAwesomeIcon
                            title={t('cancel')}
                            className={'qe-icon'}
                            icon={['fas', 'trash']}
                          />
                        </span>

                        {file.name}

                        <small> {deci(file.size / 1000000)} Mo</small>
                      </ListGroupItem>
                    ))}
                    <div className={'mt-2'}>
                      <LaddaButton
                        className={'m-2 btn btn-primary'}
                        loading={uploadPreview}
                        onClick={(e) => updateMedias()}
                        data-style={ZOOM_IN}>
                        {t('validate')}
                      </LaddaButton>

                      <Button
                        color={'link'}
                        className={'ms-5 btn btn-link btn-link-danger'}
                        onClick={(e) => toogleAvatar(e, 'dropzone')}>
                        {t('cancel')}
                      </Button>
                    </div>
                  </ListGroup>
                </>
              )}
            </>
          </Col>
        </Row>
      )}
    </>
  );
}
