import React from 'react';

import { Card } from 'reactstrap';
import Chart from 'react-apexcharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

const QuotaType4 = ({ entity = null, optionalClass = '' }) => {
  const type = entity ? entity.type ?? 'user' : 'user';
  const oldData = entity ? entity.data ?? [0, 500, 200, 350] : [0, 500, 200, 350];
  const lastValue = oldData[oldData.length - 2];
  const newValue = oldData[oldData.length - 1];
  const percentage = Math.abs(
    Math.round(((lastValue - newValue) / lastValue) * 100)
  );
  let iconType;
  let iconColor;
  let graphColor;
  if (lastValue - newValue < 0) {
    iconType = ['fas', 'angle-up'];
    iconColor = 'text-success';
    graphColor = ['#1bc943'];
  } else {
    iconType = ['fas', 'angle-down'];
    iconColor = 'text-danger';
    graphColor = ['#f83245'];
  }
  const chart51Options = {
    chart: {
      toolbar: {
        show: false
      },
      sparkline: {
        enabled: true
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      color: '#1bc943',
      width: 3
    },
    colors: graphColor,
    legend: {
      show: false
    },
    xaxis: {
      crosshairs: {
        width: 0
      }
    },
    markers: {
      size: 0
    },
    yaxis: {
      min: 0
    }
  };
  const chart51Data = [
    {
      data: oldData
    }
  ];

  return (
    <>
      <Card className={clsx('ps-3 pt-3 mb-5 ' + optionalClass )}>
        <div className="d-flex justify-content-between">
          <div className="pe-4 flex-grow-1 text-nowrap">
            <div className="fw-bold">
              <div className="font-size-xxl mb-1">
                {'storage' === type ? (
                  newValue + ' Mo'
                ) : ('bandwidth' === type ? (
                  newValue + ' Ko/s'
                ) : (newValue))}
              </div>
              <div className={iconColor}>
                <span>{percentage}%</span>
                <FontAwesomeIcon icon={iconType} className="ms-2" />
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center pt-2">
            <Chart
              options={chart51Options}
              series={chart51Data}
              type="area"
              height={104}
            />
          </div>
        </div>
      </Card>
    </>
  );
};

export default QuotaType4;
