import React from 'react';
import { DashboardStats } from 'components';
import { TenantDigitalAssets } from '..';

const TenantDashboard = ({ tenant }) => {
  return (
    <>
      <div className={'row'}>
        <div className={'col col-md-12'}>
          <DashboardStats showFooter={false} tenant={tenant} />
        </div>
      </div>

      <TenantDigitalAssets tenant={tenant} itemsPerPage={5} />
    </>
  );
};

export default TenantDashboard;
