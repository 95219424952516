import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import Select, { Option } from 'rc-select';
import { iri, list } from 'utils';
import { AutocompleteUsers } from 'components';

export default function FormVisibilitySettings({
  digitalAsset = null,
  tenant = null,
  cruds = null,
  setterV = null,
  setterG = null,
  setterU = null
}) {
  const { t } = useTranslation();
  const [sending, setSending] = useState(false);
  if (null === tenant && null !== digitalAsset) tenant = digitalAsset.tenant;
  const [groups, setGroups] = useState([{ '@uuid': -1, name: t('any') }]);
  const [visibility, setVisibility] = useState(
    null !== digitalAsset && digitalAsset.visibility
      ? digitalAsset.visibility ?? 1
      : 1
  );

  const visibilityOptions = [
    { value: 1, label: t('visibilityOption1') },
    { value: -1, label: t('visibilityOption-1') },
    { value: 0, label: t('visibilityOption0') }
  ];

  useEffect(() => {
    if (null !== tenant) {
      list({
        resourceType: 'group',
        params: [
          { label: 'tenant', value: tenant ? iri(tenant, 'tenant') : null }
        ],
        success: {
          callback: (res) => {
            let newRes = [{ '@uuid': -1, name: t('any') }];
            res.data['hydra:member'].forEach((item) => {
              newRes.push(item);
            });
            setGroups(newRes);
          }
        }
      }).then();
    }
    // eslint-disable-next-line
  }, [digitalAsset, tenant]);

  const switchVisibility = (value) => {
    setVisibility(value);

    if (null !== setterV) setterV(value);

    if (!digitalAsset || !digitalAsset['@uuid'] || true !== cruds?.update?.authorized) return;

    handleSubmit();
  };
  const [visibilitySettingsGroups, setVisibilitySettingsGroups] = useState(
    null !== digitalAsset && digitalAsset.visibilitySettings
      ? digitalAsset.visibilitySettings.groups ?? ['/groups/-1']
      : ['/groups/-1']
  );
  const switchVisibilityGroups = (value) => {
    setVisibilitySettingsGroups(value);

    if (null !== setterG) setterG(value);

    if (!digitalAsset || !digitalAsset['@uuid'] || true !== cruds?.update?.authorized) return;

    handleSubmit();
  };
  const [visibilitySettingsUsers, setVisibilitySettingsUsers] = useState(
    null !== digitalAsset && digitalAsset.visibilitySettings
      ? digitalAsset.visibilitySettings.users ?? ['/users/-1']
      : ['/users/-1']
  );

  const handleSubmit = (event = null) => {
    if (event) event.preventDefault();
    setSending(true);

    cruds.update.action({
      data: {
        visibility: visibility,
        visibilitySettings: {
          groups: visibilitySettingsGroups,
          users: visibilitySettingsUsers
        }
      },
      success: {
        callback: () => {
          setSending(false);
        }
      }
    });
  };

  const autocompleteUsersSettings = {
    params: [{ label: 'memberships.tenant', value: iri(tenant, 'tenant') }],
    multiple: true,
    fullObjects: false
  };

  return (
    <>
      {null !== visibility && (
        <>
          <div>
            <label htmlFor={'visibility'} className={'form-label mt-3'}>
              {t('visibility')} :
            </label>
            <Select
              id={'visibility'}
              defaultValue={visibility}
              onChange={switchVisibility}
              style={{ width: '100%' }}
              optionLabelProp={'children'}>
              {visibilityOptions.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </div>

          {visibility === -1 && (
            <div>
              <div>
                <label
                  htmlFor={'visibilitySettingsGroups'}
                  className={'form-label mt-3'}>
                  {t('visibilitySettingsGroups')} :
                </label>
                <Select
                  id={'visibilitySettingsGroups'}
                  defaultValue={visibilitySettingsGroups}
                  onChange={switchVisibilityGroups}
                  style={{ width: '100%' }}
                  optionLabelProp={'children'}
                  multiple={true}>
                  {groups.map((item) => (
                    <Option
                      key={item['@uuid']}
                      value={'/groups/' + item['@uuid']}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </div>

              <div>
                <label
                  htmlFor={'visibilitySettingsUsers'}
                  className={'form-label mt-3'}>
                  {t('visibilitySettingsUsers')} :
                </label>
                <AutocompleteUsers
                  setter={setterU ?? setVisibilitySettingsUsers}
                  settings={autocompleteUsersSettings}
                  selected={
                    null !== digitalAsset
                      ? digitalAsset.visibilitySettings.users
                      : null
                  }
                />
              </div>
            </div>
          )}

          {null === digitalAsset && null !== cruds && cruds.update.authorized && (
            <div className={'form-group text-end'}>
              <LaddaButton
                type={'submit'}
                className={'m-2 btn btn-primary'}
                loading={sending}
                data-style={ZOOM_IN}
                onClick={handleSubmit}>
                {t('update')}
              </LaddaButton>
            </div>
          )}
        </>
      )}
    </>
  );
}
