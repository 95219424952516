import React from 'react';
import { Card } from 'reactstrap';
import CountUp from 'react-countup';
import Circle from 'react-circle';
import { useTranslation } from 'react-i18next';
import clsx from "clsx";

// DashboardServerStatusSection1
const QuotaType3 = ({ entity = null, optionalClass = '' }) => {
  const { t } = useTranslation();
  const type = entity ? entity.type ?? 'user' : 'user';
  const maxValue = entity ? entity.maxValue ?? 500 : 500;
  const currentValue = entity ? entity.currentValue ?? 140 : 140;
  let percentage = Math.round((currentValue / maxValue) * 100);
  if (-1 === maxValue) {
    percentage = currentValue;
  }
  const suffix = 'storage' !== type ? ('bandwidth' !== type ? '' : ' Ko/s') : ' Go';

  let progressColor = percentage > 66 ? '#f83245' : percentage > 33 ? '#ffa500' : '#1bc943';
  let bgColor = percentage > 50 ? "rgba(248, 50, 69, 0.15)" : "rgba(27, 201, 67, 0.15)";
  if (-1 === maxValue) {
    progressColor = '#1bc943';
    bgColor = '#1bc943';
  }

  return (
    <>
      <Card className={clsx('card-box p-3 mb-5 border-top-0 ' + optionalClass )}>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <Circle
              animate={-1 !== maxValue}
              animationDuration="3s"
              responsive={false}
              size={'80'}
              lineWidth={'22'}
              progress={percentage}
              progressColor={progressColor}
              bgColor={bgColor}
              textColor="#3b3e66"
              roundedStroke={true}
              textStyle={{
                fontSize: '100px',
                fontWeight: 'bold'
              }}
              showPercentage={true}
              showPercentageSymbol={-1 !== maxValue}
            />
          </div>
          <div className="text-end">
            <h3 className="fw-bold display-4 mb-1 text-black">
              <CountUp
                start={0}
                end={currentValue}
                duration={4}
                separator=""
                decimals={0}
                decimal=","
                prefix=""
                suffix={suffix}
              />
            </h3>
            <span className="text-muted">{t('lastWeek')}</span>
          </div>
        </div>
      </Card>
    </>
  );
}

export default QuotaType3;