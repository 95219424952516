import React, { useEffect, useCallback, useState } from 'react';
import { getSource, list, ucfirst, country } from 'utils';
import { useTranslation } from 'react-i18next';
import { Placeholder } from 'components';
import PaymentMethodModal from './Modal';
import { useModal } from '@ebay/nice-modal-react';
import visa from 'assets/images/cards/visa.svg';
import mastercard from 'assets/images/cards/mastercard.svg';

const PaymentMethod = ({ tenant }) => {
  const isCancelled = React.useRef(false);
  const { t } = useTranslation();
  const tenantIdentifier = tenant['@uuid'];
  const paymentMethodModal = useModal(PaymentMethodModal);
  const [paymentMethodLoaded, setPaymentMethodLoaded] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);

  /**
   * Add a new payment method.
   */
  const handlePaymentMethodAdd = useCallback(() => {
    paymentMethodModal.show().then((newPaymentMethod) => {
      setPaymentMethod(newPaymentMethod);
    });
  }, [paymentMethodModal]);

  /**
   * Edit an existing payment method.
   */
  const handlePaymentMethodEdit = useCallback(
    (paymentMethod) => {
      paymentMethodModal
        .show({ tenant, paymentMethod })
        .then((newPaymentMethod) => {
          setPaymentMethod(newPaymentMethod);
        });
    },
    [paymentMethodModal, tenant]
  );

  useEffect(() => {
    const source = getSource();

    if (!paymentMethodLoaded) {
      // Load tenant payment methods.
      list({
        resourceType: 'paymentMethod',
        params: [
          { label: 'tenant', value: tenantIdentifier },
          { label: 'type', value: 'card' }
        ],
        success: {
          callback: (res) => {
            if (isCancelled.current) {
              return;
            }

            if (res.data['hydra:member'].length > 0) {
              setPaymentMethod(res.data['hydra:member'][0]);
            }

            setPaymentMethodLoaded(true);
          }
        },
        aOptions: {
          cancelToken: source.token
        }
      }).then();
    }
  });

  return (
    <>
      <div className={'col'}>
        <p className={'m-1 text-dark'}>{t('CreditCard')}</p>

        <div className={'credit-cart'}>
          {paymentMethodLoaded ? (
            <>
              {null === paymentMethod && (
                <a
                  href={'/tenants/' + tenantIdentifier + '/payment#edit'}
                  className={'m-1 d-block text-primary'}
                  onClick={() => {
                    handlePaymentMethodAdd();
                  }}>
                  {t('AddBillingInformation')}
                </a>
              )}

              {null !== paymentMethod && (
                <div>
                  {'visa' === paymentMethod.typeData.brand && (
                    <img
                      src={visa}
                      alt={t('VisaCreditCard')}
                      height={'20px'}
                      width={'auto'}
                      className={'m-1'}
                    />
                  )}

                  {'mastercard' === paymentMethod.typeData.brand && (
                    <img
                      src={mastercard}
                      alt={t('MastercardCreditCard')}
                      height={'20px'}
                      width={'auto'}
                      className={'m-1'}
                    />
                  )}

                  <span className={'m-1'}>
                    {ucfirst(paymentMethod.typeData.brand)}
                    {' - '}
                    {paymentMethod.typeData.last4}
                  </span>

                  <a
                    href={'/tenants/' + tenantIdentifier + '/payment#edit'}
                    className={'m-1 d-block text-primary'}
                    onClick={() => {
                      handlePaymentMethodEdit(paymentMethod);
                    }}>
                    {t('Edit')}
                  </a>
                </div>
              )}
            </>
          ) : (
            <Placeholder />
          )}
        </div>
      </div>
      <div className={'col'}>
        <p className={'m-1 text-dark'}>{t('BillingAddress')}</p>
        <div>
          {paymentMethodLoaded ? (
            <>
              {null === paymentMethod && (
                <span className={'m-1 d-block'}>N/A</span>
              )}

              {null !== paymentMethod && (
                <div>
                  <span className={'m-1 d-block'}>
                    {paymentMethod.billingDetails.address.line1}
                    <br />
                    {paymentMethod.billingDetails.address.postalCode}{' '}
                    {paymentMethod.billingDetails.address.city}
                    <br />
                    {t(country(paymentMethod.billingDetails.address.country))}
                  </span>

                  <a
                    href={'/tenants/' + tenantIdentifier + '/payment#edit'}
                    className={'m-1 d-block text-primary'}
                    onClick={() => {
                      handlePaymentMethodEdit(paymentMethod);
                    }}>
                    {t('Edit')}
                  </a>
                </div>
              )}
            </>
          ) : (
            <Placeholder />
          )}
        </div>
      </div>
      <div className={'col'}>
        <p className={'m-1 text-dark'}>{t('BillingTo')}</p>
        <div>
          {paymentMethodLoaded ? (
            <>
              {null === paymentMethod && (
                <span className={'m-1 d-block'}>N/A</span>
              )}

              {null !== paymentMethod && (
                <div>
                  <span className={'m-1 d-block'}>
                    {paymentMethod.billingDetails.name}
                  </span>

                  <a
                    href={'/tenants/' + tenantIdentifier + '/payment#edit'}
                    className={'m-1 d-block text-primary'}
                    onClick={() => {
                      handlePaymentMethodEdit(paymentMethod);
                    }}>
                    {t('Edit')}
                  </a>
                </div>
              )}
            </>
          ) : (
            <Placeholder />
          )}
        </div>
      </div>
    </>
  );
};

export default PaymentMethod;
