import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutocompleteUsers } from 'components';
import { create, nError, update, iri, getSource } from 'utils';
import { useUserStateValues } from 'states';
import { FormActions, Select } from 'forms';

const defaultSettings = {
  useHeader: false,
  useFooter: true
};

const MembershipForm = ({
  entity = null,
  selectedGroup = null,
  selectedRole = null,
  selectedUser = null,
  groups = null,
  roles = null,
  settings = defaultSettings
}) => {
  const { t } = useTranslation();
  const userStateValues = useUserStateValues();
  const tenant =
    userStateValues.isAuthenticated && userStateValues.user
      ? userStateValues.currentTenant
      : null;

  const g = null !== entity ? entity.group ?? selectedGroup : selectedGroup;
  const [group, setGroup] = useState(g);
  const r = null !== entity ? entity.role ?? selectedRole : selectedRole;
  const [role, setRole] = useState(r);
  const u = null !== entity ? entity.user ?? selectedUser : selectedUser;
  const [user, setUser] = useState(u);

  const [loading, setLoading] = useState(false);
  const isCancelled = React.useRef(false);

  const sendRequest = () => {
    // Ensure mandatory fields if CREATE.
    if (null === group || null === user || null === role) {
      nError({ message: t('missingFields') });
      return 0;
    }

    const source = getSource();
    const params = {
      resourceType: 'membership',
      data: {
        user: iri(user, 'user'),
        group: iri(user, 'group'),
        role: iri(user, 'groupRole')
      },
      success: {
        callback: () => {
          if (isCancelled.current) return;

          window.location.reload();
        }
      },
      error: { message: t('userGroupLinkError') },
      aOptions: { cancelToken: source.token },
      always: {
        callback: () => setLoading(false)
      }
    };

    setLoading(true);

    if (null !== entity) {
      // Send the update API call.
      update({ ...params, ...{ id: entity['@uuid'] } });
    } else {
      create(params);
    }
  };

  const switchGroup = (value) => {
    groups['hydra:member'].forEach((g) => {
      if (g['@uuid'] === value) setGroup(g);
    });
  };

  const switchRole = (value) => {
    roles['hydra:member'].forEach((r) => {
      if (r['@uuid'] === value) setRole(r);
    });
  };

  return (
    groups &&
    roles && (
      <>
        {null !== tenant && (
          <form>
            {null === entity && (
              <>
                <label
                  htmlFor={'membership-form-user'}
                  className={'form-label mt-3'}>
                  {t('user')} <sup className={'text-danger'}>*</sup>
                </label>
                <AutocompleteUsers
                  setter={setUser}
                  settings={{
                    params: [
                      {
                        label: 'memberships.tenant',
                        value: iri(tenant, 'tenant')
                      }
                    ]
                  }}
                />

                <Select
                  onChange={switchGroup}
                  field={'group'}
                  label={t('group')}
                  mandatory
                  options={groups['hydra:member']}
                  defaultValue={null !== g ? g['@uuid'] : null}
                />
              </>
            )}

            <Select
              onChange={switchRole}
              field={'role'}
              label={t('role')}
              mandatory
              options={roles['hydra:member']}
              optionNameKey={'label'}
              defaultValue={null != r ? r['@uuid'] : null}
            />

            {true === settings.useFooter && (
              <FormActions
                confirmAction={(e) => {
                  e.preventDefault();
                  sendRequest();
                }}
                confirmName={null !== entity ? t('update') : t('submit')}
                loading={loading}
              />
            )}
          </form>
        )}
      </>
    )
  );
};

export default MembershipForm;
