import { State, createState, useState } from '@hookstate/core';
import { list } from 'utils';

interface PlansList {
  plans?: any;
}

const state: State<PlansList> = createState({ plans: null } as PlansList);
export const usePlansState = () => useState(state);
export const usePlansStateValues = () => usePlansState().value;

export const refreshState = (planState, callback, aOptions = {}) => {
  list({
    resourceType: 'plan',
    success: {
      callback: (res) => {
        // Order plans into types.
        let newPlans = {
          standard: [],
          api: [],
          option: []
        };
        res.data['hydra:member'].map((plan) => {
          newPlans[plan.type].push(plan);

          // Sort features.
          const newIndex = newPlans[plan.type].length - 1;
          if (plan.type === 'standard' || plan.type === 'api') {
            newPlans[plan.type][newIndex].features.included.sort((a, b) =>
              a.label > b.label ? 1 : -1
            );

            if (
              newPlans[plan.type][newIndex].features.excluded &&
              newPlans[plan.type][newIndex].features.excluded.length > 0
            ) {
              newPlans[plan.type][newIndex].features.excluded.sort((a, b) =>
                a.label > b.label ? 1 : -1
              );
            }
          }

          return newIndex;
        });

        // Sort by price.
        newPlans.standard.sort((a, b) =>
          a.features.included.length > b.features.included.length ? 1 : -1
        );
        newPlans.api.sort((a, b) =>
          a.features.included.length > b.features.included.length ? 1 : -1
        );

        planState.plans.merge(newPlans);
        callback(newPlans);
      }
    },
    aOptions: aOptions
  }).then();
};

export const getPlan = ({ plans, name }) => {
  let p = null;
  const types = ['api', 'standard'];
  types.map((type) => {
    null !== plans &&
      plans[type].map((plan) => {
        if (plan.name === name) {
          p = plan;
        }
        return plan;
      });
    return type;
  });
  return p;
};
