import React from 'react';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { FeaturesList } from 'components';

const Plan = ({
  plan,
  priceReccurency = 'month',
  subscribeAction = () => {}
}) => {
  const { t, i18n } = useTranslation();
  let currentL = i18n.language;
  const featuresI = plan ? plan.features.included ?? [] : [];
  const featuresX = plan ? plan.features.excluded ?? [] : [];

  let btnColor = 'info';
  let cardCN = 'card mb-1';
  let divCN = 'card-body px-4 pb-4 pt-3 text-center';

  switch (plan.label) {
    case 'TEAM':
    case 'PRO':
      cardCN += ' card-box border-2 border-first';
      divCN = 'card-body px-4 pb-5 pt-4 text-center';
      btnColor = 'first';
      break;
    case 'POWER':
    case 'PREMIUM':
      btnColor = 'warning';
      break;
  }

  const div2CN = 'bg-neutral-' + btnColor + ' p-3 rounded mb-4';
  const h3CN = 'display-7 my-4 fw-bold text-' + btnColor;
  const title = plan.label;
  const price =
    priceReccurency === 'month' ? plan.prices.month : plan.prices.year;
  const currency = plan.currency ?? '€';
  const perMonth = t('per' + priceReccurency);

  return (
    <>
      <div className={cardCN}>
        <div className={divCN}>
          <h3 className={h3CN}>{title}</h3>

          <div className={div2CN}>
            {currentL === 'fr' ? (
              <>
                <small className={'text-uppercase text-black-50 pe-1'}>
                  (HT)
                </small>
                <span className={'display-5 fw-bold'}>
                  {price.value}
                  <small className={'text-uppercase text-black-50 fw-normal'}>
                    {currency + ' '}
                  </small>
                </span>
                <small className={'text-uppercase text-black-50'}>
                  {perMonth}
                </small>
              </>
            ) : (
              <>
                <small className={'text-uppercase text-black-50 pe-1'}>
                  (HT)
                </small>
                <span className={'display-5 fw-bold'}>
                  <small className={'text-uppercase text-black-50'}>
                    {currency}
                  </small>
                  {' ' + price.value + ' '}
                </span>
                <small className={'text-uppercase text-black-50'}>
                  {perMonth}
                </small>
              </>
            )}
          </div>

          <div>
            <FeaturesList features={featuresI} type={'included'} />
            <FeaturesList features={featuresX} type={'excluded'} />
          </div>
          <div className={'d-grid'}>
            <Button
              tag={'a'}
              onClick={subscribeAction}
              size={'lg'}
              className={'btn-pill'}
              outline
              color={btnColor}
              block>
              {t('buyNow')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Plan;
