import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GroupForm, MembershipForm } from 'forms';
import { DatatableDefault, DatatableMemberships, Modal } from 'components';
import Moment from 'react-moment';
import { getCruds, getSource, iri, list } from 'utils';
import { useUserStateValues } from 'states';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';

const DatatableGroups = ({ user, tenant, data = null, strict = false }) => {
  const { t, i18n } = useTranslation();
  const currentLocale = 'fr' === i18n.language ? 'fr' : 'en';
  const userState = useUserStateValues();
  const isCancelled = React.useRef(false);
  const settingsMemberships = {
    tableId: 'memberships-sub-table',
    title: t('user_plural'),
    loading: false,
    subdatatable: true
  };

  // Be careful ! data = row (locked param name)
  const ExpandedComponent = ({ data }) => {
    const [memberships, setMemberships] = useState(null);

    useEffect(() => {
      const source = getSource();
      const params = [
        { label: 'group', value: iri(data, 'group') },
        { label: 'order[user.name]', value: 'asc' }
      ];

      list({
        resourceType: 'membership',
        params: params,
        success: {
          callback: (r) => {
            if (isCancelled.current) return;

            setMemberships(r.data['hydra:member']);
          }
        },
        aOptions: { cancelToken: source.token }
      }).then();

      return () => {
        isCancelled.current = true;
        source.cancel();
        setMemberships(null);
      };

      // eslint-disable-next-line
    }, []);

    return (
      <>
        {memberships && (
          <DatatableMemberships
            data={memberships}
            settings={settingsMemberships}
            tenant={tenant}
          />
        )}
      </>
    );
  };

  const columns = [
    {
      name: t('name'),
      selector: 'name',
      sortable: true
    },
    {
      name: t('createdDate'),
      selector: 'created',
      sortable: true,
      hide: 'sm',
      center: true,
      format: (row) => (
        <Moment calendar={true} locale={currentLocale}>
          {row.created}
        </Moment>
      )
    },
    {
      name: t('actions'),
      selector: 'actions',
      center: true,
      cell: (row) => (
        <div
          className={'d-flex align-items-center w-100 justify-content-evenly'}>
          <button
            type={'button'}
            className={'btn btn-link d-f-0150'}
            onClick={() => {
              setSelectedGroup(row);
            }}
            data-bs-toggle={'modal'}
            data-bs-target={'#groups-table-datatableEdit'}
            data-tip
            data-for={'edit-row-' + row['@uuid']}>
            <span className={'btn-wrapper--icon me-2'}>
              <FontAwesomeIcon icon={['fas', 'edit']} />
              <ReactTooltip
                id={'edit-row-' + row['@uuid']}
                type={'info'}
                effect={'solid'}>
                <span>{t('edit')}</span>
              </ReactTooltip>
            </span>
          </button>

          <button
            type={'button'}
            className={'btn btn-link d-f-0150'}
            onClick={() => {
              setSelectedGroup(row);
            }}
            data-bs-toggle={'modal'}
            data-bs-target={'#groups-table-datatableLinkUserToGroup'}
            data-tip
            data-for={'add-user-row-' + row['@uuid']}>
            <span className={'btn-wrapper--icon me-2'}>
              <FontAwesomeIcon icon={['fas', 'users']} />
              <ReactTooltip
                id={'add-user-row-' + row['@uuid']}
                type={'info'}
                effect={'solid'}>
                <span>{t('linkUserToGroupShort')}</span>
              </ReactTooltip>
            </span>
          </button>
        </div>
      )
    }
  ];

  const [selectedGroup, setSelectedGroup] = useState(null);
  const [groups, setGroups] = useState(null);
  const [roles, setRoles] = useState(null);
  const [cruds, setCruds] = useState(null);

  useEffect(() => {
    let source = getSource();

    // Handle CRUDS first.
    const newCruds = getCruds({
      resourceType: 'group',
      userState,
      user,
      tenant,
      translator: t
    });

    if (true === strict) {
      // Only get user's groups.
      newCruds.read.methods.list.params = [
        { label: 'has[variations]', value: false }
      ];
    }

    setCruds(newCruds);

    // Get lists.
    list({
      resourceType: 'group',
      params: [{ label: 'tenant', value: iri(tenant, 'tenant') }],
      success: {
        callback: (res) => {
          if (isCancelled.current) return;

          setGroups(res.data);
        }
      },
      aOptions: { cancelToken: source.token }
    }).then();

    list({
      resourceType: 'groupRole',
      params: [{ label: 'tenant', value: iri(tenant, 'tenant') }],
      success: {
        callback: (res) => {
          if (isCancelled.current) return;

          setRoles(res.data);
        }
      },
      aOptions: { cancelToken: source.token }
    }).then();

    return () => {
      //isCancelled.current = true;
      setCruds(null);
    };

    // eslint-disable-next-line
  }, []);

  return (
    cruds &&
    groups && (
      <>
        <DatatableDefault
          columns={columns}
          data={data}
          tenant={tenant}
          settings={{
            tableId: 'groups-table',
            title: t('group_plural'),
            selectedMessage: {
              singular: t('group'),
              plural: t('group_plural')
            },
            expandableRows: true,
            expandableRowsComponent: <ExpandedComponent data={data} />,
            contextActionType: 'merge',
            modals: {
              actions: [
                {
                  label: 'add',
                  modal: (
                    <Modal
                      options={{
                        id: 'groups-table-datatableAdd',
                        icon: {
                          name: 'users'
                        },
                        title: t('addGroup'),
                        useFooter: false
                      }}>
                      <GroupForm />
                    </Modal>
                  )
                }
              ]
            }
          }}
          pagination={{
            orderBy: 'name',
            sortDirection: 'ASC'
          }}
          cruds={cruds}
        />

        <Modal
          options={{
            id: 'groups-table-datatableLinkUserToGroup',
            icon: {
              name: 'users'
            },
            title: t('linkUserToGroup'),
            useFooter: false
          }}>
          {selectedGroup && (
            <MembershipForm
              selectedGroup={selectedGroup}
              groups={groups}
              roles={roles}
            />
          )}
        </Modal>

        <Modal
          options={{
            id: 'groups-table-datatableEdit',
            icon: {
              name: 'edit'
            },
            title:
              t('edit') + ' ' + (selectedGroup ? selectedGroup.name ?? '' : ''),
            useFooter: false
          }}>
          <GroupForm entity={selectedGroup} />
        </Modal>
      </>
    )
  );
};

export default DatatableGroups;
