import React from 'react';
import { Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { getMediaSrc } from 'utils';
import { useTranslation } from 'react-i18next';

const DigitalAssetCard = ({ digitalAsset }) => {
  const badgeAR = getMediaSrc(digitalAsset, 'fileWB') ? {label: 'AR', color: 'success'} : null;
  const badgeQV = getMediaSrc(digitalAsset, 'fileAP') ? {label: 'QV', color: 'warning'} : null;
  const bg = Math.floor(Math.random() * Math.floor(9));
  const { t } = useTranslation();

  const stats = [
    { label: 'view', icon: 'eye', color: 'success' },
    { label: 'viewAR', icon: 'cube', color: 'warning' },
    { label: 'download', icon: 'download', color: 'info' }
  ];

  return (
    <>
      <div className="card-badges">
        {badgeQV && (
          <Badge pill color={badgeQV.color} className={'me-2 rounded-pill bg-' + badgeQV.color}>
            {badgeQV.label}
          </Badge>
        )}
        {badgeAR && (
          <Badge pill color={badgeAR.color} className={'rounded-pill bg-' + badgeAR.color}>
            {badgeAR.label}
          </Badge>
        )}
      </div>
      <img alt={digitalAsset.name} className={"card-img-top bg-gray-" + (bg * 100)} src={getMediaSrc(digitalAsset)} />
      <div className="card-body card-body-avatar">
          <div className={'d-flex justify-content-end card-body-stats'}>
            {digitalAsset.statistics && (
              <>
                {stats.map((s, index) => (
                  <div key={index} className="font-size-sm text-black-50 mb-2 mt-2 me-2">
                    <FontAwesomeIcon
                      icon={['fas', s.icon as IconName]}
                      className={'text-' + s.color + ' me-1'}
                    />
                    <strong title={t(s.label, {count: digitalAsset.statistics[s.label] ?? 0})}>{digitalAsset.statistics[s.label] ?? 0}</strong>
                  </div>
                ))}
              </>
            )}
          </div>
        { digitalAsset.tenant && (digitalAsset.tenant.medias || digitalAsset.tenant.avatar) && (
          <div className="avatar-icon-wrapper avatar-icon-wrapper--sm">
            <div className="avatar-icon">
              <img alt="owner-logo" src={getMediaSrc(digitalAsset.tenant, 'avatar')} />
            </div>
          </div>
        )}
        <h5 className="card-title fw-bold font-size-lg">
          {digitalAsset.name}
        </h5>
        {null !== digitalAsset.description && (
          <p className="card-text"
             dangerouslySetInnerHTML={{ __html: digitalAsset.description ?? '' }}>
          </p>
        )}
          <div className="card-date mt-2 d-flex">
              <FontAwesomeIcon
                  icon={['fas', 'copyright']}
                  className="text-muted me-1"
              />
              <small className="text-muted"
                     dangerouslySetInnerHTML={{ __html: digitalAsset.copyright ?? 'uncopyrighted' }}>
              </small>
          </div>
      </div>
    </>
  );
}

export default DigitalAssetCard;
