import React, { useEffect, useState } from 'react';

import { action, getSource } from 'utils';
import { Placeholder } from 'components';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentInformationInnerForm from './Inner';

const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(stripePublishableKey);

const PaymentInformationForm = ({
  tenant,
  paymentMethod,
  setLoading,
  setSubmitDisabled
}) => {
  const isCancelled = React.useRef(false);
  const tenantIdentifier = tenant['@uuid'];

  const [refreshed, setRefreshed] = useState(null);
  const [setupIntentLoaded, setSetupIntentLoaded] = useState(false);
  const [setupIntent, setSetupIntent] = useState(null);
  const [taxIdentifierLoaded, setTaxIdentifierLoaded] = useState(false);
  const [taxIdentifier, setTaxIdentifier] = useState(null);

  function loadCurrentSetupIntent(source) {
    return new Promise((resolve) => {
      if (!setupIntentLoaded) {
        // If no payment method is given, then load the tenant setup intent.
        action({
          resourceType: 'setupIntent',
          action: 'current',
          method: 'get',
          params: [{ label: 'tenant', value: tenantIdentifier }],
          error: {
            callback: (res) => {
              resolve(false);
            }
          },
          success: {
            callback: (res) => {
              if (isCancelled.current) {
                return;
              }

              if (res.data['id'].length > 0) {
                setSetupIntent(res.data);
              }

              setSetupIntentLoaded(true);
              resolve(true);
            }
          },
          aOptions: {
            cancelToken: source.token
          }
        });
      }
    });
  }

  function loadCurrentTaxIdentifier(source) {
    return new Promise((resolve) => {
      if (!taxIdentifierLoaded) {
        // If no tax identifier is given, then load the tenant tax identifier.
        action({
          resourceType: 'taxIdentifier',
          action: 'get-current',
          method: 'get',
          params: [{ label: 'tenant', value: tenantIdentifier }],
          error: {
            callback: (result) => {
              console.error('Error while loading tax identifier : ' + result);
              resolve(false);
            }
          },
          success: {
            callback: (result) => {
              if (isCancelled.current) {
                return;
              }

              if (result.data !== null && result.data['id'].length > 0) {
                setTaxIdentifier(result.data);
              }

              setTaxIdentifierLoaded(true);
              resolve(true);
            }
          },
          aOptions: {
            cancelToken: source.token
          }
        });
      }
    });
  }

  useEffect(() => {
    const source = getSource();

    const promises = [];
    promises.push(loadCurrentSetupIntent(source));
    promises.push(loadCurrentTaxIdentifier(source));

    Promise.all(promises).then(() => {
      setSubmitDisabled(false);
      setLoading(false);
    });
  }, [refreshed]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {setupIntentLoaded && taxIdentifierLoaded ? (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: setupIntent.clientSecret,
            appearance: {
              theme: 'stripe',
              variables: {
                colorPrimary: '#3b3e66',
                colorBackground: '#ffffff',
                colorText: '#3b3e66',
                colorDanger: '#f83245',
                fontFamily: 'Heebo, sans-serif',
                spacingUnit: '4px',
                borderRadius: '0.29rem'
              }
            }
          }}>
          <PaymentInformationInnerForm
            tenant={tenant}
            paymentMethod={paymentMethod}
            setupIntent={setupIntent}
            taxIdentifier={taxIdentifier}
            setLoading={setLoading}
            setSubmitDisabled={setSubmitDisabled}
            setRefreshed={setRefreshed}
          />
        </Elements>
      ) : (
        <Placeholder size={'big'} />
      )}
    </>
  );
};

export default PaymentInformationForm;
