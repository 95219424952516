import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { list, iri } from 'utils';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

const AutocompleteBase = ({ setter, settings = null, selected = null }) => {
  const { t } = useTranslation();
  const resourceType = settings['@resourceType'] ?? 'entities';
  const searchField = settings.searchField ?? 'name';

  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (settings.preload ?? false) {
      list({
        resourceType: resourceType,
        params: [
          { label: 'itemsPerPage', value: 10 },
          { label: 'page', value: 1 },
          { label: 'properties[]', value: 'name' }
        ],
        setter: setData
      });
    }
    // eslint-disable-next-line
  }, []);

  const handleSearch = (value) => {
    setLoading(true);
    const defaultParams = [
      { label: 'itemsPerPage', value: 10 },
      { label: 'page', value: 1 },
      // { label: 'properties[]', value: searchField }
    ];
    if ('' !== value) {
      defaultParams.push({ label: searchField, value: value });
    }

    const params = settings.params ? [...defaultParams, ...settings.params] : defaultParams;
    list({
      resourceType: resourceType,
      autocomplete: settings.addUriSuffix ?? true,
      params: params,
      success: {
        callback: (res) => {
          setData(res.data['hydra:member'].filter((da)=> da['@uuid'] !== settings.originUuid));
          setLoading(false);
        }
      },
      redirectOnError: settings.redirectOnError ?? false,
      notif: settings.notif ?? false
    });
  };

  const handleSelect = (values) => {
    const iris = values.map((v) => iri(v, resourceType));
    const val = (settings.multiple ?? true) ?
      ((settings.fullObjects ?? false) ? values : iris) :
      ((settings.fullObjects ?? false) ? values[0] ?? null : iris[0] ?? null);

    if (settings.setterKey) setter(settings.setterKey, val);
    else setter(val);
  };

  return (
    <>
      {(settings.displayLabel ?? true) && (
        <label
          className={settings.className ?? 'mt-3'}
          htmlFor={'autocomplete-' + resourceType}>
          {settings.label ?? t(resourceType + '_plural')}
        </label>
      )}

      <AsyncTypeahead
        allowNew={settings.allowNew ?? false}
        clearButton={settings.clearButton ?? true}
        defaultSelected={
          (selected && selected.length > 0) ? (!Array.isArray(selected) ? [selected] : selected) : []
        }
        disabled={settings.disabled ?? false}
        emptyLabel={settings.emptyLabel ?? t('emptyLabel')}
        id={'autocomplete-' + resourceType}
        isLoading={isLoading}
        labelKey={settings.key ?? 'name'}
        minLength={settings.minLength ?? 2}
        multiple={settings.multiple ?? true}
        newSelectionPrefix={settings.newSelectionPrefix ?? t('addPrefix')}
        onChange={handleSelect}
        onSearch={handleSearch}
        options={data['hydra:member'] ?? data ?? []}
        placeholder={t(resourceType + '_search')}
        positionFixed={settings.positionFixed ?? true}
        promptText={settings.promptText ?? t('promptText')}
        searchText={settings.searchText ?? t('searchText')}
      />
    </>
  );
};

export default AutocompleteBase;
