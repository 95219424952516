import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MembershipForm } from 'forms';
import { DatatableDefault, Modal } from 'components';
import { getCruds, getMediaSrc, iri } from 'utils';
import { useUserStateValues } from 'states';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import defaultAssetImg from 'assets/images/avatars/defaultAvatar.png';
import appConfig from 'config/appConfig.json';

const DatatableMemberships = ({ data = [], settings = {}, tenant = null }) => {
  const { t } = useTranslation();
  const userState = useUserStateValues();

  const columns = [
    {
      name: t('user_plural'),
      selector: 'user.name',
      cell: (row) => (
        <a href={iri(row.user, 'user') + '/profile'}>
          <div className="d-flex align-items-center">
            <div className="avatar-icon-wrapper avatar-icon-sm me-2">
              <div className="avatar-icon">
                <img
                  alt="row-media"
                  src={getMediaSrc(row, 'avatar') ?? defaultAssetImg}
                />
              </div>
            </div>
            <div>{row.user.name}</div>
          </div>
        </a>
      ),
      sortable: true
    },
    {
      name: t('actions'),
      selector: 'actions',
      center: true,
      cell: (row) => (
        <div className={'dropdown mx-3'}>
          <button
            className={'dropdown-toggle p-0 border-0 text-blue-50 no-caret'}
            data-bs-toggle={'dropdown'}
            aria-expanded={'false'}>
            <FontAwesomeIcon
              icon={['fas', 'ellipsis-h']}
              className={'font-size-lg'}
            />
          </button>
          <ul
            className={
              'dropdown-menu nav text-nowrap px-2 pt-2 pb-0 flex-column'
            }>
            <li className={'dropdown-item nav-link'}>
              <button
                type={'button'}
                className={'btn first'}
                onClick={() => {
                  setSelectedMember(row);
                }}
                data-bs-toggle={'modal'}
                data-bs-target={'#memberships-table-datatableEdit'}>
                <span className={'btn-wrapper--label'}>
                  {t('edit') + ' ' + row.name}
                </span>
                <span className={'btn-wrapper--icon me-2'}>
                  <FontAwesomeIcon icon={['fas', 'edit']} />
                </span>
              </button>
            </li>
          </ul>
        </div>
      )
    }
  ];

  const [selectedMember, setSelectedMember] = useState(null);
  const conditionalRowStyles = [
    {
      when: (row) => row.user['@uuid'] === userState.user['@uuid'],
      style: {
        backgroundColor: appConfig.colors.selectedRow.hexa,
        color: 'black'
      }
    }
  ];

  const [cruds, setCruds] = useState(null);
  useEffect(() => {
    const newCruds = getCruds({
      resourceType: 'membership',
      userState,
      translator: t
    });
    setCruds(newCruds);

    return () => {
      setCruds(null);
    };

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <DatatableDefault
        columns={columns}
        data={data}
        tenant={tenant}
        settings={{
          ...{
            tableId: 'memberships-table',
            title: t('user_plural'),
            selectedMessage: {
              singular: t('user'),
              plural: t('user_plural')
            },
            conditionalRowStyles: conditionalRowStyles,
            canReload: false
          },
          ...settings
        }}
        pagination={{
          orderBy: 'name',
          sortDirection: 'ASC'
        }}
        cruds={cruds}
      />

      <Modal
        options={{
          id: 'groups-table-datatableEdit',
          useFullBody: false,
          useFooter: false
        }}>
        <MembershipForm entity={selectedMember} />
      </Modal>
    </>
  );
};

export default DatatableMemberships;
