import React from 'react';
import clsx from 'clsx';
import { Sidebar, Header, Footer } from 'layout-components';
import { useThemeStateValues } from 'states';

const LeftSidebar = (props) => {
  const { children } = props;
  const themeOptions = useThemeStateValues();

  return (
    <div
      className={clsx(
        'app-wrapper',
        themeOptions.contentBackground,
        {
          'app-sidebar-fixed': themeOptions.sidebarFixed,
          'app-header-fixed': themeOptions.headerFixed,
          'app-footer-fixed': themeOptions.footerFixed,
          'header-drawer-open': themeOptions.headerDrawerToggle,
          'app-sidebar-collapsed': themeOptions.sidebarToggle,
          'app-sidebar-mobile-open': themeOptions.sidebarToggleMobile,
          'search-wrapper-open': themeOptions.headerSearchHover
        }
      )}>
      <div>
        <Sidebar />
      </div>
      <div className={'app-main'}>

        <Header />

        <div className={'app-content'}>
          <div className={'app-content--inner'}>
            <div className={'app-content--inner__wrapper'}>{children}</div>
          </div>

          <Footer />

        </div>
      </div>
    </div>
  );
};

export default LeftSidebar;
