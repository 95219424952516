import React from 'react';
import clsx from 'clsx';
import { useThemeState, useThemeStateValues } from 'states';
import {
  SidebarHeader,
  SidebarMenu
} from 'layout-components';

const Sidebar = () => {

  const themeState = useThemeState();
  const themeOptions = useThemeStateValues();

  function toggleSidebarMobile() {
    themeState.sidebarToggleMobile.set(!themeOptions.sidebarToggleMobile)
  }

  return (
    <>
      <div
        className={clsx('app-sidebar', themeOptions.sidebarStyle, {
          'app-sidebar--shadow': themeOptions.sidebarShadow
        })}>
      <SidebarHeader />
        <div className="app-sidebar--content">
          <SidebarMenu />
        </div>
      </div>
      <div
        onClick={toggleSidebarMobile}
        className={clsx('app-sidebar-overlay', {
          'is-active': themeOptions.sidebarToggleMobile
        })}
      />
    </>
  );
}

export default Sidebar;
