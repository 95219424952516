import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useUserStateValues } from 'states';
import { Loader, Modal } from 'components';
import Select, { Option } from 'rc-select';
import { action, remove, iri, guessFromResourceType, getSource } from 'utils';

import apiConfig from 'config/apiConfig.json';
import appConfig from 'config/appConfig.json';

const DatatableDefault = ({
  settings,
  pagination,
  cruds,
  columns = null,
  data = null,
  tenant = null
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const userState = useUserStateValues();
  const [loading, setLoading] = useState(settings.loading ?? null === data);
  const [rows, setRows] = useState([]);
  const [selected, setSelected] = useState([]);
  const [toggledClearRows, setToggledClearRows] = useState(false);
  const [authorized, setAuthorized] = useState(settings.hideAddButton ?? false);

  const isCancelled = React.useRef(false);

  // Let's merge given props with default values.
  const tableSettings = {
    ...{
      tableId: 'digital-assets-table',
      subdatatable: false,
      contextActionType: '',
      // hideAddButton: false === authorized,
      selectableRows: true,
      hideHeaders: false,
      displayTableHeaders: false
    },
    ...settings
  };

  // Let's merge given props with default values.
  const modals = {
    ...{
      actions: [],
      rows: []
    },
    ...(settings.modals ?? {})
  };

  useEffect(() => {
    const source = getSource();

    if (null !== data && data.length > 0) {
      setRows(data);
    } else {
      if (tableSettings.canReload ?? true) {
        reload(null, source);
      }
    }

    if (true !== settings.hideAddButton && true === cruds.create.authorized)
      setAuthorized(true);

    return () => {
      isCancelled.current = true;
      source.cancel();
      setRows(null);
      setSelected([]);
      setToggledClearRows(false);
      setAuthorized(settings.hideAddButton ?? false);
    };

    // eslint-disable-next-line
  }, [data]);

  // Let's merge given props with default values.
  const [paginationSettings, setPaginationSettings] = useState({
    ...{
      page: 1,
      itemsPerPage: apiConfig.pagination.itemsPerTable,
      orderBy: 'created',
      sortDirection: 'DESC'
    },
    ...pagination
  });

  const reload = (stateOverrideParams = null, source = null) => {
    if (isCancelled.current) return;

    setLoading(true);
    const params = [
      ...[
        {
          label: 'tenant',
          value: tenant
            ? tenant['@uuid']
            : userState.currentTenant && userState.currentTenant['@uuid']
            ? userState.currentTenant['@uuid']
            : ''
        },
        { label: 'page', value: paginationSettings.page },
        { label: 'itemsPerPage', value: paginationSettings.itemsPerPage },
        {
          label: 'order[' + paginationSettings.orderBy + ']',
          value: paginationSettings.sortDirection
        }
      ],
      ...(cruds.read.methods.list.params ?? []),
      ...(stateOverrideParams ?? [])
    ];

    const aOptions = null === source ? {} : { cancelToken: source.token };
    cruds.read.methods.list.action({
      resourceType: cruds.resourceType,
      params,
      success: {
        callback: (res) => {
          if (isCancelled.current) return;

          if (res.data) {
            setRows(res.data);
          }
        }
      },
      always: {
        callback: () => {
          if (isCancelled.current) return;

          setLoading(false);
          setToggledClearRows(false);
          return;
        }
      },
      aOptions
    });
  };

  const handleSort = (column, sortDirection) => {
    setPaginationSettings({
      ...paginationSettings,
      ...{
        orderBy: column.selector,
        sortDirection: sortDirection
      }
    });
    reload();
  };

  const onChangePage = (page, totalRows) => {
    const newPaginationSettings = {
      ...paginationSettings,
      ...{
        page: page
      }
    };
    setPaginationSettings(newPaginationSettings);
    // Here we pass the param because the state is not set yet
    reload([{ label: 'page', value: page }]);
  };

  const onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    const newPaginationSettings = {
      ...paginationSettings,
      ...{
        itemsPerPage: currentRowsPerPage
      }
    };
    setPaginationSettings(newPaginationSettings);
    // Here we pass the param because the state is not set yet
    reload([{ label: 'itemsPerPage', value: currentRowsPerPage }]);
  };

  const handleSelect = ({ allSelected, selectedCount, selectedRows }) => {
    setSelected(selectedRows);
    if ('merge' === tableSettings.contextActionType) {
      setContextActions(
        1 < selectedCount
          ? [contextActionDelete, contextActionMerge]
          : [contextActionDelete]
      );
    }
  };

  const actions = (
    <>
      {authorized && (
        <button
          key={'add'}
          className={'btn btn-link btn-sm'}
          data-bs-toggle={'modal'}
          data-bs-target={'#' + tableSettings.tableId + '-datatableAdd'}>
          <FontAwesomeIcon
            icon={['fas', 'plus-circle']}
            className={'text-success me-2'}
          />
          {t('add')}
        </button>
      )}
      {modals.actions.map((modal, index) => (
        <Fragment key={'modal-action-' + index}>{modal.action}</Fragment>
      ))}
    </>
  );

  const customStyles = {
    headRow: {
      style: {
        border: 'none',
        backgroundColor: appConfig.colors.headRow.hexa
      }
    },
    headCells: {
      style: {
        color: appConfig.colors.headCells.hexa,
        fontSize: '14px',
        fontWeight: 'bold'
      }
    }
  };

  const selectedMessageT = {
    ...{
      singular: t('row'),
      plural: t('row_plural'),
      message: t('selected')
    },
    ...settings.selectedMessage
  };

  const contextActionDelete = (
    <button
      key={'delete-btn'}
      className={'btn btn-link btn-sm'}
      data-bs-toggle={'modal'}
      data-bs-target={'#' + tableSettings.tableId + 'DatatableDelete'}>
      <FontAwesomeIcon icon={['fas', 'trash']} className={'text-danger me-2'} />
      {t('delete')}
    </button>
  );

  const [rowsToDelete] = useState([]);
  const checkDeleting = (uuid) => {
    rowsToDelete.splice(rowsToDelete.indexOf(uuid));
    if (rowsToDelete.length === 0) {
      reload();
    }
  };

  const handleDelete = () => {
    selected.map((r) => rowsToDelete.push(r['@uuid']));
    selected.map((item) => {
      const uuid = item['@uuid'];

      remove({
        resourceType: cruds.resourceType,
        id: uuid,
        always: {
          callback: () => checkDeleting(uuid)
        },
        error: {
          message: t('crudDeleteErrored', { itemName: item.name ?? t('item') })
        },
        success: {
          message: t('crudDeleteSucceeded', {
            itemName: item.name ?? t('item')
          })
        }
      });

      return item;
    });

    setToggledClearRows(true);
  };

  const handleMerge = () => {
    const rowsToMerge = [];
    let target = [];
    selected.map((r, index) => {
      if (index.toString() !== mergeTarget) {
        rowsToMerge.push(iri(r, cruds.resourceType));
      } else {
        target = r;
      }
      return true;
    });
    let data = {};
    const key = guessFromResourceType(cruds.resourceType, 'uri');
    data[key] = rowsToMerge;

    action({
      action: 'merge',
      resourceType: cruds.resourceType,
      id: target['@uuid'],
      data: data,
      error: {
        message: t('mergeErrored')
      },
      success: {
        message: t('mergeSucceeded'),
        callback: () => {
          if (null !== settings.redirectPath) {
            history.push(settings.redirectPath);
          }
        }
      },
      notif: true
    }).then();

    setToggledClearRows(true);
  };

  const contextActionMerge = (
    <button
      key={'action-btn'}
      className={'btn btn-link btn-sm'}
      data-bs-toggle={'modal'}
      data-bs-target={'#' + tableSettings.tableId + 'DatatableMerge'}>
      <FontAwesomeIcon
        icon={['fas', 'compress-arrows-alt']}
        className={'text-info me-2'}
      />
      {t('pieceTogether')}
    </button>
  );
  const [mergeTarget, setMergeTarget] = useState('0');
  const switchMergeTarget = (value) => {
    setMergeTarget(value);
  };
  const [contextActions, setContextActions] = useState([contextActionDelete]);

  return (
    <>
      {false === tableSettings.subdatatable ? (
        <div className={'card border-1 mb-5'} id={tableSettings.tableId}>
          <DataTable
            keyField={tableSettings.keyField || '@uuid'}
            title={tableSettings.title || ''}
            columns={columns}
            data={null === rows ? [] : rows['hydra:member'] ?? rows}
            striped={!settings.conditionalRowStyles}
            highlightOnHover
            noDataComponent={t('noDataFound')}
            persistTableHead
            progressPending={loading}
            progressComponent={<Loader type={'pacman'} />}
            sortServer
            onSort={handleSort}
            // sortIcon={sortIcon}
            noHeader={tableSettings.hideHeaders}
            noTableHead={tableSettings.displayTableHeaders}
            pagination={true}
            paginationServer
            paginationTotalRows={
              null === rows ? 0 : rows['hydra:totalItems'] ?? 0
            }
            paginationPerPage={paginationSettings.itemsPerPage}
            paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            paginationComponentOptions={{
              rowsPerPageText: t('paginationRowsPerPage') + ' : ',
              rangeSeparatorText: t('paginationOf'),
              noRowsPerPage: false,
              selectAllRowsItem: false,
              selectAllRowsItemText: t('paginationAll')
            }}
            actions={actions}
            contextActions={contextActions}
            selectableRows={tableSettings.selectableRows}
            onSelectedRowsChange={handleSelect}
            clearSelectedRows={toggledClearRows}
            expandableRows={settings.expandableRows ?? false}
            expandableRowsComponent={settings.expandableRowsComponent ?? <></>}
            contextMessage={selectedMessageT}
            customStyles={customStyles}
            conditionalRowStyles={settings.conditionalRowStyles ?? []}
          />
        </div>
      ) : (
        <DataTable
          keyField={tableSettings.keyField || '@uuid'}
          columns={columns}
          data={null === rows ? [] : rows['hydra:member'] ?? rows}
          noHeader
          highlightOnHover
          noDataComponent={t('noDataFound')}
          conditionalRowStyles={settings.conditionalRowStyles ?? []}
          customStyles={customStyles}
        />
      )}

      {false === tableSettings.subdatatable && (
        <Modal
          options={{
            id: tableSettings.tableId + 'DatatableDelete',
            title: t('deleteConfirmMessage'),
            icon: {
              name: 'trash',
              color: 'danger'
            },
            actions: {
              submit: {
                action: handleDelete,
                color: 'danger',
                title: t('delete')
              },
              cancel: {
                color: 'dark'
              }
            }
          }}>
          <p className={'mb-0 font-size-lg text-muted'}>{t('deleteMessage')}</p>
        </Modal>
      )}

      {'merge' === tableSettings.contextActionType && (
        <Modal
          options={{
            id: tableSettings.tableId + 'DatatableMerge',
            title: t('togetherConfirmMessage'),
            icon: {
              name: 'compress-arrows-alt'
            },
            actions: {
              submit: {
                action: handleMerge,
                title: t('pieceTogether')
              }
            }
          }}>
          <div>
            <label htmlFor={'mergeTarget'} className={'form-label'}>
              {t('togetherTargetMessage')}
            </label>
            <Select
              id={'mergeTarget'}
              defaultValue={mergeTarget}
              className={'w-100'}
              onChange={switchMergeTarget}
              optionLabelProp={'children'}
              dropdownStyle={{ zIndex: 2500 }}>
              {selected.map((item, index) => (
                <Option key={item.name} value={index.toString()}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>
        </Modal>
      )}

      {modals.actions.map((modal, index) => (
        <Fragment key={'modal-' + index}>{modal.modal}</Fragment>
      ))}
    </>
  );
};

export default DatatableDefault;
