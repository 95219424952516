import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import RouterLink from '../../ReactMetismenuRouterLink';
import MetisMenu from 'react-metismenu';
import { SidebarUserbox } from 'layout-components';
import { useTranslation } from 'react-i18next';
import { useThemeStateValues, useUserStateValues } from 'states';
import dummyItems from 'dummies/swaggerMethodsDummies.json';
import apiConfig from 'config/apiConfig.json';
const apiUrl = process.env.REACT_APP_API_URL;

const SidebarMenu = () => {

  const { t } = useTranslation();
  const themeOptions = useThemeStateValues();
  let apiMethods = [];

  let method = [];
  dummyItems['hydra:member'].map(item => {
    method = [
      {
        label: item.name,
        description: item.data[0].miniDesc,
        to: '/documentation/api/' + item.tag
      }
    ];

    return apiMethods.push(method[0]);
  });

  // Public menu contents.
  let sidebarMenuContent = [
    {
      label: t('digitalAssetsList'),
      icon: 'pe-7s-box2',
      to: '/' + apiConfig.resources.digitalAsset.uri
    },
    {
      label: t('api') + ' ' + t('documentation'),
      icon: 'pe-7s-light',
      externalLink: true,
      to: apiUrl
    },
    {
      label: t('plansAndPricing'),
      icon: 'pe-7s-piggy',
      to: '/subscription'
    },
  ];

  // Private menu contents.
  const userStateValues = useUserStateValues();
  if (userStateValues.isAuthenticated && userStateValues.user) {
    const sidebarMenuPrivateContent = [
      {
        label: t('digitalAssetsUpload'),
        icon: 'pe-7s-upload',
        to: '/' + apiConfig.resources.digitalAsset.uri + '/upload'
      }
    ];

    sidebarMenuContent = [...sidebarMenuContent, ...sidebarMenuPrivateContent];
  }

  // Sort menu items.
  sidebarMenuContent.sort((a, b) => a.label > b.label ? 1 : -1);

  return (
    <>
      <PerfectScrollbar>
        {themeOptions.sidebarUserbox && <SidebarUserbox />}
        <div className="sidebar-navigation">
          <div className="sidebar-header">
            <span>{t('navigationMenu')}</span>
          </div>
          <MetisMenu
            content={sidebarMenuContent}
            activeLinkFromLocation={true}
            iconNamePrefix="" // Keep this empty to override default "fa fa-"
            noBuiltInClassNames={true}
            classNameItemActive="active"
            classNameIcon="sidebar-icon"
            iconNameStateVisible="sidebar-icon-indicator"
            iconNameStateHidden="sidebar-icon-indicator"
            classNameItemHasVisibleChild="submenu-open"
            classNameItemHasActiveChild="active"
            classNameStateIcon="pe-7s-angle-down"
            LinkComponent={RouterLink} // Extension from react-metismenu-router-link
          />
        </div>
      </PerfectScrollbar>
    </>
  );
}

export default SidebarMenu;
