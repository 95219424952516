import React, { useEffect, useState } from 'react';
import { DatatableUploads, TopXitemsList } from 'components';
import { getSource, list } from 'utils';
import { useTranslation } from 'react-i18next';

const UserProfile = ({ user }) => {
  const [items, setItems] = useState([]);
  const { t } = useTranslation();

  const isCancelled = React.useRef(false);
  useEffect(() => {
    const source = getSource();

    list({
      resourceType: 'digitalAsset',
      params: [
        { label: 'page', value: 1 },
        { label: 'itemsPerPage', value: 3 },
        { label: 'order[created]', value: 'desc' },
        { label: 'upload.user', value: user['@uuid'] }
      ],
      success: {
        callback: (res) => {
          if (isCancelled.current) return;

          setItems(res.data);
        }
      },
      notif: false,
      aOptions: {
        cancelToken: source.token
      }
    }).then();

    return () => {
      isCancelled.current = true;
      setItems([]);
    };

    // eslint-disable-next-line
  }, []);

  const topXsettings = {
    title: t('topModel'),
    desc: t('topModelDescView'),
    displayDivider: true,
    type: 'digitalAsset',
    imgType: 'preview',
    imgAlt: 'logo-model',
    icon: ['fas', 'eye'],
    iconClass: 'text-success me-1'
  };

  return (
    <>
      <div className={'row'}>
        <div className={'col'}>
          <TopXitemsList
            items={items['hydra:member']}
            settings={topXsettings}
          />
        </div>
      </div>
      <div className={'row'}>
        <div className={'col'}>
          <DatatableUploads user={user} />
        </div>
      </div>
    </>
  );
};

export default UserProfile;
