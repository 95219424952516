import React, { useState } from 'react';
import { Modal, Button, Badge } from 'reactstrap';
import { Plan, Loader } from 'components';
import { useTranslation } from 'react-i18next';
import {
  updateCurrentTenantPlan,
  useBillingState,
  useBillingStateValues,
  useUserState,
  useUserStateValues
} from 'states';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { action, getColors } from 'utils';

const PlansList = ({ plans, title = 'Title', description = 'Description' }) => {
  const { t } = useTranslation()
  const billingState = useBillingState();
  const billingStateValues = useBillingStateValues();
  const userState = useUserState();
  const user = useUserStateValues();

  function togglePriceRecurrency() {
    billingState.priceRecurrency.set( billingStateValues.priceRecurrency === 'month' ? 'year' : 'month' )
  }

  const [modalChangePlan, setModalChangePlan] = useState(false);
  const toggleModalChangePlan = () => setModalChangePlan(!modalChangePlan);

  const handleChangePlan = () => {
    action({
      resourceType: 'tenant',
      id: user.currentTenant['@uuid'],
      action: 'subscribe',
      data: { plan: displayedPlan.label, price: displayedPlan.prices.month.value.toString() },
      error: { message: t('crudUpdateErrored', { itemName: displayedPlan.name }) },
      success: { callback: () => { updateCurrentTenantPlan({uState: userState, user: user, plan: displayedPlan }) }},
      notif: true,
      method: 'patch'
    });

    toggleModalChangePlan();
  };

  const [displayedPlan, setDisplayedPlan] = useState(null);

  return (
    <>
      <div className={'py-3 py-xl-1'}>
        <div className={'container py-3 py-xl-5'}>
          <div className={'text-center'}>
            <h2 className={'display-5 text-black mb-3 fw-bold'}>
              {title}
            </h2>
            <p className={'font-size-xl mb-5 text-black-50'}>
              {description}
            </p>
          </div>

          <div className={'form-check form-switch form-switch-lg mb-3'}>
            <input
              className={'form-check-input'}
              type={'checkbox'}
              id={'priceRecurrency'}
              checked={billingStateValues.priceRecurrency === 'year'}
              onChange={togglePriceRecurrency}
            />
            <label className={'form-check-label'} htmlFor={'priceRecurrency'}>
              {t('priceReccurencyLabel')}
            </label>
          </div>

          {plans.length === 0 ? (
            <Loader type={'pacman'} />
          ) : (
            <div className={'row plans-container d-flex align-items-center'}>
              {plans
                .map(plan => (
                <div className={'col col-xl-4'} key={plan.label}>
                  <Plan
                    plan={plan}
                    priceReccurency={billingStateValues.priceRecurrency}
                    subscribeAction={() => { setDisplayedPlan(plan); toggleModalChangePlan(); }}
                  />
                </div>
              ))}
            </div>
          )}

          {displayedPlan && (
            <Modal zIndex={2000} centered isOpen={modalChangePlan} toggle={toggleModalChangePlan}>
              <div className={'text-center p-5'}>
                <div className={'avatar-icon-wrapper rounded-circle m-0'}>
                  <div className={'d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-success text-success m-0 d-130'}>
                    <FontAwesomeIcon
                      icon={['fas', 'arrow-up']}
                      className={'d-flex align-self-center display-6'}
                    />
                  </div>
                </div>
                <h4 className={'fw-bold mt-4'}>
                  {t('changePlanConfirmMessage', { planType: displayedPlan.label })}
                </h4>
                <div>
                  <Badge color={getColors(user.currentTenant.plan).badge} className={'bg-' + getColors(user.currentTenant.plan).badge}>{user.currentTenant.plan.label}</Badge>
                  <FontAwesomeIcon
                    icon={['fas', 'arrow-right']}
                    className={'display-7'}
                    style={{ height: 20, width: '10%' }}
                  />
                  <Badge color={getColors(displayedPlan).badge} className={'bg-' + getColors(displayedPlan).badge}>{displayedPlan.label}</Badge>
                </div>
                <p className={'mb-0 font-size-lg text-muted'}>
                  {t('deleteMessage')}
                </p>
                <div className="pt-4">
                  <Button
                    onClick={toggleModalChangePlan}
                    color={'neutral-secondary'}
                    className={'btn-pill mx-1'}>
                    <span className={'btn-wrapper--label'}>{t('cancel')}</span>
                  </Button>
                  <Button onClick={handleChangePlan} color={'info'} className={'btn-pill mx-1'}>
                    <span className={'btn-wrapper--label'}>{t('buyNow')}</span>
                  </Button>
                </div>
              </div>
            </Modal>
          )}

        </div>
      </div>
    </>
  );
};

export default PlansList;
