import React from 'react';
import {
  DatatableCategories,
  DatatableCollections,
  DatatableVariationGroupsTemplate
} from 'components';

const Datamodel = ({ tenant }) => {
  return (
    <>
      <div className={'row'}>
        <div className={'col'}>
          <DatatableCollections tenant={tenant} />
        </div>
      </div>

      <div className={'row'}>
        <div className={'col'}>
          <DatatableCategories tenant={tenant} />
        </div>
      </div>

      <div className={'row'}>
        <div className={'col'}>
          <DatatableVariationGroupsTemplate
            tenant={tenant}
            settings={{ linkVariationGroupAvailable: true }}
          />
        </div>
      </div>
    </>
  );
};

export default Datamodel;
