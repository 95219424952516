import React, { useEffect, useState } from 'react';
import Select, { Option } from 'rc-select';

const SelectField = ({
  onChange,
  field = 'name',
  label = 'name',
  options = [],
  optionValueKey = '@uuid',
  optionNameKey = 'name',
  mandatory = false,
  defaultValue = '',
  classname = '',
  dropdownMenuStyle = {}
}) => {
  const [localValue, setLocalValue] = useState(defaultValue);

  useEffect(() => {
    setLocalValue(defaultValue);

    // eslint-disable-next-line
  }, [defaultValue]);

  const localOnChange = (value) => {
    setLocalValue(value);
    onChange(value);
  };

  return (
    <div id={'select-wrapper-' + field} className={classname}>
      <label htmlFor={'select-' + field} className={'form-label mt-3'}>
        {label}&nbsp;
        {mandatory && <sup className={'text-danger'}>*</sup>}
      </label>

      <Select
        id={'select-' + field}
        value={localValue}
        style={{ width: '100%' }}
        optionLabelProp={'children'}
        onChange={localOnChange}
        dropdownStyle={{ zIndex: 2500 }}
        getPopupContainer={() =>
          document.getElementById('select-wrapper-' + field)
        }
        dropdownMenuStyle={dropdownMenuStyle}>
        {options.map((option, index) => (
          <Option key={index} value={option[optionValueKey]}>
            {option[optionNameKey]}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default SelectField;
