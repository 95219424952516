import React from 'react';
import { useTranslation } from 'react-i18next';
import { Invoice } from 'components';

const InvoiceList = ({ items = [] }) => {
  const { t } = useTranslation();

  return (
    <table className={'table mt-2'}>
      <thead>
        <tr>
          <th>{t('number')}</th>
          <th>{t('month')}</th>
          <th>{t('amount')}</th>
          <th>{t('status')}</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, i) => {
          return <Invoice key={i} item={item} />;
        })}
      </tbody>
    </table>
  );
};

export default InvoiceList;
