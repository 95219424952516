import React from 'react';

const QRCode = (props) => {

  const src = props.base64 ? "data:image/png;base64," + props.base64 : props.src;

  return (
    <>
      <div className="mb-5 text-center">
        <img alt={"qrcode"} className={"qrcode"} src={src}/>
      </div>
    </>
  );
};

export default QRCode;
