import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactDOM from 'react-dom';

export const Modal = (props) => {
  const { t } = useTranslation();

  const options = props.options ?? {};
  const label = options.label ?? options.id ?? 'bootstrapModal';
  const icon = options.icon ?? {};
  const actions = options.actions ?? {
    submit: {},
    cancel: {}
  };
  const actionSubmit = actions.submit ?? {};
  const actionCancel = actions.cancel ?? {};

  return ReactDOM.createPortal(
    <div
      className={
        'modal ' + (options.transition ?? 'fade ') + (options.class ?? '')
      }
      tabIndex={-1}
      id={options.id ?? 'bootstrapModal'}
      aria-labelledby={label}
      aria-hidden={options.visible ?? false}>
      <div
        className={
          'modal-dialog modal-dialog-centered modal-dialog-scrollable'
        }>
        <div className={'modal-content'}>
          {(options.useHeader ?? true) && (
            <div className={'modal-header'}>
              {(options.headerTitle ?? false) && (
                <h5 className="modal-title">
                  {options.headerTitle ?? t('message')}
                </h5>
              )}
              <button
                type={'button'}
                className={'btn-close'}
                data-bs-dismiss={'modal'}
                aria-label={'Close'}
              />
            </div>
          )}

          <div className={'modal-body'}>
            {options.useFullBody ?? true ? (
              <>
                <div className={'text-center px-5 pb-3'}>
                  <div className={'avatar-icon-wrapper rounded-circle m-0'}>
                    <div
                      className={
                        'd-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-' +
                        (icon.color ?? 'info') +
                        ' text-' +
                        (icon.color ?? 'info') +
                        ' m-0 d-130'
                      }>
                      <FontAwesomeIcon
                        icon={['fas', icon.name ?? 'compress-arrows-alt']}
                        className={'d-flex align-self-center display-6'}
                      />
                    </div>
                  </div>

                  <h4 className={'modal-title fw-bold pt-3'} id={label}>
                    {options.title ?? t('message')}
                  </h4>
                </div>
                {props.children}
              </>
            ) : (
              props.children
            )}
          </div>

          {(options.useFooter ?? true) && (
            <div className={'modal-footer'}>
              <button
                type={'button'}
                className={
                  'btn btn-link text-' + (actionCancel.color ?? 'danger')
                }
                data-bs-dismiss={'modal'}
                onClick={actionCancel.action}>
                {actionCancel.title ?? t('cancel')}
              </button>
              <button
                type={'submit'}
                className={
                  'btn btn-pill btn-' + (actionSubmit.color ?? 'primary')
                }
                data-bs-dismiss={'modal'}
                onClick={actionSubmit.action}>
                {actionSubmit.title ?? t('submit')}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>,
    document.getElementById('root')
  );
};
