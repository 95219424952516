import React from 'react';
import { CardBody, Card, CardHeader } from 'reactstrap';

const CardSimple = (props) => {
  return (
    <>
      <Card className="card-box mb-5">
        <CardHeader>
          <div className="card-header--title font-size-md fw-bold py-2">
            {props.sectionHeading}
          </div>
        </CardHeader>
        <CardBody>{props.children}</CardBody>
      </Card>
    </>
  );
};

export default CardSimple;
