import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, Select } from 'forms';
import countries from 'countries-list';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import visa from '../../../assets/images/cards/visa.svg';
import mastercard from '../../../assets/images/cards/mastercard.svg';
import { action, update, nSuccess, ucfirst, read } from 'utils';
import { useModal } from '@ebay/nice-modal-react';
import { Button } from 'reactstrap';

const PaymentInformationInnerForm = ({
  tenant,
  paymentMethod,
  setupIntent,
  taxIdentifier,
  setLoading,
  setSubmitDisabled,
  setRefreshed
}) => {
  const setupIntentClientSecret = setupIntent.clientSecret;
  const tenantIdentifier = tenant['@uuid'];
  const paymentMethodIdentifier = paymentMethod
    ? paymentMethod.id ?? null
    : null;
  const stripe = useStripe();
  const elements = useElements();
  const modal = useModal();

  const { t } = useTranslation();

  const [formClass, setFormClass] = useState(null);
  const [cardEditMode, setCardEditMode] = useState(paymentMethod === null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [data, setData] = useState({
    name: paymentMethod ? paymentMethod.billingDetails.name ?? '' : '',
    country: paymentMethod
      ? paymentMethod.billingDetails.address.country ?? ''
      : '',
    state: paymentMethod
      ? paymentMethod.billingDetails.address.state ?? ''
      : '',
    postalCode: paymentMethod
      ? paymentMethod.billingDetails.address.postalCode ?? ''
      : '',
    city: paymentMethod ? paymentMethod.billingDetails.address.city ?? '' : '',
    line1: paymentMethod
      ? paymentMethod.billingDetails.address.line1 ?? ''
      : '',
    line2: paymentMethod
      ? paymentMethod.billingDetails.address.line2 ?? ''
      : '',
    taxId: taxIdentifier ? taxIdentifier.value ?? '' : ''
  });

  const countryOptions = [];
  for (const [key, value] of Object.entries(countries.countries)) {
    countryOptions.push({ key: key, value: value.name });
  }

  const updateFormData = (attribute, value) => {
    data[attribute] = value;
    setData(data);
  };

  const updateFormCountry = (value) => {
    data['country'] = value;
    setData(data);
  };

  const editCard = async () => {
    setCardEditMode(true);
  };

  const handleSubmit = async (event) => {
    const promises = [];

    setSubmitDisabled(true);
    setLoading(true);
    setFormClass(null);
    event.preventDefault();

    if (!event.target.checkValidity()) {
      setSubmitDisabled(false);
      setFormClass('was-validated');
      return;
    }

    if (cardEditMode) {
      // Create the payment method (with Stripe).
      promises.push(
        createPaymentMethod(
          modal,
          stripe,
          elements,
          setupIntentClientSecret,
          data
        )
      );
    } else {
      // Update the payment method (with our own API).
      promises.push(updatePaymentMethod(modal, paymentMethodIdentifier, data));
    }

    // Update the tax identifier.
    promises.push(updateTaxIdentifier(tenantIdentifier, data));

    Promise.all(promises).then(() => {
      setRefreshed(true);
      setSubmitDisabled(true);
      modal.hide();

      nSuccess({
        message: t('tenantPaymentMethodEditSuccess')
      });
    });
  };

  function createPaymentMethod(
    modal,
    stripe,
    elements,
    setupIntentClientSecret,
    data
  ) {
    return new Promise((resolve) => {
      // Use Stripe APIs to update data.
      const cardElement = elements.getElement(CardElement);
      if (!stripe || !cardElement) {
        // Make sure Stripe has loaded and the cardElement is available
        resolve(false);
        return;
      }

      stripe
        .confirmCardSetup(setupIntentClientSecret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: data.name,
              address: {
                city: data.city,
                country: data.country,
                line1: data.line1,
                line2: data.line2,
                postal_code: data.postalCode,
                state: data.state
              }
            }
          }
        })
        .then(function (result) {
          if (result.error) {
            setErrorMessage(result.error.message);
            console.error(
              'Error while creating card payment method: ' +
                result.error.message
            );
            resolve(false);
          }

          if (result.setupIntent) {
            const paymentMethodIdentifier = result.setupIntent.payment_method;
            read({
              resourceType: 'paymentMethod',
              id: paymentMethodIdentifier,
              error: {
                callback: (result) => {
                  console.error(
                    'Error loading the created payment method : ' + result
                  );
                  resolve(true);
                }
              },
              success: {
                callback: (result) => {
                  modal.resolve(result.data);
                  resolve(true);
                }
              }
            });
          }
        });
    });
  }

  function updatePaymentMethod(modal, paymentMethodIdentifier, data) {
    return new Promise((resolve) => {
      update({
        resourceType: 'paymentMethod',
        id: paymentMethodIdentifier,
        data: {
          billingDetails: {
            name: data.name,
            address: {
              city: data.city,
              country: data.country,
              line1: data.line1,
              line2: data.line2,
              postalCode: data.postalCode,
              state: data.state
            }
          }
        },
        error: {
          callback: (result) => {
            console.error('Error while updating payment method: ' + result);
            resolve(false);
          }
        },
        success: {
          callback: (result) => {
            modal.resolve(result.data);
            resolve(true);
          }
        }
      });
    });
  }

  function updateTaxIdentifier(tenantIdentifier, data) {
    return new Promise((resolve) => {
      action({
        resourceType: 'taxIdentifier',
        action: 'set-current',
        method: 'post',
        params: [{ label: 'tenant', value: tenantIdentifier }],
        data: {
          value: data.taxId,
          country: data.country
        },
        error: {
          callback: (result) => {
            console.error(
              'Error while trying to update tax identifier: ' + result
            );
            resolve(false);
          }
        },
        success: {
          callback: (result) => {
            resolve(true);
          }
        }
      });
    });
  }

  return (
    <form
      id={'tenant-payment-method-form'}
      onSubmit={handleSubmit}
      className={'needs-validation ' + formClass}
      noValidate>
      {errorMessage && (
        <div className={'alert alert-danger'} role={'alert'}>
          {errorMessage}
        </div>
      )}

      <div className={'form-stripe-elements'}>
        <label className={'form-label'}>
          {t('creditCard')}&nbsp;<sup className={'text-danger'}>*</sup>
        </label>

        {paymentMethod && !cardEditMode ? (
          <div className={'credit-cart'}>
            {'visa' === paymentMethod.typeData.brand && (
              <img
                src={visa}
                alt={t('VisaCreditCard')}
                height={'20px'}
                width={'auto'}
                className={'m-1'}
              />
            )}

            {'mastercard' === paymentMethod.typeData.brand && (
              <img
                src={mastercard}
                alt={t('MastercardCreditCard')}
                height={'20px'}
                width={'auto'}
                className={'m-1'}
              />
            )}

            <span className={'m-1'}>
              {ucfirst(paymentMethod.typeData.brand)}
              {' - '}
              {paymentMethod.typeData.last4}
            </span>

            <Button
              className={'m-1 text-primary'}
              tag={'a'}
              onClick={editCard}
              block>
              {t('Edit')}
            </Button>
          </div>
        ) : (
          <div className={'form-control'}>
            <CardElement
              options={{
                hidePostalCode: true,
                iconStyle: 'solid',
                style: {
                  base: {
                    iconColor: '#3b3e66',
                    color: '#3b3e66',
                    fontWeight: 400,
                    fontFamily: 'Heebo, sans-serif',
                    fontSize: '16px',
                    fontSmoothing: 'antialiased',
                    ':-webkit-autofill': {
                      color: '#3b3e66'
                    },
                    '::placeholder': {
                      color: '#d1d2db'
                    }
                  },
                  invalid: {
                    iconColor: '#f83245',
                    color: '#f83245'
                  }
                }
              }}
            />
          </div>
        )}
      </div>

      <TextField
        setter={updateFormData}
        field={'name'}
        label={t('creditCardName')}
        placeholder={t('creditCardNameExample')}
        mandatory
        defaultValue={data.name}
      />

      <Select
        onChange={updateFormCountry}
        field={'country'}
        label={t('country')}
        mandatory
        options={countryOptions}
        optionValueKey={'key'}
        optionNameKey={'value'}
        dropdownMenuStyle={{ height: 200 }}
        defaultValue={data.country}
      />

      <div className="row g-3 align-items-center">
        <div className="col-7">
          <TextField
            setter={updateFormData}
            field={'state'}
            label={t('state')}
            placeholder={t('stateExample')}
            mandatory
            defaultValue={data.state}
          />
        </div>
        <div className="col-5">
          <TextField
            setter={updateFormData}
            field={'postal_code'}
            label={t('postalCode')}
            placeholder={t('postalCodeExample')}
            mandatory
            defaultValue={data.postalCode}
          />
        </div>
      </div>

      <TextField
        setter={updateFormData}
        field={'city'}
        label={t('city')}
        placeholder={t('cityExample')}
        mandatory
        defaultValue={data.city}
      />

      <TextField
        setter={updateFormData}
        field={'line1'}
        label={t('streetAddressLine1')}
        placeholder={t('streetAddressLine1Example')}
        mandatory
        defaultValue={data.line1}
      />

      <TextField
        setter={updateFormData}
        field={'line2'}
        placeholder={t('streetAddressLine2Example')}
        classname={'mt-2'}
        defaultValue={data.line2}
      />

      <TextField
        setter={updateFormData}
        field={'taxId'}
        label={t('taxIdentifier') + ' (' + t('optional') + ')'}
        placeholder={t('taxIdentifierExample')}
        defaultValue={data.taxId}
      />
    </form>
  );
};

export default PaymentInformationInnerForm;
