import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EmailValidator from 'email-validator';
import PasswordStrengthBar from 'react-password-strength-bar/dist';
import { useDropzone } from 'react-dropzone';
import Cropper from 'react-cropper';
import { Row, Col, Form, Label, FormGroup, Input, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Loader } from 'components';
import {
  action,
  create,
  getMediaSrc,
  getRoleName,
  getUpperRoles,
  hasRole,
  iri,
  isHigher,
  list,
  nError,
  read,
  update,
  getMediaObject
} from 'utils';
import { useUserState, useUserStateValues, setPreview } from 'states';

import defaultAvatarImg from 'assets/images/avatars/defaultAvatar.png';
import apiConfig from 'config/apiConfig.json';
import cloudFile from 'assets/images/baseline-cloud_upload-24px.svg';
import appConfig from 'config/appConfig.json';
import Select, { Option } from 'rc-select';

const UserForm = ({
  uuid = null,
  cancelAction = null,
  setActiveTab = null,
  settings = null
}) => {
  const { t } = useTranslation();
  const uState = useUserState();
  const uStateValues = useUserStateValues();

  const defaultSettings = {
    ...{
      defaultClass: 'col-12 col-md-6'
    },
    ...settings
  };

  // Password Checker.
  const shortScoreWord = t('tooWeak');
  const scoreWords = [
    t('tooWeak'),
    t('weak'),
    t('normal'),
    t('good'),
    t('veryGood')
  ];

  // todo : use the regex to add special characters to password
  const passwordRegex = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$'
  );

  const [user, setUser] = useState(null);
  const [userOriginal, setUserOriginal] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [avatarStatus, setAvatarStatus] = useState('display');
  const [changePassword, setChangePassword] = useState(false);
  const [membership, setMembership] = useState(null);
  const [roles, setRoles] = useState(null);
  const [roleId, setRoleId] = useState(null);
  const [canEditRole, setCanEditRole] = useState(false);
  const [tenantId, setTenantId] = useState(
    settings && settings.tenantUuid
      ? settings.tenantUuid
      : uStateValues.currentTenant['@uuid']
  );
  const [tenants] = useState(
    uStateValues.isAuthenticated && uStateValues.user
      ? uStateValues.user.tenants
      : []
  );

  const setRolesInit = () => {
    list({
      resourceType: 'role',
      params: [{ label: 'tenant', value: iri(tenantId, 'tenant') }],
      success: {
        callback: (r) => {
          let upperRoles = getUpperRoles(
            getRoleName({ user: uStateValues.user, tenantUuid: tenantId })
          ).map((rm) => rm.name);
          setRoles(
            r.data['hydra:member'].filter(
              (ro) => !upperRoles.includes(ro.name)
            )
          );
        }
      }
    });
  }

  const toggleTenant = (tenant) => {
    setTenantId(tenant);

    if (null !== uuid) {
      setRolesInit();

      list({
        resourceType: 'member',
        params: [
          { label: 'tenant', value: tenantId },
          { label: 'user', value: uuid }
        ],
        success: {
          callback: (m) => {
            if (0 < m.data['hydra:totalItems']) {
              setMembership(m.data['hydra:member'][0]);
              setRoleId(m.data['hydra:member'][0].role['@uuid']);

              // A user can edit an other user role if he is >= webmaster AND >= other user role.
              setCanEditRole(
                hasRole({
                  roleRequired: appConfig.roles.webmaster.label,
                  user: uStateValues.user,
                  tenantUuid: tenantId
                }) &&
                  isHigher({
                    roleAname: getRoleName({
                      user: uStateValues.user,
                      tenantUuid: tenantId
                    }),
                    roleBname: m.data['hydra:member'][0].role.name
                  })
              );
            }
          }
        }
      });
    }
  };

  useEffect(() => {
    if (null !== uuid) {
      read({
        resourceType: 'user',
        id: uuid,
        success: {
          callback: (res) => {
            setUserOriginal(res.data);
            setAvatar(getMediaSrc(res.data, 'avatar') ?? defaultAvatarImg);

            toggleTenant(tenantId);
          }
        }
      });
    } else {
      setAvatar(defaultAvatarImg);
      setChangePassword(true);
      setRolesInit();
    }

    // eslint-disable-next-line
  }, [uuid]);

  const sendUserRequest = () => {
    let userData = {};
    const fields = ['email', 'familyName', 'givenName', 'nickName', 'username'];

    let valid = true;
    // Ensure email.
    if (user && user.email && !EmailValidator.validate(user.email)) {
      nError({ message: t('emailError') });
      valid = false;
    }
    // Ensure password.
    if ((true === userTypeDatabase && true === changePassword) || null === uuid) {
      if (
        user && (user.passwordTmp !== user.passwordChecker ||
          false === passwordRegex.test(user.passwordTmp))
      ) {
        nError({ message: t('passwordError') });
        nError({ message: t('passwordErrorDetails') });
        valid = false;
      }
      if (
        changePassword &&
        user &&
        user.passwordTmp &&
        user.passwordChecker &&
        user.passwordTmp === user.passwordChecker &&
        true === passwordRegex.test(user.passwordTmp)
      ) {
        userData['password'] = user.passwordTmp;
      }
    }
    // Ensure mandatory fields if CREATE.
    if (!uuid && !user) {
      nError({ message: t('missingFields') });
      valid = false;
    }
    // Username to name wording.
    if (
      user &&
      user.username &&
      ((userOriginal && user.username !== userOriginal.username) ||
        !userOriginal)
    ) {
      userData['name'] = user.username;
    }

    fields.map((key) => {
      if (uuid) {
        // Only patch changed values.
        if (
          !userOriginal ||
          (user && user[key] && userOriginal[key] !== user[key])
        ) {
          userData[key] = user[key];
        }
      } else {
        if (user) {
          userData[key] = user[key];
        }
      }

      return 1;
    });
    
    if (!uuid) {
      userData['memberships'] = [{
          tenant: iri(tenantId, 'tenant'),
          role: iri(
            roles.filter((r) => r.name === appConfig.roles.member.name)[0][
              '@uuid'
            ],
            'role'
          )
        }
      ]
    }

    if (valid) {
      if (Object.keys(userData).length > 0) {
        const params = {
          resourceType: 'user',
          data: userData,
          success: {
            callback: (res) => {
              if (uState.user.value['@uuid'] === res.data['@uuid']) {
                // Update current user state if we just edited his own profile.
                uState.user.merge(res.data);
                localStorage.setItem(
                  appConfig.keys.session.user,
                  JSON.stringify(uState.value)
                );
              }

              // Update avatar.
              if (cropper && file) {
                updateMember({ givenUuid: res.data['@uuid'], redirect: false });
                updateUserAvatar(res.data['@uuid']);
              } else {
                // Update membership if role changed.
                updateMember({ givenUuid: res.data['@uuid'] });
              }
            }
          },
          error: {
            callback: () => {
              // At least try to update avatar.
              if (uuid && cropper && file) {
                updateUserAvatar(uuid);
              }
            }
          }
        };

        if (uuid) {
          // Send the update API call.
          update({ ...params, ...{ id: uuid } });
        } else {
          create(params);
        }
      } else {
        // At least try to update membership & avatar.
        if (uuid && cropper && file) {
          updateMember({ givenUuid: uuid, redirect: false });
          updateUserAvatar(uuid);
        } else {
          // Update membership if role changed.
          updateMember({ givenUuid: uuid });
        }
      }
    }

  };

  const updateMember = ({ givenUuid = null, redirect = true }) => {
    const userUuid = givenUuid;
    if (canEditRole && roleId) {
      if (membership) {
        if (roleId !== membership.role['@uuid']) {
          update({
            resourceType: 'member',
            id: membership['@uuid'],
            data: {
              role: iri(roleId, 'role')
            },
            success: {
              callback: (res) => {
                if (redirect)
                  window.location.href = iri(userUuid, 'user') + '/profile';
              }
            }
          });
        } else {
          if (redirect) window.location.href = iri(userUuid, 'user') + '/profile';
        }
      }
    } else {
      if (redirect) window.location.href = iri(userUuid, 'user') + '/profile';
    }
  };

  const updateUserAvatar = (id) => {
    const formData = {
      base64: cropper.getCroppedCanvas().toDataURL()
    };

    action({
      resourceType: 'user',
      id: id,
      action: 'updateUserAvatar',
      data: formData,
      success: {
        callback: (r) => {
          if (uState.user.value['@uuid'] === r.data['@uuid']) {
            setPreview({
              uState: uState,
              preview: getMediaObject(r.data, 'avatar')
            });
          }
          window.location.href = iri(id, 'user');
        }
      },
      method: 'put'
    });
  };

  const updateUserData = (attribute, value) => {
    let edited = {};
    edited[attribute] = value;
    setUser({ ...user, ...edited });
  };

  const checkEmail = (value) => {
    if (!EmailValidator.validate(value)) {
      nError({ message: t('emailError') });
    }
    updateUserData('email', value);
  };

  // Dropzone.
  // Init form state
  const [file, setFile] = useState(null);
  const [cropper, setCropper] = useState(null);

  const onDropAccepted = (files) => {
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onloadend = () => {
      setFile(reader.result);
      setAvatarStatus('cropper');
    };
  };

  const onDropRejected = (fileRejections) => {
    fileRejections[0].errors.map((e) => nError({ message: t(e.code) }));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    accept: 'image/*',
    onDropAccepted,
    onDropRejected,
    minSize: 15000,
    maxSize: apiConfig.default.maxAvatarSize * 1000000
  });

  const isUploadTooLarge = () => {
    return file ? file.size / 1000000 > apiConfig.default.maxAvatarSize : false;
  };

  const toggleAvatar = (event, value = 'display') => {
    event.preventDefault();
    setAvatarStatus(value);
    if ('display' === value) {
      setFile(null);
    }
  };

  const onCropperInit = (cropper) => {
    setCropper(cropper);
  };

  const connectionType = userOriginal
    ? userOriginal.identities[0].connection ?? ''
    : '';
  const userTypeDatabase =
    connectionType === 'Username-Password-Authentication';

  const cancel = (e) => {
    return setActiveTab !== null
      ? setActiveTab('1')
      : cancelAction() ?? e.preventDefault;
  };

  const togglePassword = (event) => {
    event.preventDefault();
    setChangePassword(!changePassword);
  };

  return (
    <>
      {uuid && !userOriginal ? (
        <Loader type={'pacman'} />
      ) : (
        <Row>
          <Col className={defaultSettings.defaultClass}>
            <Form>
              <FormGroup>
                <Label className={'mt-3'} for={'username'}>
                  {t('username')} <sup>*</sup>
                </Label>
                <Input
                  name={'username'}
                  id={'username'}
                  defaultValue={userOriginal ? userOriginal.username ?? '' : ''}
                  placeholder={t('username')}
                  onChange={(e) => updateUserData('username', e.target.value)}
                  readOnly={!userTypeDatabase && null !== uuid}
                />

                {(true === userTypeDatabase || null === uuid) && (
                  <>
                    <Label className={'mt-3'} for={'email'}>
                      {t('email')} <sup>*</sup>
                    </Label>
                    <Input
                      type={'email'}
                      name={'email'}
                      id={'email'}
                      defaultValue={
                        userOriginal ? userOriginal.email ?? '' : ''
                      }
                      placeholder={t('email')}
                      onBlur={(e) => {
                        checkEmail(e.target.value);
                      }}
                    />
                  </>
                )}

                <Label className={'mt-3'} for={'lastName'}>
                  {t('lastName')}
                </Label>
                <Input
                  name={'lastName'}
                  id={'lastName'}
                  defaultValue={
                    userOriginal ? userOriginal.familyName ?? '' : ''
                  }
                  placeholder={t('lastName')}
                  onChange={(e) => updateUserData('familyName', e.target.value)}
                  readOnly={!userTypeDatabase && null !== uuid}
                />
                <Label className={'mt-3'} for={'firstName'}>
                  {t('firstName')}
                </Label>
                <Input
                  name={'firstName'}
                  id={'firstName'}
                  defaultValue={
                    userOriginal ? userOriginal.givenName ?? '' : ''
                  }
                  placeholder={t('firstName')}
                  onChange={(e) => updateUserData('givenName', e.target.value)}
                  readOnly={!userTypeDatabase && null !== uuid}
                />
                <Label className={'mt-3'} for={'nickname'}>
                  {t('nickname')}
                </Label>
                <Input
                  name={'nickname'}
                  id={'nickname'}
                  defaultValue={userOriginal ? userOriginal.nickname ?? '' : ''}
                  placeholder={t('nickname')}
                  onChange={(e) => updateUserData('nickName', e.target.value)}
                  readOnly={!userTypeDatabase && null !== uuid}
                />
                {null !== tenants && null !== uuid && (
                  <Row>
                    <Col className={'mt-3 col-6'}>
                      <Label for={'group-form-tenant'}>
                        {t('tenant')} <sup>*</sup>
                      </Label>
                      <Select
                        id={'group-form-tenant'}
                        disabled={!canEditRole || tenants.length <= 1}
                        defaultValue={tenantId}
                        style={{ width: '100%' }}
                        optionLabelProp={'children'}
                        onChange={toggleTenant}
                        dropdownStyle={{ zIndex: 2500 }}>
                        {tenants.map((t) => (
                          <Option key={t.name} value={t['@uuid']}>
                            {t.name}
                          </Option>
                        ))}
                      </Select>
                    </Col>

                    {null !== roles && null !== roleId && (
                      <Col className={'mt-3 col-6'}>
                        <Label for={'group-form-role'}>
                          {t('role')} <sup>*</sup>
                        </Label>
                        <Select
                          id={'group-form-role'}
                          disabled={!canEditRole || roles.length <= 1}
                          defaultValue={roleId}
                          style={{ width: '100%' }}
                          optionLabelProp={'children'}
                          onChange={setRoleId}
                          dropdownStyle={{ zIndex: 2500 }}>
                          {roles.map((r) => (
                            <Option key={r.name} value={r['@uuid']}>
                              {r.label}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                    )}
                  </Row>
                )}
              </FormGroup>

              {(true === userTypeDatabase || null === uuid) && (
                <>
                  {true === changePassword ? (
                    <>
                      {null !== uuid && (
                        <FormGroup className={'mt-5'}>
                          <Link
                            className={'text-primary'}
                            onClick={(e) => togglePassword(e)}
                            to={'#'}>
                            {t('dontChangePassword')}
                          </Link>
                        </FormGroup>
                      )}

                      <FormGroup className={'mt-5'}>
                        <Label className={'mt-1'} for={'password'}>
                          {t('password')} <sup>*</sup>
                        </Label>
                        <Input
                          type={'password'}
                          name={'password'}
                          id={'password'}
                          placeholder={t('password')}
                          onChange={(e) =>
                            updateUserData('passwordTmp', e.target.value)
                          }
                        />
                        <PasswordStrengthBar
                          password={user ? user.passwordTmp ?? '' : ''}
                          scoreWords={scoreWords}
                          shortScoreWord={shortScoreWord}
                        />
                        <Label className={'mt-1'} for={'passwordChecker'}>
                          {t('confirmPassword')}
                        </Label>
                        <Input
                          type={'password'}
                          name={'passwordChecker'}
                          id={'passwordChecker'}
                          placeholder={t('password')}
                          onChange={(e) => {
                            // checkPassword(e.target.value);
                            updateUserData('passwordChecker', e.target.value);
                          }}
                        />
                      </FormGroup>
                    </>
                  ) : (
                    <FormGroup className={'mt-5'}>
                      <Link
                        className={'text-primary'}
                        onClick={(e) => togglePassword(e)}
                        to={'#'}>
                        {t('changePassword')}
                      </Link>
                    </FormGroup>
                  )}
                </>
              )}

              <Button
                color={'primary'}
                className={'mt-4'}
                onClick={sendUserRequest}>
                {uuid ? t('update') : t('submit')}
              </Button>
              <Button
                className={'m-2 mt-4 btn-link-danger'}
                onClick={(e) => {
                  cancel(e);
                }}>
                <span>{t('cancel')}</span>
              </Button>
            </Form>
          </Col>

          {uuid && (
            <Col className={defaultSettings.defaultClass}>
              <FormGroup>
                {'dropzone' === avatarStatus ? (
                  <>
                    <div className={'dropzone mb-3'}>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className={'dz-message'}>
                          <img
                            alt={'upload'}
                            className={'dz-teaser-img'}
                            src={cloudFile}
                          />

                          <div className={'dx-text'}>
                            {!isDragActive && t('dropzoneMessageDefault')}
                            {isDragActive && t('dropzoneMessageActive')}
                            {isUploadTooLarge() && (
                              <div className={'text-danger mt-2'}>
                                {t('dropzoneMessageTooLarge')}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <Link onClick={(e) => toggleAvatar(e, 'display')} to={'#'}>
                      {t('cancel')}
                    </Link>
                  </>
                ) : (
                  <>
                    {file ? (
                      <>
                        <Cropper
                          src={file}
                          style={{ height: 400, width: '100%' }}
                          initialAspectRatio={1 / 1}
                          // guides={false}
                          onInitialized={onCropperInit}
                        />
                        <br />
                        <Link
                          onClick={(e) => toggleAvatar(e, 'display')}
                          to={'#'}>
                          {t('cancel')}
                        </Link>
                      </>
                    ) : (
                      <>
                        <img
                          className={'mb-3 img-fluid rounded me-3 shadow-sm'}
                          alt={'user-avatar'}
                          src={avatar}
                          width={256}
                        />
                        <br />
                        <Link
                          onClick={(e) => toggleAvatar(e, 'dropzone')}
                          to={'#'}>
                          {t('changeAvatar')}
                        </Link>
                      </>
                    )}
                  </>
                )}
              </FormGroup>
            </Col>
          )}
        </Row>
      )}
    </>
  );
};

export default UserForm;
