import { toast, Flip } from 'react-toastify';
import config from 'config/notifConfig.json';

const autoCloseTimerDefault = config.autoCloseTimerDefault;
const autoCloseTimerSlow = config.autoCloseTimerSlow;

export const nError = ({
  message = 'Whoops, something went wrong. Probably.',
  container = 'TC',
  transition = Flip
}) => {
  toast.error(message, {
    containerId: container,
    transition: transition,
    autoClose: autoCloseTimerSlow
  });
};

export const nWarning = ({
  message = 'Warning! But not an error! Just a warning!',
  container = 'TC',
  transition = Flip
}) => {
  toast.warn(message, {
    containerId: container,
    transition: transition,
    autoClose: autoCloseTimerSlow
  });
};

export const nInfo = ({
  message = 'Good evening, you lovely person!',
  container = 'TC',
  transition = Flip
}) => {
  toast.info(message, {
    containerId: container,
    transition: transition,
    autoClose: autoCloseTimerDefault
  });
};

export const nSuccess = ({
  message = 'Operation successfully terminated!',
  container = 'TC',
  transition = Flip
}) => {
  toast.success(message, {
    containerId: container,
    transition: transition,
    autoClose: autoCloseTimerDefault
  });
};
