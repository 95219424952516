import React from 'react';
import { Link } from 'react-router-dom';
import {
  UncontrolledDropdown,
  DropdownMenu
} from 'reactstrap';
import { Settings, Grid, Users, Layers } from 'react-feather';

const HeaderMenu = () => {
  return (
    <>
      <div className="app-header-menu">
        <UncontrolledDropdown tag="span" className="position-relative">
          <DropdownMenu className="overflow-hidden border-0 bg-midnight-bloom p-3 mt-2">
            <div className="dropdown-mega-menu-md">
              <div className="text-center">
                <div className="fw-bold font-size-lg mb-1 text-white">
                  Dashboards
                </div>
                <p className="text-white-50 mb-3">
                  There are currently <b className="text-white">12</b> dashboard
                  layouts available!
                </p>
              </div>
              <div className="d-flex flex-wrap">
                <div className="w-50 p-2">
                  <Link
                    to="/DashboardProjects"
                    className="btn card card-box text-start d-flex justify-content-center px-3 py-2 w-100 border-0"
                    title="">
                    <div>
                      <Grid className="h1 d-block my-2 text-success" />
                      <div className="fw-bold font-size-lg text-black">
                        Projects
                      </div>
                      <div className="font-size-md mb-1 text-black-50">
                        Dashboard 9
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="w-50 p-2">
                  <Link
                    to="/DashboardHelpdesk"
                    className="btn card card-box text-start d-flex justify-content-center px-3 py-2 w-100 border-0"
                    title="">
                    <div>
                      <Users className="h1 d-block my-2 text-danger" />
                      <div className="fw-bold font-size-lg text-black">
                        Helpdesk
                      </div>
                      <div className="font-size-md mb-1 text-black-50">
                        Dashboard 4
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="w-50 p-2">
                  <Link
                    to="/DashboardCrmManager"
                    className="btn card card-box text-start d-flex justify-content-center px-3 py-2 w-100 border-0"
                    title="">
                    <div>
                      <Settings className="h1 d-block my-2 text-warning" />
                      <div className="fw-bold font-size-lg text-black">
                        CRM UI
                      </div>
                      <div className="font-size-md mb-1 text-black-50">
                        Dashboard 6
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="w-50 p-2">
                  <Link
                    to="/DashboardCustomers"
                    className="btn card card-box text-start d-flex justify-content-center px-3 py-2 w-100 border-0"
                    title="">
                    <div>
                      <Layers className="h1 d-block my-2 text-first" />
                      <div className="fw-bold font-size-lg text-black">
                        Customers
                      </div>
                      <div className="font-size-md mb-1 text-black-50">
                        Dashboard 12
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </>
  );
};

export default HeaderMenu;
