import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { create, getSource, iri, nError, update } from 'utils';
import { useUserStateValues } from 'states';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormActions, Select, TextField } from 'forms';

const defaultSettings = {
  useHeader: false,
  useFooter: true
};

const GroupForm = ({ entity = null, settings = defaultSettings }) => {
  const { t } = useTranslation();
  const userStateValues = useUserStateValues();

  const [data, setData] = useState(entity);
  const [tenants] = useState(
    userStateValues.isAuthenticated && userStateValues.user
      ? userStateValues.user.tenants
      : []
  );
  const [tenant, setTenant] = useState(
    userStateValues.isAuthenticated && userStateValues.user
      ? userStateValues.currentTenant
      : null
  );
  const [loading, setLoading] = useState(false);
  const [updatedKeys, setUpdatedKeys] = useState([]);
  const isCancelled = React.useRef(false);

  const sendRequest = () => {
    setLoading(true);
    let formData = {};
    if (data) {
      ['name'].forEach((key) => {
        if (
          0 <= updatedKeys.indexOf(key) &&
          (!entity || entity[key] !== data[key])
        ) {
          formData[key] =
            '' === ('string' === typeof data[key] && data[key].trim())
              ? null
              : data[key];
        }
      });

      let valid = true;

      // Ensure mandatory fields if CREATE.
      if (!data.name || !tenant) {
        nError({ message: t('missingFields') });
        valid = false;
      }
      formData['tenant'] = iri(tenant, 'tenant');

      if (valid && Object.keys(data).length > 0) {
        const source = getSource();
        const params = {
          resourceType: 'group',
          data: formData,
          success: {
            callback: () => {
              if (isCancelled.current) return;

              window.location.reload();
            }
          },
          always: {
            callback: () => setLoading(false)
          },
          aOptions: { cancelToken: source.token }
        };

        if (null !== entity) {
          // Send the update API call.
          update({ ...params, ...{ id: entity['@uuid'] } });
        } else {
          create(params);
        }
      }
    }
  };

  const updateFormData = (attribute, value) => {
    let edited = {};
    edited[attribute] = value;
    setData(null !== data ? { ...data, ...edited } : edited);

    if (0 === updatedKeys.length || 0 > updatedKeys.indexOf(attribute)) {
      const newUpdatedKeys = updatedKeys;
      newUpdatedKeys.push(attribute);
      setUpdatedKeys(newUpdatedKeys);
    }
  };

  const switchTenant = (value) => {
    tenants.forEach((t) => {
      if (t['@uuid'] === value) {
        setTenant(t);
      }
    });
  };

  return (
    <>
      {0 !== tenants.length && (
        <>
          {true === settings.useHeader && (
            <div className={'text-center px-5 pb-3'}>
              <div className={'avatar-icon-wrapper rounded-circle m-0'}>
                <div
                  className={
                    'd-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-info text-info m-0 d-130'
                  }>
                  <FontAwesomeIcon
                    icon={null !== entity ? ['fas', 'edit'] : ['fas', 'users']}
                    className={'d-flex align-self-center display-6'}
                  />
                </div>
              </div>

              <h4 className={'fw-bold mt-4'}>
                {null !== entity
                  ? t('edit') + ' ' + entity.name
                  : t('addGroup')}
              </h4>
            </div>
          )}

          <form>
            {null === entity && (
              <Select
                onChange={switchTenant}
                field={'tenant'}
                label={t('tenant')}
                mandatory
                options={tenants}
                defaultValue={userStateValues.currentTenant['@uuid']}
              />
            )}

            <TextField
              setter={updateFormData}
              field={'name'}
              label={t('name')}
              mandatory
              defaultValue={data ? data.name : entity ? entity.name ?? '' : ''}
            />

            {true === settings.useFooter && (
              <FormActions
                confirmAction={(e) => {
                  e.preventDefault();
                  sendRequest();
                }}
                confirmName={null !== entity ? t('update') : t('submit')}
                loading={loading}
              />
            )}
          </form>
        </>
      )}
    </>
  );
};

export default GroupForm;
