import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { Activity } from 'react-feather';

const SidebarFooter = () => {
  return (
    <>
      <div className="app-sidebar--footer">
        <ul>
          <li>
            <a
              href="https://stats.uptimerobot.com/3qK6LH7myB"
              className="btn btn-sm btn-transition-none px-2 mx-2 text-dark"
              id="ProjectsApplicationTooltip">
              <Activity />
            </a>
            <UncontrolledTooltip
              target="ProjectsApplicationTooltip"
              container=".app-sidebar--footer">
              Page de status de l'API
            </UncontrolledTooltip>
          </li>
        </ul>
      </div>
    </>
  );
}

export default SidebarFooter;
