import React from 'react';
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import { useTranslation } from 'react-i18next';

const FormActions = ({
  confirmAction,
  confirmName = null,
  confirmForm = null,
  confirmDisabled = false,
  loading = false,
  displayCancel = true,
  cancelAction = null,
  cancelName = null,
  classname = 'd-flex align-items-center justify-content-end mt-4'
}) => {
  const { t } = useTranslation();

  return (
    <div className={classname}>
      {displayCancel && (
        <button
          className={'btn btn-link text-danger ms-2'}
          type={'button'}
          data-bs-dismiss={'modal'}
          onClick={cancelAction}>
          {cancelName ?? t('cancel')}
        </button>
      )}
      <LaddaButton
        onClick={confirmAction}
        type={'submit'}
        className={'btn btn-pill btn-primary'}
        loading={loading}
        data-style={ZOOM_IN}
        form={confirmForm}
        disabled={confirmDisabled}>
        {confirmName ?? t('submit')}
      </LaddaButton>
    </div>
  );
};

export default FormActions;
