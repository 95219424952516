import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import ReactQuill from 'react-quill';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  Row,
  Col,
  FormGroup,
  Form,
  Label,
  Input
} from 'reactstrap';
import { action, create, list, update, getCruds, iri, nInfo } from 'utils';
import {
  AutocompleteKeywords,
  DatatableVariationGroups,
  Loader
} from 'components';
import { useUserStateValues } from 'states';
import Select, { Option } from 'rc-select';

const DigitalAssetForm = ({ digitalAsset }) => {
  const { t } = useTranslation();
  const userState = useUserStateValues();
  const history = useHistory();
  const theme = 'snow';
  const [sending, setSending] = useState(false);
  const [data, setData] = useState(null);

  const [collections, setCollections] = useState([]);
  const [daCollections, setDaCollections] = useState(
    digitalAsset ? digitalAsset.collections : []
  );

  const [keywords, setKeywords] = useState([]);
  const [daKeywords, setDaKeywords] = useState(
    digitalAsset ? digitalAsset.keywords : []
  );

  const [categories, setCategories] = useState([]);
  const [daCategories, setDaCategories] = useState(
    digitalAsset ? digitalAsset.categories : []
  );

  useEffect(() => {
    // Get collections.
    list({
      resourceType: 'digitalAssetsCollection',
      params: [
        {
          label: 'tenant',
          value: digitalAsset.tenant['@uuid']
        }
      ],
      success: {
        callback: (res) => {
          setCollections(res.data['hydra:member']);
        }
      }
    });
    // Get keywords.
    list({
      resourceType: 'digitalAssetsKeyword',
      params: [
        {
          label: 'tenant',
          value: digitalAsset.tenant['@uuid']
        }
      ],
      setter: setKeywords
    });
    // Get categories.
    list({
      resourceType: 'digitalAssetsCategory',
      params: [
        {
          label: 'tenant',
          value: digitalAsset.tenant['@uuid']
        }
      ],
      success: {
        callback: (res) => {
          setCategories(res.data['hydra:member']);
        }
      }
    });
    // eslint-disable-next-line
  }, [digitalAsset]);

  const updateformData = (attribute, value) => {
    let edited = {};

    // "metadata" particular case.
    if ('platformHeight' === attribute) {
      edited['metadata'] = {platformHeight: parseInt(value)}
    } else {
      edited[attribute] = value;
    }
    setData(null !== data ? { ...data, ...edited } : edited);
  };

  const handleSubmit = () => {
    setSending(true);
    let formData = {};
    if (null !== data) {
      // Handle base fields.
      ['sku', 'supplierUrl', 'description', 'copyright', 'metadata'].map((key) => {
        // Only patch changed values.
        if (data[key] && digitalAsset[key] !== data[key]) {
          formData[key] = data[key];
        }

        return 1;
      });

      if (Object.keys(formData).length > 0) {
        update({
          resourceType: 'digitalAsset',
          id: digitalAsset['@uuid'],
          data: formData,
          notif: false,
          error: { message: t('crudUpdateErrored', { itemName: digitalAsset.name })},
          success: { callback: () => updateOtherFields() }
        });
      } else updateOtherFields();
    } else updateOtherFields();
  };

  const updateOtherFields = () => {
    daCollections.map((daCollection) => {
      if (
        !(digitalAsset.collections ?? [])
          .map((dac) => dac['@uuid'])
          .includes(daCollection['@uuid'] ?? daCollection)
      ) {
        processUpdateCollection({
          actionType: 'collectionAdd',
          data: { collection: daCollection['@id'] ?? daCollection }
        });
      }

      return true;
    });

    (digitalAsset.collections ?? []).map((col) => {
      if (
        !(daCollections ?? [])
          .map((dac) => dac['@uuid'] ?? dac)
          .includes(col['@uuid'])
      ) {
        processUpdateCollection({
          actionType: 'collectionRemove',
          data: { collection: col['@id'] }
        });
      }

      return true;
    });

    daKeywords.map((daKeyword) => {
      if (
        !(digitalAsset.keywords ?? [])
          .map((dak) => dak['@id'])
          .includes(daKeyword['@id'])
      ) {
        if (
          !(keywords['hydra:member'] ?? [])
            .map((tk) => tk['@id'])
            .includes(daKeyword['@id'])
        ) {
          create({
            resourceType: 'digitalAssetsKeyword',
            data: {
              name: daKeyword.name
            },
            success: {
              callback: (res) => {
                processUpdateCollection({
                  actionType: 'keywordAdd',
                  data: { keyword: res.data['@id'] }
                });
              }
            }
          });
        } else {
          processUpdateCollection({
            actionType: 'keywordAdd',
            data: { keyword: daKeyword['@id'] }
          });
        }
      }

      return true;
    });

    (digitalAsset.keywords ?? []).map((k) => {
      if (
        !(daKeywords ?? [])
          .map((dak) => dak['@id'])
          .includes(k['@id'])
      ) {
        processUpdateCollection({
          actionType: 'keywordRemove',
          data: { keyword: k['@id'] }
        });
      }

      return true;
    });

    daCategories.map((daCategory) => {
      if (
        !(digitalAsset.categories ?? [])
          .map((dac) => dac['@uuid'])
          .includes(daCategory['@uuid'] ?? daCategory)
      ) {
        processUpdateCollection({
          actionType: 'categoryAdd',
          data: { category: daCategory['@id'] ?? daCategory }
        });
      }

      return true;
    });

    (digitalAsset.categories ?? []).map((c) => {
      if (
        !(daCategories ?? [])
          .map((dac) => dac['@uuid'] ?? dac)
          .includes(c['@uuid'])
      ) {
        processUpdateCollection({
          actionType: 'categoryRemove',
          data: { category: c['@uuid'] }
        });
      }

      return true;
    });

    setSending(false);
    nInfo({
      message: t('crudUpdateSucceeded', { itemName: digitalAsset.name })
    });
    history.push(iri(digitalAsset, 'digitalAsset'))
  };

  const processUpdateCollection = ({ actionType, data }) => {
    action({
      action: actionType,
      resourceType: 'digitalAsset',
      id: digitalAsset['@uuid'],
      data: data
    });
  };

  const digitalAssetsSettings = {
    hideIfEmpty: false
  };

  const crudsVgroups = getCruds({
    resourceType: 'variationGroup',
    userState,
    object: digitalAsset,
    translator: t
  });
  crudsVgroups.read.methods.list.params = [
    { label: 'digitalAsset', value: digitalAsset['@uuid'] }
  ];

  return (
    <>
      {digitalAsset ? (
        <Row>
          <Col md={12} xl={6}>
            <Card className={'px-4'}>
              <Form>
                <FormGroup>
                  <Label className={'fw-bold pt-3'} for={'sku'}>
                    {t('reference')}
                  </Label>
                  <Input
                    type={'text'}
                    name={'sku'}
                    id={'sku'}
                    defaultValue={digitalAsset.sku ?? ''}
                    placeholder={t('reference')}
                    onChange={(e) => updateformData('sku', e.target.value)}
                    onBlur={(e) => updateformData('sku', e.target.value)}
                  />

                  <Label
                    className={'fw-bold pt-3'}
                    for={'platformHeight'}>
                    {t('platformHeight')}
                  </Label>
                  <Input
                    type={'number'}
                    name={'platformHeight'}
                    id={'platformHeight'}
                    defaultValue={digitalAsset.metadata?.platformHeight ?? ''}
                    placeholder={t('platformHeight')}
                    onChange={(e) =>
                      updateformData('platformHeight', e.target.value)
                    }
                    onBlur={(e) =>
                      updateformData('platformHeight', e.target.value)
                    }
                  />

                  <Label
                    className={'fw-bold pt-3'}
                    for={'supplierUrl'}>
                    {t('supplierUrl')}
                  </Label>
                  <Input
                    type={'text'}
                    name={'supplierUrl'}
                    id={'supplierUrl'}
                    defaultValue={digitalAsset.supplierUrl ?? ''}
                    placeholder={t('supplierUrl')}
                    onChange={(e) =>
                      updateformData('supplierUrl', e.target.value)
                    }
                    onBlur={(e) =>
                      updateformData('supplierUrl', e.target.value)
                    }
                  />

                  <Label
                    className={'fw-bold pt-3'}
                    for={'description'}>
                    {t('description')}
                  </Label>
                  <ReactQuill
                    id={'description'}
                    theme={theme}
                    onChange={(e) => updateformData('description', e)}
                    value={
                      (data && data.description) ??
                      digitalAsset.description ??
                      ''
                    }
                    placeholder={t('description')}
                  />

                  {collections && (
                    <>
                      <Label
                        className={'fw-bold pt-3'}
                        for={'collections'}>
                        {t('collection_plural')}
                      </Label>
                      <Select
                        id="group-form-collections"
                        defaultValue={daCollections.map((dac) => {
                          return dac['@uuid'];
                        })}
                        multiple
                        style={{ width: '100%' }}
                        optionLabelProp={'children'}
                        onChange={setDaCollections}
                        dropdownStyle={{ zIndex: 2500 }}>
                        {collections.map((c) => (
                          <Option
                            key={c.name}
                            value={c['@uuid']}>
                            {c.name}
                          </Option>
                        ))}
                      </Select>
                    </>
                  )}
                  
                  {categories && categories.length > 0 && (
                    <>
                      <Label
                        className={'fw-bold pt-3'}
                        for={'category'}>
                        {t('category_plural')}
                      </Label>
                      <Select
                        id="group-form-categories"
                        defaultValue={daCategories.map((dac) => {
                          return dac['@uuid'];
                        })}
                        multiple
                        style={{ width: '100%' }}
                        optionLabelProp={'children'}
                        onChange={setDaCategories}
                        dropdownStyle={{ zIndex: 2500 }}>
                        {categories.map((c) => (
                          <Option
                            key={c.name}
                            value={c['@uuid']}>
                            {c.name}
                          </Option>
                        ))}
                      </Select>
                    </>
                  )}

                  {keywords && (
                    <>
                      <Label
                        className={'fw-bold pt-3'}
                        for={'keywords'}>
                        {t('keyword_plural')}
                      </Label>
                      <AutocompleteKeywords
                        setter={setDaKeywords}
                        selected={digitalAsset.keywords}
                      />
                    </>
                  )}

                  <Label className={'fw-bold pt-3'}>
                    {t('copyright')}
                  </Label>
                  <ReactQuill
                    id={'copyright'}
                    theme={theme}
                    onChange={(e) => updateformData('copyright', e)}
                    value={
                      (data && data.copyright) ?? digitalAsset.copyright ?? ''
                    }
                    placeholder={t('copyright')}
                  />
                </FormGroup>
              </Form>

              <div className={'form-group text-start py-4'} data-children-count="1">
                <LaddaButton
                  type={'submit'}
                  className={'m-2 btn btn-primary'}
                  loading={sending}
                  data-style={ZOOM_IN}
                  onClick={handleSubmit}>
                  {t('update')}
                </LaddaButton>
                <Button
                  color={'link'}
                  className={'m-2 btn-link-danger'}
                  onClick={() => history.push(iri(digitalAsset, 'digitalAsset'))}>
                  <span>{t('close')}</span>
                </Button>
              </div>
            </Card>
          </Col>

          <Col md={12} xl={6} className={'mt-2'}>
            <DatatableVariationGroups
              settings={digitalAssetsSettings}
              digitalAsset={digitalAsset}
              cruds={crudsVgroups}
            />
          </Col>
        </Row>
      ) : (
        <Loader type={'pacman'} optClasses={'vh-100'} />
      )}
    </>
  );
};

export default DigitalAssetForm;
