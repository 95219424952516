import React, { useEffect, useState } from 'react';
import { getColors, getMediaSrc, getSource } from 'utils';
import { PlanTeaser } from 'components';
import {
  usePlansState,
  usePlansStateValues,
  refreshState,
  getPlan
} from 'states';

const TenantSubscription = ({ tenant }) => {
  const planStateValues = usePlansStateValues();
  const planState = usePlansState();
  const [plansList, setPlansList] = useState(null);

  const isCancelled = React.useRef(false);
  useEffect(() => {
    const source = getSource();

    if (null === planStateValues.plans) {
      refreshState(
        planState,
        () => {
          if (isCancelled.current) return;

          setPlansList(planStateValues.plans);
        },
        { cancelToken: source.token }
      );
    } else {
      setPlansList(planStateValues.plans);
    }

    return () => {
      source.cancel();
      setPlansList(null);
    };

    // eslint-disable-next-line
  }, []);

  return (
    <div className={'row'} key={tenant.name}>
      {null !== tenant.plan && (
        <>
          <div className={'col col-md-12 mb-4'}>
            <div className={'row'}>
              <div className={'col col-md-3'}>
                <div className={'card-img-wrapper rounded'}>
                  <div className={'card-badges card-badges-bottom'}>
                    <span
                      className={
                        'badge rounded-pill bg-' + getColors(tenant.plan).badge
                      }>
                      {tenant.plan.label}
                    </span>
                  </div>
                  <img
                    alt={'tenant-' + tenant.name}
                    className={'card-img-top rounded'}
                    src={getMediaSrc(tenant, 'avatar')}
                  />
                </div>
              </div>
              <div className={'col col-md-9'}>
                <PlanTeaser
                  plan={getPlan({
                    plans: plansList,
                    name: tenant.plan ? tenant.plan.name ?? '' : ''
                  })}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TenantSubscription;
