import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useThemeState, useThemeStateValues } from 'states';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UncontrolledTooltip } from 'reactstrap';
import projectLogo from 'assets/images/logo.png';
import { useTranslation } from 'react-i18next';

const SidebarHeader = () => {
  const { t } = useTranslation();
  const themeState = useThemeState();
  const themeOptions = useThemeStateValues();

  function toggleSidebarMobile() {
    themeState.sidebarToggleMobile.set(!themeOptions.sidebarToggleMobile)
  }

  function toggleSidebar() {
    themeState.sidebarToggle.set(!themeOptions.sidebarToggle)
  }

  return (
    <>
      <div className="app-sidebar--header">
        <div className="nav-logo">
          <Link
            to="/"
            title="SceneAR.io | AR Assets Manager">
            <i>
              <img
                alt="logo"
                src={projectLogo}
              />
            </i>
            <span>SceneAR.io</span>
          </Link>
        </div>
        <button
          onClick={toggleSidebar}
          className="btn btn-sm collapse-sidebar-btn"
          id="CollapseSidebarTooltip">
          <FontAwesomeIcon icon={['far', 'dot-circle']} size="lg" />
        </button>
        <UncontrolledTooltip
          target="CollapseSidebarTooltip"
          container=".app-sidebar"
          placement="right">
          {t('collapseSidebar')}
        </UncontrolledTooltip>
        <button
          className={clsx(
            'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
            { 'is-active': themeOptions.sidebarToggleMobile }
          )}
          onClick={toggleSidebarMobile}>
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
        </button>
        <button
          onClick={toggleSidebar}
          className="expand-sidebar-btn btn btn-sm"
          id="ExpandSidebarTooltip">
          <FontAwesomeIcon icon={['fas', 'arrows-alt-h']} />
        </button>
        <UncontrolledTooltip
          target="ExpandSidebarTooltip"
          container=".app-sidebar"
          placement="right">
          {t('expandSidebar')}
        </UncontrolledTooltip>
      </div>
    </>
  );
}

export default SidebarHeader;
