import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DigitalAssetCard, Loader } from 'components';
import { MasonryLayout } from 'layouts';
import { useWindowSizeState } from 'states';
import { Link } from 'react-router-dom';
import LaddaButton, { ZOOM_IN } from 'react-ladda';

const DigitalAssetsList = ({ digitalAssets, reload, isLoading = false, source = null }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const loadMore = () => {
    setLoading(true);

    reload(
      () => {
        setLoading(false);
      },
      true,
      source
    );
  };

  const [width] = useWindowSizeState();
  let columns = 1;

  // Quick'n'Dirty "RWD-like"
  if (width > 768) {
    columns = width > 1200 ? 4 : Math.trunc(width / 330);
  }

  return (
    <>
      {200 < width && (
        <>
          {isLoading ? (
            <Loader type={'pacman'} />
          ) : (
            <>
              <div className={'recordings-container'}>
                <MasonryLayout columns={columns} gap={25}>
                  {(digitalAssets['hydra:member'] ?? []).map((item) => (
                    <Link
                      key={item['@id']}
                      to={item['@id']}
                      className="card card-box-hover-rise mb-5">
                      <DigitalAssetCard digitalAsset={item} />
                    </Link>
                  ))}
                </MasonryLayout>
              </div>

              <div className={'p-3 text-center'}>
                <LaddaButton
                  onClick={loadMore}
                  type={'submit'}
                  className={'m-2 btn btn-primary'}
                  loading={loading}
                  disabled={
                    (digitalAssets['hydra:member'] ?? []).length ===
                    (digitalAssets['hydra:totalItems'] ?? 0)
                  }
                  data-style={ZOOM_IN}>
                  {t('loadMore')}
                </LaddaButton>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

export default DigitalAssetsList;
