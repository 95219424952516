import { EventSourcePolyfill } from 'event-source-polyfill';
import { sign } from 'jsonwebtoken';

const hubUrl = process.env.REACT_APP_HUB_URL;
const hubJwtKey = process.env.REACT_APP_HUB_JWT_SECRET;
const debugMode = process.env.REACT_APP_DEBUG_MODE || '0';

export const subscribe = (topics: string[], callback: any|null = null) => {
  if ('1' === debugMode) console.log('Subscribed to : ' + topics.join(', '));

  const url = new URL(hubUrl);
  topics.forEach((topic: any) => url.searchParams.append('topic', topic));

  const jwt = getHubJwt(topics);
  const eventSource = new EventSourcePolyfill(url.toString(), {headers: {'Authorization': 'Bearer ' + jwt}});
  if (eventSource) {
    eventSource.onmessage = (e) => {
      onHubEvent(e, callback);
    };
  }
};

const onHubEvent = (message: any, callback: any|null = null) => {
  if ('1' === debugMode) console.log(message);

  if (callback) {
    callback(message);
  }
};

const getHubJwt = (topics: string[]) => sign({
  mercure: {
    subscribe: topics,
    publish: []
  }
}, hubJwtKey);