import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DigitalAssetVariationGroupForm,
  DigitalAssetVariationGroupTemplateForm
} from 'forms';
import { DatatableDefault, DatatableVariationGroups } from 'components';
import { getCruds, getSource, iri, list } from 'utils';
import { useUserStateValues } from 'states';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DropdownMenu,
  DropdownToggle,
  Modal,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown
} from 'reactstrap';
import appConfig from 'config/appConfig.json';

const DatatableVariationGroupsTemplate = ({
  tenant,
  data = null,
  settings = {}
}) => {
  const { t } = useTranslation();
  const userState = useUserStateValues();
  const resourceType = 'variationGroupsModel';
  const [rows, setRows] = useState(data);
  const defaultSettings = {
    ...{
      linkVariationGroupAvailable: false
    },
    ...settings
  };

  const isCancelled = React.useRef(false);
  useEffect(() => {
    const source = getSource();

    if (null === data) {
      const variationGroupsTemplatesParams = [
        { label: 'tenant', value: tenant['@uuid'] },
        { label: 'order[weight]', value: 'ASC' },
        { label: 'page', value: 1 },
        { label: 'itemsPerPage', value: 3 }
      ];

      list({
        resourceType: resourceType,
        params: variationGroupsTemplatesParams,
        success: {
          callback: (res) => {
            if (isCancelled.current) return;

            setRows(res.data['hydra:member']);
          }
        },
        aOptions: { cancelToken: source.token }
      }).then();
    }

    return () => {
      isCancelled.current = true;
      source.cancel();
      setRows(null);
    };

    // eslint-disable-next-line
  }, [data]);

  // const setDefault = (row) => {
  //   const data = {};
  //   data['default'] = true;
  //
  //   update({
  //     resourceType: 'variationGroupsModel',
  //     id: row['@uuid'],
  //     data: data,
  //     error: {
  //       message: t('crudUpdateErrored', { itemName: row.name })
  //     },
  //     success: {
  //       message: t('crudUpdateSucceeded', { itemName: row.name })
  //     }
  //   });
  // }
  const columns = [
    {
      name: t('name'),
      selector: 'name',
      sortable: true,
      hide: 'sm',
      center: true
    },
    {
      name: t('actions'),
      selector: 'actions',
      center: true,
      cell: (row) => (
        <UncontrolledDropdown className="mx-3">
          <DropdownToggle
            color="link"
            className="p-0 border-0 text-blue-50 no-caret">
            <FontAwesomeIcon
              icon={['fas', 'ellipsis-h']}
              className="font-size-lg"
            />
          </DropdownToggle>
          <DropdownMenu right className="text-nowrap p-0">
            <Nav className="flex-column">
              <NavItem>
                <NavLink
                  href="#"
                  onClick={() => {
                    toggleVariationGroupTemplateEditModal();
                    setVariationGroupTemplateEdit(row);
                  }}>
                  <div className="nav-link-icon">
                    <FontAwesomeIcon icon={['fas', 'edit']} />
                  </div>
                  <span>{t('edit') + ' ' + row.name}</span>
                </NavLink>
              </NavItem>
              {/*{false === row.default && (*/}
              {/*  <NavItem>*/}
              {/*    <NavLink href="#" onClick={e => { setDefault(row) }}>*/}
              {/*      <div className="nav-link-icon">*/}
              {/*        <FontAwesomeIcon icon={['fas', 'award']} />*/}
              {/*      </div>*/}
              {/*      <span>{t('setItemAsDefault', { item: row.name })}</span>*/}
              {/*    </NavLink>*/}
              {/*  </NavItem>*/}
              {/*)}*/}
              {defaultSettings.linkVariationGroupAvailable && (
                <NavItem>
                  <NavLink
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      toggleAddVariationGroupModal(row);
                    }}>
                    <div className="nav-link-icon">
                      <FontAwesomeIcon icon={['fas', 'folder']} />
                    </div>
                    <span>
                      {t('linkItemToContent', {
                        item: t('variationGroup'),
                        content: row.name
                      })}
                    </span>
                  </NavLink>
                </NavItem>
              )}
            </Nav>
          </DropdownMenu>
        </UncontrolledDropdown>
      )
    }
  ];
  const [modalAddVariationGroup, setModalAddVariationGroup] = useState(false);
  const [variationGroupTempalteSelected, setVariationGroupTemplateSelected] =
    useState(null);
  const toggleAddVariationGroupModal = (value) => {
    setVariationGroupTemplateSelected(value);
    setModalAddVariationGroup(!modalAddVariationGroup);
  };

  const [variationGroupTemplateEdit, setVariationGroupTemplateEdit] =
    useState(null);
  const [modalVariationGroupTemplateEdit, setModalVariationGroupTemplateEdit] =
    useState(false);
  const toggleVariationGroupTemplateEditModal = () =>
    setModalVariationGroupTemplateEdit(!modalVariationGroupTemplateEdit);

  // Be careful ! data = row (locked param name)
  const ExpandedComponent = ({ data }) => {
    const crudsVgroups = getCruds({
      resourceType: 'variationGroup',
      userState,
      object: data,
      translator: t
    });
    crudsVgroups.read.methods.list.params = [
      { label: 'model', value: data['@uuid'] }
    ];

    return (
      <>
        <DatatableVariationGroups
          data={data.groups.length > 0 ? data.groups : []}
          tenant={tenant}
          settings={{
            linkAssetAvailable: false,
            linkVariationGroupAvailable: true,
            hideIfEmpty: false
          }}
          cruds={crudsVgroups}
          model={data}
        />
      </>
    );
  };

  const [modalAddVariationTemplateGroup, setModalAddVariationTemplateGroup] =
    useState(false);
  const toggleAddVariationGroupTemplateModal = () => {
    setModalAddVariationTemplateGroup(!modalAddVariationTemplateGroup);
  };

  const variationGroupTemplateFormSetting = {
    redirectPath: iri(tenant, 'tenant')
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.default,
      style: {
        backgroundColor: appConfig.colors.selectedRow.hexa
      }
    }
  ];

  const [cruds, setCruds] = useState(null);
  useEffect(() => {
    const newCruds = getCruds({
      resourceType: 'variationGroupsModel',
      userState,
      translator: t
    });
    setCruds(newCruds);

    return () => {
      setCruds(null);
    };

    // eslint-disable-next-line
  }, []);

  return (
    <>
      {rows && rows.length > 0 && (
        <>
          <DatatableDefault
            columns={columns}
            data={rows}
            tenant={tenant}
            settings={{
              ...{
                tableId: 'dav-' + tenant['@uuid'],
                title: t('variationGroupsModel_plural'),
                expandableRows: true,
                expandableRowsComponent: <ExpandedComponent data={rows} />,
                // user: userUuid,
                selectableRows: true,
                hideHeaders: false,
                loading: false,
                displayTableHeaders: true,
                modalForm: (
                  <DigitalAssetVariationGroupTemplateForm
                    settings={variationGroupTemplateFormSetting}
                    toggleModal={toggleAddVariationGroupTemplateModal}
                  />
                ),
                toggleModal: toggleAddVariationGroupTemplateModal,
                modalOpen: modalAddVariationTemplateGroup,
                conditionalRowStyles: conditionalRowStyles
              },
              ...settings
            }}
            pagination={{
              orderBy: 'weight',
              sortDirection: 'ASC'
            }}
            cruds={cruds}
          />

          <Modal
            zIndex={2000}
            centered
            isOpen={modalVariationGroupTemplateEdit}
            toggle={toggleVariationGroupTemplateEditModal}>
            <div className="p-5">
              {null !== variationGroupTemplateEdit && (
                <DigitalAssetVariationGroupTemplateForm
                  uuid={variationGroupTemplateEdit['@uuid']}
                  toggleModal={toggleVariationGroupTemplateEditModal}
                />
              )}
            </div>
          </Modal>

          <Modal
            zIndex={2000}
            centered
            isOpen={modalAddVariationGroup}
            toggle={toggleAddVariationGroupModal}>
            <div className="p-5">
              {null !== variationGroupTempalteSelected && (
                <DigitalAssetVariationGroupForm
                  toggleModal={toggleAddVariationGroupModal}
                  model={variationGroupTempalteSelected}
                />
              )}
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default DatatableVariationGroupsTemplate;
