import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useThemeStateValues } from 'states';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { UncontrolledTooltip, Button } from 'reactstrap';
import { NavLink as RouterLink } from 'react-router-dom';
import projectLogo from 'assets/images/react.svg';
import {
  Settings,
  CloudDrizzle,
  Search,
  Grid,
  Users,
  LifeBuoy,
  Coffee,
  Bell
} from 'react-feather';

const SidebarCollapsed = () => {

  const themeOptions = useThemeStateValues();

  return (
    <>
      <div
        className={clsx(
          'app-sidebar app-sidebar--collapsed app-sidebar--mini',
          themeOptions.sidebarStyle,
          { 'app-sidebar--shadow': themeOptions.sidebarShadow }
        )}>
        <div className="app-sidebar--header">
          <div className="nav-logo">
            <RouterLink
              to="/"
              activeClassName="active"
              title="SceneAR.io AR Assets Manager">
              <i>
                <img
                  alt="logo"
                  src={projectLogo}
                />
              </i>
              <span>SceneAR.io</span>
            </RouterLink>
          </div>
        </div>

        <div className="app-sidebar--content">
          <PerfectScrollbar>
            <ul className="sidebar-menu-collapsed">
              <li>
                <RouterLink
                  activeClassName="active"
                  to="/DashboardDefault"
                  id="DashboardTooltip1">
                  <span>
                    <Settings className="line-height-1" />
                  </span>
                </RouterLink>
                <UncontrolledTooltip
                  popperClassName="tooltip-secondary"
                  placement="right"
                  target="DashboardTooltip1"
                  container="body"
                  boundariesElement="window">
                  Dashboard
                </UncontrolledTooltip>
              </li>
              <li>
                <RouterLink
                  activeClassName="active"
                  to="/ApplicationsCalendar"
                  id="EventsCalendarTooltip1">
                  <span>
                    <CloudDrizzle className="line-height-1" />
                  </span>
                </RouterLink>
                <UncontrolledTooltip
                  popperClassName="tooltip-secondary"
                  placement="right"
                  target="EventsCalendarTooltip1"
                  container="body"
                  boundariesElement="window">
                  Events calendar
                </UncontrolledTooltip>
              </li>
              <li>
                <RouterLink
                  activeClassName="active"
                  to="/ApplicationsChat"
                  id="ChatTooltip1">
                  <span>
                    <Search className="line-height-1" />
                  </span>
                </RouterLink>
                <UncontrolledTooltip
                  popperClassName="tooltip-secondary"
                  placement="right"
                  target="ChatTooltip1"
                  container="body"
                  boundariesElement="window">
                  Chat
                </UncontrolledTooltip>
              </li>
              <li>
                <RouterLink
                  activeClassName="active"
                  to="/ApplicationsContacts"
                  id="ContactsTooltip1">
                  <span>
                    <Grid className="line-height-1" />
                  </span>
                </RouterLink>
                <UncontrolledTooltip
                  popperClassName="tooltip-secondary"
                  placement="right"
                  target="ContactsTooltip1"
                  container="body"
                  boundariesElement="window">
                  Contacts
                </UncontrolledTooltip>
              </li>
              <li>
                <RouterLink
                  activeClassName="active"
                  to="/ApplicationsFileManager"
                  id="FileManagerTooltip1">
                  <span>
                    <Users className="line-height-1" />
                  </span>
                </RouterLink>
                <UncontrolledTooltip
                  popperClassName="tooltip-secondary"
                  placement="right"
                  target="FileManagerTooltip1"
                  container="body"
                  boundariesElement="window">
                  File manager
                </UncontrolledTooltip>
              </li>
              <li>
                <RouterLink
                  activeClassName="active"
                  to="/ApplicationsMail"
                  id="MailTooltip1">
                  <span>
                    <LifeBuoy className="line-height-1" />
                  </span>
                </RouterLink>
                <UncontrolledTooltip
                  popperClassName="tooltip-secondary"
                  placement="right"
                  target="MailTooltip1"
                  container="body"
                  boundariesElement="window">
                  Mail
                </UncontrolledTooltip>
              </li>
              <li>
                <RouterLink
                  activeClassName="active"
                  to="/ApplicationsProjects"
                  id="ProjectsTooltip1">
                  <span>
                    <Coffee className="line-height-1" />
                  </span>
                </RouterLink>
                <UncontrolledTooltip
                  popperClassName="tooltip-secondary"
                  placement="right"
                  target="ProjectsTooltip1"
                  container="body"
                  boundariesElement="window">
                  Projects
                </UncontrolledTooltip>
              </li>
              <li>
                <RouterLink
                  activeClassName="active"
                  to="/ApplicationsWidgets"
                  id="AppWidgetsTooltip1">
                  <span>
                    <Bell className="line-height-1" />
                  </span>
                </RouterLink>
                <UncontrolledTooltip
                  popperClassName="tooltip-secondary"
                  placement="right"
                  target="AppWidgetsTooltip1"
                  container="body"
                  boundariesElement="window">
                  App widgets
                </UncontrolledTooltip>
              </li>
            </ul>
            <div className="text-center mb-2">
              <Button
                tag={Link}
                color="warning"
                className="m-1 p-0 d-inline-block shadow-none text-center font-size-lg d-40 rounded"
                to="/DashboardDefault"
                id="BacktoPreviousPageTooltip1">
                <FontAwesomeIcon icon={['fas', 'arrow-left']} />
              </Button>
              <UncontrolledTooltip
                popperClassName="tooltip-secondary"
                placement="right"
                target="BacktoPreviousPageTooltip1"
                container="body"
                boundariesElement="window">
                Back to dashboard
              </UncontrolledTooltip>
            </div>
          </PerfectScrollbar>
        </div>
      </div>
      <div
        className={clsx('app-sidebar-overlay', {
          'is-active': themeOptions.sidebarToggleMobile
        })}
      />
    </>
  );
}

export default SidebarCollapsed;
