import React from 'react';
import { Card } from 'reactstrap';

import Circle from 'react-circle';
import clsx from "clsx";

// DashboardProjectSection2
const QuotaType2 = ({ entity = null, optionalClass = '' }) => {
  const maxValue = entity ? entity.maxValue ?? -1 : -1;
  const currentValue = entity ? entity.currentValue ?? 10 : 10;
  let percentage = Math.round((currentValue / maxValue) * 100);
  if (-1 === maxValue) {
    percentage = currentValue;
  }
  let progressColor = percentage > 50 ? '#FF0000' : '#FFA500';
  if (-1 === maxValue) {
    progressColor = '#1bc943';
  }

  return (
    <>
      <Card className={clsx('card-box mb-5 ' + optionalClass )}>
        <div className="mx-auto">
          <Circle
            animate={true}
            animationDuration="3s"
            responsive={true}
            size={'176'}
            lineWidth={'14'}
            progress={percentage}
            progressColor={progressColor}
            bgColor="#1bc943"
            textColor="rgba(0,0,0,.85)"
            roundedStroke={true}
            textStyle={{
              fontSize: '80px'
            }}
            showPercentage={true}
            showPercentageSymbol={-1 !== maxValue && entity.type !== 'storage'}
          />
        </div>
      </Card>
    </>
  );
}

export default QuotaType2;