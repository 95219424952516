import React from 'react';
import { Feature } from 'components';

const FeaturesList = ({ features, type = 'included', checkCase = true }) => {
  const addClass = type === 'included' ? '' : 'text-black-50';

  return (
    <>
      <ul className="list-unstyled text-lg-left mb-4 fw-bold font-size-sm">
        {features
          .map(feature => (
              <li key={feature.label} className={'px-4 py-2 ' + addClass}>
                <Feature feature={feature} type={type} checkCase={checkCase} />
              </li>
          ))}
      </ul>
    </>
  );
};

export default FeaturesList;
