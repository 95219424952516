import { State, createState, useState } from '@hookstate/core';
import config from 'config/themeConfig.json';

interface ThemeConfig {
  contentBackground: string,
  footerBgTransparent: boolean,
  footerFixed: boolean,
  footerShadow: boolean,
  headerBgTransparent: boolean,
  headerDrawerToggle: boolean,
  headerFixed: boolean,
  headerSearchHover: boolean,
  headerShadow: boolean,
  pageTitleBackground: string,
  pageTitleBreadcrumb: boolean,
  pageTitleDefaultIcon: string
  pageTitleDescription: boolean,
  pageTitleIconBox: boolean,
  pageTitleShadow: boolean,
  pageTitleStyle: string,
  sidebarFixed: boolean,
  sidebarFooter: boolean,
  sidebarLighter: boolean,
  sidebarShadow: boolean,
  sidebarStyle: string,
  sidebarToggle: boolean,
  sidebarToggleMobile: boolean,
  sidebarUserbox: boolean,
  themeConfiguratorToggle: boolean
}

const state: State<ThemeConfig> = createState(config);

export const useThemeState = () => useState(state);
export const useThemeStateValues = () => useThemeState().value;
