import React from 'react';
import { Link } from 'react-router-dom';

const BreadcrumbItem = ({ title = '', url = null, index = 0, count = 0 }) => {
  const isLast = count === index + 1;

  return (
    <li className={isLast ? 'breadcrumb-item active' : 'breadcrumb-item'}>
      {isLast ? <span>{title}</span> : <Link to={url ?? '/'}>{title}</Link>}
    </li>
  );
};

export default BreadcrumbItem;
