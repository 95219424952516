import React from 'react';
import { UserForm } from 'forms';

const UserEdit = ({ uuid, setActiveTab }) => {
  return (
    <UserForm uuid={uuid} setActiveTab={setActiveTab} />
  );
};

export default UserEdit;
