import React, { useEffect } from 'react';
import { TopXItem } from 'components';
import Slider from 'react-slick';
import {
  CardBody,
  Card,
  Form,
  FormGroup
} from 'reactstrap';
import Select, { Option } from 'rc-select';
import { useTranslation } from 'react-i18next';

const TopXitemsListSelect = ({ items, setItems, settings, itemsSelect = [] }) => {
  const { t } = useTranslation();
  const defaultsSettings = {
    ...{
      title: 'title',
      desc: 'desc',
      displayDivider: true,
      type: '',
      imgType: '',
      imgAlt: 'logo',
      slider: items.length > 2
    },
    ...settings
  };

  useEffect(() => {
    refreshDaList(itemsSelect[0]['@uuid']);
    // eslint-disable-next-line
  }, []);

  const switchItem = (value) => {
    itemsSelect.map(i => {
      if (i['@uuid'] === value) {
        refreshDaList(value);
      }
      return t;
    });
  };

  const refreshDaList = (uuid = null) => {
    const tmpItems = [];
    if ( itemsSelect.filter((vg) => vg['@uuid'] === uuid)[0].variations.length > 0) {
      itemsSelect.filter((vg) => vg['@uuid'] === uuid)[0]['variations'].map((v) => (tmpItems.push(v.digitalAsset)))
    }
    setItems(tmpItems);

    return true;
  }
  return (
    <>
      <Card className={'card-box mb-5 p-3 text-center'}>
        <div className={'my-3'}>
          <Form>
            <FormGroup>
              <label htmlFor={'membership-form-user'} className={'form-label'}>
                <h6 className={'fw-bold font-size-lg mb-1 me-1 text-black'}>
                  {settings.title + ' :'}
                </h6>
              </label>
              {itemsSelect && (
                <Select
                  id={'membership-form-user'}
                  defaultValue={ itemsSelect && itemsSelect[0] ? itemsSelect[0]['@uuid'] : 'N/a' }
                  style={{ width: '25%' }}
                  optionLabelProp={'children'}
                  onChange={switchItem}
                  dropdownStyle={{ zIndex: 2500 }}
                >
                  {itemsSelect.map((item) => (
                    <Option
                        key={item.name}
                        value={item['@uuid']}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              )}
            </FormGroup>
          </Form>
          <p className={'text-black-50 mb-0'}>
            {settings.desc}
          </p>
        </div>
        {defaultsSettings.slider ? (
          <div className={'card-box d-block'}>
            <Slider {...settings.sliderSettings}>
              {items.map((item, index) => (
                <div key={item['@id']}>
                  <CardBody>
                    <div>
                      <TopXItem item={item} last={index === items.length - 1} settings={defaultsSettings}/>
                    </div>
                  </CardBody>
                </div>
              ))}
            </Slider>
          </div>
        ) : (
          <div className={'d-flex flex-row flex-wrap justify-content-center'}>
            {items.map((item, index) => (
              <TopXItem key={item['@id']} item={item} last={index === items.length - 1} settings={defaultsSettings}/>
            ))}
          </div>
        )}

      </Card>
    </>
  );
};

export default TopXitemsListSelect;
