import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const Feature = ({
 feature,
 type = 'included',
 checkCase = true
}) => {
  const { t, i18n } = useTranslation();
  let currentL = i18n.language;
  let label = feature.label;
  let unlimited = t('unlimited');
  if (currentL === 'fr') {
    unlimited = ': ' + unlimited;
  }
  let value = (-1 === feature.value) ? unlimited : feature.value;
  let unit = ' ';
  switch (label) {
    case 'storage':
      if (value !== unlimited) {
        unit = value > 1000000000 ? ' Go ' : ' Mo ';
        value = value > 1000000000 ? value/1000000000 : value/1000000;
      }
      break;
    case 'bandwidth':
      if (value !== unlimited) {
        unit = ' Go ';
        value = value/1000000;
      }
      break;
    case 'worker':
      label = 0 === value ? 'mutualizedWorker' : 'dedicatedWorker';
      value = ' ';
      break;
    case 'optimizer':
      if (!value) {
        unit = t('noFeaturePrefix');
      }
      value = ' ';
      break;
  }

  const display1 = value + unit + t(label, {count: value});
  const display2 = t(label, {count: 2}) + ' ' + value + unit;
  const display3 = value + unit + t(label, {count: 2});

  return (
    <>
      {true === checkCase ? (
        <FontAwesomeIcon
          icon={type === 'included' ? ['far', 'check-circle'] : ['far', 'times-circle']}
          className={type === 'included' ? 'text-success me-2' : 'text-danger me-2'}
        />) : (<></>)}
      {currentL === 'fr' && label !== 'storage' && label !== 'bandwidth' && value !== unlimited ? (
        <span>{display1.trim()}</span>
      ) : (
        <>
          {currentL === 'fr' && (label === 'storage' || label === 'bandwidth' || value === unlimited) ? (
            <span>{display2.trim()} </span>
          ) : (
            <>
              {currentL !== 'fr' && value === unlimited ? (
                <span>{display3.trim()}</span>
              ) : (
                <>
                  {currentL !== 'fr' && value !== unlimited ? (
                    <span>{display1.trim()}</span>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Feature;
