export const unState = (data) => {
  return null === data ? null : JSON.parse(JSON.stringify(data));
};

export { useBillingState, useBillingStateValues } from './BillingState';
export { useThemeState, useThemeStateValues } from './ThemeState';
export { useViewerState, useViewerStateValues } from './ViewerState';
export { default as useWindowSizeState } from './WindowsSizeState';
export {
  getTenant,
  inviteCallback,
  login,
  loginCallback,
  logout,
  logoutUser,
  populateUserFromProvider,
  removeTenantFromUser,
  setCurrentTenant,
  setPreview,
  updateCurrentTenantPlan,
  useUserState,
  useUserStateValues
} from './UserState';
export {
  getPlan,
  refreshState,
  usePlansState,
  usePlansStateValues
} from './PlanState';
