import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Form,
  FormGroup, Input,
  Label,
  Row
} from 'reactstrap';
import { AutocompleteVariationGroups, Loader} from 'components';
import {
  nError,
  read,
  iri,
  list, update, create
} from 'utils';
import { useUserStateValues } from 'states/UserState';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DigitalAssetVariationGroupForm = ({ uuid = null, settings = null, setActiveTab = null, selectedVariationGroup = null, digitalAsset = null, toggleModal = null, model = null }) => {
  const resourceType = 'variationGroup';
  const { t } = useTranslation();

  const [entity, setEntity] = useState(null);
  const [entityOriginal, setEntityOriginal] = useState(null);

  const userStateValues = useUserStateValues();
  const [tenants] = useState((userStateValues.isAuthenticated && userStateValues.user) ? userStateValues.user.tenants : []);
  const [tenant] = useState((userStateValues.isAuthenticated && userStateValues.user) ? userStateValues.currentTenant : null);
  const [variationGroup, setVariationGroup] = useState(
    selectedVariationGroup ?? []
  );
  const [variationGroups, setVariationGroups] = useState(null);

  useEffect(() => {
    if (null !== uuid) {
      read({
        resourceType: resourceType,
        id: uuid,
        // setter: setEntity,
        success: {
          callback: (r) => {
            setEntityOriginal(r.data);
          }
        }
      });
    }
    // eslint-disable-next-line
  }, [uuid]);

  useEffect(() => {
    list({
      resourceType: 'variationGroup',
      params: [{ label: 'tenant', value: iri(tenant, 'tenant') }],
      setter: setVariationGroups,
      success: {
        callback: (r) => {
          // if (null === variationGroup && r.data['hydra:member'].length > 0) {
          //   setVariationGroup(r.data['hydra:member'][0]);
          // }
        }
      }
    });
    // eslint-disable-next-line
  }, []);

  const sendRequest = () => {
    // Ensure mandatory fields if CREATE.

    // TODO link this to API correct endpoint.
    if (null === variationGroup) {
      nError({ message: t('missingFields') });
    } else {
      let data = {};
      if (null !== uuid) {
        ['digitalAsset', 'parent', 'name'].map((key) => {
          const keyUuid = entityOriginal[key];
          // Only patch changed values.
          if (!entityOriginal || keyUuid !== (entity? entity[key] ?? keyUuid : keyUuid)) {
            if (key !== 'name') {
              data[key] = iri(entity[key], key);
            } else {
              data[key] = entity[key];
            }
          }
          return 1;
        });
      } else {
        data['digitalAsset'] = digitalAsset ? iri(digitalAsset, 'digitalAsset') : null;
        data['model'] = model ? model['@uuid'] : null;
        data['parent'] = model ? null : variationGroup.length > 0 ? variationGroup[0]['@uuid'] : null;
        data['name'] = entity ? entity.name ?? '' : '';
        data['description'] = entity ? entity.description ?? '' : '';
        data['weight'] = 0;
        data['additionalProp1'] = [];
      }

      const params = {
        resourceType: 'variationGroup',
        data: data,
        success: {
          callback: () => {
            if (null !== toggleModal) {
              toggleModal();
            }
            if (settings.callback) {
                settings.callback();
            } else {
              window.location.href = settings.redirectPath;
            }
          }
        },
        error: { message: t('variationGroupAddError') },
      };
      
      if (uuid) {
        // Send the update API call.
        update({ ...params, ...{ id: uuid } });
      } else {
        create(params);
      }
    }
  };

  const updateData = (attribute, value) => {
    let edited = {};
    edited[attribute] = value;
    setEntity({ ...entity, ...edited });
  };

  const autocompleteSettings = {
    params: [
      { label: 'tenant', value: tenant['@uuid'] },
      { label: 'digitalAsset', value: digitalAsset ? digitalAsset['@uuid'] : null}
    ],
    displayLabel: false
  };

  return (
    <>
      {null === variationGroups || (uuid && !entityOriginal) ? (
        <Loader type={'pacman'} />
      ) : (
        <Row>
          <Col>
            {0 !== tenants.length && (
              <>
                <div className="text-center p-5">
                  <div className="avatar-icon-wrapper rounded-circle m-0">
                    <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-info text-info m-0 d-130">
                      <FontAwesomeIcon
                        icon={
                          uuid !== null ? ['fas', 'edit'] : ['fas', 'folder']
                        }
                        className="d-flex align-self-center display-6"
                      />
                    </div>
                  </div>
                  <h4 className="fw-bold mt-4">
                    {uuid !== null ? t('editVariationGroup') : t('addVariationGroup')}
                    {model !== null ? ' ' + t('to') + ' ' + model.name : ''}
                  </h4>
                </div>
                <Form>
                  <FormGroup>
                    <Label className={'mt-3'} for="name">{t('name')} <sup className="text-danger">*</sup></Label>
                    <Input
                      name="name"
                      id="name"
                      defaultValue={entityOriginal ? entityOriginal.name ?? '' : ''}
                      placeholder={t('name')}
                      onChange={(e) => updateData('name', e.target.value)}
                    />
                  </FormGroup>
                  {uuid === null && model === null && (
                    <FormGroup>
                      <Label className="pt-3">
                        {t('parent')}
                      </Label>
                      <Row>
                        <Col>
                          {null !== variationGroup && (
                            <div className="form-group" data-children-count="1">
                              <AutocompleteVariationGroups settings={autocompleteSettings} setter={setVariationGroup} selected={variationGroup}/>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </FormGroup>
                  )}
                  <Button
                    color="primary"
                    className="mt-4"
                    onClick={sendRequest}>
                    {uuid ? t('update') : t('submit')}
                  </Button>
                  { (uuid || toggleModal) && (
                    <Button
                      color="link"
                      className="m-2 mt-4 btn-link-danger"
                      onClick={() => {
                        if (null !== toggleModal) {
                          toggleModal();
                        } else {
                          setActiveTab('1');
                        }
                      }}>
                      <span>{t('cancel')}</span>
                    </Button>
                  )}
                </Form>
              </>
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

export default DigitalAssetVariationGroupForm;
