import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';

const Wysiwyg = ({
  setter,
  field = 'name',
  label = 'name',
  mandatory = false,
  defaultValue = '',
  placeholder = '#same',
  classname = ''
}) => {
  if ('#same' === placeholder) placeholder = label;

  return (
    <div className={classname}>
      <label htmlFor={field} className={'form-label mt-3'}>
        {label}&nbsp;
        {mandatory && <sup className={'text-danger'}>*</sup>}
      </label>

      <CKEditor
        editor={ClassicEditor}
        data={defaultValue}
        config={{
          language: 'fr',
          toolbar: {
            items: [
              'heading',
              '|',
              // 'fontfamily',
              // 'fontsize',
              // '|',
              // 'alignment',
              // '|',
              // 'fontColor',
              // 'fontBackgroundColor',
              // '|',
              'bold',
              'italic',
              // 'strikethrough',
              // 'underline',
              // 'subscript',
              // 'superscript',
              '|',
              'link',
              '|',
              'outdent',
              'indent',
              '|',
              'bulletedList',
              'numberedList',
              // 'todoList',
              // '|',
              // 'code',
              // 'codeBlock',
              '|',
              'insertTable',
              '|',
              // 'uploadImage',
              'mediaEmbed',
              'blockQuote',
              '|',
              'undo',
              'redo'
            ],
            shouldNotGroupWhenFull: true
          }
        }}
        onReady={(editor) => {
          // Set min editor height.
          editor.editing.view.change((writer) => {
            writer.setStyle(
              'min-height',
              '200px',
              editor.editing.view.document.getRoot()
            );
          });
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          setter(field, data);
        }}
        onBlur={(event, editor) => {
          const data = editor.getData();
          setter(field, data);
        }}
        onFocus={(event, editor) => {}}
      />
    </div>
  );
};

export default Wysiwyg;
