import React from 'react';
import { TabPane } from 'reactstrap';
import { TenantForm } from 'forms';

const TenantEditTab = ({ uuid, tabId, setActiveTab }) => {
  return (
    <TabPane tabId={tabId}>
      <TenantForm uuid={uuid} setActiveTab={setActiveTab} />
    </TabPane>
  );
};

export default TenantEditTab;
