import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';

import { create, nError, iri } from 'utils';
import { useUserStateValues } from 'states/UserState';
import Select, { Option } from 'rc-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const PageForm = ({
  settings = {},
  uuid = null,
  setActiveTab = null,
  toggleModal = null
}) => {
  const resourceType = 'pages';
  const { t } = useTranslation();

  const [page, setPage] = useState(null);
  const userStateValues = useUserStateValues();
  const [tenants] = useState(
    userStateValues.isAuthenticated && userStateValues.user
      ? userStateValues.user.tenants
      : []
  );
  const [tenant, setTenant] = useState(
    userStateValues.isAuthenticated && userStateValues.user
      ? userStateValues.currentTenant
      : null
  );

  const formSettings = {
    ...{
      firstColClass: 'col-12 col-md-6',
      redirectPath: '/pages/add'
    },
    ...settings
  };
  const switchTenant = (value) => {
    tenants.map((t) => {
      if (t['@uuid'] === value) {
        setTenant(t);
      }
      return t;
    });
  };

  const sendRequest = () => {
    let data = {};
    if (page) {
      ['title'].map((key) => {
        data[key] = page[key];
        return true;
      });
    }
    let valid = true;

    // Ensure mandatory fields if CREATE.
    if (!page.title) {
      nError({ message: t('missingFields') });
      valid = false;
    }
    data['tenant'] = iri(tenant, 'tenant');

    if (valid && Object.keys(data).length > 0) {
      const params = {
        resourceType: resourceType,
        data: data,
        success: {
          callback: (res) => {
            window.location.href = formSettings.redirectPath;
          }
        }
      };
      create(params);
    }
  };

  const updateData = (attribute, value) => {
    let edited = {};
    edited[attribute] = value;
    setPage({ ...page, ...edited });
  };

  return (
    <>
      <Row>
        <Col className={formSettings.firstColClass}>
          <>
            <div className="text-center p-5">
              <div className="avatar-icon-wrapper rounded-circle m-0">
                <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-info text-info m-0 d-130">
                  <FontAwesomeIcon
                    icon={['fas', 'folder']}
                    className="d-flex align-self-center display-6"
                  />
                </div>
              </div>
              <h4 className="fw-bold mt-4">{t('addPage')}</h4>
            </div>
            <Form>
              <FormGroup>
                <Label for="group-form-tenant">
                  {t('tenant')} <sup className="text-danger">*</sup>
                </Label>
                <Select
                  id="group-form-tenant"
                  defaultValue={userStateValues.currentTenant['@uuid']}
                  style={{ width: '100%' }}
                  optionLabelProp={'children'}
                  onChange={switchTenant}
                  dropdownStyle={{ zIndex: 2500 }}>
                  {tenants.map((tenant) => (
                    <Option key={tenant.name} value={tenant['@uuid']}>
                      {tenant.name}
                    </Option>
                  ))}
                </Select>
              </FormGroup>

              <FormGroup>
                <Label className={'mt-3'} for="title">
                  {t('title')} <sup className="text-danger">*</sup>
                </Label>
                <Input
                  name="title"
                  id="title"
                  defaultValue={''}
                  placeholder={t('title')}
                  onChange={(e) => updateData('title', e.target.value)}
                />
              </FormGroup>

              <FormGroup>
                <Label className={'mt-3'} for="abstract">
                  {t('abstract')}
                </Label>
                <Input
                  name="abstract"
                  id="abstract"
                  defaultValue={''}
                  placeholder={t('abstract')}
                  onChange={(e) => updateData('abstract', e.target.value)}
                />
              </FormGroup>

              <FormGroup>
                <Label className={'mt-3'} for="content">
                  {t('content')} <sup className="text-danger">*</sup>
                </Label>
                <CKEditor
                  editor={ClassicEditor}
                  data={''}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log('Editor is ready to use!', editor);
                  }}
                  onChange={(event, editor) => {
                    updateData('content', editor.getData());
                  }}
                  onBlur={(event, editor) => {
                    console.log('Blur.', editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log('Focus.', editor);
                  }}
                />
              </FormGroup>

              <Button color="primary" className="mt-4" onClick={sendRequest}>
                {t('submit')}
              </Button>
              {(setActiveTab || toggleModal) && (
                <Button
                  color="link"
                  className="m-2 mt-4 btn-link-danger"
                  onClick={() => {
                    if (null !== toggleModal) {
                      toggleModal();
                    } else {
                      setActiveTab('1');
                    }
                  }}>
                  <span>{t('cancel')}</span>
                </Button>
              )}
            </Form>
          </>
        </Col>
      </Row>
    </>
  );
};

export default PageForm;
