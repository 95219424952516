import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { useUserStateValues, logout } from 'states';
import {
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { getMediaSrc, iri } from 'utils';

const HeaderUserbox = () => {
  const { t } = useTranslation();
  const userState = useUserStateValues();
  const user = userState.user;
  const tenant = userState.currentTenant;

  return (
    <>
      {tenant && user && userState.isAuthenticated && (
        <UncontrolledDropdown className="user-box position-relative ms-2">
          <DropdownToggle
            color="link"
            className="p-0 text-start d-flex align-items-center">
            <div className="d-block p-0 avatar-icon-wrapper">
              <Badge color="success" className="badge-circle p-top-a bg-success">
                Online
              </Badge>
              <div className="avatar-icon rounded">
                <img src={getMediaSrc(user, 'avatar')} alt="avatar" className="nav-user-profile rounded-circle" />
              </div>
            </div>
            <div className="d-none d-xl-block ps-2">
              <div className="fw-bold">{user.username}</div>
              <span className="text-black-50">{user.nickname}</span>
            </div>
            <span className="ps-1 ps-xl-3">
              <FontAwesomeIcon
                icon={['fas', 'angle-down']}
                className="opacity-5"
              />
            </span>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>{user.email}</DropdownItem>
            <DropdownItem header>{tenant.name}</DropdownItem>
            <DropdownItem
              tag={RouterNavLink}
              to={iri(user, 'user') + '/profile'}
              className="dropdown-profile"
              activeClassName="router-link-exact-active"
            >
              <FontAwesomeIcon icon="user" className="me-3" /> {t('profile')}
            </DropdownItem>
            <DropdownItem
              id="qsLogoutBtn"
              onClick={() => logout()}
            >
              <FontAwesomeIcon icon="power-off" className="me-3" /> {t('logout')}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
    </>
  );
};

export default HeaderUserbox;
