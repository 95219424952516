import React from 'react';

const CheckboxField = ({
  toggle,
  field = 'name',
  label = '',
  value = 0,
  checked = false,
  classname = 'form-check'
}) => {
  return (
    <div className={classname}>
      <input
        className={'form-check-input'}
        type={'checkbox'}
        value={value}
        onChange={toggle}
        checked={checked}
        id={field} />
        <label className={'form-check-label'} htmlFor={field}>
          {label}
        </label>
    </div>
  );
};

export default CheckboxField;
