import React, { lazy, Suspense } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ClimbingBoxLoader } from 'react-spinners';
import { LeftSidebar } from 'layout-components';

import apiConfig from 'config/apiConfig.json';

// const PageTests = lazy(() => import('./pages/PageTests'));
const PageCGU = lazy(() => import('./pages/static/CGU'));
const PageCGV = lazy(() => import('./pages/static/CGV'));
// const PageCollectionForm = lazy(() => import('./pages/forms/datamodel/Collection'));
const PageContact = lazy(() => import('./pages/forms/Contact'));
const PageDigitalAssetsUpload = lazy(
  () => import('./pages/forms/upload/DigitalAssets')
);
const PageRestriction = lazy(() => import('./pages/Restriction'));
// const PageGroupForm = lazy(() => import('./pages/forms/datamodel/groups/Group'));
const PageHome = lazy(() => import('./pages/Home'));
const PageInviteCallback = lazy(
  () => import('./pages/Authorization/InviteCallback')
);
const PageLoginCallback = lazy(
  () => import('./pages/Authorization/LoginCallback')
);
const PageLogoutCallback = lazy(
  () => import('./pages/Authorization/LogoutCallback')
);
// const PageMembershipForm = lazy(() => import('./pages/forms/datamodel/groups/Membership'));
// const PagePageForm = lazy(() => import('./pages/forms/datamodel/Page'));
const PagePrivacy = lazy(() => import('./pages/static/Privacy'));
const PageSearch = lazy(() => import('./pages/forms/search'));
const PageSubscription = lazy(() => import('./pages/Subscription'));
const PageUploadStatus = lazy(() => import('./pages/forms/upload/Status'));
// const PageUserForm = lazy(() => import('./pages/forms/datamodel/User'));
const PageProfileBase = lazy(() => import('./pages/profiles/BaseProfile'));

const Routes = () => {
  const location = useLocation();
  const daUri = apiConfig.resources.digitalAsset.uri;
  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };
  const SuspenseLoading = () => {
    return (
      <>
        <div
          className={
            'd-flex align-items-center flex-column vh-100 justify-content-center text-center py-3'
          }>
          <div className={'d-flex align-items-center flex-column px-4'}>
            <ClimbingBoxLoader color={'#3c44b1'} loading={true} />
          </div>
        </div>
      </>
    );
  };

  const routes = [
    {
      path: '/logout',
      exact: true,
      main: PageLogoutCallback
    },
    {
      path: '/login/callback',
      exact: true,
      main: PageLoginCallback
    },
    {
      path: '/subscription',
      exact: true,
      main: PageSubscription
    },
    {
      path: '/error/:errorid?/:errorcode?',
      main: PageRestriction
    },
    {
      path: '/contact',
      exact: true,
      main: PageContact
    },
    {
      path: '/conditions',
      exact: true,
      main: PageCGU
    },
    {
      path: '/cgv',
      exact: true,
      main: PageCGV
    },
    {
      path: '/privacy',
      exact: true,
      main: PagePrivacy
    },
    {
      path: '/',
      exact: true,
      main: PageHome
    },
    {
      path: '/:resourceType(tenants)/:uuid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/:page(dashboard|subscription|payment|members|advanced)?/:tab?/:action?',
      main: PageProfileBase
    },
    {
      path: '/:resourceType(digital-assets|users)/:uuid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/:page(dashboard|database|manage|profile|accounts)?/:tab?/:action?',
      main: PageProfileBase
    },
    {
      path: '/invite/:id/accept/:secret',
      main: PageInviteCallback
    },
    {
      path: '/' + daUri + '/upload',
      main: PageDigitalAssetsUpload
    },
    {
      path: '/:resourceType(uploads)/:uuid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
      main: PageUploadStatus
    },
    {
      path: '/' + daUri,
      main: PageSearch
    },
    {
      path: '*',
      main: PageRestriction
    }
  ];

  return (
    <AnimatePresence>
      <Suspense fallback={<SuspenseLoading />}>
        <LeftSidebar>
          <Switch>
            {routes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <Switch
                  location={location}
                  key={location.pathname + location.search + location.hash}>
                  <motion.div
                    initial={'initial'}
                    animate={'in'}
                    exit={'out'}
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      component={route.main}
                    />
                  </motion.div>
                </Switch>
              </Route>
            ))}
          </Switch>
        </LeftSidebar>
      </Suspense>
    </AnimatePresence>
  );
};

export default Routes;
