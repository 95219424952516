import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import EmailValidator from 'email-validator';
import { create, getSource, nError, read } from 'utils';
import { AutocompleteTenants, Loader } from 'components';
import ReCAPTCHA from 'react-google-recaptcha';
import config from 'config/apiConfig.json'

const ContactForm = ({ uuid = null }) => {
  const { t } = useTranslation();
  const [tenant, setTenant] = useState(null); // <- Todo get tenant from id in url
  const [autocompleteSettings, setAutocompleteSettings] = useState({ defaultValue: [''] })
  const [loading, setLoading] = useState(uuid !== 'undefined' && uuid !== null && uuid !== false && uuid !== '');
  let componentMounted = true;

  useEffect(() => {
    const source = getSource();

    if (componentMounted && uuid !== 'undefined' && uuid !== null && uuid !== false && uuid !== '') {
      read({
        resourceType: 'tenant',
        id: uuid,
        success: {
          callback: (r) => {
            setAutocompleteSettings({ defaultValue: [r.data.name] });
            setTenant(r.data['hydra:member'].filter((t) => (t.name === 'SceneAR.io'))[0]);
          }
        },
        always: {
          callback: () => {
            setLoading(false);
          }
        },
        aOptions: { cancelToken: source.token }
      });
    }

    return () => {
      // eslint-disable-next-line
      componentMounted = false;
      source.cancel();
      setTenant(null);
      setAutocompleteSettings({ defaultValue: [''] });
    };

    // eslint-disable-next-line
  }, []);

  const captchaKey = process.env.REACT_APP_CAPTCHA_KEY ?? '123456';
  const [captcha, setCaptcha] = useState(false);
  const updateCaptcha = (value) => {
    data['security'] = value;
    setCaptcha(!!value);
  };

  const checkEmail = (value) => {
    if (!EmailValidator.validate(value)) {
      nError({ message: t('emailError') });
    }
  };
  const [data, setData] = useState({ sendCopy: false });
  const updateData = (attribute, value) => {
    const newData = data;

    newData[attribute] = value;
    setData(newData);
  };

  const handleValidate = () => {
    if (captcha) {
      const requestData = { ...data, ...{ tenant: tenant ? tenant['@uuid'] : config.default.tenant } };
      let missingFields = false;
      ['subject', 'message', 'email'].forEach((field) => {
        if (!data[field]) {
          missingFields = true;
        }
      });

      // Exit early if some mandatory fields are missing...
      if (missingFields) {
        return nError({ message: t('missingFields') });
      }

      requestData.sendCopy = data.sendCopy && data.sendCopy === true;
      create({
        resourceType: 'contact',
        data: requestData,
        error: { message: t('emailSentError') },
        success: { message: t('emailSentSuccess') },
        always: {
          callback: () => {
            window.location.href = '/';
          }
        },
        notif: true
      });
    }
  };

  return (
    <>
      {!loading ? (
        <form>
          <div>
            <label htmlFor={'tenant'} className={'form-label mt-3'}>
              {t('tenant')}
            </label>
            <AutocompleteTenants setter={setTenant} settings={autocompleteSettings} />
          </div>

          <div>
            <label htmlFor={'object'} className={'form-label mt-3'}>
              {t('object')} <sup className={'text-danger'}>*</sup>
            </label>
            <input
              className={'form-control'}
              type={'text'}
              name={'object'}
              id={'object'}
              defaultValue={''}
              placeholder={t('object')}
              onChange={(e) => updateData('subject', e.target.value)}/>
          </div>

          <div>
            <label htmlFor={'message'} className={'form-label mt-3'}>
              {t('message')} <sup className={'text-danger'}>*</sup>
            </label>
            <textarea
              className={'form-control'}
              name={'message'}
              id={'message'}
              rows={5}
              defaultValue={''}
              placeholder={t('message')}
              onChange={(e) => updateData('message', e.target.value)}/>
          </div>

          <div>
            <label htmlFor={'email'} className={'form-label mt-3'}>
              {t('email')} <sup className="text-danger">*</sup>
            </label>
            <input
              className={'form-control'}
              type={'email'}
              name={'email'}
              id={'email'}
              defaultValue={''}
              placeholder={t('email')}
              onBlur={(e) => checkEmail(e.target.value)}
              onChange={(e) => updateData('email', e.target.value)}/>
          </div>

          <div className={'d-flex justify-content-end mt-3'}>
            <div className={'form-check form-check-md'}>
              <input
                className={'form-check-input'}
                type={'checkbox'}
                id={'receiveEmail'}
                onChange={(e) => updateData('sendCopy', e.target.checked)}
              />
              <label className={'form-check-label'} htmlFor={'receiveEmail'}>
                {t('receiveEmail')}
              </label>
            </div>
          </div>

          <div className={'d-flex align-items-center'}>
            <ReCAPTCHA
              sitekey={captchaKey}
              onChange={updateCaptcha}/>
            <button
              className={'btn btn-primary ms-2'}
              type={'submit'}
              onClick={handleValidate}>
              {t('send')}
            </button>
          </div>

        </form>
      ) : (
        <Loader type={'pacman'} />
      )}

    </>
  );
};

export default ContactForm;
