import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Badge,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

import hero1 from 'assets/images/hero-bg/hero-1.jpg';

const PageSubscriptionHeader = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className={'hero-wrapper bg-composed-wrapper bg-plum-plate'}>
        <div className={'flex-grow-1 w-100 d-flex align-items-center pb-5'}>
          <div
            className={'bg-composed-wrapper--image'}
            style={{ backgroundImage: 'url(' + hero1 + ')' }}
          />
          <div className={'bg-composed-wrapper--bg bg-second opacity-5'} />
          <div className={'bg-composed-wrapper--content'}>
            <div className={'container py-5'}>
              <div className={'row'}>
                <div className={'col col-xl-7'}>
                  <div className={'d-flex align-items-center'}>
                    <Badge pill color="second" className="px-4 h-auto py-1 bg-second rounded-pill">
                      Release 1.0.0
                    </Badge>
                  </div>
                  <div className={'text-white mt-3'}>
                    <h1 className={'display-6 mb-3 fw-bold'}>
                      Hébergez vos modèles 3D
                    </h1>
                    <p className={'font-size-lg text-white-50'}>
                      Découvrez les différents plans pour héberger vos modèles 3D sur SceneAR.io et les rendre visible au plus grand nombre.
                    </p>
                    <div className={'divider border-2 my-5 border-light opacity-2 rounded-circle w-25'} />
                    <div>
                      <Button
                        tag={'a'}
                        href={'/subscription#plans'}
                        size={'lg'}
                        color={'warning'}>
                        <span className={'btn-wrapper--label'}>
                          {t('browsePlans')}
                        </span>
                        <span className={'btn-wrapper--icon'}>
                          <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                        </span>
                      </Button>
                      <Button
                        tag={'a'}
                        href={'/documentation/api/authorization'}
                        color={'link'}
                        className={'btn-link-light ps-1 pe-1 ms-3'}>
                        <span>Documentation</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={'hero-indicator animated font-size-xxl text-white opacity-10'}>
          <FontAwesomeIcon icon={['fas', 'arrow-down']} />
        </div>
      </div>
    </>
  );
};

export default PageSubscriptionHeader;
