import React from 'react';
import ReactDOM from 'react-dom';
// import * as Sentry from '@sentry/react';
// import { BrowserTracing } from '@sentry/tracing';
import App from './App';
import * as serviceWorker from './serviceWorker';
import NiceModal from '@ebay/nice-modal-react';
import './config/i18nConfig';

// const appEnv = process.env.REACT_APP_ENV || 'prod';
//
// if (appEnv === 'prod') {
//   // Enable sentry if the DSN is specified on PROD only.
//   const sentryDsn = process.env.REACT_APP_SENTRY_DSN || null;
//   if (sentryDsn !== null) {
//     Sentry.init({
//       dsn: sentryDsn,
//       integrations: [new BrowserTracing()],
//       // Set tracesSampleRate to 1.0 to capture 100%
//       // of transactions for performance monitoring.
//       // We recommend adjusting this value in production
//       tracesSampleRate: .1
//     });
//   }
// }

ReactDOM.render(
  <NiceModal.Provider>
    <App />
  </NiceModal.Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
