import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { UserForm } from 'forms';
import { DatatableDefault } from 'components';
import { getCruds, getMediaSrc, iri } from 'utils';
import { useUserStateValues } from 'states';

import appConfig from 'config/appConfig.json';
import defaultAssetImg from 'assets/images/avatars/defaultAvatar.png';

const DatatableUsers = ({ data = null, settings = {}, tenant = null }) => {
  const { t } = useTranslation();
  const userState = useUserStateValues();

  const columns = [
    {
      name: t('user_plural'),
      selector: 'name',
      cell: (row) => (
        <Link to={iri(row, 'user') + '/profile'}>
          <div className={'d-flex align-items-center'}>
            <div className={'avatar-icon-wrapper avatar-icon-sm me-2'}>
              <div className={'avatar-icon'}>
                <img
                  alt={'user-avatar'}
                  src={getMediaSrc(row, 'avatar') ?? defaultAssetImg}
                />
              </div>
            </div>
            <div>{row.name}</div>
          </div>
        </Link>
      ),
      sortable: true
    },
    {
      name: t('email'),
      selector: 'email',
      sortable: true,
      hide: 'md'
    },
    {
      name: t('createdDate'),
      selector: 'created',
      sortable: true,
      hide: 'sm',
      center: true,
      format: (row) => (
        <Moment calendar={true} locale={'fr'}>
          {row.created}
        </Moment>
      )
    },
    {
      name: t('lastUpdate'),
      selector: 'updated',
      sortable: true,
      hide: 'lg',
      center: true,
      format: (row) => (
        <Moment calendar={true} locale={'fr'}>
          {row.updated}
        </Moment>
      )
    },
    {
      name: t('lastLogin'),
      selector: 'lastLogin',
      sortable: true,
      hide: 'lg',
      center: true,
      format: (row) => (
        <Moment calendar={true} locale={'fr'}>
          {row.lastLogin}
        </Moment>
      )
    }
  ];

  // Highlight current user.
  const conditionalRowStyles = [
    {
      when: (row) => row['@uuid'] === userState.user['@uuid'],
      style: {
        backgroundColor: appConfig.colors.selectedRow.hexa
      }
    }
  ];

  const [modalAddUser, setModalAddUser] = useState(false);
  const toggleAddUserModal = () => {
    setModalAddUser(!modalAddUser);
  };

  const userFormSettings = {
    defaultClass: 'col-12',
    tenantUuid: tenant['@uuid']
  };

  const [cruds, setCruds] = useState(null);
  useEffect(() => {
    const newCruds = getCruds({
      resourceType: 'user',
      userState,
      translator: t
    });
    setCruds(newCruds);

    return () => {
      setCruds(null);
    };

    // eslint-disable-next-line
  }, []);

  return (
    cruds && (
      <DatatableDefault
        columns={columns}
        data={data}
        tenant={tenant}
        settings={{
          ...{
            tableId: 'users-table',
            title: t('user_plural'),
            selectedMessage: {
              singular: t('user'),
              plural: t('user_plural')
            },
            conditionalRowStyles: conditionalRowStyles,
            modalForm: (
              <UserForm
                settings={userFormSettings}
                cancelAction={toggleAddUserModal}
              />
            ),
            toggleModal: toggleAddUserModal,
            modalOpen: modalAddUser
          },
          ...settings
        }}
        pagination={{
          orderBy: 'name',
          sortDirection: 'ASC'
        }}
        cruds={cruds}
      />
    )
  );
};

export default DatatableUsers;
