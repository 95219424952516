import React from 'react';
import { useTranslation } from 'react-i18next';
import { DatatableDefault, DatatableDigitalAssets } from 'components';
import Moment from 'react-moment';
import { getCruds, getMediaSrc } from 'utils';
import { useUserStateValues } from 'states';
import { alt } from 'utils';

const DatatableUploads = ({ user = null, data = null }) => {
  const { t } = useTranslation();
  const userState = useUserStateValues();
  if (null === user) user = userState.user;
  const settingsDa = {
    tableId: 'da-sub-table',
    title: t('asset_plural'),
    loading: false,
    subdatatable: true
  };

  // Be careful ! data = row (locked param name)
  const ExpandedComponent = ({ data }) => {
    return (
      data.digitalAssets.length > 0 && (
        <DatatableDigitalAssets
          data={data.digitalAssets}
          settings={settingsDa}
        />
      )
    );
  };

  const columns = [
    {
      name: t('upload_plural'),
      selector: 'uploads',
      cell: (row) => (
        <div className="avatar-wrapper-overlap">
          {row.digitalAssets.map((digitalAsset) => (
            <div
              key={digitalAsset['@uuid']}
              className="avatar-icon-wrapper avatar-icon-sm">
              <div className="avatar-icon">
                <img
                  alt={alt(digitalAsset, 'digitalAsset', 'preview')}
                  src={getMediaSrc(digitalAsset, 'preview')}
                />
              </div>
            </div>
          ))}
        </div>
      ),
      sortable: true
    },
    {
      name: t('createdDate'),
      selector: 'created',
      sortable: true,
      center: true,
      format: (row) => (
        <Moment calendar={true} locale={'fr'}>
          {row.created}
        </Moment>
      )
    },
    {
      name: t('user'),
      selector: 'users',
      sortable: true,
      hide: 'sm',
      center: true,
      format: (row) => <div>{row.user.name}</div>
    },
    {
      name: t('digitalAsset_plural'),
      selector: 'da',
      sortable: true,
      hide: 'sm',
      center: true,
      format: (row) => <div>{row.digitalAssets.length}</div>
    }
  ];

  const uploadDefaultCruds = getCruds({
    resourceType: 'upload',
    userState,
    translator: t
  });
  uploadDefaultCruds.read.methods.list.params.push({
    label: 'user',
    value: userState.user['@uuid'] ?? null
  });

  return (
    <DatatableDefault
      columns={columns}
      settings={{
        tableId: 'uploads-table',
        title: t('upload_plural'),
        selectedMessage: {
          singular: t('upload'),
          plural: t('upload_plural')
        },
        expandableRows: true,
        expandableRowsComponent: <ExpandedComponent data={data} />,
        user: user
      }}
      pagination={{
        orderBy: 'created',
        sortDirection: 'DESC'
      }}
      cruds={uploadDefaultCruds}
    />
  );
};

export default DatatableUploads;
