import React from 'react';
import { useTranslation } from 'react-i18next';

import img from 'assets/images/illustrations/500.svg';

const Error500 = ({code = 500}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={'flex-grow-1 w-100 d-flex align-items-center'}>
        <div className={'col col-lg-6 col-md-9 px-4 px-lg-0 mx-auto text-center text-black'}>
          <img
            src={img}
            className={'w-50 mx-auto d-block my-5 img-fluid'}
            alt={'403'}
          />
          <h2 className={'display-4 mb-3 px-4 fw-bold'}>
            Internal Server Error <strong><sup>[{code}]</sup></strong>
          </h2>
          <h3 className={'font-size-xxl line-height-sm fw-light d-block px-3 mb-3 text-black-50'}>
            {t('500error')}
          </h3>
          <p>
            {t('500desc')}
          </p>
        </div>
      </div>
    </>
  )};

export default Error500;
