import React from 'react';
import '@google/model-viewer';
import { useViewerStateValues } from 'states';
import { getMediaSrc } from 'utils';

const ModelViewer = ({ digitalAsset }) => {
  const viewerStateValues = useViewerStateValues();
  // See complete options list here https://modelviewer.dev
  return (
    <>
      <model-viewer
        id={"model-viewer-element"}
        src={getMediaSrc(digitalAsset, 'fileWB')}
        alt={digitalAsset.name}
        environment-image={viewerStateValues.currentEnvironment !== "none" ? "../shared-assets/backgrounds/" + viewerStateValues.currentEnvironment : null}
        skybox-image={viewerStateValues.skyboxToggle && viewerStateValues.currentEnvironment !== "none" ? "../shared-assets/backgrounds/" + viewerStateValues.currentEnvironment : null}
        ios-src={getMediaSrc(digitalAsset, 'fileAP')}
        loading="lazy"
        poster={getMediaSrc(digitalAsset)}
        autoplay
        ar
        ar-modes={"webxr scene-viewer quick-look fallback"}
        ar-scale={"auto"}
        magic-leap
        camera-controls
        quick-look-browsers={"safari chrome firefox"}
      />
    </>
  );
};

export default ModelViewer;
