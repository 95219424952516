import React from 'react';
import PropTypes from 'prop-types';

// see https://medium.com/the-andela-way/how-to-create-a-masonry-layout-component-using-react-f30ec9ca5e99
const MasonryLayout = (props) => {
  const columnWrapper = {};
  const result = [];

  // Create columns.
  for (let i = 0; i < props.columns; i++) {
    columnWrapper[`column${i}`] = [];
  }

  // Divide children into columns.
  for (let i = 0; i < props.children.length; i++) {
    const columnIndex = i % props.columns;
    columnWrapper[`column${columnIndex}`].push(
      <div
        key={"masonry-item-" + i+1}
        style={{ marginBottom: `${props.gap}px`}}
      >
        {props.children[i]}
      </div>
    );
  }

  // Wrap children in each column with a div.
  for (let i = 0; i < props.columns; i++) {
    result.push(
      <div
        key={"masonry-column-" + i+1}
        style={{
          marginLeft: `${i > 0 ? props.gap : 0}px`,
          flex: 1,
        }}>
        {columnWrapper[`column${i}`]}
      </div>
    );
  }

  return (
    <div style={{ display: 'flex' }}>
      {result}
    </div>
  );
}

MasonryLayout.propTypes = {
  columns: PropTypes.number.isRequired,
  gap: PropTypes.number.isRequired,
  children: PropTypes.arrayOf(PropTypes.element),
};

MasonryLayout.defaultProps = {
  columns: 3,
  gap: 20,
};

export default MasonryLayout;
