import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap';

import flagen from 'assets/images/flags/flag-us.png';
import flagfr from 'assets/images/flags/flag-fr.png';

const LanguageSwitcher = () => {

  const { t, i18n } = useTranslation();
  let currentL = i18n.language;
  let nextL = currentL === 'fr' ? 'en' : 'fr';
  let currentF = currentL === 'fr' ? flagfr : flagen;
  let nextF = currentL === 'fr' ? flagen : flagfr;

  function switchLang() {

    currentL = i18n.language;
    nextL = currentL === 'fr' ? 'en' : 'fr';
    currentF = currentL === 'fr' ? flagfr : flagen;
    nextF = currentL === 'fr' ? flagen : flagfr;

    i18n.changeLanguage(nextL)
  }

  return (
    <>
        <UncontrolledDropdown className="user-box position-relative ms-2">
          <DropdownToggle
            color="link"
            className="p-0 text-start d-flex align-items-center">
            <div className="d-block p-0 avatar-icon-wrapper">
              <div className="avatar-icon rounded">
                <img src={currentF} alt="avatar" className="nav-user-profile rounded-circle" />
              </div>
            </div>

            <span className="ps-1 ps-xl-3">
                <FontAwesomeIcon
                  icon={['fas', 'angle-down']}
                  className="opacity-5"
                />
              </span>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>{t('pickLanguage')}</DropdownItem>
            <DropdownItem
              className="dropdown-profile"
              onClick={switchLang}
            >
              <div className="d-block p-0 avatar-icon-wrapper">
                <div className="avatar-icon rounded">
                  <img src={nextF} alt="avatar" className="nav-user-profile rounded-circle" />
                </div>
              </div>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
    </>
  );
};

export default LanguageSwitcher;
