import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Form,
  FormGroup, Label,
  Row
} from 'reactstrap';
import { AutocompleteAssets, Loader } from 'components';
import {
  nError,
  read,
  iri,
  list, update, create
} from 'utils';
import { useUserStateValues } from 'states';
import Select, { Option } from "rc-select";

const DigitalAssetVariationForm = ({ uuid = null, settings = null, setActiveTab = null, selectedVariationGroup = null, selectedAsset = null, toggleModal = null }) => {
  const resourceType = 'variationGroup';
  const { t } = useTranslation();

  const [entity, setEntity] = useState(null);
  const [entityOriginal, setEntityOriginal] = useState(null);

  const userStateValues = useUserStateValues();
  const [tenants] = useState((userStateValues.isAuthenticated && userStateValues.user) ? userStateValues.user.tenants : []);
  const [tenant] = useState((userStateValues.isAuthenticated && userStateValues.user) ? userStateValues.currentTenant : null);
  const [variationGroup, setVariationGroup] = useState(entity ? entity.group : selectedVariationGroup);
  const [variationGroups, setVariationGroups] = useState(null);
  const [asset, setAsset] = useState(entity ? entity.asset : selectedAsset);

  useEffect(() => {
    if (null !== uuid) {
      read({
        resourceType: resourceType,
        id: uuid,
        setter: setEntity,
        success: {
          callback: (r) => {
            setEntityOriginal(r.data);
          }
        }
      });
    }
    // eslint-disable-next-line
  }, [uuid]);

  useEffect(() => {
    list({
      resourceType: 'variationGroup',
      params: [
        { label: 'tenant', value: iri(tenant, 'tenant') },
        { label: 'digitalAsset', value: iri(asset, 'digitalAsset') }
      ],
      success: {
        callback: (r) => {
          setVariationGroups(r.data['hydra:member']);
          if (null === variationGroup && r.data['hydra:member'].length > 0) {
            setVariationGroup(r.data['hydra:member'][0]);
          }
        }
      }
    });
    // eslint-disable-next-line
  }, []);

  const sendRequest = () => {
    // Ensure mandatory fields if CREATE.

    // TODO link this to API correct endpoint.

    if (null === variationGroup) {
      nError({ message: t('missingFields') });
    } else {
      let data = {};
      if (null !== uuid) {
        ['variationGroup', 'asset'].map((key) => {
          // Only patch changed values.
          if (!entityOriginal || entityOriginal[key].name !== entity[key].name) {
            data[key] = iri(entity, key + 's');
          }

          return 1;
        });
      } else {
        data['digitalAsset'] = asset;
        data['group'] = iri(variationGroup, 'variationGroup');
        data['weight'] = 0; // TODO improve when drag n drop will be implemented
      }

      const params = {
        resourceType: 'variation',
        data: data,
        success: {
          callback: () => {
            if (null !== toggleModal) {
              toggleModal();
            }
            if (settings.callback) {
                settings.callback();
            } else {
              window.location.href = settings.redirectPath;
            }
          }
        },
        error: { message: t('userGroupLinkError') },
      };

      if (uuid) {
        // Send the update API call.
        update({ ...params, ...{ id: uuid } });
      } else {
        create(params);
      }
    }
  };

  const autocompleteSettings = {
    params: [
      { label: 'tenant', value: tenant['@uuid'] },
      { label: 'digitalAsset', value: selectedAsset ? selectedAsset['@uuid'] : null }
    ],
    disabled: true
  };

  return (
    <>
      {null === variationGroups || (uuid && !entityOriginal) ? (
        <Loader type={'pacman'} />
      ) : (
        <Row>
          <Col>
            {0 !== tenants.length && (
              <Form>
                <FormGroup>
                  <AutocompleteAssets setter={setAsset} settings={{params: autocompleteSettings.params, originUuid: settings.originUuid}}/>
                  <Label
                    className={'pt-3'}
                    for={'variationGroup'}>
                    {t('variationGroup_plural')}
                  </Label>
                  <Select
                    id="group-form-variation-groups"
                    defaultValue={variationGroup['@uuid']}
                    style={{ width: '100%' }}
                    optionLabelProp={'children'}
                    onChange={setVariationGroup}
                    dropdownStyle={{ zIndex: 2500 }}>
                    {variationGroups.map((v) => (
                      <Option
                        key={v.name}
                        value={v['@uuid']}>
                        {v.name}
                      </Option>
                    ))}
                  </Select>
                </FormGroup>

                <Button
                  color="primary"
                  className="mt-4"
                  onClick={sendRequest}>
                  {uuid ? t('update') : t('submit')}
                </Button>
                { (uuid || toggleModal) && (
                  <Button
                    color="link"
                    className="m-2 mt-4 btn-link-danger"
                    onClick={() => {
                      if (null !== toggleModal) {
                        toggleModal();
                      } else {
                        setActiveTab('1');
                      }
                    }}>
                    <span>{t('cancel')}</span>
                  </Button>
                )}
              </Form>
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

export default DigitalAssetVariationForm;
