import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { useThemeStateValues } from 'states';
import { useTranslation } from 'react-i18next';
import CookieConsent from 'react-cookie-consent';

const Footer = () => {
  const { t } = useTranslation();
  const themeOptions = useThemeStateValues();

  return (
    <>
      <div
        className={clsx('app-footer text-black-50', {
          'app-footer--shadow': themeOptions.footerShadow,
          'app-footer--opacity-bg': themeOptions.footerBgTransparent
        })}>
        <div className="app-footer--first">
          <CookieConsent
            containerClasses={'cookie-consent'}
            buttonClasses={'cookie-consent-accept'}
            location="bottom"
            enableDeclineButton={true}
            flipButtons={true}
            buttonText={t('accept')}
            declineButtonText={t('decline')}
            cookieName="cookieConsent"
          >
            {t('usingCookies')}
          </CookieConsent>

          <Nav>
            <NavItem>
              <NavLink tag={Link} to="/contact">
                {t('contact')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to="/conditions">
                {t('conditions')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to="/cgv">
                {t('cgv')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to="/privacy">
                {t('privacyPolicy')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to={{pathname: "https://stats.uptimerobot.com/3qK6LH7myB"}} target="_blank">
                {t('apiStatusPage')}
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <div className="app-footer--second">
          <span>SceneAR.io | AR Assets Manager</span> © 2023 - crafted with{' '}
          <span className="text-danger px-1">❤</span> by{' '}
          <a
            href="https://www.saas-production.com"
            target="_blank"
            rel="noopener noreferrer"
            title="SAAS-Production">
            SAAS-Production
          </a>
        </div>
      </div>
    </>
  );
};

export default Footer;
