import React from 'react';
import clsx from 'clsx';
import { useThemeStateValues } from 'states';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, EditableTextfield } from 'components';
import Truncate from 'react-truncate-html';

const PageTitle = ({
  titleHeading = '',
  titleDescription = '',
  titleField = 'title',
  translate = true,
  editable = false,
  icon = null,
  breadcrumb = { items: [] },
  cruds = null,
  ...props
}) => {
  const { children } = props;
  const themeOptions = useThemeStateValues();
  const { t } = useTranslation();
  if (icon === null) {
    icon = themeOptions.pageTitleDefaultIcon ?? 'umbrella';
  }

  if (true === translate) {
    titleHeading = t(titleHeading);
    titleDescription = t(titleDescription);
  }

  return (
    <div
      className={clsx(
        'app-page-title',
        themeOptions.pageTitleStyle,
        themeOptions.pageTitleBackground,
        { 'app-page-title--shadow': themeOptions.pageTitleShadow }
      )}>
      <div>
        {themeOptions.pageTitleBreadcrumb && (
          <Breadcrumb items={breadcrumb.items} />
        )}

        <div className={'app-page-title--first'}>
          {themeOptions.pageTitleIconBox && (
            <div className={'app-page-title--iconbox d-70'}>
              <div className={'d-70 d-flex gradient-icon align-items-center justify-content-center'}>
                <i className={'pe-7s-' + icon + ' display-6 gradient-icon bg-ripe-malin'} />
              </div>
            </div>
          )}
          <div className={'app-page-title--heading'}>
            <h1>
              {editable && true === cruds?.update?.authorized ? (
                <EditableTextfield
                  fieldName={titleField}
                  defaultValue={titleHeading}
                  callback={cruds.update.action}
                />
              ) : (
                <>
                  {titleHeading}
                </>
              )}
            </h1>
            {themeOptions.pageTitleDescription && titleDescription !== '' && (
              <div className={'app-page-title--description'}>
                <Truncate
                  lines={1}
                  dangerouslySetInnerHTML={{
                    __html: titleDescription
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};

export default PageTitle;
