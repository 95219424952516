import React from 'react';
import { useTranslation } from 'react-i18next';
import { deci } from 'utils';
import { CaoDataBenchmark } from 'components';
import { Badge, ListGroup, ListGroupItem } from 'reactstrap';

const DigitalAssetCaoData = ({ digitalAsset }) => {
  const { t } = useTranslation();

  return (
    <>
      <ListGroup flush>
        <CaoDataBenchmark
          title={t('components')}
          description={t('componentsBaseline')}
          icon={'plugin'}
          keys={[
            'nNodes',
            'maxDepth',
            'nMeshes',
            'nTextures',
            'nMaterials',
            'nCameras',
            'nLights',
            'nAnimations'
          ]}
          digitalAsset={digitalAsset}
        />

        <CaoDataBenchmark
          title={t('skeleton')}
          description={t('skeletonBaseline')}
          icon={'science'}
          keys={['nVertices', 'nFaces', 'nBones']}
          digitalAsset={digitalAsset}
        />

        <ListGroupItem className="d-flex justify-content-between align-items-center">
          <b>{t('boundingBox')}</b>
          <Badge
            pill
            color="neutral-info"
            className={'rounded-pill bg-neutral-info'}>
            {'min: (' +
              deci(digitalAsset.caoData.boundingBox[0]) +
              ', ' +
              deci(digitalAsset.caoData.boundingBox[1]) +
              ', ' +
              deci(digitalAsset.caoData.boundingBox[2]) +
              ')'}
          </Badge>
          <Badge
            pill
            color="neutral-danger"
            className={'rounded-pill bg-neutral-danger'}>
            {'max: (' +
              deci(digitalAsset.caoData.boundingBox[3]) +
              ', ' +
              deci(digitalAsset.caoData.boundingBox[4]) +
              ', ' +
              deci(digitalAsset.caoData.boundingBox[5]) +
              ')'}
          </Badge>
        </ListGroupItem>

        <ListGroupItem className="d-flex justify-content-between align-items-center">
          <b>{t('dimensions')}</b>
          <Badge
            pill
            color="neutral-warning"
            className={'rounded-pill bg-neutral-warning'}>
            {'x: ' +
              deci(
                digitalAsset.caoData.boundingBox[3] -
                  digitalAsset.caoData.boundingBox[0]
              )}
          </Badge>
          <Badge
            pill
            color="neutral-warning"
            className={'rounded-pill bg-neutral-warning'}>
            {'y: ' +
              deci(
                digitalAsset.caoData.boundingBox[4] -
                  digitalAsset.caoData.boundingBox[1]
              )}
          </Badge>
          <Badge
            pill
            color="neutral-warning"
            className={'rounded-pill bg-neutral-warning'}>
            {'z: ' +
              deci(
                digitalAsset.caoData.boundingBox[5] -
                  digitalAsset.caoData.boundingBox[2]
              )}
          </Badge>
        </ListGroupItem>
      </ListGroup>
    </>
  );
};

export default DigitalAssetCaoData;
