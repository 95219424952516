import React from 'react';
import { useTranslation } from 'react-i18next';
import { FeaturesList } from 'components';
import { getColors } from 'utils';
import { HashLink } from 'react-router-hash-link';

const PlanTeaser = ({ plan, priceReccurency = 'month' }) => {
  const { t } = useTranslation();
  const featuresI = plan ? plan.features.included ?? [] : [];
  const title = plan ? plan.label ?? 'title' : 'title';
  const price = plan ? priceReccurency === 'month' ? plan.prices.month : plan.prices.year : 'month';
  const currency = plan ? plan.currency ?? '€' : '€';
  const perMonth = t('per' + priceReccurency);
  const remainingMonths = 6;
  const bgColor = plan ? getColors(plan).background ?? '' : '';

  return (
    <>
      <div className={'card card-box border-primary text-white ' + bgColor}>
        <h5 className={'fw-bold p-3'}>
          {t('activeSubscription')} : {title}
        </h5>
        <div className={'row mx-0'}>
          <div className={'col col-xl-8 d-flex align-items-center justify-content-center col-12'}>
            <div className={'col col-md-6'}>
              <div>
                <span className={'display-5 fw-bold'}>{price.value + currency}</span>
                <span className={'opacity-8 ms-1'}>{perMonth}</span>
              </div>
            </div>

            <div className={'col col-md-6 px-0'}>
              <FeaturesList features={featuresI} type={'included'} checkCase={false}/>
            </div>
          </div>

          <div className={'col d-flex align-items-center justify-content-center col-xl-4 col-12'}>
            <div className={'text-center'}>
              <div className={'mb-0 font-size-lg fw-bold'}>
                <b>{remainingMonths}</b> {t('remainingMonths')}
              </div>

              <button className={'btn btn-warning px-3 py-1 font-size-xs fw-bold text-uppercase'}>
                <small>{t('extend')}</small>
              </button>
            </div>
          </div>
        </div>

        <div className={'divider opacity-5 my-2'} />

        <div className={'text-center mb-2'}>
          {title === 'PREMIUM' ? (
            <button className={'btn btn-sm btn-danger'}>
              <span className={'btn-wrapper--label'}>
                {t('buyNewWorker')}
              </span>
            </button>
          ) : (
            <HashLink to={'/subscription#plans'} size={'sm'}>
              <button className={'btn btn-danger'}>
                <span className={'btn-wrapper--label'}>
                  {t('upgradePlan')}
                </span>
              </button>
            </HashLink>
          )}
        </div>
      </div>
    </>
  );
};

export default PlanTeaser;
