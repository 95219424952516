import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Progress } from 'reactstrap';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { action, getSource } from 'utils';

import appConfig from 'config/appConfig.json';
import statsConfig from 'config/statsConfig.json';

const DashboardStats = ({ tenant, showFooter = true }) => {
  const { t } = useTranslation();

  const [data, setData] = useState(null);

  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const firstDay = new Date(year, 0, 0);
  const lastDay = new Date(year + 1, 0, 0);
  const start = firstDay.toISOString();
  const end = lastDay.toISOString();

  let chart5Data = [];

  const isCancelled = React.useRef(false);
  useEffect(() => {
    const source = getSource();

    action({
      action: 'quota',
      resourceType: 'tenant',
      id: tenant['@uuid'],
      data: {
        start: start,
        end: end,
        interval: statsConfig.interval
      },
      success: {
        callback: (res) => {
          if (isCancelled.current) return;

          handleQuotas({
            current: res.data.current['hydra:member'],
            history: res.data.history['hydra:member']
          });
        }
      },
      aOptions: { cancelToken: source.token }
    });

    return () => {
      isCancelled.current = true;
      source.cancel();
      setData(null);
    };

    // eslint-disable-next-line
  }, []);

  const handleQuotas = (quotas) => {
    if (quotas.current !== null && quotas.history !== null) {
      const current = quotas.current[0];
      const history = quotas.history;
      const userEvolution = [];
      const bandwidthEvolution = [];
      const assetsEvolution = [];
      const storageEvolution = [];
      for (let i = 0; i < month + 1; i++) {
        userEvolution.push(history[i].data.users.usage);
        bandwidthEvolution.push(
          (history[i].data.bandwidth.usage / 1000000000).toFixed(2)
        );
        assetsEvolution.push(history[i].data.assets.usage);
        storageEvolution.push(
          (history[i].data.storage.usage / 1000000000).toFixed(2)
        );
      }

      const newData = [
        {
          id: 'user',
          data: {
            maxValue: current.users.quota,
            currentValue: current.users.usage,
            unit: null,
            evolution: {
              name: 'user_plural',
              data: userEvolution
            }
          }
        },
        {
          id: 'bandwidth',
          data: {
            maxValue:
              current.bandwidth.quota === -1
                ? current.bandwidth.quota
                : (current.bandwidth.quota / 1000000000).toFixed(2),
            currentValue: (current.bandwidth.usage / 1000000000).toFixed(2),
            unit: 'Go',
            evolution: {
              name: 'bandwidth',
              data: bandwidthEvolution
            }
          }
        },
        {
          id: 'digitalAsset',
          data: {
            maxValue: current.assets.quota,
            currentValue: current.assets.usage,
            unit: null,
            evolution: {
              name: 'asset_plural',
              data: assetsEvolution
            }
          }
        },
        {
          id: 'storage',
          data: {
            maxValue:
              current.storage.quota === -1
                ? current.storage.quota
                : (current.storage.quota / 1000000000).toFixed(2),
            currentValue: (current.storage.usage / 1000000000).toFixed(2),
            unit: 'Go',
            evolution: {
              name: 'storage',
              data: storageEvolution
            }
          }
        }
      ];

      newData.map((infos) => chart5Data.push(infos.data.evolution));

      setData(newData);
    }
  };

  const chart5Options = {
    chart: {
      toolbar: {
        show: false
      },
      sparkline: {
        enabled: true
      },
      stacked: true
    },
    dataLabels: {
      enabled: true
    },
    plotOptions: {
      bar: {
        horizontal: false,
        // endingShape: 'rounded',
        columnWidth: '80%'
      }
    },
    colors: [
      appConfig.colors.user.hexa,
      appConfig.colors.bandwidth.hexa,
      appConfig.colors.digitalAsset.hexa,
      appConfig.colors.storage.hexa
    ],
    fill: {
      opacity: 1
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    legend: {
      show: false
    },
    labels: [
      t('january') + ' ' + year,
      t('february') + ' ' + year,
      t('march') + ' ' + year,
      t('april') + ' ' + year,
      t('may') + ' ' + year,
      t('june') + ' ' + year,
      t('july') + ' ' + year,
      t('august') + ' ' + year,
      t('september') + ' ' + year,
      t('october') + ' ' + year,
      t('november') + ' ' + year,
      t('december') + ' ' + year
    ]
  };

  return (
    <>
      <div className={'card mb-5'}>
        <div className={'card-header-alt d-flex justify-content-between p-4'}>
          <div>
            <h6 className={'fw-bold font-size-lg mb-1 text-black'}>
              {t('myAccount')}
            </h6>
            <p className={'text-black-50 mb-0'}>{t('myAccountStatsDesc')}</p>
          </div>
        </div>
        <div className={'mx-4 divider'} />
        <div className={'mx-4 divider'} />
        <div className={'p-4'}>
          <div className={'row'}>
            <div className={'col col-lg-5'}>
              <div className={'p-4 mb-4 rounded bg-secondary'}>
                {data !== null &&
                  data.map((infos) => (
                    <div key={infos.id} className={'mb-4'}>
                      <div className={'line-height-1'}>
                        <span className={'font-size-lg fw-bold pe-3'}>
                          {infos.data.currentValue}
                          {infos.data.unit !== null && (
                            <span className={'font-size-lg fw-bold pe-3'}>
                              &nbsp;{infos.data.unit}
                            </span>
                          )}
                        </span>
                        <span className={'text-black-50 mb-0'}>
                          {infos.id === 'users'
                            ? t('user_plural')
                            : infos.id === 'digitalAsset'
                            ? t('asset_plural')
                            : t(infos.id)}
                        </span>
                      </div>
                      <div
                        className={
                          'd-flex justify-content-center align-items-center'
                        }>
                        <div className={'flex-grow-1'}>
                          <Progress
                            value={
                              infos.data.currentValue > 0
                                ? (infos.data.currentValue /
                                    (infos.data.maxValue > 0
                                      ? infos.data.maxValue
                                      : infos.data.currentValue * 10)) *
                                  100
                                : 0
                            }
                            className={'progress-bar-rounded progress-xs'}
                            color={appConfig.colors[infos.id].name}
                          />
                        </div>
                        <div className={'text-dark fw-bold ps-3'}>
                          {infos.data.maxValue > 0 && (
                            <span className={'text-black-50 mb-0'}>
                              {Math.round(
                                (infos.data.currentValue /
                                  infos.data.maxValue) *
                                  100
                              )}{' '}
                              %
                            </span>
                          )}
                          {infos.data.maxValue === -1 && (
                            <span
                              className={'text-black-50 mb-0'}
                              title={t('unlimited')}>
                              {'∞'}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className={'col col-lg-7'}>
              <Chart
                options={chart5Options}
                series={chart5Data}
                height={280}
                type={'bar'}
              />
            </div>
          </div>
          <div className={'mx-4 divider'} />
          <div className={'mx-4 divider'} />
        </div>

        {true === showFooter && (
          <div className={'text-center mb-4'}>
            <button className={'btn first'}>
              <span className={'btn-wrapper--label'}>{t('add')}</span>
              <span className={'btn-wrapper--icon text-success me-2'}>
                <FontAwesomeIcon icon={['fas', 'plus-circle']} />
              </span>
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default DashboardStats;
