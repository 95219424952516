import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency, ucfirst } from 'utils';

const Invoice = ({ item }) => {
  const { t } = useTranslation();
  const date = Date.parse(item.created);

  const monthFormatter = new Intl.DateTimeFormat('fr', { month: 'long' });
  const month = ucfirst(monthFormatter.format(date));

  const yearFormatter = new Intl.DateTimeFormat('fr', { year: 'numeric' });
  const year = yearFormatter.format(date);

  return (
    <tr>
      <td>
        {null !== item.pdfUrl ? (
          <a
            href={item.pdfUrl}
            rel={'noopener noreferrer'}
            target={'_blank'}
            title={t('invoiceDownload')}
            className={'text-primary'}>
            {item.number}
          </a>
        ) : (
          <>{item.number}</>
        )}
      </td>

      <td>
        {month} {year}
      </td>

      <td>{formatCurrency(item.total, item.currency)}</td>

      <td>
        {item.paid ? (
          <span className={'badge badge-success bg-success'}>{t('Paid')}</span>
        ) : (
          <>
            <span className={'badge badge-danger bg-danger'}>{t('Due')}</span>
            {null !== item.payUrl && (
              <a
                href={item.payUrl}
                rel={'noopener noreferrer'}
                target={'_blank'}
                title={t('Pay the invoice')}
                className={'mt-1 d-block text-primary'}>
                {t('Pay')}
              </a>
            )}
          </>
        )}
      </td>
    </tr>
  );
};

export default Invoice;
