import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DatatableDefault } from 'components';
import Moment from 'react-moment';
import { action, getCruds, getMediaSrc, iri, remove } from 'utils';
import { useUserStateValues } from 'states';
import {
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
  DropdownMenu,
  Nav,
  NavLink,
  NavItem,
  Button
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import defaultAssetImg from 'assets/images/avatars/defaultModelAvatar.png';

const DatatableDigitalAssets = ({
  data = null,
  settings = {},
  pagination = {},
  tenant = null
}) => {
  const { t } = useTranslation();
  const userState = useUserStateValues();
  const [rows, setRows] = useState(data ?? []);

  const defaultSettings = {
    ...{
      removeAction: false,
      parent: null,
      datatableParent: ''
    },
    ...settings
  };

  const columns = [
    {
      name: t('asset_plural'),
      selector: 'name',
      cell: (row) => (
        <Link to={iri(row, 'digitalAsset')}>
          <div className="d-flex align-items-center">
            <div className="avatar-icon-wrapper avatar-icon-sm me-2">
              <div className="avatar-icon">
                <img
                  alt="row-media"
                  src={getMediaSrc(row, 'preview') ?? defaultAssetImg}
                />
              </div>
            </div>
            <div>{row.name}</div>
          </div>
        </Link>
      ),
      sortable: true
    },
    {
      name: t('version'),
      selector: 'version',
      sortable: true,
      hide: 'md',
      center: true
    },
    {
      name: t('createdDate'),
      selector: 'created',
      sortable: true,
      hide: 'sm',
      center: true,
      format: (row) => (
        <Moment calendar={true} locale={'fr'}>
          {row.created}
        </Moment>
      )
    },
    {
      name: t('lastUpdate'),
      selector: 'updated',
      sortable: true,
      center: true,
      format: (row) => (
        <Moment calendar={true} locale={'fr'}>
          {row.updated}
        </Moment>
      )
    },
    {
      name: t('actions'),
      selector: 'actions',
      center: true,
      cell: (row) => (
        <UncontrolledDropdown className="mx-3">
          <DropdownToggle
            color="link"
            className="p-0 border-0 text-blue-50 no-caret">
            <FontAwesomeIcon
              icon={['fas', 'ellipsis-h']}
              className="font-size-lg"
            />
          </DropdownToggle>
          <DropdownMenu right className="text-nowrap p-0">
            <Nav className="flex-column">
              <NavItem>
                <NavLink
                  onClick={() => {
                    toggleRemoveAssetModal();
                    setAssetToRemove(row);
                  }}>
                  <div className="nav-link-icon">
                    <FontAwesomeIcon icon={['fas', 'trash']} />
                  </div>
                  {defaultSettings.parent && (
                    <span>
                      {t('unLinkItemFromContent', {
                        item: row.name,
                        content: defaultSettings.parent.name
                      })}
                    </span>
                  )}
                </NavLink>
              </NavItem>
            </Nav>
          </DropdownMenu>
        </UncontrolledDropdown>
      )
    }
  ];

  if (false === defaultSettings.removeAction) {
    columns.splice(-1, 1);
  }
  const [assetToRemove, setAssetToRemove] = useState(null);
  const [modalRemoveAsset, setModalRemoveAsset] = useState(false);
  const toggleRemoveAssetModal = () => setModalRemoveAsset(!modalRemoveAsset);

  const handleRemove = () => {
    switch (defaultSettings.datatableParent) {
      case 'variationGroup':
        removeAssetFromGroup();
        break;

      case 'collection':
        removeAssetFromCollection();
        break;

      case 'category':
        removeAssetFromCategory();
        break;

      default:
        break;
    }

    toggleRemoveAssetModal();
  };

  const removeAssetFromCollection = () => {
    const uuidAsset = assetToRemove['@uuid'];
    const iriCollection = iri(
      defaultSettings.parent,
      'digitalAssetsCollection'
    );
    action({
      action: 'collectionRemove',
      resourceType: 'digitalAsset',
      id: uuidAsset,
      data: { collection: iriCollection }
      // success: {
      //   callback: () => window.location.reload()
      // }
    }).then();
  };

  const removeAssetFromCategory = () => {
    const uuidAsset = assetToRemove['@uuid'];
    const iriCollection = iri(defaultSettings.parent, 'digitalAssetsCategory');
    action({
      action: 'categoryRemove',
      resourceType: 'digitalAsset',
      id: uuidAsset,
      data: { category: iriCollection }
      // success: {
      //   callback: () => window.location.reload()
      // }
    }).then();
  };

  const removeAssetFromGroup = () => {
    if (assetToRemove._variationId) {
      remove({
        resourceType: 'variation',
        id: assetToRemove._variationId,
        error: {
          message: t('crudDeleteErrored', {
            itemName: assetToRemove.name ?? t('item')
          })
        },
        success: {
          message: t('crudDeleteSucceeded', {
            itemName: assetToRemove.name ?? t('item')
          }),
          callback: () => window.location.reload()
        }
      });

      setRows(rows.filter((r) => r['@uuid'] !== assetToRemove['@uuid']));
    }
  };

  const [cruds, setCruds] = useState(null);
  useEffect(() => {
    const newCruds = getCruds({
      resourceType: 'digitalAsset',
      userState,
      translator: t
    });

    newCruds.read.methods.list.params = [
      { label: 'has[variations]', value: false }
    ];

    setCruds(newCruds);

    return () => {
      setCruds(null);
    };

    // eslint-disable-next-line
  }, []);

  return (
    rows &&
    cruds && (
      <>
        <DatatableDefault
          columns={columns}
          data={rows}
          tenant={tenant}
          settings={{
            ...{
              tableId: 'digital-assets-table',
              title: t('asset_plural'),
              selectedMessage: {
                singular: t('asset'),
                plural: t('asset_plural')
              }
            },
            ...settings
          }}
          pagination={{
            ...{
              orderBy: 'created',
              sortDirection: 'DESC'
            },
            ...pagination
          }}
          cruds={cruds}
        />

        <Modal
          zIndex={2000}
          centered
          isOpen={modalRemoveAsset}
          toggle={toggleRemoveAssetModal}>
          <div className="text-center p-5">
            <div className="avatar-icon-wrapper rounded-circle m-0">
              <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
                <FontAwesomeIcon
                  icon={['fas', 'trash']}
                  className="d-flex align-self-center display-6"
                />
              </div>
            </div>
            <h4 className="fw-bold mt-4">{t('deleteConfirmMessage')}</h4>
            <p className="mb-0 font-size-lg text-muted">{t('deleteMessage')}</p>
            <div className="pt-4">
              <Button
                onClick={toggleRemoveAssetModal}
                color="neutral-secondary"
                className="btn-pill mx-1">
                <span className="btn-wrapper--label">{t('cancel')}</span>
              </Button>
              <Button
                onClick={handleRemove}
                color="danger"
                className="btn-pill mx-1">
                <span className="btn-wrapper--label">{t('delete')}</span>
              </Button>
            </div>
          </div>
        </Modal>
      </>
    )
  );
};

export default DatatableDigitalAssets;
