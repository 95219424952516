import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Card,
  Progress
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getMediaSrc } from 'utils/MediaObject';
import clsx from 'clsx';

// DashboardAnalyticsSection2
const QuotaType1 = ({ entity = null, optionalClass = '', icon = null, color = 'dark' }) => {
  const { t } = useTranslation();
  const type = entity ? entity.type ?? 'Mo' : 'Mo';
  const maxValue = entity ? entity.maxValue ?? 50 : 50;
  const currentValue = entity ? entity.currentValue ?? 30 : 30;
  let percentage = Math.round((currentValue / maxValue) * 100);
  const suffix = 'storage' !== type ? ('bandwidth' !== type ? '' : ' Ko/s') : ' Mo';
  let progressColor = percentage > 66 ? 'danger' : percentage > 33 ? 'warning' : 'success';
  if (-1 === maxValue) {
    progressColor = 'success';
    percentage = 100;
  }

  return (
    <>
      <Card className={clsx('card-box p-4 mb-5 ' + optionalClass )}>
        <div className="d-flex align-items-start">
          {null !== entity ? (
            null !== getMediaSrc(entity, 'avatar') ? (
              <img
                alt="preview"
                className="img-fluid"
                src={getMediaSrc(entity, 'avatar')}
              />
            ) : (
              <FontAwesomeIcon
                icon={['fas', icon ?? 'object-group']}
                className={'display-4 text-' + color}
              />
            )
          ) : (
            <FontAwesomeIcon
              icon={['fas', icon ?? 'object-group']}
              className={'display-4 text-' + color}
            />
          )}
          <div className="flex-grow-1 ps-4">
            <div className="d-block mb-2 mt-1">
              <b>{currentValue}{suffix}</b>
              {-1 !== maxValue ? (
              <> {t('usedOutOf')} <b>{maxValue}</b></>
              ) : (
                <>{' '}{type === 'digitalAsset' ? t('digitalAsset') : t('used_plural')} </>
              )}
            </div>
            <Progress
              className="progress-animated-alt"
              color={progressColor}
              value={percentage}>
              {-1 !== maxValue ? percentage + '%' : t('unlimited')}
            </Progress>
          </div>
        </div>
        <small className="d-block mt-3">
          {-1 !== maxValue && (
            <span className="d-block pb-3">
              {'user' === type ? (
                <div>
                  {t('moreOfAlt')} <b>{t(type)}</b> ? {t('upgradePlan')}
                </div>
              ) : (
                <div>
                  {t('moreOf')} <b>{t(type)}</b> ? {t('upgradePlan')}
                </div>
              )}
              <Link
                to={'/subscription'}
              >{t('here')}
              </Link>
              .
            </span>
          )}
        </small>
      </Card>
    </>
  );
}

export default QuotaType1;