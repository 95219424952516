import React from 'react';
import { AutocompleteBase } from 'components';

const AutocompleteCategories = ({ setter, settings = null, selected = null }) => {
  settings = settings ?? {};
  settings['@resourceType'] = 'digitalAssetsCategory';
  settings.displayLabel = false;
  settings.fullObjects = true;
  settings.multiple = true;
  settings.allowNew = false;

  return (
    <AutocompleteBase setter={setter} settings={settings} selected={selected} />
  );
};

export default AutocompleteCategories;
