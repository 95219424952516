import React from 'react';
import { Badge } from 'reactstrap';
import { getColors, getMediaSrc, iri } from 'utils';
import { DatatableGroups } from 'components';

const UserTenants = ({ user }) => {
  const tenants = [];
  (user.memberships.tenant ?? []).forEach((tenant) =>
    tenants.push(tenant.tenant)
  );

  return (
    <>
      {tenants.map((tenant) => (
        <div className={'row'} key={tenant.name}>
          <div className={'col'}>
            <h4>{tenant.name}</h4>
            <hr />
          </div>
          <div className={'row mb-4'}>
            <div className={'col col-md-3'}>
              <a
                href={iri(tenant, 'tenant')}
                className={'card-img-wrapper rounded'}>
                <div className={'card-badges card-badges-bottom'}>
                  <Badge
                    pill
                    color={getColors(tenant.plan).badge}
                    className={
                      'rounded-pill bg-' + getColors(tenant.plan).badge
                    }>
                    {tenant.plan.label}
                  </Badge>
                </div>
                <img
                  alt={'logo-tenant'}
                  className={'card-img-top rounded'}
                  src={getMediaSrc(tenant, 'avatar')}
                />
              </a>
            </div>
            <div className={'col col-md-9'}>
              {<DatatableGroups user={user} tenant={tenant} strict />}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default UserTenants;
