import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader} from 'components';
import { nError, read, iri, list, update, create } from 'utils';
import { useUserStateValues } from 'states';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DigitalAssetVariationGroupTemplateForm = ({ uuid = null, settings = null, setActiveTab = null, selectedVariationGroup = null, digitalAsset = null, toggleModal = null }) => {
  const resourceType = 'variationGroupsModel';
  const { t } = useTranslation();

  const [entity, setEntity] = useState(null);
  const [entityOriginal, setEntityOriginal] = useState(null);

  const userStateValues = useUserStateValues();
  const [tenants] = useState((userStateValues.isAuthenticated && userStateValues.user) ? userStateValues.user.tenants : []);
  const [tenant] = useState((userStateValues.isAuthenticated && userStateValues.user) ? userStateValues.currentTenant : null);
  const [variationGroup, setVariationGroup] = useState(
    entityOriginal ? entityOriginal.group : selectedVariationGroup
  );
  const [variationGroups, setVariationGroups] = useState(null);

  useEffect(() => {
    if (null !== uuid) {
      read({
        resourceType: resourceType,
        id: uuid,
        success: {
          callback: (r) => {
            setEntityOriginal(r.data);
          }
        }
      });
    }
    // eslint-disable-next-line
  }, [uuid]);

  useEffect(() => {
    list({
      resourceType: 'variationGroup',
      params: [{ label: 'tenant', value: iri(tenant, 'tenant') }],
      setter: setVariationGroups,
      success: {
        callback: (r) => {
          if (null === variationGroup && r.data['hydra:member'].length > 0) {
            setVariationGroup(r.data['hydra:member'][0]);
          }
        }
      }
    });
    // eslint-disable-next-line
  }, []);

  const sendRequest = () => {
    // Ensure mandatory fields if CREATE.

    // TODO link this to API correct endpoint.

    if (null === variationGroup) {
      nError({ message: t('missingFields') });
    } else {
      let data = {};
      if (null !== uuid) {
        ['tenant', 'name'].map((key) => {
          const keyUuid = entityOriginal[key];
          // Only patch changed values.
          if (!entityOriginal || keyUuid !== (entity? entity[key] ?? keyUuid : keyUuid)) {
            if (key !== 'name') {
              data[key] = iri(entity[key], key);
            } else {
              data[key] = entity[key];
            }

          }
          return 1;
        });
      } else {
        data['tenant'] = iri(tenant, 'tenant');
        data['name'] = entity.name;
        data['description'] = '';
        data['default'] = useByDefault;
        data['additionalProp1'] = [];
      }

      const params = {
        resourceType: 'variationGroupsModel',
        data: data,
        success: {
          callback: () => {
            if (null !== toggleModal) {
              toggleModal();
            }
            if (settings.callback) {
                settings.callback();
            } else {
              window.location.href = settings.redirectPath;
            }
          }
        },
        error: { message: t('variationGroupAddError') },
      };

      if (uuid) {
        // Send the update API call.
        update({ ...params, ...{ id: uuid } });
      } else {
        create(params);
      }
    }
  };

  const updateData = (attribute, value) => {
    let edited = {};
    edited[attribute] = value;
    setEntity({ ...entity, ...edited });
  };
  const [useByDefault, setUseByDefault] = useState(false);

  return (
    <>
      {null === variationGroups || (uuid && !entityOriginal) ? (
        <Loader type={'pacman'} />
      ) : (
        <div className={'row'}>
          <div className={'col'}>
            {0 !== tenants.length && (
              <>
                <div className={'text-center p-5'}>
                  <div className={'avatar-icon-wrapper rounded-circle m-0'}>
                    <div className={'d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-info text-info m-0 d-130'}>
                      <FontAwesomeIcon
                        icon={
                          uuid !== null ? ['fas', 'edit'] : ['fas', 'folder']
                        }
                        className={'d-flex align-self-center display-6'}
                      />
                    </div>
                  </div>
                  <h4 className={'fw-bold mt-4'}>
                    {uuid !== null ? t('editVariationGroupModel') : t('addVariationGroupModel')}
                  </h4>
                </div>

                <form>
                  <div>
                    <label htmlFor={'name'} className={'form-label mt-3'}>
                      {t('name')} <sup className={'text-danger'}>*</sup>
                    </label>
                    <input
                      className={'form-control'}
                      type={'text'}
                      name={'name'}
                      id={'name'}
                      defaultValue={entityOriginal ? entityOriginal.name ?? '' : ''}
                      placeholder={t('name')}
                      onChange={(e) => updateData('name', e.target.value)}
                    />
                  </div>

                  {null === uuid && (
                    <div className={'form-check form-check-md mt-3'}>
                      <input
                        className={'form-check-input'}
                        type={'checkbox'}
                        id={'uploadForm-useByDefault'}
                        name={'uploadForm-useByDefault'}
                        defaultChecked={useByDefault}
                        onChange={(e) => { setUseByDefault(e.target.checked) }}
                      />
                      <label className={'form-check-label'} htmlFor={'uploadForm-useByDefault'}>
                        {t('useByDefault')}
                      </label>
                    </div>
                  )}

                  <button
                    className={'btn btn-primary mt-4'}
                    type={'submit'}
                    onClick={sendRequest}>
                    {uuid ? t('update') : t('submit')}
                  </button>
                  { (uuid || toggleModal) && (
                    <button
                      className={'btn btn-link mt-4 btn-link-danger'}
                      type={'button'}
                      onClick={() => {
                        if (null !== toggleModal) {
                          toggleModal();
                        } else {
                          setActiveTab('1');
                        }
                      }}>
                      <span>{t('cancel')}</span>
                    </button>
                  )}
                </form>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default DigitalAssetVariationGroupTemplateForm;
