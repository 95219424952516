import React, { useEffect } from 'react';
import 'moment/locale/fr';
import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';
import appConfig from './config/appConfig.json';
import { ToastContainer, toast } from 'react-toastify';
import './assets/base.scss';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  fab,
  faDiscord,
  faDribbble,
  faFacebook,
  faGithub,
  faGoogle,
  faHtml5,
  faInstagram,
  faPinterest,
  faReact,
  faSlack,
  faTwitter,
  faVuejs,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';
import {
  faAddressCard,
  faBell,
  faBuilding,
  faCalendarAlt,
  faChartBar,
  faCheckCircle,
  faClock,
  faCommentDots,
  faComments,
  faDotCircle,
  faEnvelope,
  faEye,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileCode,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFileVideo,
  faFileWord,
  faFolder,
  faFolderOpen,
  faGem,
  faImages,
  faKeyboard,
  faLifeRing,
  faLightbulb,
  faMap,
  faObjectGroup,
  faQuestionCircle,
  far,
  faSquare,
  faThumbsUp,
  faTimesCircle,
  faTrashAlt,
  faUser,
  faUserCircle
} from '@fortawesome/free-regular-svg-icons';
import {
  faAlignCenter,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowsAltH,
  faArrowUp,
  faAward,
  faBars,
  faBatteryEmpty,
  faBatteryFull,
  faBirthdayCake,
  faBomb,
  faBusAlt,
  faCameraRetro,
  faCarBattery,
  faCaretRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCog,
  faCubes,
  faDownload,
  faEllipsisH,
  faEllipsisV,
  faExternalLinkAlt,
  faEyeDropper,
  faFilm,
  faHeart,
  faInfoCircle,
  faLemon,
  faLink,
  faMapMarkedAlt,
  faNetworkWired,
  faPager,
  faPlayCircle,
  faPlus,
  faPlusCircle,
  faPrint,
  faQuoteRight,
  faReply,
  fas,
  faSave,
  faSearch,
  faShapes,
  faShareSquare,
  faSignOutAlt,
  faSitemap,
  faSlidersH,
  faSmile,
  faStar,
  faStarHalfAlt,
  faSync,
  faTable,
  faTachometerAlt,
  faTimes,
  faUnlockAlt,
  faUpload
} from '@fortawesome/free-solid-svg-icons';
import {
  populateUserFromProvider,
  useUserState,
  useUserStateValues
} from 'states';

import { Modal } from 'bootstrap';
library.add(Modal);

library.add(
  faAddressCard,
  faAlignCenter,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowsAltH,
  faArrowUp,
  faAward,
  fab,
  faBars,
  faBatteryEmpty,
  faBatteryFull,
  faBell,
  faBirthdayCake,
  faBomb,
  faBuilding,
  faBusAlt,
  faCalendarAlt,
  faCameraRetro,
  faCarBattery,
  faCaretRight,
  faChartBar,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClock,
  faCog,
  faCommentDots,
  faComments,
  faCubes,
  faDiscord,
  faDotCircle,
  faDownload,
  faDribbble,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faExternalLinkAlt,
  faEye,
  faEyeDropper,
  faFacebook,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileCode,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFileVideo,
  faFileWord,
  faFilm,
  faFolder,
  faFolderOpen,
  faGem,
  faGithub,
  faGoogle,
  faHeart,
  faHtml5,
  faImages,
  faInfoCircle,
  faInstagram,
  faKeyboard,
  faLemon,
  faLifeRing,
  faLightbulb,
  faLink,
  faMap,
  faMapMarkedAlt,
  faNetworkWired,
  faObjectGroup,
  faPager,
  faPinterest,
  faPlayCircle,
  faPlus,
  faPlusCircle,
  faPrint,
  faQuestionCircle,
  faQuoteRight,
  far,
  faReact,
  faReply,
  fas,
  faSave,
  faSearch,
  faShapes,
  faShareSquare,
  faSignOutAlt,
  faSitemap,
  faSlack,
  faSlidersH,
  faSmile,
  faSquare,
  faStar,
  faStarHalfAlt,
  faSync,
  faTable,
  faTachometerAlt,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faTwitter,
  faUnlockAlt,
  faUpload,
  faUser,
  faUserCircle,
  faVuejs,
  faYoutube
);

const App = () => {
  const userState = useUserState();
  const user = useUserStateValues();

  // Get user from session.
  useEffect(() => {
    if (!user.isAuthenticated) {
      const storedUser = localStorage.getItem(appConfig.keys.session.user);
      if (storedUser) {
        const jsonUser = JSON.parse(storedUser);
        if (
          jsonUser &&
          jsonUser.user &&
          jsonUser.tokens &&
          jsonUser.currentTenant
        ) {
          populateUserFromProvider({
            uState: userState,
            user: jsonUser.user,
            currentTenant: jsonUser.currentTenant,
            tokens: jsonUser.tokens
          });
        }
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <BrowserRouter basename={'/'}>
      <Routes />

      <ToastContainer
        enableMultiContainer
        containerId={'TC'}
        position={toast.POSITION.TOP_CENTER}
      />
      <ToastContainer
        enableMultiContainer
        containerId={'BC'}
        position={toast.POSITION.BOTTOM_CENTER}
      />
    </BrowserRouter>
  );
};

export default App;
