import React from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Modal } from 'components';
import { getSource } from 'utils';

const PageTitleActions = ({ cruds, actions = null }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const isCancelled = React.useRef(false);

  const handleDelete = () => {
    const source = getSource();

    cruds.delete.action({
      success: {
        callback: () => {
          if (isCancelled.current) return;

          if (null !== cruds.delete.redirect) {
            history.push(cruds.delete.redirect);
          }
        }
      },
      aOptions: { cancelToken: source.token }
    });
    if (actions.length > 2) {
      actions[2].filter((t) => t.type === 'delete')[0].action();
    }

    return () => {
      isCancelled.current = true;
      source.cancel();
    };
  };

  const handleEdit = () => {
    if (null !== cruds.update.link) {
      history.push(cruds.update.link);
    } else {
      if (null !== cruds.update.tabAction) {
        cruds.update.tabAction();
      } else {
        if (null !== cruds.update.action) {
          cruds.update.action();
        }
      }
    }
  };

  const handleAction = (action = null) => {
    switch (action.type) {
      case 'delete':
        break;
      case 'edit':
        handleEdit();
        break;
      default:
        action.action();
        break;
    }
  };

  return (
    <>
      <ul className={'nav'}>
        {true === cruds?.update?.authorized && (
          <div className={'d-flex align-items-center'}>
            {true === cruds?.create?.authorized && null !== cruds.create.link && (
              <a
                className={'btn btn-success mr-3'}
                href={cruds.create.link ?? '#'}
                data-bs-toggle={'tooltip'}
                data-bs-placement={'top'}
                title={t('addNewEntry')}>
                <span className={'btn-wrapper--icon'}>
                  <FontAwesomeIcon
                    icon={['fas', 'plus']}
                    className={'opacity-8 font-size-xs'}
                  />
                </span>
              </a>
            )}

            {actions &&
              actions.length > 0 &&
              actions.filter((a) => true === a.display).length > 0 && (
                <li className={'nav-item dropdown mx-3'}>
                  <button
                    className={
                      'dropdown-toggle btn btn-outline-first btn-sm btn-block'
                    }
                    data-bs-toggle={'dropdown'}
                    aria-expanded={'false'}>
                    {t('actions')}
                  </button>
                  <ul className={'dropdown-menu'}>
                    {actions
                      .filter((sa) => true === (sa.display ?? true))
                      .map((action, index) => (
                        <li key={'part' + index}>
                          {0 !== index && <hr className={'dropdown-divider'} />}
                          <a
                            style={{cursor: 'pointer'}}
                            className={'dropdown-item nav-link'}
                            data-bs-toggle={action.type === 'delete' ? 'modal' : null}
                            data-bs-target={'#entity-delete-mod'}
                            onClick={() => {
                              if (action.link) history.push(action.link);
                              else handleAction(action);
                            }}>
                            <div className={'nav-link-icon'}>
                              <FontAwesomeIcon
                                icon={action.iconLink}
                                color={action.iconColor}
                              />
                            </div>
                            <span className={action.textColor}>
                              {t(action.label)}
                            </span>
                          </a>
                        </li>
                      ))}
                  </ul>
                </li>
              )}
          </div>
        )}
      </ul>

      <Modal
        options={{
          id: 'entity-delete-mod',
          title: t('deleteConfirmMessage'),
          icon: {
            name: 'trash',
            color: 'danger'
          },
          actions: {
            submit: {
              action: handleDelete,
              color: 'danger',
              title: t('delete')
            },
            cancel: {
              color: 'dark'
            }
          },
        }}>
        <p className={'mb-0 font-size-lg text-muted'}>{t('deleteMessage')}</p>
      </Modal>
    </>
  );
};

export default PageTitleActions;
