import { State, createState, useState } from '@hookstate/core';
import { action, getMediaSrc, iri, setMediaObject } from 'utils';
import apiConfig from 'config/apiConfig.json';
import appConfig from 'config/appConfig.json';

interface UserState {
  user?: any;
  currentTenant?: any;
  isAuthenticated: boolean;
  tokens?: {
    accessToken: string;
    refreshToken: string;
    expire: string;
  };
}

const emptyUser: UserState = {
  user: null,
  currentTenant: null,
  tokens: {
    accessToken: '',
    refreshToken: '',
    expire: ''
  },
  isAuthenticated: false
};
const state: State<UserState> = createState(emptyUser);

export const useUserState = () => useState(state);
export const useUserStateValues = () => useUserState().value;

export const login = ({ errorMessage }) => {
  action({
    resourceType: 'authorization',
    action: 'login',
    data: {
      redirectionUrl:
        window.location.protocol +
        '//' +
        window.location.host +
        apiConfig.callbacks.login
    },
    success: {
      callback: (res) => {
        if (res.data && res.data.authorizationUrl) {
          window.location = res.data.authorizationUrl;
        }
      }
    },
    error: { message: errorMessage, forceNotif: true }
  });
};

export const loginCallback = ({ code, state, uState, errorMessage }) => {
  action({
    resourceType: 'authorization',
    action: 'loginCallback',
    data: { code: code, state: state },
    success: {
      callback: (res) => {
        if (res.data && res.data.user && res.data.accessToken) {
          populateUserFromProvider({
            uState: uState,
            user: res.data.user,
            tokens: res.data.accessToken,
            redirectUrl: iri(res.data.user.tenants[0], 'tenant')
          });
        }
      }
    },
    error: { message: errorMessage, forceNotif: true }
  });
};

export const logout = () => {
  action({
    resourceType: 'authorization',
    action: 'logout',
    data: {
      redirectionUrl:
        window.location.protocol +
        '//' +
        window.location.host +
        apiConfig.callbacks.logout
    },
    success: {
      callback: (res) => {
        if (res.data && res.data.logoutUrl) {
          window.location = res.data.logoutUrl;
        }
      }
    }
  });
};

export const inviteCallback = ({ id, secret, uState, errorMessage }) => {
  action({
    resourceType: 'invitation',
    id: id,
    action: 'accept',
    data: { secret: secret },
    success: {
      callback: (res) => {
        if (res.data && res.data.user && res.data.accessToken) {
          populateUserFromProvider({
            uState: uState,
            user: res.data.user,
            tokens: res.data.accessToken,
            redirectUrl: iri(res.data.tenant, 'tenant')
          });
        } else {
          window.location.href = '/';
        }
      }
    },
    error: { message: errorMessage, forceNotif: true }
  });
};

export const populateUserFromProvider = ({
  uState,
  user,
  currentTenant = null,
  tokens,
  reset = false,
  redirectUrl = null
}) => {
  // Map authenticated state.
  uState.isAuthenticated.set(!reset);

  if (!reset) {
    // Map user state.
    uState.user.merge(user);
    setCurrentTenant({
      uState: uState,
      tenant: currentTenant ?? user.tenants[0] ?? []
    });

    const jwt = require('jsonwebtoken');
    const payload = jwt.decode(tokens.accessToken);
    if (payload && payload.exp) {
      // Map tokens state.
      uState.tokens.accessToken.set(tokens.accessToken);
      uState.tokens.refreshToken.set(tokens.refreshToken);
      uState.tokens.expire.set(payload.exp);
    }

    // Store data to session.
    localStorage.setItem(
      appConfig.keys.session.user,
      JSON.stringify(uState.value)
    );
  } else {
    // Clean session.
    localStorage.removeItem(appConfig.keys.session.user);
  }

  if (null !== redirectUrl) window.location = redirectUrl;
};

export const removeTenantFromUser = ({ uState, user, uuid }) => {
  //TODO API call to remove tenant from user

  const newUser = user.user;
  newUser.tenants.filter((t) => t['@uuid'] === uuid)[0] = null;
  uState.user.merge(newUser);

  if (0 === user.user.tenants.length) {
    logout();
  }

  // Store data to session.
  // localStorage.setItem(appConfig.keys.session.user, JSON.stringify(uState.value));
};

export const updateCurrentTenantPlan = ({ uState, user, plan }) => {
  const newUser = user.user;
  newUser.currentTenant.plan = plan;
  uState.user.merge(newUser);

  // Store data to session.
  localStorage.setItem(
    appConfig.keys.session.user,
    JSON.stringify(uState.value)
  );
};

export const logoutUser = ({ uState }) => {
  // Reset user to null.
  populateUserFromProvider({
    uState: uState,
    user: emptyUser.user,
    tokens: emptyUser.tokens,
    reset: true
  });
};

export const setCurrentTenant = ({ uState, tenant }) => {
  if (null === uState.value.currentTenant) {
    uState.currentTenant.set(tenant);
  } else {
    uState.merge({ currentTenant: tenant });
  }

  // Store data to session.
  localStorage.setItem(
    appConfig.keys.session.user,
    JSON.stringify(uState.value)
  );
};

export const getTenant = ({ tenants, uuid }) => {
  let t = null;
  tenants.map((tenant) => {
    if (tenant['@uuid'] === uuid) {
      t = tenant;
    }
    return t;
  });
  return t;
};

export const setPreview = ({ uState, preview }) => {
  if (null !== getMediaSrc(uState.value.user, 'avatar')) {
    const newMedias = setMediaObject(uState.value.user, 'avatar', preview);
    const newUser = JSON.parse(JSON.stringify(uState.value.user));
    newUser.medias = newMedias;
    uState.user.set(newUser);
  }
  // Store data to session.
  localStorage.setItem(
    appConfig.keys.session.user,
    JSON.stringify(uState.value)
  );
};
