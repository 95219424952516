import {
  create,
  read,
  list,
  update,
  remove,
  can,
  guessFromResourceType
} from 'utils';
import apiConfig from 'config/apiConfig.json';

const _ = require('lodash');

export const clone = (any) => {
  return _.cloneDeep(any);
};

/**
 * Transform first letter to uppercase.
 *
 * @param string
 *
 * @return string
 */
export const ucfirst = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Transform country code into country name.
 *
 * @param code
 *
 * @return string
 */
export const country = (code: string): string => {
  switch (code) {
    case 'FR':
      return 'France';

    default:
      return code;
  }
};

/**
 * Format a number and currency code.
 *
 * @param amount
 * @param currency
 *
 * @return string
 */
export const formatCurrency = (amount: number, currency: string): string => {
  const numberFormat = Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: currency
  });

  amount = amount / 100;
  return numberFormat.format(amount);
};

// Format to readable decimal.
export const deci = (x) => {
  return Number.parseFloat(x).toFixed(2);
};

// Format to IRI.
export const iri = (object, type) => {
  const apiType = apiConfig.resources[type].uri;

  if (null !== object && undefined !== object['@id']) return object['@id'];

  // Let's handle particular cases in a Switch here if needed...
  return null !== object
    ? '/' +
        apiType +
        '/' +
        ('object' === typeof object
          ? object['@uuid'] ?? object.uuid ?? object.id ?? ''
          : '')
    : '/';
};

// Format to alt.
export const alt = (object, type, altText = 'alt') => {
  const apiType = apiConfig.resources[type].uri;

  // Let's handle particular cases in a Switch here if needed...
  return (
    (null !== object
      ? apiType +
        '-' +
        (object ? object['@uuid'] ?? object.uuid ?? object.id ?? object : '')
      : '') +
    '-' +
    altText
  );
};

// Build CRUD object.
export const getCruds = ({
  resourceType,
  userState,  // wrong named property : this is userState.value expected here !
  translator,
  object = null,
  user = null,
  tenant = null,
  objectTitleKey = 'name',
  allowRead = false
}) => {
  const t =
    tenant ??
    (userState.currentTenant && undefined !== userState.currentTenant['@uuid']
      ? userState.currentTenant
      : null);

  const userIri = iri(
    null !== user && user['@uuid']
      ? user
      : userState.user && undefined !== userState.user['@uuid']
      ? userState.user
      : null,
    'user'
  );
  const tenantIri = iri(t, 'tenant');
  const resourceIri =
    null !== object
      ? iri(object, resourceType)
      : guessFromResourceType(resourceType, 'iriStart');
  const isAuth = true === userState.isAuthenticated;
  const defaultCrud = isAuth
    ? can({
        actionType: 'create',
        resourceIri: '/' + resourceIri.split('/')[1],
        userIri: userIri,
        tenantIri: tenantIri
      })
    : false;

  // Start with defaults.
  let cruds = {
    resourceType: resourceType,
    create: {
      authorized: defaultCrud,
      link: null,
      action: create
    },
    read: {
      authorized:
        true === allowRead
          ? true
          : null !== object
          ? can({
              actionType: 'read',
              resourceIri: resourceIri,
              userIri: userIri,
              tenantIri: tenantIri
            })
          : defaultCrud,
      methods: {
        read: {
          action: read,
          params: []
        },
        list: {
          action: list,
          params: []
        }
      }
    },
    update: {
      authorized: isAuth
        ? null !== object
          ? can({
              actionType: 'edit',
              resourceIri: resourceIri,
              userIri: userIri,
              tenantIri: tenantIri
            })
          : defaultCrud
        : false,
      action: ({ data, error, success, always }) => {
        update({
          resourceType: resourceType,
          id: object['@uuid'],
          data: data,
          error: {
            ...error,
            ...{
              message: translator('crudUpdateErrored', {
                itemName: object[objectTitleKey]
              })
            }
          },
          success: {
            ...success,
            ...{
              message: translator('crudUpdateSucceeded', {
                itemName: object[objectTitleKey]
              })
            }
          },
          always: always
        });
      },
      tabAction: null
    },
    delete: {
      authorized: isAuth
        ? null !== object
          ? can({
              actionType: 'delete',
              resourceIri: resourceIri,
              userIri: userIri,
              tenantIri: tenantIri
            })
          : defaultCrud
        : false,
      link: null,
      action: ({ error, success, always }) => {
        remove({
          resourceType: resourceType,
          id: object['@uuid'],
          error: {
            ...error,
            ...{
              message: translator('crudDeleteErrored', {
                itemName: object[objectTitleKey]
              })
            }
          },
          success: {
            ...success,
            ...{
              message: translator('crudDeleteSucceeded', {
                itemName: object[objectTitleKey]
              })
            }
          },
          always: always
        });
      },
      redirect: null
    }
  };

  // Let's override by type.
  switch (resourceType) {
    case 'digitalAsset':
      cruds.create.link = '/digital-assets/upload';
      break;

    case 'digitalAssetsCollection':
      break;

    case 'digitalAssetsCategory':
      break;

    case 'membership':
      cruds.create.link = '/memberships/add';
      cruds.read.methods.list.params = [
        ...cruds.read.methods.list.params,
        ...[
          { label: 'user', value: userIri },
          { label: 'group.tenant', value: tenantIri }
        ]
      ];
      break;

    case 'group':
      cruds.create.link = '/groups/add';
      cruds.read.methods.list.params = [
        ...cruds.read.methods.list.params,
        ...[{ label: 'tenant', value: tenantIri }]
      ];
      break;

    case 'media':
      break;

    case 'role':
      break;

    case 'tenant':
      break;

    case 'upload':
      cruds.create.link = '/digital-assets/upload';
      break;

    case 'user':
      cruds.read.methods.list.params = [
        ...cruds.read.methods.list.params,
        ...[
          { label: 'memberships.tenant', value: t['@uuid'] },
          { label: 'group.tenant', value: tenantIri }
        ]
      ];
      cruds.create.link = '/users/add';
      break;

    default:
      break;
  }

  return cruds;
};
