import React from 'react';

const TextField = ({
  setter,
  field = null,
  fieldSubstitute = null,
  label = null,
  type = 'text',
  inputGroupLabel = null,
  mandatory = false,
  defaultValue = '',
  placeholder = '#same',
  readOnly = false,
  classname = 'form-textfield form-field--' + field
}) => {
  if ('#same' === placeholder) {
    placeholder = label;
  }

  return (
    <div className={classname}>
      {null !== label && (
        <label htmlFor={field} className={'form-label mt-3'}>
          {label}&nbsp;
          {mandatory && <sup className={'text-danger'}>*</sup>}
        </label>
      )}

      {null !== inputGroupLabel ? (
        <div className={'input-group'}>
          <span className={'input-group-text text-dark'} id={'ig-' + field}>
            {inputGroupLabel}
          </span>
          <input
            className={'form-control'}
            type={type}
            name={field}
            id={field}
            defaultValue={defaultValue}
            placeholder={placeholder}
            onChange={(e) => setter(fieldSubstitute ?? field, e.target.value)}
            onBlur={(e) => setter(fieldSubstitute ?? field, e.target.value)}
            aria-describedby={inputGroupLabel ? 'ig-' + field : null}
            readOnly={readOnly}
            required={mandatory}
          />
        </div>
      ) : (
        <input
          className={'form-control'}
          type={type}
          name={field}
          id={field}
          defaultValue={defaultValue}
          placeholder={placeholder}
          onChange={(e) => setter(fieldSubstitute ?? field, e.target.value)}
          onBlur={(e) => setter(fieldSubstitute ?? field, e.target.value)}
          aria-describedby={inputGroupLabel ? 'ig-' + field : null}
          readOnly={readOnly}
          required={mandatory}
        />
      )}
    </div>
  );
};

export default TextField;
