import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { generateUUID } from 'rc-select/es/util';

const EditableTextfield = ({
  fieldName,
  callback,
  defaultValue,
  actionsType = 'both', // can be 'text', 'icon' or 'both'
  placeholder = null
}) => {
  const { t } = useTranslation();
  const [isEditing, setEditing] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const id = "qe-" + fieldName + "-" + generateUUID();

  useEffect(() => {
    if (isEditing === true) {
      document.getElementById(id).focus();
    }
  }, [id, isEditing]);

  const handleKeyDown = (event) => {
    const { key } = event;
    const cancelKeys = ["Escape", "Tab"];
    const enterKey = "Enter";
    const allKeys = [...cancelKeys, enterKey];

    if (enterKey === event.key) {
      // User just pressed "Enter" so callback.
      handleValidate();
    } else {
      if (cancelKeys.indexOf(key) > -1) {
        // User just cancelled so revert to default value.
        handleCancel();
      }
    }

    // User just pressed a special key so quit edit mode.
    if (allKeys.indexOf(key) > -1) {
      setEditing(false);
    }
  };

  const handleCancel = () => {
    setValue(defaultValue);
    setEditing(false);
  }

  const handleValidate = () => {
    const data = {};
    data[fieldName] = value;

    callback({
      data,
      error: {callback: handleCancel}
    });

    setEditing(false);
  }

  return (
    <>
      {isEditing ? (
        <div
          onKeyDown={e => handleKeyDown(e)}
        >
          <input
            id={id}
            type="text"
            name={fieldName}
            className="qe-input qe-input-text border rounded w-full py-2 px-3"
            placeholder={placeholder ?? undefined}
            value={value}
            onChange={e => setValue(e.target.value)}
          />

          <span
            onClick={handleValidate}
            className={"ms-3 qe-icon-wrapper text-success"}
          >
            {('icon' === actionsType || 'both' === actionsType) && (
              <FontAwesomeIcon
                title={t('submit')}
                className={"qe-icon"}
                icon={['fas', 'check']}
              />
            )}
            {('icon' !== actionsType) && (
              <Link to={'#'} className={"qe-link"}>
                {t('submit')}
              </Link>
            )}
          </span>

          <span
            onClick={handleCancel}
            className={"ms-1 qe-icon-wrapper text-danger"}
          >
            {('icon' === actionsType || 'both' === actionsType) && (
              <FontAwesomeIcon
                title={t('cancel')}
                className={"qe-icon"}
                icon={['fas', 'reply']}
              />
            )}
            {('icon' !== actionsType) && (
              <Link to={'#'} className={"qe-link"}>
                {t('cancel')}
              </Link>
            )}
          </span>
        </div>
      ) : (
        <>
          <span className={"qe-value"}>
            {value}
          </span>

          <span
            onClick={() => setEditing(true)}
            className={"ms-3 qe-icon-wrapper text-primary"}
          >
            {('icon' === actionsType || 'both' === actionsType) && (
              <FontAwesomeIcon
                title={t('edit')}
                className={"qe-icon"}
                icon={['fas', 'terminal']}
              />
            )}
            {('icon' !== actionsType) && (
              <Link to={'#'} className={"qe-link"}>
                {t('edit')}
              </Link>
            )}
          </span>
        </>
      )}
    </>
  );
};

export default EditableTextfield;
