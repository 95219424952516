import React from 'react';
import { AutocompleteBase } from 'components';

const AutocompleteRoles = ({ setter, settings = null, selected = null }) => {
  settings = settings ?? {};
  settings['@resourceType'] = 'role';
  settings.displayLabel = false;
  settings.multiple = false;
  settings.key = 'label'
  return (
    <AutocompleteBase setter={setter} settings={settings} selected={selected} />
  );
};

export default AutocompleteRoles;
