import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EmailValidator from 'email-validator';
import { useDropzone } from 'react-dropzone';
import Cropper from 'react-cropper';
import { Row, Col, Form, Label, FormGroup, Input, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Loader } from 'components';
import {
  action,
  create,
  getMediaSrc,
  iri,
  nError,
  read,
  update
} from 'utils';
import { useUserState } from 'states';

import defaultAvatarImg from 'assets/images/avatars/defaultAvatar.png';
import apiConfig from 'config/apiConfig.json';
import cloudFile from 'assets/images/baseline-cloud_upload-24px.svg';
import appConfig from 'config/appConfig.json';

const TenantForm = ({ uuid = null, setActiveTab = null }) => {
  const { t } = useTranslation();
  const uState = useUserState();

  const [tenant, setTenant] = useState(null);
  const [tenantOriginal, setTenantOriginal] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [avatarStatus, setAvatarStatus] = useState('display');

  useEffect(() => {
    if (null !== uuid) {
      read({
        resourceType: 'tenant',
        id: uuid,
        setter: setTenant,
        success: {
          callback: (r) => {
            setTenantOriginal(r.data);
            setAvatar(getMediaSrc(r.data, 'avatar') ?? defaultAvatarImg);
          }
        }
      });
    } else {
      setAvatar(defaultAvatarImg);
    }
    // eslint-disable-next-line
  }, [uuid]);

  const sendTenantRequest = () => {
    let tenantData = {};
    if (tenant) {
      ['email', 'phone', 'website'].map(
        (key) => {
        // Only patch changed values.
        if (!tenantOriginal || tenantOriginal[key] !== tenant[key]) {
          tenantData[key] = tenant[key];
        }
        return 1;
      });

      let valid = true;
      // Ensure email.
      if (!EmailValidator.validate(tenant.email)) {
        nError({ message: t('emailError') });
        valid = false;
      }
      // Ensure mandatory fields if CREATE.
      if (!uuid && (!tenant.email)) {
        nError({ message: t('missingFields') });
        valid = false;
      }

      if (valid && Object.keys(tenantData).length > 0) {
        const params = {
          resourceType: 'tenant',
          data: tenantData,
          success: {
            callback: (res) => {
              if (uuid === res.data['@uuid']) {
                // Update current user state if we just edited his own profile.
                uState.user.merge(res.data);
                localStorage.setItem(appConfig.keys.session.user, JSON.stringify(uState.value));
              }

              // Update avatar.
              if (cropper && file) {
                updateTenantAvatar(res.data['@uuid']);
              } else {
                window.location.href = iri(res.data, 'tenant') + '/activity';
              }
            }
          },
          error: {
            callback: () => {
              // At least try to update avatar.
              if (uuid && cropper && file) {
                updateTenantAvatar(uuid);
              }
            }
          }
        };

        if (uuid) {
          // Send the update API call.
          update({ ...params, ...{ id: uuid } });
        } else {
          create(params);
        }
      } else {
        // At least try to update avatar.
        if (uuid && cropper && file) {
          updateTenantAvatar(uuid);
        }
      }
    }
  };

  const updateTenantAvatar = (id) => {
    const formData = {
      base64: cropper.getCroppedCanvas().toDataURL()
    };

    action({
      resourceType: 'tenant',
      id: id,
      action: 'updateTenantAvatar',
      data: formData,
      success: { callback: () => { window.location.href = iri(id, 'tenant') }},
      method: 'put'
    });
  };

  const updateTenantData = (attribute, value) => {
    let edited = {};
    edited[attribute] = value;
    setTenant({ ...tenant, ...edited });
  };

  const checkEmail = (value) => {
    if (!EmailValidator.validate(value)) {
      nError({ message: t('emailError') });
    }
    updateTenantData('email', value);
  };

  // Dropzone.
  // Init form state
  const [file, setFile] = useState(null);
  const [cropper, setCropper] = useState(null);

  const onDropAccepted = (files) => {
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onloadend = () => {
      setFile(reader.result);
      setAvatarStatus('cropper');
    };
  };

  const onDropRejected = (fileRejections) => {
    fileRejections[0].errors.map((e) => nError({ message: t(e.code) }));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    accept: 'image/*',
    onDropAccepted,
    onDropRejected,
    minSize: 15000,
    maxSize: apiConfig.default.maxAvatarSize * 1000000
  });

  const isUploadTooLarge = () => {
    return file ? file.size / 1000000 > apiConfig.default.maxAvatarSize : false;
  };

  const toogleAvatar = (event, value = 'display') => {
    event.preventDefault();
    setAvatarStatus(value);
    if ('display' === value) {
      setFile(null);
    }
  };

  const onCropperInit = (cropper) => {
    setCropper(cropper);
  };

  const allowedToEdit = true;
  
  return (
    <>
      {uuid && !tenantOriginal ? (
        <Loader type={'pacman'} />
      ) : (
        <Row>
          <Col className="col-12 col-md-6">
            <Form>
              <FormGroup>
                {true === allowedToEdit && (
                  <>
                    <Label className={'mt-3'} for="email">
                      {t('email')} <sup>*</sup>
                    </Label>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      defaultValue={ tenantOriginal ? tenantOriginal.email ?? '' : '' }
                      placeholder={t('email')}
                      onBlur={(e) => checkEmail(e.target.value)}
                    />
                  </>
                )}

                <Label className={'mt-3'} for="phone">
                  {t('phone')}
                </Label>
                <Input
                  name="phone"
                  id="phone"
                  defaultValue={ tenantOriginal ? tenantOriginal.phone ?? '' : '' }
                  placeholder={t('phone')}
                  onChange={(e) => updateTenantData('phone', e.target.value)}
                  readOnly={!allowedToEdit}
                />
              </FormGroup>
              <FormGroup className="mt-5">
                <Label className={'mt-1'} for="website">
                  {t('website')} <sup>*</sup>
                </Label>
                <Input
                  name="website"
                  id="website"
                  defaultValue={ tenantOriginal ? tenantOriginal.website ?? '' : '' }
                  placeholder={t('website')}
                  onChange={(e) =>
                    updateTenantData('website', e.target.value)
                  }
                />
              </FormGroup>
              <Button
                color="primary"
                className="mt-4"
                onClick={sendTenantRequest}>
                {uuid ? t('update') : t('submit')}
              </Button>
              { uuid && (
                <Button
                  color="link"
                  className="m-2 mt-4 btn-link-danger"
                  onClick={() => setActiveTab('1')}>
                  <span>{t('cancel')}</span>
                </Button>
              )}
            </Form>
          </Col>
          <Col className="col-12 col-md-6">
            <FormGroup>
              {'dropzone' === avatarStatus ? (
                <>
                  <div className="dropzone mb-3">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className="dz-message">
                        <img
                          alt="upload"
                          className="dz-teaser-img"
                          src={cloudFile}
                        />

                        <div className="dx-text">
                          {!isDragActive && t('dropzoneMessageDefault')}
                          {isDragActive && t('dropzoneMessageActive')}
                          {isUploadTooLarge() && (
                            <div className="text-danger mt-2">
                              {t('dropzoneMessageTooLarge')}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <Link onClick={(e) => toogleAvatar(e, 'display')} to={'#'}>
                    {t('cancel')}
                  </Link>
                </>
              ) : (
                <>
                  {file ? (
                    <>
                      <Cropper
                        src={file}
                        style={{ height: 400, width: '100%' }}
                        initialAspectRatio={1 / 1}
                        // guides={false}
                        onInitialized={onCropperInit}
                      />
                      <br />
                      <Link
                        onClick={(e) => toogleAvatar(e, 'display')}
                        to={'#'}>
                        {t('cancel')}
                      </Link>
                    </>
                  ) : (
                    <>
                      <img
                        className={'mb-3 img-fluid rounded me-3 shadow-sm'}
                        alt="user-avatar"
                        src={avatar}
                        width="256"
                      />
                      <br />
                      <Link
                        onClick={(e) => toogleAvatar(e, 'dropzone')}
                        to={'#'}>
                        {t('changeAvatar')}
                      </Link>
                    </>
                  )}
                </>
              )}
            </FormGroup>
          </Col>
        </Row>
      )}
    </>
  );
};

export default TenantForm;
