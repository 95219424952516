import React from 'react';
import { useTranslation } from 'react-i18next';
import appConfig from 'config/appConfig.json';
import { QuotaType1, QuotaType2, QuotaType3, QuotaType4 } from 'components';

const QuotaTypeWrapper = ({ entity = null, quotaTypeType = null, optionalClass='card-box-half-rounded-bottom', icon = null, color = null }) => {
  const { t } = useTranslation();
  const type = entity ? entity.type ?? 'user' : 'user';
  const titleKey = type ? type !== 'users' ? type !== 'digitalAsset' ? type : 'digitalAsset_plural' : 'user_plural' : 'user_plural';
  const bgColorHead = 'color-swatch--bg bg-' + appConfig.colors[type].name;

  return (
    <>
      <div className="color-swatch mb-n5">
        <div className={bgColorHead}>
          <div className="text-center text-white font-size-lg pt-1">{t(titleKey)}</div>
        </div>
      </div>
      {null !== quotaTypeType && (
        ('progress' === quotaTypeType ? (
          <QuotaType1 entity={entity} optionalClass={optionalClass} icon={icon} color={color} />
        ) : ('jaugeBig' === quotaTypeType ? (
          <QuotaType2 entity={entity} optionalClass={optionalClass} />
        ) : ('jauge' === quotaTypeType ? (
          <QuotaType3 entity={entity} optionalClass={optionalClass} />
        ) : ('chart' === quotaTypeType && (
          <QuotaType4 entity={entity} optionalClass={optionalClass} />
        )))))
      )}
    </>
  );
};

export default QuotaTypeWrapper;
